var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./malli.registry.js");require("./malli.util.js");
'use strict';var KF,LF,MF,Zca,NF,PF,QF;
$CLJS.JF=function(a,b){return function(){function c(l,m,t){l=null==l?b:l;return a.j?a.j(l,m,t):a.call(null,l,m,t)}function d(l,m){l=null==l?b:l;return a.g?a.g(l,m):a.call(null,l,m)}function e(l){l=null==l?b:l;return a.h?a.h(l):a.call(null,l)}var f=null,k=function(){function l(t,u,v,x){var A=null;if(3<arguments.length){A=0;for(var D=Array(arguments.length-3);A<D.length;)D[A]=arguments[A+3],++A;A=new $CLJS.y(D,0,null)}return m.call(this,t,u,v,A)}function m(t,u,v,x){return $CLJS.P.N(a,null==t?b:t,u,
v,x)}l.A=3;l.B=function(t){var u=$CLJS.B(t);t=$CLJS.C(t);var v=$CLJS.B(t);t=$CLJS.C(t);var x=$CLJS.B(t);t=$CLJS.Kc(t);return m(u,v,x,t)};l.l=m;return l}();f=function(l,m,t,u){switch(arguments.length){case 1:return e.call(this,l);case 2:return d.call(this,l,m);case 3:return c.call(this,l,m,t);default:var v=null;if(3<arguments.length){v=0;for(var x=Array(arguments.length-3);v<x.length;)x[v]=arguments[v+3],++v;v=new $CLJS.y(x,0,null)}return k.l(l,m,t,v)}throw Error("Invalid arity: "+arguments.length);
};f.A=3;f.B=k.B;f.h=e;f.g=d;f.j=c;f.l=k.l;return f}()};KF=function(a,b){this.db=a;this.vh=b;this.C=393216;this.I=0};LF=function(a){for(var b=null;;){var c;a=$CLJS.po.g(a,b);a=c=$CLJS.In(a)?$CLJS.Cn(a):a;if(!$CLJS.In(c))return a}};MF=function(a){$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return!($CLJS.wd(a)&&!0===$CLJS.Ft.h(a))};
Zca=function(a,b,c){var d=LF($CLJS.po.g(a,c));return $CLJS.vq($CLJS.tn(d),$CLJS.qn(d),function(){var e=$CLJS.sn(d);return b.h?b.h(e):b.call(null,e)}(),function(){var e=$CLJS.rn($CLJS.po.g(d,null));return $CLJS.n(e)?e:c}())};NF=function(a){return function(b,c,d){var e=$CLJS.z(c);b=$CLJS.B(e);e=$CLJS.C(e);c=$CLJS.Jk.g(function(f){return $CLJS.po.g(f,d)},c);return new $CLJS.Q(null,3,5,$CLJS.R,[c,$CLJS.Jk.g($CLJS.du,c),$CLJS.ab(function(f,k){return a.j?a.j(f,k,d):a.call(null,f,k,d)},b,e)],null)}};
$CLJS.OF=function(a,b,c,d,e,f,k,l,m,t,u,v,x,A,D,J,M,V,Z){this.form=a;this.options=b;this.Me=c;this.forms=d;this.U=e;this.Li=f;this.Xd=k;this.ya=l;this.children=m;this.min=t;this.Eb=u;this.Rb=v;this.parent=x;this.qe=A;this.type=D;this.cache=J;this.max=M;this.Ue=V;this.bi=Z;this.C=393216;this.I=0};PF=function(a,b,c,d,e,f,k,l,m){this.Ue=a;this.qe=b;this.type=c;this.min=d;this.max=e;this.Xd=f;this.Eb=k;this.Rb=l;this.Me=m;this.C=393216;this.I=0};
QF=function(a){var b=$CLJS.O(a),c=$CLJS.I.g(b,$CLJS.hj),d=$CLJS.I.g(b,$CLJS.Qn),e=$CLJS.I.g(b,$CLJS.dk),f=$CLJS.I.g(b,$CLJS.zu),k=$CLJS.I.g(b,$CLJS.Xp),l=$CLJS.I.g(b,$CLJS.it);return new PF(a,b,c,d,e,f,k,l,new $CLJS.h(null,1,[$CLJS.hj,$CLJS.Cp],null))};$CLJS.Y=function(a,b){$CLJS.Gh.v(RF,$CLJS.S,a,b);$CLJS.Pe($CLJS.SF,$CLJS.N);return null};KF.prototype.P=function(a,b){return new KF(this.db,b)};KF.prototype.O=function(){return this.vh};KF.prototype.rd=$CLJS.r;
KF.prototype.bd=function(a,b){return $CLJS.dn($CLJS.fn($CLJS.q(this.db)),b)};var TF=function TF(a){switch(arguments.length){case 2:return TF.g(arguments[0],arguments[1]);case 3:return TF.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};TF.g=function(a,b){return TF.j(a,b,null)};
TF.j=function(a,b,c){function d(v,x){return $CLJS.F.g($CLJS.ck,v)?new $CLJS.Q(null,2,5,$CLJS.R,[null,x],null):$CLJS.Ye.g(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.Kq.h(x)],null),$CLJS.Mq.h(x))}function e(v,x){return $CLJS.n($CLJS.n(v)?x:v)?$CLJS.Zk.l($CLJS.G([v,x])):$CLJS.n(v)?v:x}a=$CLJS.n(a)?LF($CLJS.po.g(a,c)):null;b=$CLJS.n(b)?LF($CLJS.po.g(b,c)):null;var f=$CLJS.n(a)?$CLJS.Wn.h(a):null,k=$CLJS.n(b)?$CLJS.Wn.h(b):null,l=$CLJS.O(c),m=$CLJS.I.j(l,$CLJS.uu,function(v,x){return x}),t=$CLJS.I.j(l,$CLJS.xu,
function(v,x){return x});if(null==a)return b;if(null==b)return a;if($CLJS.Sa(function(){var v=new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.rt,null,$CLJS.ck,null],null),null);v=v.h?v.h(f):v.call(null,f);return $CLJS.n(v)?(v=new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.rt,null,$CLJS.ck,null],null),null),v.h?v.h(k):v.call(null,k)):v}()))return m.j?m.j(a,b,c):m.call(null,a,b,c);if($CLJS.F.g(f,$CLJS.ck)&&$CLJS.F.g(k,$CLJS.ck)){l=e($CLJS.qn(a),$CLJS.qn(b));var u=$CLJS.Oe($CLJS.Ug);a=$CLJS.ab(function(v,x){var A=
$CLJS.H(x,0,null);if($CLJS.n(function(){var D=$CLJS.q(u);return D.h?D.h(A):D.call(null,A)}()))return $CLJS.ab(function(D,J){var M=$CLJS.H(J,0,null),V=$CLJS.$d.g;if($CLJS.F.g(M,A)){M=$CLJS.H(J,0,null);var Z=$CLJS.H(J,1,null),fa=$CLJS.H(J,2,null);$CLJS.H(x,0,null);var Da=$CLJS.H(x,1,null),Ha=$CLJS.H(x,2,null);J=MF(J);var mb=MF(x);J=t.g?t.g(J,mb):t.call(null,J,mb);Z=$CLJS.Zk.l($CLJS.G([Z,Da]));Ha=new $CLJS.Q(null,3,5,$CLJS.R,[M,$CLJS.S.j(Z,$CLJS.Ft,$CLJS.Sa(J)),TF.j?TF.j(fa,Ha,c):TF.call(null,fa,Ha,
c)],null);M=$CLJS.H(Ha,0,null);fa=$CLJS.H(Ha,1,null);Ha=$CLJS.H(Ha,2,null);M=$CLJS.Sa(Ha)?new $CLJS.Q(null,2,5,$CLJS.R,[M,fa],null):$CLJS.n($CLJS.n(fa)?!1===$CLJS.Ft.h(fa)&&$CLJS.F.g(1,$CLJS.E(fa)):fa)?new $CLJS.Q(null,2,5,$CLJS.R,[M,Ha],null):$CLJS.Sa($CLJS.z(fa))?new $CLJS.Q(null,2,5,$CLJS.R,[M,Ha],null):!1===$CLJS.Ft.h(fa)?new $CLJS.Q(null,3,5,$CLJS.R,[M,$CLJS.zk.g(fa,$CLJS.Ft),Ha],null):new $CLJS.Q(null,3,5,$CLJS.R,[M,fa,Ha],null)}else M=J;return V.call($CLJS.$d,D,M)},$CLJS.tf,v);$CLJS.Gh.j(u,
$CLJS.$d,A);return $CLJS.$d.g(v,x)},$CLJS.tf,$CLJS.Wf.g($CLJS.sn(a),$CLJS.sn(b)));return $CLJS.vq($CLJS.ck,l,a,c)}return function(v,x){var A=$CLJS.z(v);v=$CLJS.B(A);var D=$CLJS.C(A);A=$CLJS.B(D);D=$CLJS.C(D);var J=$CLJS.z(x);x=$CLJS.B(J);var M=$CLJS.C(J);J=$CLJS.B(M);M=$CLJS.C(M);return $CLJS.vq($CLJS.rt,e(v,x),$CLJS.Ye.l(new $CLJS.Q(null,1,5,$CLJS.R,[TF.j(A,J,c)],null),D,$CLJS.G([M])),c)}(d(f,a),d(k,b))};TF.A=3;
var UF=function UF(a){switch(arguments.length){case 2:return UF.g(arguments[0],arguments[1]);case 3:return UF.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};UF.g=function(a,b){return UF.j(a,b,null)};
UF.j=function(a,b,c){return TF.j(a,b,$CLJS.Kk.j($CLJS.Kk.j(c,$CLJS.uu,$CLJS.JF($CLJS.Rd,function(d,e,f){var k=$CLJS.F.g($CLJS.du.g(d,null),$CLJS.du.g(e,null));return $CLJS.n(k)?d:$CLJS.po.g(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,d,e],null),f)})),$CLJS.xu,$CLJS.JF($CLJS.Rd,function(d,e){return $CLJS.n(d)?e:d})))};UF.A=3;
var VF=function VF(a){switch(arguments.length){case 2:return VF.g(arguments[0],arguments[1]);case 3:return VF.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};VF.g=function(a,b){return VF.j(a,b,null)};VF.j=function(a,b,c){var d=$CLJS.Yg(b);return Zca(a,function(e){return $CLJS.bf(function(f){f=$CLJS.H(f,0,null);return d.h?d.h(f):d.call(null,f)},e)},c)};VF.A=3;$CLJS.g=$CLJS.OF.prototype;
$CLJS.g.P=function(a,b){return new $CLJS.OF(this.form,this.options,this.Me,this.forms,this.U,this.Li,this.Xd,this.ya,this.children,this.min,this.Eb,this.Rb,this.parent,this.qe,this.type,this.cache,this.max,this.Ue,b)};$CLJS.g.O=function(){return this.bi};$CLJS.g.Ya=$CLJS.r;$CLJS.g.xa=function(){return $CLJS.nn(this.ya)};$CLJS.g.La=function(){return this.options};$CLJS.g.Ta=function(a,b,c,d){return $CLJS.Yo(this,new $CLJS.Q(null,1,5,$CLJS.R,[this.ya],null),b,c,d)};$CLJS.g.$a=function(){return this.U};
$CLJS.g.ta=function(){return this.children};$CLJS.g.Sa=function(){return $CLJS.q(this.form)};$CLJS.g.Ra=function(a,b){return $CLJS.on(this.ya,b)};$CLJS.g.Za=function(){return this.parent};$CLJS.g.Wa=$CLJS.r;$CLJS.g.ia=$CLJS.ta(5);$CLJS.g.ie=$CLJS.r;$CLJS.g.Kd=function(){return this.ya};$CLJS.g=PF.prototype;$CLJS.g.P=function(a,b){return new PF(this.Ue,this.qe,this.type,this.min,this.max,this.Xd,this.Eb,this.Rb,b)};$CLJS.g.O=function(){return this.Me};$CLJS.g.Xa=$CLJS.r;$CLJS.g.Pa=function(){return this.type};
$CLJS.g.Qa=function(){return this.Eb};$CLJS.g.Oa=function(a,b,c,d){var e=this;$CLJS.Rn(e.type,b,c,e.min,e.max);a=function(){var m=$CLJS.uf(c);return e.Rb.j?e.Rb.j(b,m,d):e.Rb.call(null,b,m,d)}();var f=$CLJS.H(a,0,null),k=$CLJS.H(a,1,null),l=$CLJS.H(a,2,null);return new $CLJS.OF(new $CLJS.Bh(function(){return $CLJS.vo(e.type,b,k,d)}),d,e.Me,k,b,a,e.Xd,l,f,e.min,e.Eb,e.Rb,this,e.qe,e.type,$CLJS.uo(),e.max,e.Ue,new $CLJS.h(null,1,[$CLJS.hj,$CLJS.aq],null))};
$CLJS.WF=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit","metabase.lib.schema.temporal-bucketing/unit",1808010880);var RF;$CLJS.SF=$CLJS.Oe($CLJS.N);
RF=$CLJS.Oe($CLJS.Zk.l($CLJS.G([$CLJS.Bt(),new $CLJS.h(null,3,[$CLJS.vu,QF(new $CLJS.h(null,2,[$CLJS.hj,$CLJS.vu,$CLJS.it,NF(TF)],null)),$CLJS.tu,QF(new $CLJS.h(null,2,[$CLJS.hj,$CLJS.tu,$CLJS.it,NF(UF)],null)),$CLJS.Au,QF(new $CLJS.h(null,5,[$CLJS.hj,$CLJS.Au,$CLJS.zu,1,$CLJS.Qn,2,$CLJS.dk,2,$CLJS.it,function(a,b,c){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Kk.j(b,0,function(d){return $CLJS.po.g(d,c)}),$CLJS.Kk.j(b,0,function(d){return $CLJS.du.g(d,c)}),$CLJS.P.g(VF,$CLJS.$d.g(b,c))],null)}],null))],
null)])));$CLJS.Pe($CLJS.nl,$CLJS.fn(new KF(RF,$CLJS.N)));