var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var Vka,k1,Wka,m1,n1,o1,F1,Xka,Yka,J1,y1,L1,Zka,A1;Vka=function(a){return $CLJS.z(a)?a.normalize("NFKD").replace(RegExp("[̀-ͯ]","gu"),""):null};k1=function(a,b){return 128>a.charCodeAt(0)?$CLJS.Gd($CLJS.eca,a)?a:"_":$CLJS.n(b)?encodeURIComponent(a):a};
Wka=function(a){var b=$CLJS.O(new $CLJS.h(null,1,[$CLJS.kD,!0],null)),c=$CLJS.I.g(b,$CLJS.cca),d=$CLJS.I.g(b,$CLJS.kD);return $CLJS.z(a)?(b=$CLJS.$W(function(){return function k(f){return new $CLJS.le(null,function(){for(;;){var l=$CLJS.z(f);if(l){if($CLJS.zd(l)){var m=$CLJS.kc(l),t=$CLJS.E(m),u=$CLJS.oe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v);x=k1(x,$CLJS.Sa(d));u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.re($CLJS.te(u),k($CLJS.lc(l))):$CLJS.re($CLJS.te(u),null)}u=$CLJS.B(l);return $CLJS.ce(k1(u,
$CLJS.Sa(d)),k($CLJS.Kc(l)))}return null}},null,null)}(Vka($CLJS.QC(a)))}()),$CLJS.n(c)?$CLJS.$W($CLJS.Se(c,b)):b):null};$CLJS.l1=function(a){return $CLJS.IQ.h($CLJS.B($CLJS.UM.h(a)))};m1=function(a,b){if(null!=a&&null!=a.ug)a=a.ug(a,b);else{var c=m1[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=m1._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.Ya("MetadataProvider.table",a);}return a};
n1=function(a,b){if(null!=a&&null!=a.mg)a=a.mg(a,b);else{var c=n1[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=n1._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.Ya("MetadataProvider.card",a);}return a};o1=function(a,b){if(null!=a&&null!=a.pg)a=a.pg(a,b);else{var c=o1[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=o1._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.Ya("MetadataProvider.fields",a);}return a};
$CLJS.p1=function(a){return $CLJS.X_(a)?a:$CLJS.ZF.h(a)};$CLJS.q1=function(a,b){return m1($CLJS.p1(a),b)};$CLJS.r1=function(a,b){return o1($CLJS.p1(a),b)};$CLJS.s1=function(a,b){return n1($CLJS.p1(a),b)};
F1=function(a,b,c){var d=$CLJS.t1.j(a,b,c);return $CLJS.Zk.l($CLJS.G([$CLJS.Wl(d,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.T,$CLJS.cG,$CLJS.Pj],null)),function(){var e=$CLJS.u1.v(a,b,c,$CLJS.v1);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.w1,e],null):null}(),function(){var e=$CLJS.rC($CLJS.zi,$CLJS.iD)(d);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.zi,e],null):null}(),function(){var e=$CLJS.GM.h(d);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.qY,$CLJS.x1.j(a,b,$CLJS.Ed(e)?$CLJS.q1(a,e):"string"===typeof e?
$CLJS.s1(a,$CLJS.jY(e)):null)],null):null}(),function(){var e=$CLJS.vM.h(d);return $CLJS.n(e)?new $CLJS.h(null,4,[y1,$CLJS.F.g(e,$CLJS.Z_),$CLJS.z1,$CLJS.F.g(e,$CLJS.h0),A1,$CLJS.F.g(e,$CLJS.e0),$CLJS.B1,$CLJS.F.g(e,$CLJS.g0)],null):null}(),function(){var e=$CLJS.m0.h(d);return null==e?null:new $CLJS.h(null,1,[$CLJS.C1,e],null)}(),$CLJS.Wl(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.D1,$CLJS.E1],null))]))};$CLJS.G1=new $CLJS.K(null,"direction","direction",-633359395);
$CLJS.B1=new $CLJS.K(null,"is-implicitly-joinable","is-implicitly-joinable",2054926844);$CLJS.D1=new $CLJS.K(null,"breakout-position","breakout-position",-760153191);$CLJS.H1=new $CLJS.K(null,"include-implicitly-joinable?","include-implicitly-joinable?",-772701866);$CLJS.C1=new $CLJS.K(null,"selected","selected",574897764);Xka=new $CLJS.K(null,"is-order-by-column","is-order-by-column",1320802549);
Yka=new $CLJS.w("metabase.lib.metadata.calculation","display-name-method","metabase.lib.metadata.calculation/display-name-method",37675198,null);$CLJS.I1=new $CLJS.K("metabase.lib.metadata.calculation","source-field-id","metabase.lib.metadata.calculation/source-field-id",352667413);J1=new $CLJS.K("metabase.lib.metadata.calculation","display-info","metabase.lib.metadata.calculation/display-info",-2072755534);$CLJS.K1=new $CLJS.K(null,"include-joined?","include-joined?",-410632824);
y1=new $CLJS.K(null,"is-from-previous-stage","is-from-previous-stage",956780376);L1=new $CLJS.K(null,"is-source-table","is-source-table",1559075056);$CLJS.M1=new $CLJS.K(null,"unique-name-fn","unique-name-fn",-755402870);$CLJS.z1=new $CLJS.K(null,"is-from-join","is-from-join",-1107917905);$CLJS.N1=new $CLJS.K(null,"requires-column","requires-column",934105295);$CLJS.E1=new $CLJS.K(null,"order-by-position","order-by-position",-1307229997);
Zka=new $CLJS.K(null,"is-breakout-column","is-breakout-column",46784229);$CLJS.w1=new $CLJS.K(null,"long-display-name","long-display-name",1550784464);$CLJS.O1=new $CLJS.K(null,"include-implicitly-joinable-for-source-card?","include-implicitly-joinable-for-source-card?",1240062794);$CLJS.v1=new $CLJS.K(null,"long","long",-171452093);$CLJS.P1=new $CLJS.K(null,"short-name","short-name",-1767085022);$CLJS.Q1=new $CLJS.K("lib","source-uuid","lib/source-uuid",1828897581);
$CLJS.R1=new $CLJS.K(null,"include-expressions?","include-expressions?",-1817639288);A1=new $CLJS.K(null,"is-calculated","is-calculated",-86687269);$CLJS.S1=function(){var a=$CLJS.Oe($CLJS.N),b=$CLJS.Oe($CLJS.N),c=$CLJS.Oe($CLJS.N),d=$CLJS.Oe($CLJS.N),e=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.cj,$CLJS.XD],null),$CLJS.cj,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));return new $CLJS.Xh($CLJS.xh.g("metabase.lib.metadata.calculation","display-name-method"),function(f,k,l){return $CLJS.VD(l)},e,a,b,c,d)}();
$CLJS.T1=function(){var a=$CLJS.Oe($CLJS.N),b=$CLJS.Oe($CLJS.N),c=$CLJS.Oe($CLJS.N),d=$CLJS.Oe($CLJS.N),e=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.cj,$CLJS.XD],null),$CLJS.cj,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));return new $CLJS.Xh($CLJS.xh.g("metabase.lib.metadata.calculation","column-name-method"),function(f,k,l){return $CLJS.VD(l)},e,a,b,c,d)}();
$CLJS.u1=function(){function a(f,k,l,m){var t=$CLJS.cG.h($CLJS.vG(l));if($CLJS.n(t))return t;try{return $CLJS.S1.v(f,k,l,m)}catch(u){if(u instanceof Error)throw k=u,$CLJS.$h($CLJS.YF("Error calculating display name for {0}: {1}",$CLJS.G([$CLJS.Eh.l($CLJS.G([l])),$CLJS.WW(k)])),new $CLJS.h(null,2,[$CLJS.kO,f,$CLJS.oG,l],null),k);throw u;}}function b(f,k,l){return e.v?e.v(f,k,l,$CLJS.Wh):e.call(null,f,k,l,$CLJS.Wh)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){return e.g?
e.g(f,f):e.call(null,f,f)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();
$CLJS.U1=function(){function a(d,e,f){var k=$CLJS.T.h($CLJS.vG(f));if($CLJS.n(k))return k;try{return $CLJS.T1.j(d,e,f)}catch(l){if(l instanceof Error)throw k=l,$CLJS.$h($CLJS.YF("Error calculating column name for {0}: {1}",$CLJS.G([$CLJS.Eh.l($CLJS.G([f])),$CLJS.WW(k)])),new $CLJS.h(null,3,[$CLJS.oG,f,$CLJS.kO,d,$CLJS.oY,e],null),k);throw l;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,
d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.S1.m(null,$CLJS.Wh,function(a,b,c){a=$CLJS.BC($CLJS.xA);$CLJS.n($CLJS.AC("metabase.lib.metadata.calculation",a))&&$CLJS.zC("metabase.lib.metadata.calculation",a,$CLJS.YX("Don't know how to calculate display name for %s. Add an impl for %s for %s",$CLJS.G([$CLJS.Eh.l($CLJS.G([c])),Yka,$CLJS.VD(c)])),null);return $CLJS.yd(c)&&$CLJS.B(c)instanceof $CLJS.K?$CLJS.Zg($CLJS.B(c)):$CLJS.Eh.l($CLJS.G([c]))});
$CLJS.T1.m(null,$CLJS.Wh,function(a,b,c){return Wka($CLJS.vC($CLJS.u1.j(a,b,c),/[\(\)]/,""))});$CLJS.V1=function(){var a=$CLJS.Oe($CLJS.N),b=$CLJS.Oe($CLJS.N),c=$CLJS.Oe($CLJS.N),d=$CLJS.Oe($CLJS.N),e=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.cj,$CLJS.XD],null),$CLJS.cj,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));return new $CLJS.Xh($CLJS.xh.g("metabase.lib.metadata.calculation","describe-top-level-key-method"),function(f,k,l){return l},e,a,b,c,d)}();
$CLJS.$ka=function(){function a(d,e,f){return $CLJS.V1.j(d,e,$CLJS.rh.h(f))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.W1=function(){var a=$CLJS.Oe($CLJS.N),b=$CLJS.Oe($CLJS.N),c=$CLJS.Oe($CLJS.N),d=$CLJS.Oe($CLJS.N),e=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.cj,$CLJS.XD],null),$CLJS.cj,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));return new $CLJS.Xh($CLJS.xh.g("metabase.lib.metadata.calculation","type-of-method"),function(f,k,l){return $CLJS.VD(l)},e,a,b,c,d)}();
$CLJS.X1=function(){function a(d,e,f){var k=$CLJS.vG(f);k=$CLJS.O(k);var l=$CLJS.I.g(k,$CLJS.dI),m=$CLJS.zi.h(k);if($CLJS.n(m))return m;l=$CLJS.n($CLJS.n(l)?$CLJS.Gd($CLJS.gJ,l):l)?$CLJS.rj:null;if($CLJS.n(l))return l;k=$CLJS.iD.h(k);if($CLJS.n(k))return k;d=$CLJS.W1.j(d,e,f);return $CLJS.iE(d,$CLJS.vj)?d:$CLJS.vj}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.W1.m(null,$CLJS.Wh,function(a,b,c){return $CLJS.CG(c)});$CLJS.W1.m(null,$CLJS.TG,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);return $CLJS.X1.j(a,b,c)});$CLJS.W1.m(null,$CLJS.DK,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var d=$CLJS.H(c,2,null);return"string"===typeof d?$CLJS.CG(c):$CLJS.X1.j(a,b,d)});
$CLJS.Y1=function(){var a=$CLJS.Oe($CLJS.N),b=$CLJS.Oe($CLJS.N),c=$CLJS.Oe($CLJS.N),d=$CLJS.Oe($CLJS.N),e=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.cj,$CLJS.XD],null),$CLJS.cj,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));return new $CLJS.Xh($CLJS.xh.g("metabase.lib.metadata.calculation","metadata-method"),function(f,k,l){return $CLJS.VD(l)},e,a,b,c,d)}();
$CLJS.Y1.m(null,$CLJS.Wh,function(a,b,c){try{return new $CLJS.h(null,4,[$CLJS.ov,$CLJS.tM,$CLJS.iD,$CLJS.X1.j(a,b,c),$CLJS.T,$CLJS.U1.j(a,b,c),$CLJS.cG,$CLJS.u1.j(a,b,c)],null)}catch(e){if(e instanceof Error){var d=e;throw $CLJS.$h($CLJS.YF("Error calculating metadata for {0}: {1}",$CLJS.G([$CLJS.Eh.l($CLJS.G([$CLJS.VD(c)])),$CLJS.WW(d)])),new $CLJS.h(null,3,[$CLJS.kO,a,$CLJS.oY,b,$CLJS.oG,c],null),d);}throw e;}});
$CLJS.t1=function(){function a(e,f,k){return $CLJS.Y1.j(e,f,k)}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return d.j?d.j(e,-1,e):d.call(null,e,-1,e)}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();
$CLJS.Z1=function(){var a=$CLJS.Oe($CLJS.N),b=$CLJS.Oe($CLJS.N),c=$CLJS.Oe($CLJS.N),d=$CLJS.Oe($CLJS.N),e=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.cj,$CLJS.XD],null),$CLJS.cj,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));return new $CLJS.Xh($CLJS.xh.g("metabase.lib.metadata.calculation","display-info-method"),function(f,k,l){return $CLJS.VD(l)},e,a,b,c,d)}();
$CLJS.Y(J1,new $CLJS.Q(null,19,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cG,$CLJS.Tj],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.w1,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.Tj],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qY,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,J1],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[y1,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,$CLJS.zt],null)],
null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.z1,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,$CLJS.zt],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[A1,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,$CLJS.zt],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.B1,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,$CLJS.zt],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[L1,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.kt,$CLJS.zt],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Zka,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,$CLJS.zt],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Xka,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,$CLJS.zt],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.Tj],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.IH,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.Tj],null),
new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Tu,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.Tj],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.P1,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.Tj],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.N1,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.zt],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.C1,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.zt],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wh,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.zt],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.G1,
new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,$CLJS.VH,$CLJS.OH],null)],null)],null));
$CLJS.x1=function(){function a(d,e,f){try{return $CLJS.Z1.j(d,e,f)}catch(l){if(l instanceof Error){var k=l;throw $CLJS.$h($CLJS.YF("Error calculating display info for {0}: {1}",$CLJS.G([$CLJS.VD(f),$CLJS.WW(k)])),new $CLJS.h(null,3,[$CLJS.kO,d,$CLJS.oY,e,$CLJS.oG,f],null),k);}throw l;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();$CLJS.Z1.m(null,$CLJS.Wh,function(a,b,c){return F1(a,b,c)});$CLJS.Z1.m(null,$CLJS.AM,function(a,b,c){return $CLJS.Zk.l($CLJS.G([F1(a,b,c),new $CLJS.h(null,1,[L1,$CLJS.F.g($CLJS.l1(a),$CLJS.Ti.h(c))],null)]))});
$CLJS.$1=function(){var a=$CLJS.Oe($CLJS.N),b=$CLJS.Oe($CLJS.N),c=$CLJS.Oe($CLJS.N),d=$CLJS.Oe($CLJS.N),e=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.cj,$CLJS.XD],null),$CLJS.cj,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));return new $CLJS.Xh($CLJS.xh.g("metabase.lib.metadata.calculation","returned-columns-method"),function(f,k,l){return $CLJS.VD(l)},e,a,b,c,d)}();$CLJS.$1.m(null,$CLJS.lD,function(){return $CLJS.tf});
$CLJS.a2=function(){function a(f,k,l,m){m=$CLJS.Zk.l($CLJS.G([new $CLJS.h(null,1,[$CLJS.M1,$CLJS.lY()],null),m]));return $CLJS.$1.v(f,k,l,m)}function b(f,k,l){return e.v?e.v(f,k,l,null):e.call(null,f,k,l,null)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){var k=$CLJS.iY(f,-1);return e.g?e.g(f,k):e.call(null,f,k)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,
f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();$CLJS.b2=function(){var a=$CLJS.Oe($CLJS.N),b=$CLJS.Oe($CLJS.N),c=$CLJS.Oe($CLJS.N),d=$CLJS.Oe($CLJS.N),e=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.cj,$CLJS.XD],null),$CLJS.cj,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));return new $CLJS.Xh($CLJS.xh.g("metabase.lib.metadata.calculation","visible-columns-method"),function(f,k,l){return $CLJS.VD(l)},e,a,b,c,d)}();$CLJS.b2.m(null,$CLJS.lD,function(){return $CLJS.tf});
$CLJS.b2.m(null,$CLJS.Wh,function(a,b,c,d){return $CLJS.$1.v(a,b,c,d)});
$CLJS.c2=function(){function a(f,k,l,m){m=$CLJS.Zk.l($CLJS.G([$CLJS.Zk.l($CLJS.G([new $CLJS.h(null,1,[$CLJS.M1,$CLJS.lY()],null),new $CLJS.h(null,4,[$CLJS.K1,!0,$CLJS.R1,!0,$CLJS.H1,!0,$CLJS.O1,!0],null)])),m]));return $CLJS.b2.v(f,k,l,m)}function b(f,k,l){return e.v?e.v(f,k,l,null):e.call(null,f,k,l,null)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){var k=$CLJS.iY(f,-1);return e.g?e.g(f,k):e.call(null,f,k)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,
f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();module.exports={display_name:$CLJS.u1};