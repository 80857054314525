var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.types.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var bH,kH,hH,hda;bH=function(a){if(null!=a&&null!=a.ia)a=a.cache;else{var b=bH[$CLJS.va(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=bH._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.Ya("Cached.-cache",a);}return a};
$CLJS.cH=function(a,b,c){if(null!=a&&$CLJS.r===a.Wa){var d=bH(a);var e=$CLJS.q(d);e=e.h?e.h(b):e.call(null,b);if($CLJS.n(e))return e;a=$CLJS.Gh.v(d,$CLJS.S,b,c.h?c.h(a):c.call(null,a));return a.h?a.h(b):a.call(null,b)}return c.h?c.h(a):c.call(null,a)};$CLJS.eH=function(a,b,c){a=$CLJS.dH.g(a,c);return a.h?a.h(b):a.call(null,b)};$CLJS.fH=function(a,b){return $CLJS.eH(a,b,null)};$CLJS.gH=function(a,b){$CLJS.Gh.v($CLJS.XD,$CLJS.WC,a,b)};$CLJS.iH=function(a,b){a=hH(a,b);return $CLJS.n(a)?a:$CLJS.vj};
kH=function(){return $CLJS.Wf.j(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qt,new $CLJS.h(null,2,[$CLJS.Ii,$CLJS.B,$CLJS.yu,function(a){a=$CLJS.O(a);a=$CLJS.I.g(a,$CLJS.yj);return $CLJS.yd(a)?["Invalid ",$CLJS.Eh.l($CLJS.G([$CLJS.B(a)]))," clause: ",$CLJS.Eh.l($CLJS.G([a]))].join(""):"not an MBQL clause"}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ro,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,1,[$CLJS.wu,"not a known MBQL clause"],null),$CLJS.Le(!1)],null)],null)],null),$CLJS.Ze.h(function(a){return new $CLJS.Q(null,
2,5,$CLJS.R,[a,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.rh.g("mbql.clause",$CLJS.Zg(a))],null)],null)}),$CLJS.q(jH))};$CLJS.nH=function(a,b){$CLJS.P.v(lH,mH,a,b)};$CLJS.pH=function(a,b){$CLJS.P.v(lH,oH,a,b)};$CLJS.qp.prototype.ia=$CLJS.ua(26,function(){return this.cache});$CLJS.sp.prototype.ia=$CLJS.ua(25,function(){return this.cache});$CLJS.up.prototype.ia=$CLJS.ua(24,function(){return this.cache});$CLJS.wp.prototype.ia=$CLJS.ua(23,function(){return this.cache});
$CLJS.yp.prototype.ia=$CLJS.ua(22,function(){return this.cache});$CLJS.Ap.prototype.ia=$CLJS.ua(21,function(){return this.cache});$CLJS.Dp.prototype.ia=$CLJS.ua(20,function(){return this.cache});$CLJS.Fp.prototype.ia=$CLJS.ua(19,function(){return this.cache});$CLJS.Hp.prototype.ia=$CLJS.ua(18,function(){return this.cache});$CLJS.Jp.prototype.ia=$CLJS.ua(17,function(){return this.cache});$CLJS.Lp.prototype.ia=$CLJS.ua(16,function(){return this.cache});$CLJS.Np.prototype.ia=$CLJS.ua(15,function(){return this.cache});
$CLJS.Pp.prototype.ia=$CLJS.ua(14,function(){return this.cache});$CLJS.Rp.prototype.ia=$CLJS.ua(13,function(){return this.cache});$CLJS.Tp.prototype.ia=$CLJS.ua(12,function(){return this.cache});$CLJS.Vp.prototype.ia=$CLJS.ua(11,function(){return this.cache});$CLJS.Yp.prototype.ia=$CLJS.ua(10,function(){return this.cache});$CLJS.cq.prototype.ia=$CLJS.ua(9,function(){return this.cache});$CLJS.eq.prototype.ia=$CLJS.ua(8,function(){return this.cache});$CLJS.hq.prototype.ia=$CLJS.ua(7,function(){return this.cache});
$CLJS.rq.prototype.ia=$CLJS.ua(6,function(){return this.cache});$CLJS.OF.prototype.ia=$CLJS.ua(5,function(){return this.cache});$CLJS.dH=function dH(a){switch(arguments.length){case 1:return dH.h(arguments[0]);case 2:return dH.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.dH.h=function(a){return $CLJS.dH.g(a,null)};$CLJS.dH.g=function(a,b){return $CLJS.cH($CLJS.po.g(a,b),$CLJS.pk,$CLJS.nn)};$CLJS.dH.A=2;
hH=function hH(a,b){return $CLJS.F.g(a,$CLJS.vj)?null:$CLJS.F.g(b,$CLJS.vj)?null:$CLJS.iE(a,b)?b:$CLJS.iE(b,a)?a:$CLJS.Je(function(d){return $CLJS.Je(function(e){return $CLJS.F.g(new $CLJS.Q(null,2,5,$CLJS.R,[d,e],null),new $CLJS.Q(null,2,5,$CLJS.R,[a,b],null))?null:hH.g?hH.g(d,e):hH.call(null,d,e)},$CLJS.ce(b,$CLJS.Qh(b)))},$CLJS.ce(a,$CLJS.Qh(a)))};$CLJS.qH=new $CLJS.K(null,"exp","exp",-261706262);$CLJS.rH=new $CLJS.K(null,"substring","substring",1140866276);
$CLJS.sH=new $CLJS.K(null,"basic-aggregations","basic-aggregations",-1628959228);$CLJS.tH=new $CLJS.K(null,"ceil","ceil",-1824929952);$CLJS.uH=new $CLJS.K(null,"power","power",-937852079);$CLJS.vH=new $CLJS.K(null,"segment","segment",-964921196);$CLJS.wH=new $CLJS.K(null,"concat","concat",-2108183992);$CLJS.xH=new $CLJS.K(null,"abs","abs",-246026477);$CLJS.yH=new $CLJS.K(null,"is-null","is-null",-1997050930);$CLJS.zH=new $CLJS.K(null,"between","between",1131099276);
$CLJS.AH=new $CLJS.K(null,"is-empty","is-empty",-1040302908);$CLJS.BH=new $CLJS.K(null,"filter","filter",-948537934);$CLJS.CH=new $CLJS.K(null,"distinct","distinct",-1788879121);$CLJS.DH=new $CLJS.K(null,"var","var",-769682797);$CLJS.EH=new $CLJS.K(null,"next","next",-117701485);$CLJS.FH=new $CLJS.K(null,"inside","inside",1972503011);$CLJS.GH=new $CLJS.K(null,"case","case",1143702196);$CLJS.HH=new $CLJS.K(null,"not-empty","not-empty",388922063);
$CLJS.IH=new $CLJS.K(null,"column-name","column-name",551523580);$CLJS.JH=new $CLJS.K(null,"percentile","percentile",-601188752);$CLJS.KH=new $CLJS.K(null,"not-null","not-null",-1326718535);$CLJS.LH=new $CLJS.K(null,"sqrt","sqrt",-1270051929);$CLJS.MH=new $CLJS.K(null,"breakout","breakout",-732419050);$CLJS.NH=new $CLJS.K(null,"aggregation","aggregation",1597476696);$CLJS.OH=new $CLJS.K(null,"desc","desc",2093485764);$CLJS.PH=new $CLJS.K(null,"starts-with","starts-with",366503009);
$CLJS.QH=new $CLJS.K(null,"does-not-contain","does-not-contain",-274946853);$CLJS.RH=new $CLJS.K(null,"short","short",1928760516);$CLJS.SH=new $CLJS.K(null,"avg","avg",197406200);$CLJS.TH=new $CLJS.K(null,"metric","metric",408798077);$CLJS.UH=new $CLJS.K(null,"percentile-aggregations","percentile-aggregations",1241763983);$CLJS.VH=new $CLJS.K(null,"asc","asc",356854569);$CLJS.WH=new $CLJS.K(null,"floor","floor",1882041021);$CLJS.XH=new $CLJS.K(null,"contains","contains",676899812);
$CLJS.YH=new $CLJS.K(null,"field","field",-1302436500);$CLJS.ZH=new $CLJS.K(null,"cum-count","cum-count",-1964431543);$CLJS.$H=new $CLJS.K(null,"regex-match-first","regex-match-first",-657299718);$CLJS.aI=new $CLJS.K(null,"coalesce","coalesce",654622029);$CLJS.bI=new $CLJS.K("metabase.lib.schema.ref","ref","metabase.lib.schema.ref/ref",-1124116498);$CLJS.cI=new $CLJS.K(null,"round","round",2009433328);$CLJS.dI=new $CLJS.K(null,"temporal-unit","temporal-unit",-1892306358);
$CLJS.eI=new $CLJS.K(null,"share","share",-589433933);$CLJS.fI=new $CLJS.K(null,"case-sensitive","case-sensitive",-1005239404);$CLJS.gI=new $CLJS.K(null,"count-where","count-where",385407720);$CLJS.hI=new $CLJS.K(null,"standard-deviation-aggregations","standard-deviation-aggregations",-1683333464);$CLJS.iI=new $CLJS.K(null,"ends-with","ends-with",-456791011);$CLJS.jI=new $CLJS.K(null,"time-interval","time-interval",704622015);$CLJS.kI=new $CLJS.K(null,"sum","sum",136986814);
$CLJS.lI=new $CLJS.K(null,"median","median",569566131);$CLJS.mI=new $CLJS.K("metabase.lib.schema.filter","operator","metabase.lib.schema.filter/operator",-805746630);hda=new $CLJS.K("metabase.lib.schema.mbql-clause","update-schemas","metabase.lib.schema.mbql-clause/update-schemas",-447263953);$CLJS.nI=new $CLJS.K(null,"include-current","include-current",-1602371981);$CLJS.oI=new $CLJS.K(null,"stddev","stddev",-865474939);$CLJS.pI=new $CLJS.K(null,"trim","trim",774319767);
$CLJS.qI=new $CLJS.K(null,"!\x3d","!\x3d",-1841737356);$CLJS.rI=new $CLJS.K(null,"ltrim","ltrim",1654269283);$CLJS.sI=new $CLJS.K(null,"category","category",-593092832);$CLJS.tI=new $CLJS.K(null,"sum-where","sum-where",2135348428);$CLJS.uI=new $CLJS.K(null,"/","/",1282502798);$CLJS.vI=new $CLJS.K(null,"cum-sum","cum-sum",2064902349);$CLJS.wI=new $CLJS.K(null,"log","log",-1595516004);$CLJS.xI=new $CLJS.K(null,"replace","replace",-786587770);$CLJS.yI=new $CLJS.K(null,"rtrim","rtrim",-661336449);var jH=$CLJS.Oe($CLJS.Ug),oH;$CLJS.Y($CLJS.DG,kH());$CLJS.YD(jH,hda,function(){return $CLJS.Y($CLJS.DG,kH())});
$CLJS.zI=function(){function a(d,e,f,k){c.g?c.g(d,k):c.call(null,d,k);$CLJS.BG.m(null,d,function(){return f});return null}function b(d,e){var f=$CLJS.rh.g("mbql.clause",$CLJS.Zg(d));$CLJS.Y(f,e);$CLJS.Gd($CLJS.q(jH),d)||$CLJS.Gh.j(jH,$CLJS.$d,d);return null}var c=null;c=function(d,e,f,k){switch(arguments.length){case 2:return b.call(this,d,e);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.v=a;return c}();
oH=function oH(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return oH.l(arguments[0],1<c.length?new $CLJS.y(c.slice(1),0,null):null)};
oH.l=function(a,b){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,$CLJS.Wf.g(new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.at,new $CLJS.h(null,1,[$CLJS.wu,["Valid ",$CLJS.p.h(a)," clause"].join("")],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Bj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,a],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.aj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.mG],null)],null)],null)],null),b)],null)};oH.A=1;
oH.B=function(a){var b=$CLJS.B(a);a=$CLJS.C(a);return this.l(b,a)};var mH=function mH(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return mH.l(arguments[0],1<c.length?new $CLJS.y(c.slice(1),0,null):null)};
mH.l=function(a,b){return $CLJS.Wf.g(new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ot,new $CLJS.h(null,1,[$CLJS.wu,["Valid ",$CLJS.p.h(a)," clause"].join("")],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,a],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.mG],null)],null),b)};mH.A=1;mH.B=function(a){var b=$CLJS.B(a);a=$CLJS.C(a);return this.l(b,a)};
var lH=function lH(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return lH.l(arguments[0],arguments[1],2<c.length?new $CLJS.y(c.slice(2),0,null):null)};lH.l=function(a,b,c){c=$CLJS.F.g($CLJS.B(c),$CLJS.mu)?$CLJS.ce($CLJS.hd(c),$CLJS.Te(2,c)):$CLJS.ce(null,c);var d=$CLJS.z(c);c=$CLJS.B(d);d=$CLJS.C(d);a=$CLJS.P.j(a,b,d);return $CLJS.n(c)?$CLJS.zI.v(b,$CLJS.mu,c,a):$CLJS.zI.g(b,a)};lH.A=2;
lH.B=function(a){var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);c=$CLJS.C(c);return this.l(b,a,c)};