var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.util.malli.registry.js");
'use strict';var yda,zda,Ada,Bda,Cda,Dda,Eda,Fda,Gda;yda=function(a,b){a.sort(b||$CLJS.Ca)};zda=function(a,b){const c=Array(a.length);for(let e=0;e<a.length;e++)c[e]={index:e,value:a[e]};const d=b||$CLJS.Ca;yda(c,function(e,f){return d(e.value,f.value)||e.index-f.index});for(b=0;b<a.length;b++)a[b]=c[b].value};
$CLJS.JI=function(a){return $CLJS.F.g(a,$CLJS.Hd)?$CLJS.Hd:function(b,c){var d=a.g?a.g(b,c):a.call(null,b,c);return"number"===typeof d?d:$CLJS.n(d)?-1:$CLJS.n(a.g?a.g(c,b):a.call(null,c,b))?1:0}};$CLJS.KI=function(a,b){if($CLJS.z(b)){var c=$CLJS.ue.h?$CLJS.ue.h(b):$CLJS.ue.call(null,b);zda(c,$CLJS.JI(a));return $CLJS.od($CLJS.z(c),$CLJS.pd(b))}return $CLJS.Jc};$CLJS.LI=new $CLJS.K(null,"second","second",-444702010);$CLJS.MI=new $CLJS.K(null,"second-of-minute","second-of-minute",222734326);
$CLJS.NI=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.date-time","metabase.lib.schema.temporal-bucketing/unit.date-time",1924841408);$CLJS.OI=new $CLJS.K(null,"millisecond","millisecond",-540123566);$CLJS.PI=new $CLJS.K(null,"year-of-era","year-of-era",682445876);Ada=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.date-time.extract","metabase.lib.schema.temporal-bucketing/unit.date-time.extract",1230935001);
Bda=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.time","metabase.lib.schema.temporal-bucketing/unit.time",713582593);$CLJS.QI=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.date-time.truncate","metabase.lib.schema.temporal-bucketing/unit.date-time.truncate",686665771);$CLJS.RI=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.time.interval","metabase.lib.schema.temporal-bucketing/unit.time.interval",-718964325);
$CLJS.SI=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.date","metabase.lib.schema.temporal-bucketing/unit.date",1876937510);Cda=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.time.extract","metabase.lib.schema.temporal-bucketing/unit.time.extract",-456342719);Dda=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.date.truncate","metabase.lib.schema.temporal-bucketing/unit.date.truncate",1199064479);
Eda=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.date.extract","metabase.lib.schema.temporal-bucketing/unit.date.extract",1595055425);Fda=new $CLJS.K("metabase.lib.schema.temporal-bucketing","option","metabase.lib.schema.temporal-bucketing/option",-1756789401);$CLJS.TI=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.date-time.interval","metabase.lib.schema.temporal-bucketing/unit.date-time.interval",659301368);
$CLJS.UI=new $CLJS.K("option","temporal-bucketing","option/temporal-bucketing",-108914562);$CLJS.VI=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.date.interval","metabase.lib.schema.temporal-bucketing/unit.date.interval",-1463811525);Gda=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.time.truncate","metabase.lib.schema.temporal-bucketing/unit.time.truncate",-948820335);var WI=new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.Ai,$CLJS.Aj,$CLJS.zj,$CLJS.ji,$CLJS.fk,$CLJS.ui,$CLJS.ci,$CLJS.PI],null),XI=$CLJS.Yg(WI),Hda,Ida,Jda;$CLJS.Y(Eda,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"Valid date extraction unit"],null)],null),XI));var YI=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.mk,$CLJS.jj,$CLJS.sj,$CLJS.ii,$CLJS.ci],null),ZI=$CLJS.Yg(YI);
$CLJS.Y(Dda,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"Valid date truncation unit"],null)],null),ZI));$CLJS.$I=$CLJS.Wf.j($CLJS.tf,$CLJS.al.o(),$CLJS.Ye.g(YI,WI));Hda=$CLJS.Yg($CLJS.$I);$CLJS.Y($CLJS.SI,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"Valid date bucketing unit"],null)],null),Hda));var aJ=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.MI,$CLJS.dj,$CLJS.pi],null),bJ=$CLJS.Yg(aJ);
$CLJS.Y(Cda,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"Valid time extraction unit"],null)],null),bJ));var cJ=new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.OI,$CLJS.LI,$CLJS.Ni,$CLJS.pv],null),dJ=$CLJS.Yg(cJ);$CLJS.Y(Gda,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"Valid time truncation unit"],null)],null),dJ));$CLJS.eJ=$CLJS.Wf.j($CLJS.tf,$CLJS.al.o(),$CLJS.Ye.g(cJ,aJ));Ida=$CLJS.Yg($CLJS.eJ);
$CLJS.Y(Bda,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"Valid time bucketing unit"],null)],null),Ida));$CLJS.fJ=$CLJS.Wf.j($CLJS.tf,$CLJS.al.o(),$CLJS.Ye.l(cJ,YI,$CLJS.G([aJ,WI])));Jda=$CLJS.Yg($CLJS.fJ);$CLJS.Y($CLJS.NI,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"Valid datetime bucketing unit"],null)],null),$CLJS.fJ));var Kda=$CLJS.$d.g(Jda,$CLJS.Wh);
$CLJS.Y($CLJS.WF,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"Valid temporal bucketing unit"],null)],null),Kda));var Lda=$CLJS.qv.g(ZI,dJ);$CLJS.Y($CLJS.QI,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"Valid datetime truncation unit"],null)],null),Lda));$CLJS.gJ=$CLJS.qv.g(XI,bJ);$CLJS.Y(Ada,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"Valid datetime extraction unit"],null)],null),$CLJS.gJ));
var hJ=$CLJS.$d.g(ZI,$CLJS.ci);$CLJS.Y($CLJS.VI,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"Valid date interval unit"],null)],null),hJ));$CLJS.Y($CLJS.RI,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"Valid time interval unit"],null)],null),dJ));var Mda=$CLJS.qv.g(hJ,dJ);$CLJS.Y($CLJS.TI,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"Valid datetime interval unit"],null)],null),Mda));
$CLJS.Y(Fda,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ov,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,$CLJS.UI],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.li,$CLJS.WF],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wh,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.zt],null)],null));