var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var N4,P4,Q4,R4,$ma;N4=function(a,b,c){var d=$CLJS.iY(a,b);d=$CLJS.O(d);d=$CLJS.I.g(d,$CLJS.NH);d=$CLJS.jJ($CLJS.Hk.j($CLJS.Vg([c]),$CLJS.nG,$CLJS.hd),d);if(!$CLJS.n(d))throw $CLJS.ai($CLJS.YF("No aggregation with uuid {0}",$CLJS.G([c])),new $CLJS.h(null,3,[$CLJS.Xj,c,$CLJS.kO,a,$CLJS.oY,b],null));return d};$CLJS.O4=function(a){return $CLJS.e3($CLJS.kI,new $CLJS.Q(null,1,5,$CLJS.R,[a],null))};$CLJS.Zma=new $CLJS.K(null,"aggregation-index","aggregation-index",-1057045587);
P4=new $CLJS.K("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592);Q4=new $CLJS.K("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",-21314018);R4=new $CLJS.K("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815);$ma=new $CLJS.K(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.V1.m(null,$CLJS.NH,function(a,b){var c=$CLJS.De($CLJS.NH.h($CLJS.iY(a,b)));return $CLJS.n(c)?$CLJS.f3($CLJS.zG("and"),function(){return function f(e){return new $CLJS.le(null,function(){for(;;){var k=$CLJS.z(e);if(k){if($CLJS.zd(k)){var l=$CLJS.kc(k),m=$CLJS.E(l),t=$CLJS.oe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u);v=$CLJS.u1.v(a,b,v,$CLJS.v1);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.re($CLJS.te(t),f($CLJS.lc(k))):$CLJS.re($CLJS.te(t),null)}t=$CLJS.B(k);return $CLJS.ce($CLJS.u1.v(a,
b,t,$CLJS.v1),f($CLJS.Kc(k)))}return null}},null,null)}(c)}()):null});$CLJS.Y1.m(null,$CLJS.NH,function(a,b,c){$CLJS.H(c,0,null);var d=$CLJS.H(c,1,null),e=$CLJS.O(d);d=$CLJS.I.g(e,$CLJS.iD);e=$CLJS.I.g(e,$CLJS.zi);c=$CLJS.H(c,2,null);c=N4(a,b,c);return $CLJS.Zk.l($CLJS.G([$CLJS.t1.j(a,b,c),new $CLJS.h(null,2,[$CLJS.vM,$CLJS.NM,$CLJS.Q1,$CLJS.nG.h($CLJS.hd(c))],null),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.iD,d],null):null,$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.zi,e],null):null]))});
$CLJS.S1.m(null,$CLJS.NH,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);return $CLJS.u1.v(a,b,N4(a,b,c),d)});$CLJS.gH(Q4,R4);
for(var S4=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Kz,$CLJS.ZH],null)),T4=null,U4=0,V4=0;;)if(V4<U4){var ana=T4.X(null,V4);$CLJS.gH(ana,Q4);V4+=1}else{var W4=$CLJS.z(S4);if(W4){var X4=W4;if($CLJS.zd(X4)){var Y4=$CLJS.kc(X4),bna=$CLJS.lc(X4),cna=Y4,dna=$CLJS.E(Y4);S4=bna;T4=cna;U4=dna}else{var ena=$CLJS.B(X4);$CLJS.gH(ena,Q4);S4=$CLJS.C(X4);T4=null;U4=0}V4=0}else break}
$CLJS.S1.m(null,Q4,function(a,b,c,d){var e=$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);if($CLJS.n(c))switch(a=$CLJS.u1.v(a,b,c,d),e=e instanceof $CLJS.K?e.T:null,e){case "count":return $CLJS.YF("Count of {0}",$CLJS.G([a]));case "cum-count":return $CLJS.YF("Cumulative count of {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}else switch(e=e instanceof $CLJS.K?e.T:null,e){case "count":return $CLJS.zG("Count");case "cum-count":return $CLJS.zG("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.T1.m(null,Q4,function(a,b,c){a=$CLJS.H(c,0,null);a=a instanceof $CLJS.K?a.T:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});$CLJS.Y1.m(null,Q4,function(a,b,c){var d=$CLJS.S.j,e=$CLJS.Uh($CLJS.Y1,R4);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.S,a,$CLJS.Pj,$CLJS.KE)});$CLJS.S1.m(null,$CLJS.GH,function(){return $CLJS.zG("Case")});
$CLJS.T1.m(null,$CLJS.GH,function(){return"case"});$CLJS.gH(P4,R4);for(var Z4=$CLJS.z(new $CLJS.Q(null,9,5,$CLJS.R,[$CLJS.SH,$CLJS.vI,$CLJS.CH,$CLJS.dk,$CLJS.lI,$CLJS.Qn,$CLJS.oI,$CLJS.kI,$CLJS.DH],null)),$4=null,a5=0,b5=0;;)if(b5<a5){var fna=$4.X(null,b5);$CLJS.gH(fna,P4);b5+=1}else{var c5=$CLJS.z(Z4);if(c5){var d5=c5;if($CLJS.zd(d5)){var e5=$CLJS.kc(d5),gna=$CLJS.lc(d5),hna=e5,ina=$CLJS.E(e5);Z4=gna;$4=hna;a5=ina}else{var jna=$CLJS.B(d5);$CLJS.gH(jna,P4);Z4=$CLJS.C(d5);$4=null;a5=0}b5=0}else break}
$CLJS.T1.m(null,P4,function(a,b,c){a=$CLJS.H(c,0,null);$CLJS.H(c,1,null);$CLJS.H(c,2,null);c=a instanceof $CLJS.K?a.T:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.S1.m(null,P4,function(a,b,c,d){var e=$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);a=$CLJS.u1.v(a,b,c,d);e=e instanceof $CLJS.K?e.T:null;switch(e){case "avg":return $CLJS.YF("Average of {0}",$CLJS.G([a]));case "cum-sum":return $CLJS.YF("Cumulative sum of {0}",$CLJS.G([a]));case "distinct":return $CLJS.YF("Distinct values of {0}",$CLJS.G([a]));case "max":return $CLJS.YF("Max of {0}",$CLJS.G([a]));case "median":return $CLJS.YF("Median of {0}",$CLJS.G([a]));case "min":return $CLJS.YF("Min of {0}",
$CLJS.G([a]));case "stddev":return $CLJS.YF("Standard deviation of {0}",$CLJS.G([a]));case "sum":return $CLJS.YF("Sum of {0}",$CLJS.G([a]));case "var":return $CLJS.YF("Variance of {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.S1.m(null,$CLJS.JH,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var e=$CLJS.H(c,2,null);c=$CLJS.H(c,3,null);return $CLJS.YF("{0}th percentile of {1}",$CLJS.G([c,$CLJS.u1.v(a,b,e,d)]))});$CLJS.T1.m(null,$CLJS.JH,function(){return"percentile"});
$CLJS.gH($CLJS.JH,R4);$CLJS.S1.m(null,$CLJS.tI,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var e=$CLJS.H(c,2,null);$CLJS.H(c,3,null);return $CLJS.YF("Sum of {0} matching condition",$CLJS.G([$CLJS.u1.v(a,b,e,d)]))});$CLJS.T1.m(null,$CLJS.tI,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var d=$CLJS.H(c,2,null);$CLJS.H(c,3,null);return["sum_where_",$CLJS.p.h($CLJS.U1.j(a,b,d))].join("")});$CLJS.gH($CLJS.tI,R4);$CLJS.S1.m(null,$CLJS.eI,function(){return $CLJS.zG("Share of rows matching condition")});
$CLJS.T1.m(null,$CLJS.eI,function(){return"share"});$CLJS.gH($CLJS.eI,R4);$CLJS.S1.m(null,$CLJS.gI,function(){return $CLJS.zG("Count of rows matching condition")});$CLJS.T1.m(null,$CLJS.gI,function(){return"count-where"});$CLJS.gH($CLJS.gI,R4);
$CLJS.Y1.m(null,R4,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var d=$CLJS.H(c,2,null),e=$CLJS.Zk.l;d=$CLJS.n(d)?$CLJS.Wl($CLJS.t1.j(a,b,d),new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.HM],null)):null;var f=$CLJS.Uh($CLJS.Y1,$CLJS.Wh);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.Zk,$CLJS.G([d,a]))});
$CLJS.kna=function(){function a(d){return $CLJS.e3($CLJS.Kz,new $CLJS.Q(null,1,5,$CLJS.R,[d],null))}function b(){return $CLJS.e3($CLJS.Kz,$CLJS.tf)}var c=null;c=function(d){switch(arguments.length){case 0:return b.call(this);case 1:return a.call(this,d)}throw Error("Invalid arity: "+arguments.length);};c.o=b;c.h=a;return c}();$CLJS.SX.m(null,$CLJS.NH,function(a){return a});
$CLJS.f5=function(){function a(d,e,f){for(;;)if($CLJS.F.g($CLJS.VD(f),$CLJS.zM))f=$CLJS.UX(f);else return $CLJS.P2(d,e,$CLJS.NH,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.g5=function(){function a(d,e){return $CLJS.De($CLJS.NH.h($CLJS.iY(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.h5=function(){function a(d,e){var f=$CLJS.De($CLJS.NH.h($CLJS.iY(d,e)));return null==f?null:$CLJS.Wf.j($CLJS.tf,$CLJS.Ze.h(function(k){var l=$CLJS.t1.j(d,e,k),m=$CLJS.S.l,t=$CLJS.iD.h(l);return m.call($CLJS.S,$CLJS.d3(l,$CLJS.zi,$CLJS.n(t)?t:$CLJS.vj),$CLJS.vM,$CLJS.NM,$CLJS.G([$CLJS.Q1,$CLJS.nG.h($CLJS.hd(k))]))}),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+
arguments.length);};c.h=b;c.g=a;return c}();$CLJS.S1.m(null,$CLJS.HI,function(a,b,c){a=$CLJS.O(c);a=$CLJS.I.g(a,$CLJS.DI);return $CLJS.cG.h(a.o?a.o():a.call(null))});$CLJS.Z1.m(null,$CLJS.HI,function(a,b,c){var d=$CLJS.O(c);a=$CLJS.I.g(d,$CLJS.RH);b=$CLJS.I.g(d,$CLJS.DI);c=$CLJS.I.g(d,$CLJS.CI);d=$CLJS.I.g(d,$CLJS.m0);a=$CLJS.S.l(b.o?b.o():b.call(null),$CLJS.P1,$CLJS.PC(a),$CLJS.G([$CLJS.N1,c]));return null!=d?$CLJS.S.j(a,$CLJS.C1,d):a});
$CLJS.lna=function(){function a(d,e){var f=function(){var m=$CLJS.ZB.h($CLJS.j2($CLJS.p1(d)));return $CLJS.n(m)?m:$CLJS.Ug}(),k=$CLJS.iY(d,e),l=$CLJS.c2.j(d,e,k);return $CLJS.De($CLJS.Wf.j($CLJS.tf,$CLJS.Hk.j($CLJS.af(function(m){m=$CLJS.FI.h(m);var t=null==m;return t?t:f.h?f.h(m):f.call(null,m)}),$CLJS.Ol(function(m){m=$CLJS.O(m);var t=$CLJS.I.g(m,$CLJS.CI),u=$CLJS.I.g(m,$CLJS.AI);if($CLJS.Sa(t))return m;if($CLJS.F.g(u,$CLJS.ei))return $CLJS.S.j(m,$CLJS.fY,l);t=$CLJS.De($CLJS.c3(function(v){return $CLJS.X0(u,
v)},l));return $CLJS.n(t)?$CLJS.S.j(m,$CLJS.fY,t):null}),$CLJS.Ze.h(function(m){return $CLJS.S.j(m,$CLJS.ov,$CLJS.HI)})),$CLJS.II))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.mna=function(){function a(d,e){return $CLJS.TX(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.RH.h(d),$CLJS.N,$CLJS.VX.h(e)],null))}function b(d){if($CLJS.Sa($CLJS.CI.h(d)))return $CLJS.TX(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.RH.h(d),$CLJS.N],null));var e=$CLJS.RH.h(d);e=$CLJS.ha.g?$CLJS.ha.g("aggregation operator %s requires an argument",e):$CLJS.ha.call(null,"aggregation operator %s requires an argument",e);throw $CLJS.ai(e,new $CLJS.h(null,1,[$ma,d],null));}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,
d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();