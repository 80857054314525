var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';$CLJS.yL=new $CLJS.K("operator","filter","operator/filter",-1518842858);$CLJS.zL=new $CLJS.K(null,"display-name-variant","display-name-variant",-1831788853);for(var AL=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.rt,$CLJS.lt],null)),BL=null,CL=0,DL=0;;)if(DL<CL){var Rea=BL.X(null,DL);$CLJS.pH(Rea,$CLJS.G([$CLJS.mu,$CLJS.Fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Tt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ws,new $CLJS.h(null,1,[$CLJS.Qn,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.MG],null)],null)],null)],null)]));DL+=1}else{var EL=$CLJS.z(AL);if(EL){var FL=EL;if($CLJS.zd(FL)){var GL=$CLJS.kc(FL),Sea=$CLJS.lc(FL),
Tea=GL,Uea=$CLJS.E(GL);AL=Sea;BL=Tea;CL=Uea}else{var Vea=$CLJS.B(FL);$CLJS.pH(Vea,$CLJS.G([$CLJS.mu,$CLJS.Fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Tt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ws,new $CLJS.h(null,1,[$CLJS.Qn,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.MG],null)],null)],null)],null)]));AL=$CLJS.C(FL);BL=null;CL=0}DL=0}else break}$CLJS.nH($CLJS.nt,$CLJS.G([$CLJS.mu,$CLJS.Fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.MG],null)]));
for(var HL=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,$CLJS.qI],null)),IL=null,JL=0,KL=0;;)if(KL<JL){var Wea=IL.X(null,KL);$CLJS.pH(Wea,$CLJS.G([$CLJS.mu,$CLJS.Fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Tt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ws,new $CLJS.h(null,1,[$CLJS.Qn,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.SG],null)],null)],null)],null)]));KL+=1}else{var LL=$CLJS.z(HL);if(LL){var ML=LL;if($CLJS.zd(ML)){var NL=$CLJS.kc(ML),Xea=$CLJS.lc(ML),
Yea=NL,Zea=$CLJS.E(NL);HL=Xea;IL=Yea;JL=Zea}else{var $ea=$CLJS.B(ML);$CLJS.pH($ea,$CLJS.G([$CLJS.mu,$CLJS.Fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Tt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ws,new $CLJS.h(null,1,[$CLJS.Qn,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.SG],null)],null)],null)],null)]));HL=$CLJS.C(ML);IL=null;JL=0}KL=0}else break}
for(var OL=$CLJS.z(new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Is,$CLJS.Ks,$CLJS.Es,$CLJS.Gs],null)),PL=null,QL=0,RL=0;;)if(RL<QL){var afa=PL.X(null,RL);$CLJS.nH(afa,$CLJS.G([$CLJS.mu,$CLJS.Fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.UG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.UG],null)]));RL+=1}else{var SL=$CLJS.z(OL);if(SL){var TL=SL;if($CLJS.zd(TL)){var UL=$CLJS.kc(TL),bfa=$CLJS.lc(TL),cfa=UL,dfa=$CLJS.E(UL);OL=bfa;PL=cfa;QL=dfa}else{var efa=$CLJS.B(TL);$CLJS.nH(efa,$CLJS.G([$CLJS.mu,$CLJS.Fj,
new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.UG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.UG],null)]));OL=$CLJS.C(TL);PL=null;QL=0}RL=0}else break}$CLJS.nH($CLJS.zH,$CLJS.G([$CLJS.mu,$CLJS.Fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.UG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.UG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.UG],null)]));
$CLJS.nH($CLJS.FH,$CLJS.G([$CLJS.mu,$CLJS.Fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.UG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.UG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.UG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.UG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.UG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.UG],null)]));
for(var VL=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.yH,$CLJS.KH],null)),WL=null,XL=0,YL=0;;)if(YL<XL){var ffa=WL.X(null,YL);$CLJS.nH(ffa,$CLJS.G([$CLJS.mu,$CLJS.Fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.NG],null)]));YL+=1}else{var ZL=$CLJS.z(VL);if(ZL){var $L=ZL;if($CLJS.zd($L)){var aM=$CLJS.kc($L),gfa=$CLJS.lc($L),hfa=aM,ifa=$CLJS.E(aM);VL=gfa;WL=hfa;XL=ifa}else{var jfa=$CLJS.B($L);$CLJS.nH(jfa,$CLJS.G([$CLJS.mu,$CLJS.Fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.NG],null)]));VL=$CLJS.C($L);
WL=null;XL=0}YL=0}else break}
for(var bM=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.AH,$CLJS.HH],null)),cM=null,dM=0,eM=0;;)if(eM<dM){var kfa=cM.X(null,eM);$CLJS.nH(kfa,$CLJS.G([$CLJS.mu,$CLJS.Fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.WG],null)]));eM+=1}else{var fM=$CLJS.z(bM);if(fM){var gM=fM;if($CLJS.zd(gM)){var hM=$CLJS.kc(gM),lfa=$CLJS.lc(gM),mfa=hM,nfa=$CLJS.E(hM);bM=lfa;cM=mfa;dM=nfa}else{var ofa=$CLJS.B(gM);$CLJS.nH(ofa,$CLJS.G([$CLJS.mu,$CLJS.Fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.WG],null)]));bM=$CLJS.C(gM);
cM=null;dM=0}eM=0}else break}
for(var iM=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.fI,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.zt],null)],null),jM=$CLJS.z(new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.PH,$CLJS.iI,$CLJS.XH,$CLJS.QH],null)),kM=null,lM=0,mM=0;;)if(mM<lM){var nM=kM.X(null,mM);$CLJS.zI.v(nM,$CLJS.mu,$CLJS.Fj,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.ot,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,nM],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,$CLJS.mG,iM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,
$CLJS.XG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.XG],null)],null));mM+=1}else{var oM=$CLJS.z(jM);if(oM){var pM=oM;if($CLJS.zd(pM)){var qM=$CLJS.kc(pM),pfa=$CLJS.lc(pM),qfa=qM,rfa=$CLJS.E(qM);jM=pfa;kM=qfa;lM=rfa}else{var rM=$CLJS.B(pM);$CLJS.zI.v(rM,$CLJS.mu,$CLJS.Fj,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.ot,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,rM],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,$CLJS.mG,iM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.XG],null),new $CLJS.Q(null,2,5,$CLJS.R,
[$CLJS.X,$CLJS.XG],null)],null));jM=$CLJS.C(pM);kM=null;lM=0}mM=0}else break}
$CLJS.zI.v($CLJS.jI,$CLJS.mu,$CLJS.Fj,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.ot,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,$CLJS.jI],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,$CLJS.mG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.nI,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.zt],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.KG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ht,$CLJS.vx,$CLJS.Oz,$CLJS.EH],null),new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.X,$CLJS.$G],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.TI],null)],null));$CLJS.zI.v($CLJS.vH,$CLJS.mu,$CLJS.Fj,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ot,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,$CLJS.vH],null),$CLJS.mG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,$CLJS.$F,$CLJS.hG],null)],null));
$CLJS.Y($CLJS.mI,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ov,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,$CLJS.yL],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.RH,new $CLJS.Q(null,17,5,$CLJS.R,[$CLJS.ht,$CLJS.Ls,$CLJS.qI,$CLJS.FH,$CLJS.zH,$CLJS.Is,$CLJS.Es,$CLJS.Ks,$CLJS.Gs,$CLJS.yH,$CLJS.KH,$CLJS.AH,$CLJS.HH,$CLJS.XH,$CLJS.QH,$CLJS.PH,$CLJS.iI],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.zL,$CLJS.Ri],null)],null));