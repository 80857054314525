var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.util.malli.registry.js");
'use strict';var bK,cK,sea,tea,uea,gK,hK,iK,kK,vea,mK;bK=function(a){switch(arguments.length){case 2:return $CLJS.fH(arguments[0],arguments[1]);case 3:return $CLJS.eH(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};cK=function(a){return["(?:",$CLJS.p.h($CLJS.P.g($CLJS.p,a)),")?"].join("")};sea=new $CLJS.K("metabase.lib.schema.literal","temporal","metabase.lib.schema.literal/temporal",1227892305);
tea=new $CLJS.K("metabase.lib.schema.literal","non-integer-real","metabase.lib.schema.literal/non-integer-real",1396696695);$CLJS.dK=new $CLJS.K("metabase.lib.schema.literal","string.year","metabase.lib.schema.literal/string.year",-936788792);uea=new $CLJS.K("metabase.lib.schema.literal","string","metabase.lib.schema.literal/string",-1434211409);$CLJS.eK=new $CLJS.K("metabase.lib.schema.literal","time","metabase.lib.schema.literal/time",1928421901);
$CLJS.fK=new $CLJS.K("metabase.lib.schema.literal","string.zone-offset","metabase.lib.schema.literal/string.zone-offset",-437074263);gK=new $CLJS.K("metabase.lib.schema.literal","string.time","metabase.lib.schema.literal/string.time",1501090167);hK=new $CLJS.K("metabase.lib.schema.literal","string.date","metabase.lib.schema.literal/string.date",1716358402);iK=new $CLJS.K("metabase.lib.schema.literal","string.datetime","metabase.lib.schema.literal/string.datetime",-1597850940);
$CLJS.jK=new $CLJS.K("metabase.lib.schema.literal","date","metabase.lib.schema.literal/date",-906463835);kK=new $CLJS.K("metabase.lib.schema.literal","value.options","metabase.lib.schema.literal/value.options",-1918568845);vea=new $CLJS.K("metabase.lib.schema.literal","integer","metabase.lib.schema.literal/integer",-1120986283);$CLJS.lK=new $CLJS.K("metabase.lib.schema.literal","string.year-month","metabase.lib.schema.literal/string.year-month",-1014149016);
mK=new $CLJS.K("metabase.lib.schema.literal","boolean","metabase.lib.schema.literal/boolean",1793502815);$CLJS.nK=new $CLJS.K("metabase.lib.schema.literal","datetime","metabase.lib.schema.literal/datetime",985830761);$CLJS.BG.m(null,$CLJS.lD,function(){return $CLJS.vj});$CLJS.Y(mK,$CLJS.zt);$CLJS.BG.m(null,$CLJS.pD,function(){return $CLJS.Fj});$CLJS.Y(mK,$CLJS.zt);$CLJS.Y(vea,$CLJS.ri);$CLJS.BG.m(null,$CLJS.aD,function(){return $CLJS.rj});$CLJS.Y(tea,$CLJS.wt);$CLJS.BG.m(null,$CLJS.ZC,function(){return $CLJS.EF});$CLJS.Y(uea,$CLJS.Tj);
var wea=[":\\d{2}",cK($CLJS.G(["\\.\\d{1,6}"]))].join(""),oK=["\\d{2}:\\d{2}",cK($CLJS.G([wea]))].join(""),pK=["\\d{4}-\\d{2}-\\d{2}T",oK].join(""),qK=["(?:Z|(?:[+-]",oK,"))"].join(""),xea=$CLJS.hh(["(?:Z|(?:[+-]",oK,"))"].join("")),yea=$CLJS.hh("^\\d{4}-\\d{2}-\\d{2}$"),zea=$CLJS.hh(["^",oK,"$"].join("")),Aea=$CLJS.hh(["^",oK,qK,"$"].join("")),Bea=$CLJS.hh(["^",pK,"$"].join("")),Cea=$CLJS.hh(["^",pK,qK,"$"].join(""));
$CLJS.Y(hK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,new $CLJS.h(null,1,[$CLJS.wu,"date string literal"],null),yea],null));$CLJS.Y($CLJS.fK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,new $CLJS.h(null,1,[$CLJS.wu,"timezone offset string literal"],null),xea],null));
$CLJS.Y(gK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,new $CLJS.h(null,1,[$CLJS.wu,"local time string literal"],null),zea],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,new $CLJS.h(null,1,[$CLJS.wu,"offset time string literal"],null),Aea],null)],null));
$CLJS.Y(iK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,new $CLJS.h(null,1,[$CLJS.wu,"local date time string literal"],null),Bea],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,new $CLJS.h(null,1,[$CLJS.wu,"offset date time string literal"],null),Cea],null)],null));
$CLJS.BG.m(null,$CLJS.$C,function(a){return $CLJS.n($CLJS.fH?$CLJS.fH(iK,a):bK.call(null,iK,a))?new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.Yj,null,$CLJS.hF,null],null),null):$CLJS.n($CLJS.fH?$CLJS.fH(hK,a):bK.call(null,hK,a))?new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.JE,null,$CLJS.Yj,null],null),null):$CLJS.n($CLJS.fH?$CLJS.fH(gK,a):bK.call(null,gK,a))?new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.HF,null,$CLJS.Yj,null],null),null):$CLJS.Yj});$CLJS.Y($CLJS.jK,hK);$CLJS.Y($CLJS.eK,gK);
$CLJS.Y($CLJS.nK,iK);$CLJS.Y(sea,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.lt,$CLJS.jK,$CLJS.eK,$CLJS.nK],null));$CLJS.rK=$CLJS.hh("^\\d{4}-\\d{2}$");$CLJS.Y($CLJS.lK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,new $CLJS.h(null,1,[$CLJS.wu,"year-month string literal"],null),$CLJS.rK],null));$CLJS.sK=$CLJS.hh("^\\d{4}$");$CLJS.Y($CLJS.dK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,new $CLJS.h(null,1,[$CLJS.wu,"year string literal"],null),$CLJS.sK],null));
$CLJS.Y(kK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.mG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.zi,$CLJS.qG],null)],null)],null));$CLJS.zI.g($CLJS.yj,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.ot,new $CLJS.h(null,1,[$CLJS.wu,"Value :value clause"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,$CLJS.yj],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,kK],null),$CLJS.ul],null));