var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./medley.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var g_,Nja,h_,Oja,Pja,Qja,i_,Sja,Tja,Uja,Vja,l_,m_,n_,o_,q_,Xja,r_,s_,Yja,Zja,$ja,k_,Rja,t_,u_,Wja,w_;g_=function(a,b){var c=$CLJS.z(b);for(a=$CLJS.z($CLJS.Te(a,b));;)if(a)c=$CLJS.C(c),a=$CLJS.C(a);else return c};
Nja=function(){return function(){function a(k,l,m){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hj.j?$CLJS.hj.j(k,l,m):$CLJS.hj.call(null,k,l,m),$CLJS.el.j?$CLJS.el.j(k,l,m):$CLJS.el.call(null,k,l,m)],null)}function b(k,l){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hj.g?$CLJS.hj.g(k,l):$CLJS.hj.call(null,k,l),$CLJS.el.g?$CLJS.el.g(k,l):$CLJS.el.call(null,k,l)],null)}function c(k){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hj.h?$CLJS.hj.h(k):$CLJS.hj.call(null,k),$CLJS.el.h?$CLJS.el.h(k):$CLJS.el.call(null,
k)],null)}function d(){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hj.o?$CLJS.hj.o():$CLJS.hj.call(null),$CLJS.el.o?$CLJS.el.o():$CLJS.el.call(null)],null)}var e=null,f=function(){function k(m,t,u,v){var x=null;if(3<arguments.length){x=0;for(var A=Array(arguments.length-3);x<A.length;)A[x]=arguments[x+3],++x;x=new $CLJS.y(A,0,null)}return l.call(this,m,t,u,x)}function l(m,t,u,v){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.P.N($CLJS.hj,m,t,u,v),$CLJS.P.N($CLJS.el,m,t,u,v)],null)}k.A=3;k.B=function(m){var t=
$CLJS.B(m);m=$CLJS.C(m);var u=$CLJS.B(m);m=$CLJS.C(m);var v=$CLJS.B(m);m=$CLJS.Kc(m);return l(t,u,v,m)};k.l=l;return k}();e=function(k,l,m,t){switch(arguments.length){case 0:return d.call(this);case 1:return c.call(this,k);case 2:return b.call(this,k,l);case 3:return a.call(this,k,l,m);default:var u=null;if(3<arguments.length){u=0;for(var v=Array(arguments.length-3);u<v.length;)v[u]=arguments[u+3],++u;u=new $CLJS.y(v,0,null)}return f.l(k,l,m,u)}throw Error("Invalid arity: "+arguments.length);};e.A=
3;e.B=f.B;e.o=d;e.h=c;e.g=b;e.j=a;e.l=f.l;return e}()};h_=function(a){return $CLJS.rN($CLJS.PX)(a,$CLJS.tf,$CLJS.tf)};Oja=function(a,b,c){return $CLJS.ab(function(d,e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);return null==e?d:$CLJS.S.j(d,f,e)},null==b?a:$CLJS.S.j(a,$CLJS.NH,b),$CLJS.fu(2,2,c))};Pja=function(a,b){var c=$CLJS.Xl(a,$CLJS.jS);if($CLJS.n(c)){var d=$CLJS.S.j;c=$CLJS.zb(c);b=$CLJS.Gu.g?$CLJS.Gu.g(c,b):$CLJS.Gu.call(null,c,b);a=d.call($CLJS.S,a,$CLJS.jS,b)}return a};
Qja=function(a){return $CLJS.Ye.g($CLJS.oX(a),$CLJS.pX(a))};i_=function(a,b,c){var d=new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.Kt,null,$CLJS.kl,null],null),null),e=$CLJS.n(d.h?d.h(b):d.call(null,b))?$CLJS.Pv(2,c):$CLJS.Pv(1,c);b=$CLJS.n(d.h?d.h(b):d.call(null,b))?g_(2,c):g_(1,c);var f=$CLJS.H(b,0,null);return $CLJS.z(e)?$CLJS.YY.j(a,e,function(k){var l=$CLJS.S.j(k,f,null);return $CLJS.yd(k)?$CLJS.Wf.j($CLJS.tf,$CLJS.Ik.h($CLJS.rl),l):$CLJS.wd(k)?$CLJS.yC($CLJS.Ta,l):l}):$CLJS.zk.g(a,f)};
Sja=function(a){return $CLJS.ab(function(b,c){var d=$CLJS.H(c,0,null);$CLJS.H(c,1,null);return i_(b,Rja,d)},a,Qja(a))};
Tja=function(a){for(var b=0;;){var c=$CLJS.bd($CLJS.UM.h(a),b);a:{var d=c;for(var e=$CLJS.tf;;){var f=$CLJS.B($CLJS.Ze.g(Nja(),$CLJS.bf($CLJS.Hk.j(j_,$CLJS.B,$CLJS.el),$CLJS.Ot.h(h_(d)))));if($CLJS.n(f)){var k=f;f=$CLJS.H(k,0,null);k=$CLJS.H(k,1,null);var l=i_(d,f,k),m=$CLJS.BC($CLJS.xA);if($CLJS.n($CLJS.AC("metabase.lib.convert",m))){var t=$CLJS.rD.l($CLJS.G([$CLJS.sB,$CLJS.Eh.l($CLJS.G([k]))])),u=$CLJS.rD,v=u.l,x=$CLJS.Eh,A=x.l;var D=f;D=$CLJS.n(D)?D:$CLJS.wN(h_(d));$CLJS.zC("metabase.lib.convert",
m,$CLJS.YX("Clean: Removing bad clause in %s due to error %s:\n%s",$CLJS.G([t,v.call(u,$CLJS.G([$CLJS.sB,A.call(x,$CLJS.G([D]))])),$CLJS.rD.l($CLJS.G([$CLJS.hB,$CLJS.ZX($CLJS.B($CLJS.uv(d,l)))]))])),null)}if($CLJS.F.g(l,d))break a;d=l;e=$CLJS.$d.g(e,new $CLJS.Q(null,2,5,$CLJS.R,[f,k],null))}else break a}}d=Sja(d);if($CLJS.F.g(c,d)){if($CLJS.F.g(b,$CLJS.E($CLJS.UM.h(a))-1))return a;b+=1}else a=$CLJS.Kk.N(a,$CLJS.UM,$CLJS.S,b,d)}};
Uja=function(a){var b=$CLJS.lY();return $CLJS.Jk.g(function(c){return $CLJS.F.g($CLJS.LG.h(c),"__join")?$CLJS.Kk.j(c,$CLJS.LG,b):c},a)};Vja=function(a){return"string"===typeof $CLJS.IQ.h(a)?$CLJS.zk.g($CLJS.S.j(a,$CLJS.qX,$CLJS.jY($CLJS.IQ.h(a))),$CLJS.IQ):a};l_=function(a,b){var c=$CLJS.I.j(a,b,k_);if($CLJS.F.g(c,k_))throw $CLJS.ai(["Unable to find ",$CLJS.Eh.l($CLJS.G([b]))," in map."].join(""),new $CLJS.h(null,2,[Wja,a,$CLJS.Fy,b],null));return c};
m_=function(){return $CLJS.Ik.h(function(a){var b=$CLJS.H(a,0,null);$CLJS.H(a,1,null);(a=$CLJS.ke(b))?(b=$CLJS.ge(b),b=$CLJS.n(b)?$CLJS.F.g(b,"lib")||0==b.lastIndexOf("metabase.lib.",0):null):b=a;return b})};n_=function(a){return $CLJS.Wf.j($CLJS.N,m_(),a)};o_=function(a){return $CLJS.De($CLJS.Wf.j($CLJS.N,$CLJS.Hk.g(m_(),$CLJS.Ik.h(function(b){var c=$CLJS.H(b,0,null);$CLJS.H(b,1,null);return $CLJS.F.g(c,$CLJS.zi)})),a))};
q_=function(a){var b=$CLJS.z(a);a=$CLJS.B(b);var c=$CLJS.C(b);b=$CLJS.B(c);c=$CLJS.C(c);var d=$CLJS.Wf.j(new $CLJS.Q(null,1,5,$CLJS.R,[a],null),$CLJS.Ze.h($CLJS.p_),c);a=$CLJS.F.g(a,$CLJS.GH)&&$CLJS.C(c)?$CLJS.$d.g($CLJS.rd(d),new $CLJS.h(null,1,[$CLJS.Wh,$CLJS.qd(d)],null)):d;b=$CLJS.De(o_(b));return $CLJS.n(b)?new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.XN,a,b],null):a};Xja=function(a){return $CLJS.Wf.j($CLJS.tf,$CLJS.Hk.g($CLJS.Ze.h(function(b){return $CLJS.tC(b,$CLJS.wD)}),$CLJS.Ze.h($CLJS.p_)),$CLJS.fY.h(a))};
r_=function(a){a=$CLJS.O(a);a=$CLJS.I.g(a,$CLJS.UM);a=$CLJS.B($CLJS.ab(function(b,c){var d=$CLJS.H(b,0,null),e=$CLJS.H(b,1,null);b=$CLJS.R;var f=$CLJS.p_.h(c);d=$CLJS.n(d)?$CLJS.S.j(f,$CLJS.lS,d):f;d=$CLJS.n(e)?$CLJS.S.j(d,$CLJS.uQ,Xja(e)):d;return new $CLJS.Q(null,2,5,b,[d,$CLJS.QW.h(c)],null)},null,a));return $CLJS.n($CLJS.MR.h(a))?$CLJS.cX(a,new $CLJS.h(null,1,[$CLJS.MR,$CLJS.kO],null)):a};
s_=function(a,b,c){var d=$CLJS.F.g($CLJS.E($CLJS.I.g(a,b)),1)?$CLJS.vY(a,b,$CLJS.Hk.g($CLJS.p_,$CLJS.B)):a;a=1<$CLJS.E($CLJS.I.g(a,b))?$CLJS.vY(d,b,function(e){return $CLJS.Wf.j(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.rt],null),$CLJS.Ze.h($CLJS.p_),e)}):d;return $CLJS.cX(a,$CLJS.Ce([b,c]))};Yja=function(a){var b=$CLJS.qX.h(a);return $CLJS.n(b)?$CLJS.S.j($CLJS.zk.g(a,$CLJS.qX),$CLJS.IQ,["card__",$CLJS.p.h(b)].join("")):a};
Zja=new $CLJS.K(null,"legacy-index-\x3epMBQL-uuid","legacy-index-\x3epMBQL-uuid",-192973336);$ja=new $CLJS.K(null,"legacy-ref","legacy-ref",1686404700);k_=new $CLJS.K("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550);Rja=new $CLJS.K("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475);t_=new $CLJS.K("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672);
u_=new $CLJS.K("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782);$CLJS.v_=new $CLJS.K("lib.convert","converted?","lib.convert/converted?",1937433683);Wja=new $CLJS.K(null,"m","m",1632677161);w_=new $CLJS.K("metabase.lib.convert","aggregation","metabase.lib.convert/aggregation",108290790);var j_,aka,bka,cka,dka,eka,fka,gka,hka,ika,jka;$CLJS.x_=$CLJS.N;$CLJS.y_=$CLJS.N;j_=new $CLJS.Tg(null,new $CLJS.h(null,7,[$CLJS.MX,null,$CLJS.HG,null,$CLJS.gP,null,$CLJS.CR,null,$CLJS.MH,null,$CLJS.rT,null,$CLJS.NH,null],null),null);aka=$CLJS.Oe($CLJS.N);bka=$CLJS.Oe($CLJS.N);cka=$CLJS.Oe($CLJS.N);dka=$CLJS.Oe($CLJS.N);eka=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.cj,$CLJS.XD],null),$CLJS.cj,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));
$CLJS.z_=new $CLJS.Xh($CLJS.xh.g("metabase.lib.convert","-\x3epMBQL"),$CLJS.VD,eka,aka,bka,cka,dka);$CLJS.z_.m(null,$CLJS.Wh,function(a){if($CLJS.yd(a)&&$CLJS.B(a)instanceof $CLJS.K){var b=$CLJS.qd(a);var c=$CLJS.wd(b);a=c?$CLJS.rd(a):a;var d=$CLJS.z(a);a=$CLJS.B(d);d=$CLJS.C(d);b=$CLJS.TX($CLJS.Wf.j(new $CLJS.Q(null,2,5,$CLJS.R,[a,c?b:$CLJS.N],null),$CLJS.Ze.h($CLJS.z_),d))}else b=a;return b});$CLJS.z_.m(null,$CLJS.KX,function(a){return a});
$CLJS.z_.m(null,$CLJS.xX,function(a){var b=$CLJS.z_.h($CLJS.NH.h(a)),c=$CLJS.De($CLJS.Jk.g(function(t){var u=$CLJS.H(t,0,null);t=$CLJS.H(t,1,null);return $CLJS.cY($CLJS.z_.h(t),u)},$CLJS.CR.h(a))),d=$CLJS.Wf.j($CLJS.N,$CLJS.Yl(function(t,u){$CLJS.H(u,0,null);u=$CLJS.H(u,1,null);u=$CLJS.O(u);u=$CLJS.I.g(u,$CLJS.nG);return new $CLJS.Q(null,2,5,$CLJS.R,[t,u],null)}),b),e=$CLJS.Wf.j($CLJS.N,$CLJS.Yl(function(t,u){$CLJS.H(u,0,null);u=$CLJS.H(u,1,null);u=$CLJS.O(u);u=$CLJS.I.g(u,$CLJS.nG);return new $CLJS.Q(null,
2,5,$CLJS.R,[u,t],null)}),b),f=$CLJS.y_,k=$CLJS.x_;$CLJS.y_=d;$CLJS.x_=e;try{var l=Oja(Vja(a),b,$CLJS.G([$CLJS.CR,c])),m=$CLJS.ab(function(t,u){return $CLJS.Sa($CLJS.I.g(t,u))?t:$CLJS.Kk.j(t,u,$CLJS.z_)},l,$CLJS.Ak.l(j_,$CLJS.NH,$CLJS.G([$CLJS.CR])));return $CLJS.n($CLJS.gP.h(m))?$CLJS.Kk.j(m,$CLJS.gP,Uja):m}finally{$CLJS.x_=k,$CLJS.y_=f}});$CLJS.z_.m(null,$CLJS.EX,function(a){return Pja(a,function(b){return $CLJS.vY(b,$CLJS.MN,$CLJS.z_)})});
$CLJS.z_.m(null,$CLJS.PM,function(a){a=$CLJS.Kk.j($CLJS.Kk.j(a,$CLJS.XM,$CLJS.z_),$CLJS.UM,$CLJS.z_);var b=$CLJS.n($CLJS.HG.h(a))?$CLJS.Kk.j(a,$CLJS.HG,function(c){return $CLJS.Dl(c)?$CLJS.Jk.g($CLJS.z_,c):$CLJS.rh.h(c)}):a;return $CLJS.Sa($CLJS.LG.h(a))?$CLJS.S.j(b,$CLJS.LG,"__join"):b});$CLJS.z_.m(null,$CLJS.cD,function(a){return $CLJS.Jk.g($CLJS.z_,a)});
$CLJS.z_.m(null,$CLJS.eD,function(a){return $CLJS.n($CLJS.hj.h(a))?Tja($CLJS.S.j($CLJS.Kk.j($CLJS.gY(a),$CLJS.UM,function(b){return $CLJS.Jk.g($CLJS.z_,b)}),$CLJS.v_,!0)):$CLJS.Gu(a,$CLJS.z_)});$CLJS.z_.m(null,$CLJS.YH,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);a=$CLJS.wd(b)?new $CLJS.Q(null,2,5,$CLJS.R,[a,b],null):new $CLJS.Q(null,2,5,$CLJS.R,[b,a],null);b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return $CLJS.TX(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.YH,a,b],null))});
$CLJS.z_.m(null,$CLJS.yj,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);a=$CLJS.cX(a,new $CLJS.h(null,3,[$CLJS.WE,$CLJS.iD,$CLJS.gQ,$CLJS.Pj,$CLJS.zT,$CLJS.jG],null));var c=$CLJS.S.j;var d=$CLJS.zi.h(a);$CLJS.n(d)||(d=$CLJS.iD.h(a),d=$CLJS.n(d)?d:$CLJS.CG(b));a=c.call($CLJS.S,a,$CLJS.zi,d);return $CLJS.TX(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.yj,a,b],null))});
$CLJS.z_.m(null,$CLJS.GH,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.Wh.h(c);b=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.GH,$CLJS.zk.g(c,$CLJS.Wh),$CLJS.Jk.g($CLJS.z_,b)],null);b=$CLJS.TX(b);return null!=a?$CLJS.$d.g(b,$CLJS.z_.h(a)):b});$CLJS.z_.m(null,$CLJS.fD,function(a){var b=$CLJS.H(a,0,null),c=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.TX(new $CLJS.Q(null,3,5,$CLJS.R,[b,a,c],null))});
$CLJS.z_.m(null,$CLJS.NH,function(a){var b=$CLJS.H(a,0,null),c=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.TX(new $CLJS.Q(null,3,5,$CLJS.R,[b,a,l_($CLJS.y_,c)],null))});$CLJS.z_.m(null,$CLJS.XN,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);b=$CLJS.z_.h(b);var c=$CLJS.z(b);b=$CLJS.B(c);var d=$CLJS.C(c);c=$CLJS.B(d);d=$CLJS.C(d);return $CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[b,$CLJS.Zk.l($CLJS.G([c,a]))],null),d)});fka=$CLJS.Oe($CLJS.N);gka=$CLJS.Oe($CLJS.N);
hka=$CLJS.Oe($CLJS.N);ika=$CLJS.Oe($CLJS.N);jka=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.cj,$CLJS.XD],null),$CLJS.cj,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));$CLJS.p_=new $CLJS.Xh($CLJS.xh.g("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.VD,jka,fka,gka,hka,ika);
$CLJS.p_.m(null,$CLJS.Wh,function(a){if($CLJS.yd(a)&&$CLJS.B(a)instanceof $CLJS.K){a=$CLJS.z(a);var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);var d=$CLJS.C(c);if($CLJS.wd(a)){c=$CLJS.Wf.g;b=new $CLJS.Q(null,1,5,$CLJS.R,[b],null);var e=$CLJS.Ye.g;d=$CLJS.Ze.g($CLJS.p_,d);a=o_(a);a=c.call($CLJS.Wf,b,e.call($CLJS.Ye,d,$CLJS.n(a)?new $CLJS.Q(null,1,5,$CLJS.R,[a],null):null))}else a=$CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[b],null),$CLJS.Ze.g($CLJS.p_,$CLJS.ce(a,d)))}else a=$CLJS.wd(a)?$CLJS.Gu(n_(a),$CLJS.p_):
a;return a});for(var A_=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[w_,u_],null)),B_=null,C_=0,D_=0;;)if(D_<C_){var kka=B_.X(null,D_);$CLJS.gH(kka,t_);D_+=1}else{var E_=$CLJS.z(A_);if(E_){var F_=E_;if($CLJS.zd(F_)){var G_=$CLJS.kc(F_),lka=$CLJS.lc(F_),mka=G_,nka=$CLJS.E(G_);A_=lka;B_=mka;C_=nka}else{var oka=$CLJS.B(F_);$CLJS.gH(oka,t_);A_=$CLJS.C(F_);B_=null;C_=0}D_=0}else break}
for(var H_=$CLJS.z(new $CLJS.Q(null,12,5,$CLJS.R,[$CLJS.Kz,$CLJS.SH,$CLJS.gI,$CLJS.CH,$CLJS.dk,$CLJS.lI,$CLJS.Qn,$CLJS.JH,$CLJS.eI,$CLJS.oI,$CLJS.kI,$CLJS.tI],null)),I_=null,J_=0,K_=0;;)if(K_<J_){var pka=I_.X(null,K_);$CLJS.gH(pka,w_);K_+=1}else{var L_=$CLJS.z(H_);if(L_){var M_=L_;if($CLJS.zd(M_)){var N_=$CLJS.kc(M_),qka=$CLJS.lc(M_),rka=N_,ska=$CLJS.E(N_);H_=qka;I_=rka;J_=ska}else{var tka=$CLJS.B(M_);$CLJS.gH(tka,w_);H_=$CLJS.C(M_);I_=null;J_=0}K_=0}else break}
for(var O_=$CLJS.z($CLJS.pf([$CLJS.Os,$CLJS.mu,$CLJS.Ps,$CLJS.uI,$CLJS.GH,$CLJS.aI,$CLJS.xH,$CLJS.wI,$CLJS.qH,$CLJS.LH,$CLJS.tH,$CLJS.WH,$CLJS.cI,$CLJS.uH,$CLJS.GK,$CLJS.IK,$CLJS.yA,$CLJS.CK,$CLJS.KK,$CLJS.wK,$CLJS.OK,$CLJS.BK,$CLJS.uK,$CLJS.NK,$CLJS.VK,$CLJS.RK,$CLJS.AK,$CLJS.YK,$CLJS.QK,$CLJS.WK,$CLJS.wH,$CLJS.rH,$CLJS.xI,$CLJS.NJ,$CLJS.$H,$CLJS.Iz,$CLJS.pI,$CLJS.rI,$CLJS.yI,$CLJS.Ku,$CLJS.Su],!0)),P_=null,Q_=0,R_=0;;)if(R_<Q_){var uka=P_.X(null,R_);$CLJS.gH(uka,u_);R_+=1}else{var S_=$CLJS.z(O_);
if(S_){var T_=S_;if($CLJS.zd(T_)){var U_=$CLJS.kc(T_),vka=$CLJS.lc(T_),wka=U_,xka=$CLJS.E(U_);O_=vka;P_=wka;Q_=xka}else{var yka=$CLJS.B(T_);$CLJS.gH(yka,u_);O_=$CLJS.C(T_);P_=null;Q_=0}R_=0}else break}$CLJS.p_.m(null,t_,function(a){return q_(a)});$CLJS.p_.m(null,$CLJS.eD,function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.Hk.g(m_(),$CLJS.Ze.h(function(b){var c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[c,$CLJS.p_.h(b)],null)})),a)});
$CLJS.p_.m(null,$CLJS.NH,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);if($CLJS.wd(b))try{var d=o_(b),e=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.NH,l_($CLJS.x_,c)],null);return $CLJS.n(d)?$CLJS.$d.g(e,d):e}catch(f){throw b=f,c=$CLJS.WW(b),c=$CLJS.ha.g?$CLJS.ha.g("Error converting aggregation reference to pMBQL: %s",c):$CLJS.ha.call(null,"Error converting aggregation reference to pMBQL: %s",c),$CLJS.$h(c,new $CLJS.h(null,1,[$CLJS.X,a],null),b);}else return a});
$CLJS.p_.m(null,$CLJS.cD,function(a){return $CLJS.Jk.g($CLJS.p_,a)});$CLJS.p_.m(null,$CLJS.YH,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);a=null==b||$CLJS.wd(b)?new $CLJS.Q(null,2,5,$CLJS.R,[b,a],null):new $CLJS.Q(null,2,5,$CLJS.R,[a,b],null);b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.YH,$CLJS.p_.h(a),o_(b)],null)});
$CLJS.p_.m(null,$CLJS.yj,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);b=o_($CLJS.cX(b,new $CLJS.h(null,3,[$CLJS.iD,$CLJS.WE,$CLJS.Pj,$CLJS.gQ,$CLJS.jG,$CLJS.zT],null)));return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.yj,a,b],null)});
$CLJS.p_.m(null,$CLJS.PM,function(a){var b=n_(a);a=0==$CLJS.LG.h(a).lastIndexOf("__join",0)?$CLJS.zk.g(b,$CLJS.LG):b;return $CLJS.Zk.l($CLJS.G([$CLJS.Gu($CLJS.zk.l(a,$CLJS.UM,$CLJS.G([$CLJS.XM])),$CLJS.p_),s_($CLJS.Wl(a,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.XM],null)),$CLJS.XM,$CLJS.tT),r_(a)]))});
$CLJS.p_.m(null,$CLJS.xX,function(a){var b=$CLJS.NH.h(a),c=$CLJS.Wf.j($CLJS.N,$CLJS.Yl(function(f,k){$CLJS.H(k,0,null);k=$CLJS.H(k,1,null);k=$CLJS.O(k);k=$CLJS.I.g(k,$CLJS.nG);return new $CLJS.Q(null,2,5,$CLJS.R,[f,k],null)}),b);b=$CLJS.Wf.j($CLJS.N,$CLJS.Yl(function(f,k){$CLJS.H(k,0,null);k=$CLJS.H(k,1,null);k=$CLJS.O(k);k=$CLJS.I.g(k,$CLJS.nG);return new $CLJS.Q(null,2,5,$CLJS.R,[k,f],null)}),b);var d=$CLJS.y_,e=$CLJS.x_;$CLJS.y_=c;$CLJS.x_=b;try{return $CLJS.ab(function(f,k){return $CLJS.vY(f,
k,$CLJS.p_)},s_($CLJS.vY($CLJS.vY(Yja(n_(a)),$CLJS.NH,function(f){return $CLJS.Jk.g(q_,f)}),$CLJS.CR,function(f){return $CLJS.Wf.g($CLJS.N,function(){return function m(l){return new $CLJS.le(null,function(){for(;;){var t=$CLJS.z(l);if(t){if($CLJS.zd(t)){var u=$CLJS.kc(t),v=$CLJS.E(u),x=$CLJS.oe(v);a:for(var A=0;;)if(A<v){var D=$CLJS.kd(u,A),J=$CLJS.p_.h(D);D=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bY(D),$CLJS.F.g($CLJS.yj,$CLJS.B(J))?$CLJS.hd(J):J],null);x.add(D);A+=1}else{u=!0;break a}return u?$CLJS.re($CLJS.te(x),
m($CLJS.lc(t))):$CLJS.re($CLJS.te(x),null)}x=$CLJS.B(t);u=$CLJS.p_.h(x);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bY(x),$CLJS.F.g($CLJS.yj,$CLJS.B(u))?$CLJS.hd(u):u],null),m($CLJS.Kc(t)))}return null}},null,null)}(f)}())}),$CLJS.MX,$CLJS.BH),$CLJS.Ak.l(j_,$CLJS.NH,$CLJS.G([$CLJS.MX,$CLJS.CR])))}finally{$CLJS.x_=e,$CLJS.y_=d}});$CLJS.p_.m(null,$CLJS.EX,function(a){return $CLJS.Gu(n_(a),$CLJS.p_)});
$CLJS.p_.m(null,$CLJS.KX,function(a){try{var b=n_(a),c=$CLJS.YO.h(b),d=r_(b),e=$CLJS.F.g($CLJS.ov.h($CLJS.id($CLJS.UM.h(a))),$CLJS.EX)?$CLJS.MR:$CLJS.kO;return $CLJS.Zk.l($CLJS.G([$CLJS.Gu($CLJS.zk.l(b,$CLJS.UM,$CLJS.G([$CLJS.YO,$CLJS.v_])),$CLJS.p_),function(){var k=$CLJS.Ce([$CLJS.hj,e,e,d]);return $CLJS.z(c)?$CLJS.S.j(k,$CLJS.YO,c):k}()]))}catch(k){var f=k;throw $CLJS.$h(function(){var l=$CLJS.WW(f);return $CLJS.ha.g?$CLJS.ha.g("Error converting MLv2 query to legacy query: %s",l):$CLJS.ha.call(null,
"Error converting MLv2 query to legacy query: %s",l)}(),new $CLJS.h(null,1,[$CLJS.kO,a],null),f);}});
$CLJS.V_=function(){function a(d,e,f){f=$CLJS.f_(null,$CLJS.FB(f,$CLJS.G([$CLJS.ti,!0])));var k=$CLJS.iY(d,e);k=$CLJS.O(k);var l=$CLJS.I.g(k,$CLJS.NH);k=$CLJS.Wf.j($CLJS.N,$CLJS.Yl(function(v,x){$CLJS.H(x,0,null);x=$CLJS.H(x,1,null);x=$CLJS.O(x);x=$CLJS.I.g(x,$CLJS.nG);return new $CLJS.Q(null,2,5,$CLJS.R,[v,x],null)}),l);l=$CLJS.Wf.j($CLJS.N,$CLJS.Yl(function(v,x){$CLJS.H(x,0,null);x=$CLJS.H(x,1,null);x=$CLJS.O(x);x=$CLJS.I.g(x,$CLJS.nG);return new $CLJS.Q(null,2,5,$CLJS.R,[x,v],null)}),l);var m=
$CLJS.y_,t=$CLJS.x_;$CLJS.y_=k;$CLJS.x_=l;try{try{return $CLJS.z_.h(f)}catch(v){var u=v;throw $CLJS.$h(function(){var x=$CLJS.WW(u);return $CLJS.ha.g?$CLJS.ha.g("Error converting legacy ref to pMBQL: %s",x):$CLJS.ha.call(null,"Error converting legacy ref to pMBQL: %s",x)}(),new $CLJS.h(null,4,[$CLJS.kO,d,$CLJS.oY,e,$ja,f,Zja,$CLJS.y_],null),u);}}finally{$CLJS.x_=t,$CLJS.y_=m}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();