var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var xY,Via,zY,BY,CY,Wia,EY,FY,wY,GY,Xia,Yia,Zia,$ia,JY,KY,MY,NY,OY,PY,QY,RY,yY,bja,cja,UY,dja,VY,eja,XY,fja,gja,hja,ija,jja,tY;$CLJS.uY=function(a,b){if("string"===typeof b)return tY(a,b);throw new TypeError("re-seq must match against a string.");};$CLJS.vY=function(a,b,c){var d=$CLJS.Xl(a,b);if($CLJS.n(d)){var e=$CLJS.S.j;d=$CLJS.zb(d);c=c.h?c.h(d):c.call(null,d);a=e.call($CLJS.S,a,b,c)}return a};
xY=function(a){var b=wY;return $CLJS.xC(function(c){return function(d,e,f){e=b.h?b.h(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};Via=function(a){var b=yY;return $CLJS.xC(function(c){return function(d,e,f){f=b.h?b.h(f):b.call(null,f);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};zY=function(a){if($CLJS.md(a))return a;throw $CLJS.ai("Invalid pattern: don't know how to handle symbol.",new $CLJS.h(null,1,[$CLJS.Bi,a],null));};
$CLJS.AY=function(a,b,c){return $CLJS.wd(c)?$CLJS.Wf.g(c,function(){return function f(e){return new $CLJS.le(null,function(){for(;;){var k=$CLJS.z(e);if(k){if($CLJS.zd(k)){var l=$CLJS.kc(k),m=$CLJS.E(l),t=$CLJS.oe(m);return function(){for(var A=0;;)if(A<m){var D=$CLJS.kd(l,A),J=$CLJS.H(D,0,null),M=$CLJS.H(D,1,null);D=t;var V=$CLJS.R,Z=J;J=$CLJS.$d.g(b,J);J=a.g?a.g(J,M):a.call(null,J,M);D.add(new $CLJS.Q(null,2,5,V,[Z,J],null));A+=1}else return!0}()?$CLJS.re($CLJS.te(t),f($CLJS.lc(k))):$CLJS.re($CLJS.te(t),
null)}var u=$CLJS.B(k),v=$CLJS.H(u,0,null),x=$CLJS.H(u,1,null);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[v,function(){var A=$CLJS.$d.g(b,v),D=x;return a.g?a.g(A,D):a.call(null,A,D)}()],null),f($CLJS.Kc(k)))}return null}},null,null)}(c)}()):$CLJS.vd(c)?$CLJS.Jk.g($CLJS.Me(a,$CLJS.B(c)instanceof $CLJS.K?$CLJS.$d.g(b,$CLJS.B(c)):b),c):c};BY=function(a){var b=(b=a instanceof $CLJS.K)?$CLJS.ge(a):b;return $CLJS.n(b)?[$CLJS.ge(a),"/",$CLJS.Zg(a)].join(""):$CLJS.Zg(a)};
CY=function(a,b){var c=$CLJS.vd(b)&&!$CLJS.qf(b)&&$CLJS.B(b)instanceof $CLJS.K;return c?$CLJS.td(a)?(b=$CLJS.B(b),a=$CLJS.Yg(a),a.h?a.h(b):a.call(null,b)):$CLJS.F.g(a,$CLJS.B(b)):c};
Wia=function(a,b){return $CLJS.$e(function(c){var d=$CLJS.bf;a:try{if($CLJS.yd(c)&&1<=$CLJS.E(c))try{var e=$CLJS.Ok.j(c,0,1);if($CLJS.yd(e)&&1===$CLJS.E(e))try{var f=$CLJS.bd(e,0);if($CLJS.n($CLJS.Me($CLJS.F,a)(f))){var k=$CLJS.Ok.g(c,1);var l=new $CLJS.Q(null,1,5,$CLJS.R,[k],null)}else throw $CLJS.VW;}catch(t){if(t instanceof Error){var m=t;if(m===$CLJS.VW)throw $CLJS.VW;throw m;}throw t;}else throw $CLJS.VW;}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.VW)throw $CLJS.VW;throw m;}throw t;}else throw $CLJS.VW;
}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.VW){l=new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Q(null,1,5,$CLJS.R,[c],null)],null);break a}throw m;}throw t;}return $CLJS.B($CLJS.z(d($CLJS.Ta,l)))},$CLJS.G([b]))};
EY=function(a,b){for(;;)switch(b=$CLJS.al.h($CLJS.bf($CLJS.Ta,b)),$CLJS.E(b)){case 0:return null;case 1:return a=$CLJS.B(b),DY.h?DY.h(a):DY.call(null,a);default:if($CLJS.n($CLJS.Je($CLJS.Me(CY,a),b))){var c=a;b=Wia(a,b);a=c}else{c=$CLJS.Ze.g(DY,b);if($CLJS.F.g(c,b))return $CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[a],null),b);b=c}}};FY=function(a){var b=$CLJS.vd(a);return b?(b=!$CLJS.qf(a))?$CLJS.rC($CLJS.ee,$CLJS.Va)($CLJS.B(a)):b:b};
wY=function(a){return $CLJS.n($CLJS.rC($CLJS.ee,$CLJS.Va)(a))?$CLJS.rh.h($CLJS.vC(BY(a).toLowerCase(),/_/,"-")):a};GY=function(a,b){var c=FY(b);return $CLJS.n(c)?(b=wY($CLJS.B(b)),$CLJS.td(a)?(a=$CLJS.Yg(a),a.h?a.h(b):a.call(null,b)):$CLJS.F.g(a,b)):c};Xia=function(a){return $CLJS.I.j(new $CLJS.h(null,3,[$CLJS.Wh,$CLJS.Rd,$CLJS.hj,wY,$CLJS.tP,wY],null),a,function(b){var c=$CLJS.I,d=c.g;b=$CLJS.Ce([a,b]);b=HY.g?HY.g(b,IY):HY.call(null,b,IY);return d.call(c,b,a)})};
Yia=function(a){a=$CLJS.Wf.j($CLJS.N,$CLJS.Ze.h(function(b){var c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);c=wY(c);var d=Xia(c);return new $CLJS.Q(null,2,5,$CLJS.R,[c,d.h?d.h(b):d.call(null,b)],null)}),a);return $CLJS.F.g($CLJS.hj.h(a),$CLJS.MN)&&$CLJS.Sa($CLJS.tP.h(a))?$CLJS.S.j(a,$CLJS.tP,$CLJS.sI):a};Zia=function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.Ze.h(function(b){var c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);c=BY(c);return new $CLJS.Q(null,2,5,$CLJS.R,[c,$CLJS.S.j(Yia(b),$CLJS.T,c)],null)}),a)};
$ia=function(a){a=HY.h?HY.h(a):HY.call(null,a);return yY.h?yY.h(a):yY.call(null,a)};JY=function(a){return $CLJS.vY($CLJS.vY($CLJS.ab(function(b,c){return $CLJS.vY(b,c,$CLJS.rh)},a,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.WE,$CLJS.Ica,$CLJS.gQ,aja,$CLJS.TB,$CLJS.li],null)),$CLJS.WD,$ia),$CLJS.vP,$CLJS.ZW)};KY=function(a){return $CLJS.Ed(a)?new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.YH,a,null],null):a};MY=function(a){return LY.h(KY(a))};
NY=function(a){var b=$CLJS.z(a);a=$CLJS.B(b);b=$CLJS.C(b);return DY($CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[a],null),$CLJS.Ze.g(LY,b)))};OY=function(a){var b=$CLJS.z(a);a=$CLJS.B(b);var c=$CLJS.C(b);b=$CLJS.B(c);c=$CLJS.C(c);return $CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[a,MY(b)],null),$CLJS.Ze.g(LY,c))};PY=function(a){var b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return $CLJS.n(a)?new $CLJS.Q(null,2,5,$CLJS.R,[b,MY(a)],null):new $CLJS.Q(null,1,5,$CLJS.R,[b],null)};
QY=function(a){var b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[b,MY(a)],null)};RY=function(a){var b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[b,LY.h(a)],null)};
yY=function(a){return SY(function(b){if($CLJS.wd(b))return Via(b);if($CLJS.Sa(FY(b)))return b;try{return LY.h(b)}catch(f){if(f instanceof Error){var c=f,d=$CLJS.BC($CLJS.vA);if($CLJS.n($CLJS.AC("metabase.mbql.normalize",d))){var e=$CLJS.zG("Invalid clause:");e instanceof Error?$CLJS.zC("metabase.mbql.normalize",d,$CLJS.fy.l($CLJS.G([b])),e):$CLJS.zC("metabase.mbql.normalize",d,$CLJS.fy.l($CLJS.G([e,b])),null)}throw $CLJS.$h($CLJS.YF("Invalid MBQL clause: {0}",$CLJS.G([$CLJS.WW(c)])),new $CLJS.h(null,
1,[$CLJS.CT,b],null),c);}throw f;}},a)};bja=function(a){return $CLJS.uf($CLJS.Ul(yY,TY(a)))};cja=function(a){for(;;)if($CLJS.n(FY(a)))a=new $CLJS.Q(null,1,5,$CLJS.R,[a],null);else return $CLJS.De($CLJS.Jk.g(KY,a))};UY=function(a){a=$CLJS.O(a);var b=$CLJS.I.g(a,$CLJS.MN);return $CLJS.n(b)?$CLJS.Kk.j(a,$CLJS.MN,LY):a};
dja=function(a){return $CLJS.Wf.g($CLJS.N,function(){return function d(c){return new $CLJS.le(null,function(){for(;;){var e=$CLJS.z(c);if(e){if($CLJS.zd(e)){var f=$CLJS.kc(e),k=$CLJS.E(f),l=$CLJS.oe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.H(t,0,null);t=$CLJS.H(t,1,null);u=new $CLJS.Q(null,2,5,$CLJS.R,[u,UY(t)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.re($CLJS.te(l),d($CLJS.lc(e))):$CLJS.re($CLJS.te(l),null)}f=$CLJS.B(e);l=$CLJS.H(f,0,null);f=$CLJS.H(f,1,null);return $CLJS.ce(new $CLJS.Q(null,
2,5,$CLJS.R,[l,UY(f)],null),d($CLJS.Kc(e)))}return null}},null,null)}(a)}())};VY=function(a){a=$CLJS.O(a);var b=$CLJS.I.g(a,$CLJS.jS);return $CLJS.n(b)?$CLJS.Kk.j(a,$CLJS.jS,dja):a};eja=function(a){var b=$CLJS.O(a);a=$CLJS.I.g(b,$CLJS.MR);b=$CLJS.Sa(a)?WY.h?WY.h(b):WY.call(null,b):b;return $CLJS.n(a)?VY(b):b};XY=function(a){return $CLJS.td(a)?$CLJS.z(a):null!=a};
fja=function(a){var b=$CLJS.O(a);a=$CLJS.I.g(b,$CLJS.kO);var c=$CLJS.I.g(b,$CLJS.YO),d=$CLJS.I.g(b,$CLJS.uQ),e=$CLJS.I.g(b,$CLJS.MR);try{if($CLJS.n(d)){var f=$CLJS.O(b),k=$CLJS.I.g(f,$CLJS.uQ);var l=$CLJS.xN($CLJS.zk.g(f,$CLJS.uQ),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kO,$CLJS.uQ],null),k)}else l=b;var m=$CLJS.n(a)?$CLJS.Kk.j(l,$CLJS.kO,WY):l,t=$CLJS.n(c)?$CLJS.Kk.j(m,$CLJS.YO,$CLJS.Me($CLJS.Jk,yY)):m,u=$CLJS.n(e)?$CLJS.Kk.j(t,$CLJS.MR,VY):t;return yY(u)}catch(v){if(v instanceof Error)throw m=v,$CLJS.$h($CLJS.YF("Error canonicalizing query: {0}",
$CLJS.G([$CLJS.WW(m)])),new $CLJS.h(null,1,[$CLJS.kO,a],null),m);throw v;}};
gja=function(a){a=$CLJS.O(a);var b=$CLJS.I.g(a,$CLJS.kO),c=$CLJS.O(b);b=$CLJS.I.g(c,$CLJS.MH);c=$CLJS.I.g(c,$CLJS.HG);return $CLJS.z(b)&&$CLJS.z(c)?(b=$CLJS.Wf.j($CLJS.Ug,$CLJS.hE,$CLJS.z($CLJS.bf($CLJS.Ta,function k(e,f){try{if($CLJS.yd(f)&&3===$CLJS.E(f))try{var l=$CLJS.bd(f,0);if($CLJS.fe(l,$CLJS.YH)){var m=$CLJS.bd(f,1),t=$CLJS.bd(f,2);return new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[f,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.YH,m,$CLJS.zk.g(t,$CLJS.dI)],null)],null)],null)}throw $CLJS.VW;
}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.VW)throw $CLJS.VW;throw l;}throw u;}else throw $CLJS.VW;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.VW)return $CLJS.dX(k,e,f);throw l;}throw u;}}($CLJS.tf,b)))),$CLJS.YY.j(a,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kO,$CLJS.HG],null),$CLJS.Hk.g($CLJS.uf,$CLJS.Me($CLJS.Ik,b)))):a};
hja=function(a){try{return gja(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.$h($CLJS.zG("Error performing whole query transformations"),new $CLJS.h(null,1,[$CLJS.kO,a],null),b);}throw c;}};
ija=function(a,b){var c=$CLJS.Wf.g($CLJS.jd(a),function(){return function f(e){return new $CLJS.le(null,function(){for(var k=e;;)if(k=$CLJS.z(k)){if($CLJS.zd(k)){var l=$CLJS.kc(k),m=$CLJS.E(l),t=$CLJS.oe(m);return function(){for(var A=0;;)if(A<m){var D=$CLJS.kd(l,A),J=$CLJS.H(D,0,null);D=$CLJS.H(D,1,null);var M=$CLJS.$d.g(b,J);D=ZY.g?ZY.g(D,M):ZY.call(null,D,M);null!=D&&t.add(new $CLJS.Q(null,2,5,$CLJS.R,[J,D],null));A+=1}else return!0}()?$CLJS.re($CLJS.te(t),f($CLJS.lc(k))):$CLJS.re($CLJS.te(t),
null)}var u=$CLJS.B(k),v=$CLJS.H(u,0,null),x=$CLJS.H(u,1,null);u=function(){var A=x,D=$CLJS.$d.g(b,v);return ZY.g?ZY.g(A,D):ZY.call(null,A,D)}();if(null!=u)return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[v,u],null),f($CLJS.Kc(k)));k=$CLJS.Kc(k)}else return null},null,null)}(a)}());return $CLJS.z(c)?c:null};jja=function(a,b){a=$CLJS.Jk.g(function(c){var d=$CLJS.$d.g(b,$Y);return ZY.g?ZY.g(c,d):ZY.call(null,c,d)},a);return $CLJS.n($CLJS.Je($CLJS.Ta,a))?a:null};
tY=function tY(a,b){var d=a.exec(b);if(null==d)return null;var e=d[0],f=1===d.length?e:$CLJS.uf(d);return $CLJS.ce(f,new $CLJS.le(null,function(){var k=e.length;k=d.index+(1>k?1:k);return k<=b.length?(k=b.substring(k),tY.g?tY.g(a,k):tY.call(null,a,k)):null},null,null))};
$CLJS.YY=function YY(a){switch(arguments.length){case 3:return YY.j(arguments[0],arguments[1],arguments[2]);case 4:return YY.v(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return YY.N(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return YY.W(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return YY.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.y(c.slice(6),0,null))}};$CLJS.YY.j=function(a,b,c){var d=$CLJS.z(b);b=$CLJS.B(d);if(d=$CLJS.C(d))a=$CLJS.S.j(a,b,$CLJS.YY.j($CLJS.I.g(a,b),d,c));else{d=$CLJS.S.j;var e=$CLJS.I.g(a,b);c=c.h?c.h(e):c.call(null,e);a=d.call($CLJS.S,a,b,c)}return a};
$CLJS.YY.v=function(a,b,c,d){var e=$CLJS.z(b);b=$CLJS.B(e);if(e=$CLJS.C(e))a=$CLJS.S.j(a,b,$CLJS.YY.v($CLJS.I.g(a,b),e,c,d));else{e=$CLJS.S.j;var f=$CLJS.I.g(a,b);c=c.g?c.g(f,d):c.call(null,f,d);a=e.call($CLJS.S,a,b,c)}return a};$CLJS.YY.N=function(a,b,c,d,e){var f=$CLJS.z(b);b=$CLJS.B(f);if(f=$CLJS.C(f))a=$CLJS.S.j(a,b,$CLJS.YY.N($CLJS.I.g(a,b),f,c,d,e));else{f=$CLJS.S.j;var k=$CLJS.I.g(a,b);c=c.j?c.j(k,d,e):c.call(null,k,d,e);a=f.call($CLJS.S,a,b,c)}return a};
$CLJS.YY.W=function(a,b,c,d,e,f){var k=$CLJS.z(b);b=$CLJS.B(k);if(k=$CLJS.C(k))a=$CLJS.S.j(a,b,$CLJS.YY.W($CLJS.I.g(a,b),k,c,d,e,f));else{k=$CLJS.S.j;var l=$CLJS.I.g(a,b);c=c.v?c.v(l,d,e,f):c.call(null,l,d,e,f);a=k.call($CLJS.S,a,b,c)}return a};$CLJS.YY.l=function(a,b,c,d,e,f,k){var l=$CLJS.z(b);b=$CLJS.B(l);return(l=$CLJS.C(l))?$CLJS.S.j(a,b,$CLJS.P.l($CLJS.YY,$CLJS.I.g(a,b),l,c,d,$CLJS.G([e,f,k]))):$CLJS.S.j(a,b,$CLJS.P.l(c,$CLJS.I.g(a,b),d,e,f,$CLJS.G([k])))};
$CLJS.YY.B=function(a){var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);var d=$CLJS.C(c);c=$CLJS.B(d);var e=$CLJS.C(d);d=$CLJS.B(e);var f=$CLJS.C(e);e=$CLJS.B(f);var k=$CLJS.C(f);f=$CLJS.B(k);k=$CLJS.C(k);return this.l(b,a,c,d,e,f,k)};$CLJS.YY.A=6;
var SY=function SY(a,b){return $CLJS.XW($CLJS.Me(SY,a),$CLJS.Rd,a.h?a.h(b):a.call(null,b))},DY=function DY(a){for(;;){if($CLJS.wd(a))return $CLJS.Gu(a,DY);if($CLJS.Cd(a))a=$CLJS.uf(a);else if($CLJS.yd(a)){if($CLJS.Sa($CLJS.Je($CLJS.Ta,a)))return null;var c=a,d=$CLJS.z(c),e=$CLJS.B(d),f=$CLJS.C(d),k=e,l=f,m=$CLJS.H(l,0,null),t=l,u=k,v=u instanceof $CLJS.K?u.T:null;switch(v){case "not":if($CLJS.Dl(m)){var x=$CLJS.B(m),A=x instanceof $CLJS.K?x.T:null;switch(A){case "not":a=$CLJS.hd(m);continue;case "and":return EY($CLJS.lt,
$CLJS.Ze.g(function(){return function(D){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.nt,D],null)}}(a,x,A,u,v,c,d,e,f,k,l,m,t),$CLJS.Kc(m)));case "or":return EY($CLJS.rt,$CLJS.Ze.g(function(){return function(D){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.nt,D],null)}}(a,x,A,u,v,c,d,e,f,k,l,m,t),$CLJS.Kc(m)));default:return a}}else return a;case "and":return EY($CLJS.rt,t);case "or":return EY($CLJS.lt,t);default:return $CLJS.Jk.g(DY,a)}}else return a}},kja=new $CLJS.K(null,"value_field","value_field",
-980977878),aZ=new $CLJS.K(null,"ascending","ascending",-988350486),bZ=new $CLJS.K(null,"named","named",-422393479),cZ=new $CLJS.K(null,"values_source_config","values_source_config",-590570309),dZ=new $CLJS.K(null,"descending","descending",-24766135),eZ=new $CLJS.K(null,"binning-strategy","binning-strategy",2063329158),fZ=new $CLJS.K(null,"datetime-field","datetime-field",21731696),lja=new $CLJS.K(null,"use-as-display-name?","use-as-display-name?",686752941),gZ=new $CLJS.K(null,"field-literal","field-literal",
-1295883554),hZ=new $CLJS.K(null,"viz-settings","viz-settings",256055379),mja=new $CLJS.K(null,"rows","rows",850049680),nja=new $CLJS.K(null,"special-fn","special-fn",1290649344),$Y=new $CLJS.K("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683),IY=new $CLJS.K(null,"ignore-path","ignore-path",944069061),oja=new $CLJS.K(null,"label_field","label_field",-1573182765),pja=new $CLJS.K(null,"fk-\x3e","fk-\x3e",-499026738),qja=new $CLJS.K(null,"joined-field","joined-field",
-2048778268),aja=new $CLJS.K(null,"visibility_type","visibility_type",-508434247);var iZ,rja=$CLJS.Oe($CLJS.N),sja=$CLJS.Oe($CLJS.N),tja=$CLJS.Oe($CLJS.N),uja=$CLJS.Oe($CLJS.N),vja=$CLJS.I.j($CLJS.N,$CLJS.cj,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));iZ=new $CLJS.Xh($CLJS.xh.g("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.Hk.g(wY,$CLJS.B),vja,rja,sja,tja,uja);iZ.m(null,$CLJS.NH,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);b=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.NH,b],null);return null!=a?$CLJS.$d.g(b,a):b});
iZ.m(null,$CLJS.fD,function(a){$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fD,a instanceof $CLJS.K?BY(a):a],null)});iZ.m(null,eZ,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return $CLJS.n(a)?$CLJS.$d.g(iZ.h(new $CLJS.Q(null,3,5,$CLJS.R,[eZ,b,c],null)),a):new $CLJS.Q(null,3,5,$CLJS.R,[eZ,HY.g?HY.g(b,IY):HY.call(null,b,IY),wY(c)],null)});
iZ.m(null,$CLJS.YH,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);var c=HY.g?HY.g(a,IY):HY.call(null,a,IY);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.YH,b,function(){var d=$CLJS.n($CLJS.iD.h(c))?$CLJS.Kk.j(c,$CLJS.iD,$CLJS.rh):c;d=$CLJS.n($CLJS.dI.h(c))?$CLJS.Kk.j(d,$CLJS.dI,$CLJS.rh):d;return $CLJS.n($CLJS.YS.h(c))?$CLJS.Kk.j(d,$CLJS.YS,function(e){return $CLJS.n($CLJS.sG.h(e))?$CLJS.Kk.j(e,$CLJS.sG,$CLJS.rh):e}):d}()],null)});
iZ.m(null,gZ,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[gZ,b instanceof $CLJS.K?BY(b):b,$CLJS.rh.h(a)],null)});iZ.m(null,fZ,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return $CLJS.n(a)?new $CLJS.Q(null,4,5,$CLJS.R,[fZ,HY.g?HY.g(b,IY):HY.call(null,b,IY),$CLJS.ku,wY(a)],null):new $CLJS.Q(null,3,5,$CLJS.R,[fZ,HY.g?HY.g(b,IY):HY.call(null,b,IY),wY(c)],null)});
iZ.m(null,$CLJS.jI,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null),d=$CLJS.H(a,3,null);a=$CLJS.H(a,4,null);return $CLJS.n(a)?$CLJS.$d.g(iZ.h(new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.jI,b,c,d],null)),HY.g?HY.g(a,IY):HY.call(null,a,IY)):new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.jI,HY.g?HY.g(b,IY):HY.call(null,b,IY),$CLJS.Ed(c)?c:wY(c),wY(d)],null)});
iZ.m(null,$CLJS.IK,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.n(a)?new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.IK,b,wY(a)],null):new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.IK,$CLJS.vx],null)});iZ.m(null,$CLJS.GK,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.GK,b,wY(a)],null)});
iZ.m(null,$CLJS.QK,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.QK,HY.g?HY.g(b,IY):HY.call(null,b,IY),c,wY(a)],null)});iZ.m(null,$CLJS.WK,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.WK,HY.g?HY.g(b,IY):HY.call(null,b,IY),c,wY(a)],null)});
iZ.m(null,$CLJS.OK,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.n(a)?new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.OK,HY.g?HY.g(b,IY):HY.call(null,b,IY),wY(a)],null):new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.OK,HY.g?HY.g(b,IY):HY.call(null,b,IY)],null)});
iZ.m(null,$CLJS.UK,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return $CLJS.n(a)?new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.UK,HY.g?HY.g(b,IY):HY.call(null,b,IY),wY(c),wY(a)],null):new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.UK,HY.g?HY.g(b,IY):HY.call(null,b,IY),wY(c)],null)});
iZ.m(null,$CLJS.FK,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.FK,HY.g?HY.g(b,IY):HY.call(null,b,IY),HY.g?HY.g(c,IY):HY.call(null,c,IY),wY(a)],null)});iZ.m(null,$CLJS.yj,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.yj,b,a],null)});
iZ.m(null,$CLJS.Wh,function(a){var b=$CLJS.z(a);a=$CLJS.B(b);b=$CLJS.C(b);return $CLJS.Wf.j(new $CLJS.Q(null,1,5,$CLJS.R,[wY(a)],null),$CLJS.Ze.h(function(c){return HY.g?HY.g(c,IY):HY.call(null,c,IY)}),b)});
var jZ=function jZ(a){if($CLJS.n($CLJS.rC($CLJS.ee,$CLJS.Va)(a))){var c=wY(a);var d=new $CLJS.Tg(null,new $CLJS.h(null,18,[$CLJS.Qn,null,$CLJS.Ps,null,$CLJS.oI,null,$CLJS.gI,null,$CLJS.mu,null,$CLJS.ZH,null,$CLJS.tI,null,$CLJS.uI,null,$CLJS.CH,null,$CLJS.JH,null,$CLJS.Os,null,$CLJS.DH,null,$CLJS.lI,null,$CLJS.eI,null,$CLJS.dk,null,$CLJS.Kz,null,$CLJS.SH,null,$CLJS.kI,null],null),null);c=d.h?d.h(c):d.call(null,c)}else c=null;return $CLJS.n(c)?c:$CLJS.n(FY(a))?(a=$CLJS.B(a),jZ.h?jZ.h(a):jZ.call(null,
a)):null},wja=new $CLJS.h(null,8,[$CLJS.hj,wY,$CLJS.MR,function(a){a=xY(a);return $CLJS.z($CLJS.jS.h(a))?$CLJS.Kk.j(a,$CLJS.jS,Zia):a},$CLJS.kO,new $CLJS.h(null,6,[$CLJS.NH,function kZ(a){if($CLJS.n($CLJS.rC($CLJS.ee,$CLJS.Va)(a)))return wY(a);if($CLJS.n(GY(bZ,a))){a=$CLJS.z(a);$CLJS.B(a);var c=$CLJS.C(a);a=$CLJS.B(c);c=$CLJS.C(c);return $CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[bZ,kZ.h?kZ.h(a):kZ.call(null,a)],null),c)}return $CLJS.n($CLJS.n(FY(a))?jZ($CLJS.hd(a)):null)?$CLJS.Jk.g(kZ,a):HY.g?HY.g(a,
IY):HY.call(null,a,IY)},$CLJS.CR,function(a){return $CLJS.Wf.g($CLJS.N,function(){return function d(c){return new $CLJS.le(null,function(){for(;;){var e=$CLJS.z(c);if(e){if($CLJS.zd(e)){var f=$CLJS.kc(e),k=$CLJS.E(f),l=$CLJS.oe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.H(t,0,null);t=$CLJS.H(t,1,null);u=new $CLJS.Q(null,2,5,$CLJS.R,[BY(u),HY.g?HY.g(t,IY):HY.call(null,t,IY)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.re($CLJS.te(l),d($CLJS.lc(e))):$CLJS.re($CLJS.te(l),null)}f=
$CLJS.B(e);l=$CLJS.H(f,0,null);f=$CLJS.H(f,1,null);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[BY(l),HY.g?HY.g(f,IY):HY.call(null,f,IY)],null),d($CLJS.Kc(e)))}return null}},null,null)}(a)}())},$CLJS.rT,function(a){return $CLJS.uf(function(){return function d(c){return new $CLJS.le(null,function(){for(;;){var e=$CLJS.z(c);if(e){if($CLJS.zd(e)){var f=$CLJS.kc(e),k=$CLJS.E(f),l=$CLJS.oe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m);t=$CLJS.n(FY(t))?iZ.h(t):$CLJS.ae(iZ.h($CLJS.ae(t)));l.add(t);m+=
1}else{f=!0;break a}return f?$CLJS.re($CLJS.te(l),d($CLJS.lc(e))):$CLJS.re($CLJS.te(l),null)}l=$CLJS.B(e);return $CLJS.ce($CLJS.n(FY(l))?iZ.h(l):$CLJS.ae(iZ.h($CLJS.ae(l))),d($CLJS.Kc(e)))}return null}},null,null)}(a)}())},$CLJS.lS,function(a){a=xY(a);a=$CLJS.O(a);var b=$CLJS.I.g(a,$CLJS.MR);if($CLJS.n(b))return a=$CLJS.cX(a,new $CLJS.h(null,1,[$CLJS.MR,$CLJS.kO],null)),b=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.MR],null),a=HY.g?HY.g(a,b):HY.call(null,a,b),$CLJS.cX(a,new $CLJS.h(null,1,[$CLJS.kO,$CLJS.MR],
null));b=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.kO],null);return HY.g?HY.g(a,b):HY.call(null,a,b)},$CLJS.uQ,new $CLJS.h(null,1,[$Y,JY],null),$CLJS.gP,new $CLJS.h(null,1,[$Y,function(a){a=HY.g?HY.g(a,$CLJS.kO):HY.call(null,a,$CLJS.kO);var b=$CLJS.O(a),c=$CLJS.I.g(b,$CLJS.sG),d=$CLJS.I.g(b,$CLJS.HG);a=$CLJS.I.g(b,$CLJS.LG);b=$CLJS.n(c)?$CLJS.Kk.j(b,$CLJS.sG,wY):b;d=$CLJS.n($CLJS.rC($CLJS.ee,$CLJS.Va)(d))?$CLJS.Kk.j(b,$CLJS.HG,wY):b;return $CLJS.n(a)?$CLJS.Kk.j(d,$CLJS.LG,BY):d}],null)],null),$CLJS.zA,
new $CLJS.h(null,1,[$CLJS.SP,$CLJS.Rd],null),$CLJS.YO,new $CLJS.h(null,1,[$Y,function(a){var b=$CLJS.O(a),c=$CLJS.I.g(b,$CLJS.hj),d=$CLJS.I.g(b,$CLJS.aL),e=$CLJS.I.g(b,$CLJS.Ti);a=$CLJS.I.g(b,cZ);b=$CLJS.n(e)?$CLJS.Kk.j(b,$CLJS.Ti,BY):b;c=$CLJS.n(c)?$CLJS.Kk.j(b,$CLJS.hj,wY):b;d=$CLJS.n(d)?$CLJS.Kk.j(c,$CLJS.aL,function(f){return HY.g?HY.g(f,IY):HY.call(null,f,IY)}):c;d=$CLJS.n(a)?$CLJS.YY.j(d,new $CLJS.Q(null,2,5,$CLJS.R,[cZ,oja],null),function(f){return HY.g?HY.g(f,IY):HY.call(null,f,IY)}):d;return $CLJS.n(a)?
$CLJS.YY.j(d,new $CLJS.Q(null,2,5,$CLJS.R,[cZ,kja],null),function(f){return HY.g?HY.g(f,IY):HY.call(null,f,IY)}):d}],null),$CLJS.TO,function(a){return null==a?null:wY(a)},$CLJS.uQ,new $CLJS.h(null,1,[$Y,JY],null),hZ,wY],null),HY=function HY(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return HY.l(arguments[0],1<c.length?new $CLJS.y(c.slice(1),0,null):null)};
HY.l=function(a,b){b=$CLJS.H(b,0,null);var c=b instanceof $CLJS.K?new $CLJS.Q(null,1,5,$CLJS.R,[b],null):$CLJS.uf(b);b=$CLJS.z(c)?$CLJS.sC(wja,c):null;try{return $CLJS.md(b)?b.h?b.h(a):b.call(null,a):$CLJS.xd(a)?a:$CLJS.wd(a)?$CLJS.Wf.g($CLJS.N,function(){return function k(f){return new $CLJS.le(null,function(){for(;;){var l=$CLJS.z(f);if(l){if($CLJS.zd(l)){var m=$CLJS.kc(l),t=$CLJS.E(m),u=$CLJS.oe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v),A=$CLJS.H(x,0,null);x=$CLJS.H(x,1,null);A=wY(A);A=new $CLJS.Q(null,
2,5,$CLJS.R,[A,HY.l(x,$CLJS.G([$CLJS.$d.g($CLJS.uf(c),A)]))],null);u.add(A);v+=1}else{m=!0;break a}return m?$CLJS.re($CLJS.te(u),k($CLJS.lc(l))):$CLJS.re($CLJS.te(u),null)}m=$CLJS.B(l);u=$CLJS.H(m,0,null);m=$CLJS.H(m,1,null);u=wY(u);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[u,HY.l(m,$CLJS.G([$CLJS.$d.g($CLJS.uf(c),u)]))],null),k($CLJS.Kc(l)))}return null}},null,null)}(a)}()):$CLJS.n(FY(a))?iZ.h(a):$CLJS.vd(a)?$CLJS.Jk.g(function(e){return HY.l(e,$CLJS.G([$CLJS.$d.g($CLJS.uf(c),$Y)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.$h($CLJS.YF("Error normalizing form: {0}",$CLJS.G([$CLJS.WW(d)])),new $CLJS.h(null,3,[$CLJS.RB,a,$CLJS.gl,c,nja,b],null),d);}throw e;}};HY.A=1;HY.B=function(a){var b=$CLJS.B(a);a=$CLJS.C(a);return this.l(b,a)};
var LY=function(){var a=$CLJS.Oe($CLJS.N),b=$CLJS.Oe($CLJS.N),c=$CLJS.Oe($CLJS.N),d=$CLJS.Oe($CLJS.N),e=$CLJS.I.j($CLJS.N,$CLJS.cj,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));return new $CLJS.Xh($CLJS.xh.g("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.n(FY(f))?$CLJS.B(f):null},e,a,b,c,d)}();LY.m(null,$CLJS.Wh,function(a){return a});
LY.m(null,$CLJS.YH,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);if($CLJS.n(GY($CLJS.YH,b))){$CLJS.H(b,0,null);var c=$CLJS.H(b,1,null);b=$CLJS.H(b,2,null);return LY.h(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.YH,c,$CLJS.De($CLJS.Zk.l($CLJS.G([b,a])))],null))}return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.YH,b,$CLJS.De(a)],null)});
LY.m(null,$CLJS.NH,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.sd(a)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.NH,b],null):new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.NH,b,a],null)});LY.m(null,$CLJS.CM,function(a){$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return $CLJS.n(FY(a))?LY.h(a):new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.YH,a,null],null)});
LY.m(null,gZ,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.YH,b,new $CLJS.h(null,1,[$CLJS.iD,a],null)],null)});LY.m(null,pja,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);var c=MY(b);$CLJS.H(c,0,null);b=$CLJS.H(c,1,null);$CLJS.H(c,2,null);c=MY(a);$CLJS.H(c,0,null);a=$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.YH,a,$CLJS.S.j(c,$CLJS.hP,b)],null)});
LY.m(null,qja,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);a=MY(a);return $CLJS.P.v($CLJS.jX,a,$CLJS.S,$CLJS.G([$CLJS.XQ,b]))});
LY.m(null,fZ,function(a){var b=$CLJS.E(a);switch(b){case 3:$CLJS.H(a,0,null);b=$CLJS.H(a,1,null);var c=$CLJS.H(a,2,null);a=MY(b);b=c;$CLJS.H(a,0,null);$CLJS.H(a,1,null);c=$CLJS.H(a,2,null);c=$CLJS.O(c);var d=$CLJS.I.g(c,$CLJS.iD);$CLJS.Sa(d)||$CLJS.nU.g(d,b)?a=$CLJS.P.v($CLJS.jX,a,$CLJS.S,$CLJS.G([$CLJS.dI,b])):(c=$CLJS.BC($CLJS.xA),$CLJS.n($CLJS.AC("metabase.mbql.util",c))&&(b=$CLJS.YF("{0} is not a valid temporal unit for {1}; not adding to clause {2}",$CLJS.G([b,d,$CLJS.Eh.l($CLJS.G([a]))])),b instanceof
Error?$CLJS.zC("metabase.mbql.util",c,$CLJS.fy(),b):$CLJS.zC("metabase.mbql.util",c,$CLJS.fy.l($CLJS.G([b])),null)));return a;case 4:return $CLJS.H(a,0,null),b=$CLJS.H(a,1,null),$CLJS.H(a,2,null),c=$CLJS.H(a,3,null),LY.h(new $CLJS.Q(null,3,5,$CLJS.R,[fZ,b,c],null));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}});
LY.m(null,eZ,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null),d=$CLJS.H(a,3,null);a=$CLJS.H(a,4,null);var e=MY(b);$CLJS.H(e,0,null);b=$CLJS.H(e,1,null);e=$CLJS.H(e,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.YH,b,$CLJS.S.j(e,$CLJS.YS,$CLJS.Zk.l($CLJS.G([new $CLJS.h(null,1,[$CLJS.sG,c],null),$CLJS.n(d)?$CLJS.Ce([c,d]):null,a])))],null)});
for(var lZ=$CLJS.z(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rt,$CLJS.lt,$CLJS.nt],null)),mZ=null,nZ=0,oZ=0;;)if(oZ<nZ){var pZ=mZ.X(null,oZ);LY.m(null,pZ,function(){return function(a){return NY(a)}}(lZ,mZ,nZ,oZ,pZ));oZ+=1}else{var qZ=$CLJS.z(lZ);if(qZ){var rZ=qZ;if($CLJS.zd(rZ)){var sZ=$CLJS.kc(rZ),xja=$CLJS.lc(rZ),yja=sZ,zja=$CLJS.E(sZ);lZ=xja;mZ=yja;nZ=zja}else{var tZ=$CLJS.B(rZ);LY.m(null,tZ,function(){return function(a){return NY(a)}}(lZ,mZ,nZ,oZ,tZ,rZ,qZ));lZ=$CLJS.C(rZ);mZ=null;nZ=0}oZ=0}else break}
LY.m(null,$CLJS.FH,function(a){a=$CLJS.z(a);$CLJS.B(a);var b=$CLJS.C(a);a=$CLJS.B(b);var c=$CLJS.C(b);b=$CLJS.B(c);c=$CLJS.C(c);return $CLJS.Wf.g(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.FH,MY(a),MY(b)],null),c)});LY.m(null,$CLJS.jI,function(a){a=$CLJS.z(a);$CLJS.B(a);var b=$CLJS.C(a);a=$CLJS.B(b);b=$CLJS.C(b);var c=MY(a);a=$CLJS.n(CY($CLJS.YH,a))?$CLJS.jX.l(c,$CLJS.zk,$CLJS.G([$CLJS.dI])):c;return $CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jI,a],null),b)});
for(var uZ=$CLJS.z(new $CLJS.Q(null,15,5,$CLJS.R,[$CLJS.PH,$CLJS.iI,$CLJS.XH,$CLJS.QH,$CLJS.Ls,$CLJS.qI,$CLJS.Is,$CLJS.Ks,$CLJS.Es,$CLJS.Gs,$CLJS.AH,$CLJS.HH,$CLJS.yH,$CLJS.KH,$CLJS.zH],null)),vZ=null,wZ=0,xZ=0;;)if(xZ<wZ){var yZ=vZ.X(null,xZ);LY.m(null,yZ,function(){return function(a){return OY(a)}}(uZ,vZ,wZ,xZ,yZ));xZ+=1}else{var zZ=$CLJS.z(uZ);if(zZ){var AZ=zZ;if($CLJS.zd(AZ)){var BZ=$CLJS.kc(AZ),Aja=$CLJS.lc(AZ),Bja=BZ,Cja=$CLJS.E(BZ);uZ=Aja;vZ=Bja;wZ=Cja}else{var CZ=$CLJS.B(AZ);LY.m(null,CZ,
function(){return function(a){return OY(a)}}(uZ,vZ,wZ,xZ,CZ,AZ,zZ));uZ=$CLJS.C(AZ);vZ=null;wZ=0}xZ=0}else break}LY.m(null,mja,function(){return null});LY.m(null,$CLJS.XN,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.XN,LY.h(b),a],null)});
LY.m(null,bZ,function(a){a=$CLJS.z(a);$CLJS.B(a);a=$CLJS.C(a);var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);var d=$CLJS.C(c);c=LY.h;var e=$CLJS.R;b=LY.h(b);d=$CLJS.H(d,0,null);d=$CLJS.O(d);a=!1===$CLJS.I.g(d,lja)?new $CLJS.h(null,1,[$CLJS.T,a],null):new $CLJS.h(null,1,[$CLJS.cG,a],null);return c.call(LY,new $CLJS.Q(null,3,5,e,[$CLJS.XN,b,a],null))});
for(var DZ=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Kz,$CLJS.ZH],null)),EZ=null,FZ=0,GZ=0;;)if(GZ<FZ){var HZ=EZ.X(null,GZ);LY.m(null,HZ,function(){return function(a){return PY(a)}}(DZ,EZ,FZ,GZ,HZ));GZ+=1}else{var IZ=$CLJS.z(DZ);if(IZ){var JZ=IZ;if($CLJS.zd(JZ)){var KZ=$CLJS.kc(JZ),Dja=$CLJS.lc(JZ),Eja=KZ,Fja=$CLJS.E(KZ);DZ=Dja;EZ=Eja;FZ=Fja}else{var LZ=$CLJS.B(JZ);LY.m(null,LZ,function(){return function(a){return PY(a)}}(DZ,EZ,FZ,GZ,LZ,JZ,IZ));DZ=$CLJS.C(JZ);EZ=null;FZ=0}GZ=0}else break}
for(var MZ=$CLJS.z(new $CLJS.Q(null,9,5,$CLJS.R,[$CLJS.SH,$CLJS.vI,$CLJS.CH,$CLJS.oI,$CLJS.kI,$CLJS.Qn,$CLJS.dk,$CLJS.lI,$CLJS.DH],null)),NZ=null,OZ=0,PZ=0;;)if(PZ<OZ){var QZ=NZ.X(null,PZ);LY.m(null,QZ,function(){return function(a){return QY(a)}}(MZ,NZ,OZ,PZ,QZ));PZ+=1}else{var RZ=$CLJS.z(MZ);if(RZ){var SZ=RZ;if($CLJS.zd(SZ)){var TZ=$CLJS.kc(SZ),Gja=$CLJS.lc(SZ),Hja=TZ,Ija=$CLJS.E(TZ);MZ=Gja;NZ=Hja;OZ=Ija}else{var UZ=$CLJS.B(SZ);LY.m(null,UZ,function(){return function(a){return QY(a)}}(MZ,NZ,OZ,PZ,
UZ,SZ,RZ));MZ=$CLJS.C(SZ);NZ=null;OZ=0}PZ=0}else break}LY.m(null,$CLJS.JH,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.JH,MY(b),a],null)});
for(var VZ=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.eI,$CLJS.gI],null)),WZ=null,XZ=0,YZ=0;;)if(YZ<XZ){var ZZ=WZ.X(null,YZ);LY.m(null,ZZ,function(){return function(a){return RY(a)}}(VZ,WZ,XZ,YZ,ZZ));YZ+=1}else{var $Z=$CLJS.z(VZ);if($Z){var a_=$Z;if($CLJS.zd(a_)){var b_=$CLJS.kc(a_),Jja=$CLJS.lc(a_),Kja=b_,Lja=$CLJS.E(b_);VZ=Jja;WZ=Kja;XZ=Lja}else{var c_=$CLJS.B(a_);LY.m(null,c_,function(){return function(a){return RY(a)}}(VZ,WZ,XZ,YZ,c_,a_,$Z));VZ=$CLJS.C(a_);WZ=null;XZ=0}YZ=0}else break}
LY.m(null,$CLJS.tI,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.tI,LY.h(b),LY.h(a)],null)});
LY.m(null,$CLJS.GH,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.n(a)?$CLJS.$d.g(LY.h(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.GH,b],null)),HY.l(a,$CLJS.G([IY]))):new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.GH,$CLJS.uf(function(){return function e(d){return new $CLJS.le(null,function(){for(;;){var f=$CLJS.z(d);if(f){if($CLJS.zd(f)){var k=$CLJS.kc(f),l=$CLJS.E(k),m=$CLJS.oe(l);a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.H(u,0,null);u=$CLJS.H(u,1,null);v=new $CLJS.Q(null,
2,5,$CLJS.R,[LY.h(v),LY.h(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.re($CLJS.te(m),e($CLJS.lc(f))):$CLJS.re($CLJS.te(m),null)}k=$CLJS.B(f);m=$CLJS.H(k,0,null);k=$CLJS.H(k,1,null);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[LY.h(m),LY.h(k)],null),e($CLJS.Kc(f)))}return null}},null,null)}(b)}())],null)});
LY.m(null,$CLJS.rH,function(a){a=$CLJS.z(a);$CLJS.B(a);var b=$CLJS.C(a);a=$CLJS.B(b);var c=$CLJS.C(b);b=$CLJS.B(c);c=$CLJS.C(c);return $CLJS.Wf.g(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rH,LY.h(a),$CLJS.F.g(0,b)?1:LY.h(b)],null),$CLJS.Ze.g(LY,c))});
var TY=function TY(a){return function f(d,e){try{if($CLJS.n(function(){var x=zY($CLJS.Cd);return x.h?x.h(e):x.call(null,e)}()))return f(d,$CLJS.uf(e));throw $CLJS.VW;}catch(x){if(x instanceof Error)if(d=x,d===$CLJS.VW)try{if($CLJS.n(function(){var A=zY($CLJS.ee);return A.h?A.h(e):A.call(null,e)}()))return new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Q(null,1,5,$CLJS.R,[e],null)],null);throw $CLJS.VW;}catch(A){if(A instanceof Error)if(d=A,d===$CLJS.VW)try{if($CLJS.yd(e)&&2<=$CLJS.E(e))try{var k=$CLJS.Ok.j(e,
0,2);if($CLJS.yd(k)&&2===$CLJS.E(k))try{var l=$CLJS.bd(k,0);if($CLJS.n($CLJS.qN($CLJS.ee,$CLJS.Ke(new $CLJS.Tg(null,new $CLJS.h(null,5,[$CLJS.Ps,null,$CLJS.mu,null,$CLJS.uI,null,$CLJS.Os,null,bZ,null],null),null)))(l)))try{var m=$CLJS.bd(k,1);if($CLJS.n(jZ(m)))return $CLJS.Wf.j($CLJS.tf,$CLJS.XF(TY),a);throw $CLJS.VW;}catch(D){if(D instanceof Error){var t=D;if(t===$CLJS.VW)throw $CLJS.VW;throw t;}throw D;}else throw $CLJS.VW;}catch(D){if(D instanceof Error){t=D;if(t===$CLJS.VW)throw $CLJS.VW;throw t;
}throw D;}else throw $CLJS.VW;}catch(D){if(D instanceof Error){t=D;if(t===$CLJS.VW)throw $CLJS.VW;throw t;}throw D;}else throw $CLJS.VW;}catch(D){if(D instanceof Error)if(t=D,t===$CLJS.VW)try{if($CLJS.yd(e)&&1<=$CLJS.E(e))try{var u=$CLJS.Ok.j(e,0,1);if($CLJS.yd(u)&&1===$CLJS.E(u))try{if($CLJS.bd(u,0)instanceof $CLJS.K)return new $CLJS.Q(null,1,5,$CLJS.R,[e],null);throw $CLJS.VW;}catch(J){if(J instanceof Error){var v=J;if(v===$CLJS.VW)throw $CLJS.VW;throw v;}throw J;}else throw $CLJS.VW;}catch(J){if(J instanceof
Error){v=J;if(v===$CLJS.VW)throw $CLJS.VW;throw v;}throw J;}else throw $CLJS.VW;}catch(J){if(J instanceof Error){v=J;if(v===$CLJS.VW)return e;throw v;}throw J;}else throw t;else throw D;}else throw d;else throw A;}else throw d;else throw x;}}($CLJS.tf,a)},d_=function d_(a){return function f(d,e){try{var k=zY($CLJS.Cd);var l=k.h?k.h(e):k.call(null,e);if($CLJS.n(l))return f(d,$CLJS.uf(e));throw $CLJS.VW;}catch(D){if(D instanceof Error)if(l=D,l===$CLJS.VW)try{if($CLJS.yd(e)&&2===$CLJS.E(e))try{var m=
$CLJS.bd(e,1);if($CLJS.fe(m,$CLJS.VH)){var t=$CLJS.bd(e,0);return f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.VH,t],null))}throw $CLJS.VW;}catch(J){if(J instanceof Error)if(l=J,l===$CLJS.VW)try{m=$CLJS.bd(e,1);if($CLJS.fe(m,$CLJS.OH))return t=$CLJS.bd(e,0),f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.OH,t],null));throw $CLJS.VW;}catch(M){if(M instanceof Error){var u=M;if(u===$CLJS.VW)try{m=$CLJS.bd(e,1);if($CLJS.fe(m,aZ))return t=$CLJS.bd(e,0),f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.VH,t],null));throw $CLJS.VW;
}catch(V){if(V instanceof Error)if(l=V,l===$CLJS.VW)try{m=$CLJS.bd(e,1);if($CLJS.fe(m,dZ))return t=$CLJS.bd(e,0),f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.OH,t],null));throw $CLJS.VW;}catch(Z){if(Z instanceof Error)if(m=Z,m===$CLJS.VW)try{var v=$CLJS.bd(e,0);if($CLJS.fe(v,aZ))return t=$CLJS.bd(e,1),f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.VH,t],null));throw $CLJS.VW;}catch(fa){if(fa instanceof Error)if(m=fa,m===$CLJS.VW)try{v=$CLJS.bd(e,0);if($CLJS.fe(v,dZ))return t=$CLJS.bd(e,1),f(d,new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.OH,t],null));throw $CLJS.VW;}catch(Da){if(Da instanceof Error)if(m=Da,m===$CLJS.VW)try{v=$CLJS.bd(e,0);if($CLJS.fe(v,$CLJS.VH))return t=$CLJS.bd(e,1),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.VH,MY(t)],null);throw $CLJS.VW;}catch(Ha){if(Ha instanceof Error)if(m=Ha,m===$CLJS.VW)try{v=$CLJS.bd(e,0);if($CLJS.fe(v,$CLJS.OH))return t=$CLJS.bd(e,1),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.OH,MY(t)],null);throw $CLJS.VW;}catch(mb){if(mb instanceof Error){t=mb;if(t===$CLJS.VW)throw $CLJS.VW;throw t;
}throw mb;}else throw m;else throw Ha;}else throw m;else throw Da;}else throw m;else throw fa;}else throw m;else throw Z;}else throw l;else throw V;}else throw u;}else throw M;}else throw l;else throw J;}else throw $CLJS.VW;}catch(J){if(J instanceof Error)if(l=J,l===$CLJS.VW)try{if($CLJS.yd(e)&&0<=$CLJS.E(e))try{var x=$CLJS.Ok.j(e,0,0);if($CLJS.yd(x)&&0===$CLJS.E(x))try{var A=$CLJS.Ok.g(e,0);if($CLJS.F.g(A,a))return $CLJS.uf($CLJS.al.h($CLJS.Ze.g(d_,a)));throw $CLJS.VW;}catch(M){if(M instanceof Error){u=
M;if(u===$CLJS.VW)throw $CLJS.VW;throw u;}throw M;}else throw $CLJS.VW;}catch(M){if(M instanceof Error){u=M;if(u===$CLJS.VW)throw $CLJS.VW;throw u;}throw M;}else throw $CLJS.VW;}catch(M){if(M instanceof Error){u=M;if(u===$CLJS.VW)return $CLJS.AY(f,d,e);throw u;}throw M;}else throw l;else throw J;}else throw l;else throw D;}}($CLJS.tf,a)},WY=$CLJS.Hk.g(yY,function(a){var b=XY($CLJS.NH.h(a))?$CLJS.Kk.j(a,$CLJS.NH,bja):a;b=XY($CLJS.MH.h(a))?$CLJS.Kk.j(b,$CLJS.MH,cja):b;b=XY($CLJS.HG.h(a))?$CLJS.Kk.j(b,
$CLJS.HG,$CLJS.Me($CLJS.Jk,KY)):b;b=XY($CLJS.rT.h(a))?$CLJS.Kk.j(b,$CLJS.rT,d_):b;return XY($CLJS.lS.h(a))?$CLJS.Kk.j(b,$CLJS.lS,eja):b}),Mja=new $CLJS.h(null,3,[$CLJS.MR,$CLJS.Rd,$CLJS.kO,new $CLJS.h(null,2,[$CLJS.lS,function(a){a=$CLJS.O(a);var b=$CLJS.I.g(a,$CLJS.MR);if($CLJS.n(b))return a=$CLJS.cX(a,new $CLJS.h(null,1,[$CLJS.MR,$CLJS.kO],null)),b=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.MR],null),a=ZY.g?ZY.g(a,b):ZY.call(null,a,b),$CLJS.cX(a,new $CLJS.h(null,1,[$CLJS.kO,$CLJS.MR],null));b=new $CLJS.Q(null,
1,5,$CLJS.R,[$CLJS.kO],null);return ZY.g?ZY.g(a,b):ZY.call(null,a,b)},$CLJS.gP,new $CLJS.h(null,1,[$Y,function(a){var b=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.kO],null);return ZY.g?ZY.g(a,b):ZY.call(null,a,b)}],null)],null),hZ,$CLJS.Rd],null),ZY=function ZY(a){switch(arguments.length){case 1:return ZY.h(arguments[0]);case 2:return ZY.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};ZY.h=function(a){return ZY.g(a,$CLJS.tf)};
ZY.g=function(a,b){try{var c=$CLJS.z(b)?$CLJS.sC(Mja,b):null;return $CLJS.md(c)?c.h?c.h(a):c.call(null,a):$CLJS.xd(a)?a:$CLJS.wd(a)?ija(a,b):$CLJS.vd(a)?jja(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.$h("Error removing empty clauses from form.",new $CLJS.h(null,2,[$CLJS.RB,a,$CLJS.gl,b],null),d);throw d;}};ZY.A=2;
$CLJS.e_=function(){var a=$CLJS.Hk.l(ZY,hja,fja,$CLJS.G([HY]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.$h($CLJS.YF("Error normalizing query: {0}",$CLJS.G([$CLJS.WW(c)])),new $CLJS.h(null,1,[$CLJS.kO,b],null),c);}throw d;}}}();$CLJS.f_=function f_(a,b){if($CLJS.Sa($CLJS.z(a)))a=(0,$CLJS.e_)(b);else{var d=$CLJS.I,e=d.g;var f=$CLJS.Eu(a);b=$CLJS.Ce([$CLJS.id(a),b]);f=f_.g?f_.g(f,b):f_.call(null,f,b);a=e.call(d,f,$CLJS.id(a))}return a};