var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var kJ,lJ,Nda,nJ,Oda,Pda,Qda,oJ,mJ;$CLJS.iJ=function(a,b){return $CLJS.ec($CLJS.ab(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.Zf.j(c,e,$CLJS.$d.g($CLJS.I.j(c,e,$CLJS.tf),d))},$CLJS.cc($CLJS.N),b))};$CLJS.jJ=function(a,b){return $CLJS.ab(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Rc(d):null},null,b)};
kJ=function(a,b){$CLJS.H(a,0,null);$CLJS.H(a,1,null);$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);b=$CLJS.iE(b,$CLJS.JE)?$CLJS.VI:$CLJS.iE(b,$CLJS.HF)?$CLJS.RI:$CLJS.iE(b,$CLJS.hF)?$CLJS.TI:null;return $CLJS.n(b)?$CLJS.fH(b,a):!0};
lJ=function(a){a=$CLJS.z(a);$CLJS.B(a);a=$CLJS.C(a);$CLJS.B(a);a=$CLJS.C(a);a=$CLJS.iJ(function(d){return $CLJS.iE($CLJS.CG(d),$CLJS.UE)},a);var b=$CLJS.O(a);a=$CLJS.I.g(b,!1);b=$CLJS.I.g(b,!0);if($CLJS.Fk.g($CLJS.E(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.E(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.CG($CLJS.B(a));return $CLJS.Je(function(d){$CLJS.H(d,0,null);$CLJS.H(d,1,null);$CLJS.H(d,2,null);
var e=$CLJS.H(d,3,null);return $CLJS.n(kJ(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
Nda=function(a){return new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.rt,new $CLJS.h(null,1,[$CLJS.wu,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.Ys,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,a],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.mG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ws,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,mJ],null)],
null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.KG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ws,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,mJ],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,1,[$CLJS.yu,function(b){b=$CLJS.O(b);b=$CLJS.I.g(b,$CLJS.yj);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(lJ(b))].join("")}],null),$CLJS.Ke(lJ)],null)],null)};
nJ=function(a){var b=$CLJS.R,c=Nda(a);a=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Ys,new $CLJS.h(null,1,[$CLJS.wu,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,a],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.mG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ws,new $CLJS.h(null,1,[$CLJS.Qn,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.ZG],null)],null)],null)],null);
return new $CLJS.Q(null,3,5,b,[$CLJS.lt,c,a],null)};Oda=function(a){return $CLJS.Od($CLJS.iH,$CLJS.Ze.g(function(b){var c=$CLJS.CG(b),d=$CLJS.iE(c,$CLJS.OG);b=d?$CLJS.fH($CLJS.bI,b):d;return $CLJS.n(b)?$CLJS.Jj:c},a))};Pda=function(a){a=$CLJS.jJ(function(b){return!$CLJS.iE(b,$CLJS.UE)},$CLJS.Ze.g($CLJS.CG,a));return $CLJS.iE(a,$CLJS.OG)?$CLJS.Qj:a};Qda=function(a){return $CLJS.n($CLJS.Je(function(b){return $CLJS.iE($CLJS.CG(b),$CLJS.UE)},a))?Pda(a):Oda(a)};
oJ=new $CLJS.K("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);$CLJS.pJ=new $CLJS.K("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);mJ=new $CLJS.K("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.Y(new $CLJS.K("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.at,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.IG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.KG],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[new $CLJS.K(null,"intervals","intervals",2096054013),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Os,new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.X,mJ],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,1,[$CLJS.wu,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.z(a);a=$CLJS.B(b);b=$CLJS.C(b);var c=$CLJS.CG(a);return $CLJS.Ie(function(d){return kJ(d,c)},b)}],null)],null));
$CLJS.Y(oJ,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ws,new $CLJS.h(null,1,[$CLJS.Qn,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.ZG],null)],null)],null));$CLJS.zI.g($CLJS.Os,nJ($CLJS.Os));$CLJS.zI.g($CLJS.mu,nJ($CLJS.mu));$CLJS.pH($CLJS.Ps,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Tt,oJ],null)]));$CLJS.pH($CLJS.uI,$CLJS.G([$CLJS.mu,$CLJS.EF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Tt,oJ],null)]));
for(var qJ=$CLJS.z(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Os,$CLJS.mu,$CLJS.Ps],null)),rJ=null,sJ=0,tJ=0;;)if(tJ<sJ){var Rda=rJ.X(null,tJ);$CLJS.gH(Rda,$CLJS.pJ);tJ+=1}else{var uJ=$CLJS.z(qJ);if(uJ){var vJ=uJ;if($CLJS.zd(vJ)){var wJ=$CLJS.kc(vJ),Sda=$CLJS.lc(vJ),Tda=wJ,Uda=$CLJS.E(wJ);qJ=Sda;rJ=Tda;sJ=Uda}else{var Vda=$CLJS.B(vJ);$CLJS.gH(Vda,$CLJS.pJ);qJ=$CLJS.C(vJ);rJ=null;sJ=0}tJ=0}else break}$CLJS.BG.m(null,$CLJS.pJ,function(a){a=$CLJS.z(a);$CLJS.B(a);a=$CLJS.C(a);$CLJS.B(a);a=$CLJS.C(a);return Qda(a)});
$CLJS.nH($CLJS.xH,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.ZG],null)],null)]));$CLJS.gH($CLJS.xH,$CLJS.TG);
for(var xJ=$CLJS.z(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.wI,$CLJS.qH,$CLJS.LH],null)),yJ=null,zJ=0,AJ=0;;)if(AJ<zJ){var Wda=yJ.X(null,AJ);$CLJS.nH(Wda,$CLJS.G([$CLJS.mu,$CLJS.EF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.ZG],null)],null)]));AJ+=1}else{var BJ=$CLJS.z(xJ);if(BJ){var CJ=BJ;if($CLJS.zd(CJ)){var DJ=$CLJS.kc(CJ),Xda=$CLJS.lc(CJ),Yda=DJ,Zda=$CLJS.E(DJ);xJ=Xda;yJ=Yda;zJ=Zda}else{var $da=$CLJS.B(CJ);$CLJS.nH($da,$CLJS.G([$CLJS.mu,$CLJS.EF,new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.ZG],null)],null)]));xJ=$CLJS.C(CJ);yJ=null;zJ=0}AJ=0}else break}
for(var EJ=$CLJS.z(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.tH,$CLJS.WH,$CLJS.cI],null)),FJ=null,GJ=0,HJ=0;;)if(HJ<GJ){var aea=FJ.X(null,HJ);$CLJS.nH(aea,$CLJS.G([$CLJS.mu,$CLJS.rj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.ZG],null)],null)]));HJ+=1}else{var IJ=$CLJS.z(EJ);if(IJ){var JJ=IJ;if($CLJS.zd(JJ)){var KJ=$CLJS.kc(JJ),bea=$CLJS.lc(JJ),cea=KJ,dea=$CLJS.E(KJ);EJ=bea;FJ=cea;GJ=dea}else{var eea=$CLJS.B(JJ);$CLJS.nH(eea,$CLJS.G([$CLJS.mu,$CLJS.rj,new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.ZG],null)],null)]));EJ=$CLJS.C(JJ);FJ=null;GJ=0}HJ=0}else break}$CLJS.nH($CLJS.uH,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.ZG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.ZG],null)],null)]));
$CLJS.BG.m(null,$CLJS.uH,function(a){$CLJS.H(a,0,null);$CLJS.H(a,1,null);var b=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return $CLJS.iE($CLJS.CG(b),$CLJS.rj)&&$CLJS.iE($CLJS.CG(a),$CLJS.rj)?$CLJS.rj:$CLJS.EF});