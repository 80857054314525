var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';$CLJS.c3=function(a,b){return $CLJS.ec($CLJS.ab(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.dc(c,d):c},$CLJS.cc($CLJS.tf),b))};$CLJS.d3=function(a,b,c){return null==c||$CLJS.Gd(a,b)?a:$CLJS.S.j(a,b,c)};$CLJS.e3=function(a,b){return $CLJS.Wf.j(new $CLJS.Q(null,2,5,$CLJS.R,[a,new $CLJS.h(null,1,[$CLJS.nG,$CLJS.p.h($CLJS.yG())],null)],null),$CLJS.Ze.h($CLJS.VX),b)};
$CLJS.f3=function(a,b){if($CLJS.z(b)){if($CLJS.F.g($CLJS.E(b),1))return $CLJS.B(b);a=[" ",(0,$CLJS.ma)(a)," "].join("");return $CLJS.F.g($CLJS.E(b),2)?[$CLJS.p.h($CLJS.B(b)),a,$CLJS.p.h($CLJS.hd(b))].join(""):[$CLJS.Hu(", ",$CLJS.Eu(b)),",",a,$CLJS.p.h($CLJS.id(b))].join("")}return null};$CLJS.h3=function(a,b){return $CLJS.g3.g(a,b instanceof $CLJS.K?b:$CLJS.li.h(b))};var Jla,Kla,Lla,Mla,Nla,k3;
$CLJS.i3=function(){function a(e,f){if($CLJS.Sa(f))return"";e=Math.abs(e);var k=$CLJS.rh.h(f);switch(k instanceof $CLJS.K?k.T:null){case "default":return $CLJS.bX("Default period","Default periods",e);case "millisecond":return $CLJS.bX("Millisecond","Milliseconds",e);case "second":return $CLJS.bX("Second","Seconds",e);case "minute":return $CLJS.bX("Minute","Minutes",e);case "hour":return $CLJS.bX("Hour","Hours",e);case "day":return $CLJS.bX("Day","Days",e);case "week":return $CLJS.bX("Week","Weeks",
e);case "month":return $CLJS.bX("Month","Months",e);case "quarter":return $CLJS.bX("Quarter","Quarters",e);case "year":return $CLJS.bX("Year","Years",e);case "minute-of-hour":return $CLJS.bX("Minute of hour","Minutes of hour",e);case "hour-of-day":return $CLJS.bX("Hour of day","Hours of day",e);case "day-of-week":return $CLJS.bX("Day of week","Days of week",e);case "day-of-month":return $CLJS.bX("Day of month","Days of month",e);case "day-of-year":return $CLJS.bX("Day of year","Days of year",e);case "week-of-year":return $CLJS.bX("Week of year",
"Weeks of year",e);case "month-of-year":return $CLJS.bX("Month of year","Months of year",e);case "quarter-of-year":return $CLJS.bX("Quarter of year","Quarters of year",e);default:return f=$CLJS.dv($CLJS.Zg(f),/-/),e=$CLJS.z(f),f=$CLJS.B(e),e=$CLJS.C(e),$CLJS.Hu(" ",$CLJS.ce($CLJS.oC(f),e))}}function b(e){return d.g?d.g(1,e):d.call(null,1,e)}function c(){return d.g?d.g(1,null):d.call(null,1,null)}var d=null;d=function(e,f){switch(arguments.length){case 0:return c.call(this);case 1:return b.call(this,
e);case 2:return a.call(this,e,f)}throw Error("Invalid arity: "+arguments.length);};d.o=c;d.h=b;d.g=a;return d}();$CLJS.g3=function(){var a=$CLJS.Oe($CLJS.N),b=$CLJS.Oe($CLJS.N),c=$CLJS.Oe($CLJS.N),d=$CLJS.Oe($CLJS.N),e=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.cj,$CLJS.XD],null),$CLJS.cj,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));return new $CLJS.Xh($CLJS.xh.g("metabase.lib.temporal-bucket","with-temporal-bucket-method"),function(f){return $CLJS.VD(f)},e,a,b,c,d)}();Jla=$CLJS.Oe($CLJS.N);Kla=$CLJS.Oe($CLJS.N);
Lla=$CLJS.Oe($CLJS.N);Mla=$CLJS.Oe($CLJS.N);Nla=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.cj,$CLJS.XD],null),$CLJS.cj,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));$CLJS.j3=new $CLJS.Xh($CLJS.xh.g("metabase.lib.temporal-bucket","temporal-bucket-method"),$CLJS.VD,Nla,Jla,Kla,Lla,Mla);$CLJS.j3.m(null,$CLJS.Wh,function(){return null});$CLJS.j3.m(null,$CLJS.UI,function(a){return $CLJS.li.h(a)});k3=new $CLJS.Tg(null,new $CLJS.h(null,4,[$CLJS.LI,null,$CLJS.OI,null,$CLJS.PI,null,$CLJS.MI,null],null),null);
$CLJS.Ola=$CLJS.Wf.j($CLJS.tf,$CLJS.Hk.g($CLJS.Ik.h(k3),$CLJS.Ze.h(function(a){var b=new $CLJS.h(null,2,[$CLJS.ov,$CLJS.UI,$CLJS.li,a],null);return $CLJS.F.g(a,$CLJS.pv)?$CLJS.S.j(b,$CLJS.Wh,!0):b})),$CLJS.eJ);$CLJS.Pla=$CLJS.Jk.g(function(a){var b=new $CLJS.h(null,2,[$CLJS.ov,$CLJS.UI,$CLJS.li,a],null);return $CLJS.F.g(a,$CLJS.mk)?$CLJS.S.j(b,$CLJS.Wh,!0):b},$CLJS.$I);
$CLJS.Qla=$CLJS.Wf.j($CLJS.tf,$CLJS.Hk.g($CLJS.Ik.h(k3),$CLJS.Ze.h(function(a){var b=new $CLJS.h(null,2,[$CLJS.ov,$CLJS.UI,$CLJS.li,a],null);return $CLJS.F.g(a,$CLJS.mk)?$CLJS.S.j(b,$CLJS.Wh,!0):b})),$CLJS.fJ);$CLJS.S1.m(null,$CLJS.UI,function(a,b,c){a=$CLJS.O(c);a=$CLJS.I.g(a,$CLJS.li);return $CLJS.i3.h(a)});
$CLJS.Z1.m(null,$CLJS.UI,function(a,b,c){return $CLJS.Zk.l($CLJS.G([new $CLJS.h(null,2,[$CLJS.cG,$CLJS.u1.j(a,b,c),$CLJS.P1,$CLJS.PC($CLJS.j3.h(c))],null),$CLJS.Wl(c,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wh,$CLJS.C1],null))]))});
$CLJS.l3=function(){var a=$CLJS.Oe($CLJS.N),b=$CLJS.Oe($CLJS.N),c=$CLJS.Oe($CLJS.N),d=$CLJS.Oe($CLJS.N),e=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.cj,$CLJS.XD],null),$CLJS.cj,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));return new $CLJS.Xh($CLJS.xh.g("metabase.lib.temporal-bucket","available-temporal-buckets-method"),function(f,k,l){return $CLJS.VD(l)},e,a,b,c,d)}();$CLJS.l3.m(null,$CLJS.Wh,function(){return $CLJS.Ug});
$CLJS.m3=function(){function a(d,e,f){return $CLJS.l3.j(d,e,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();