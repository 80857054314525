var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$crc_32.js");require("./clojure.set.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var WX,Oia,Pia,dY,Qia,Ria,Tia,$X,Uia,Sia;WX=function(a){return a+1};$CLJS.XX=function(a){if("string"===typeof a){var b=$CLJS.fh(/[+-]?\d+/,a);return $CLJS.n(b)?(a=parseInt(a),a<=Number.MAX_SAFE_INTEGER&&a>=Number.MIN_SAFE_INTEGER?a:null):b}throw Error($CLJS.am(a));};
Oia=function(a){var b=new $CLJS.Ea,c=$CLJS.Rv;$CLJS.Rv=new $CLJS.sc(b);try{var d=$CLJS.Rv,e=$CLJS.Sa($CLJS.hx(d)),f=$CLJS.Rv;$CLJS.Rv=e?$CLJS.Zw(d):d;try{d=$CLJS.jx;$CLJS.jx=!0;try{$CLJS.lx(a)}finally{$CLJS.jx=d}$CLJS.F.g(0,$CLJS.Zv($CLJS.Rv,$CLJS.aw))||$CLJS.$b($CLJS.Rv,"\n");$CLJS.Yv()}finally{$CLJS.Rv=f}$CLJS.mh($CLJS.p.h(b))}finally{$CLJS.Rv=c}};$CLJS.YX=function(a,b){return $CLJS.P.j($CLJS.ha,a,b)};
$CLJS.ZX=function(a){var b=new $CLJS.Ea,c=$CLJS.Oa,d=$CLJS.Pa;$CLJS.Oa=!0;$CLJS.Pa=function(e){return b.append(e)};try{Oia(a)}finally{$CLJS.Pa=d,$CLJS.Oa=c}return $CLJS.p.h(b)};
Pia=function(a){a=$CLJS.O(a);var b=$CLJS.I.j(a,$CLJS.gX,$CLJS.Rd),c=$CLJS.I.j(a,$CLJS.eX,function(f,k){return[$CLJS.p.h(f),"_",$CLJS.p.h(k)].join("")}),d=$CLJS.Oe($CLJS.N),e=$CLJS.Oe($CLJS.N);return function(){function f(m,t){for(;;){var u=b.h?b.h(t):b.call(null,t),v=function(){var A=new $CLJS.Q(null,2,5,$CLJS.R,[m,u],null),D=$CLJS.q(d);return D.h?D.h(A):D.call(null,A)}();if($CLJS.n(v))return v;var x=$CLJS.I.g($CLJS.Gh.v(e,$CLJS.Kk,u,$CLJS.JF(WX,0)),u);if($CLJS.F.g(x,1))return $CLJS.Gh.v(d,$CLJS.S,
new $CLJS.Q(null,2,5,$CLJS.R,[m,u],null),t),t;t=function(){var A=t,D=$CLJS.p.h(x);return c.g?c.g(A,D):c.call(null,A,D)}()}}function k(m){var t=l,u=t.g;null==$X&&($X=$CLJS.Oe(0));var v=$CLJS.xh.h([$CLJS.p.h("G__"),$CLJS.p.h($CLJS.Gh.g($X,WX))].join(""));return u.call(t,v,m)}var l=null;l=function(m,t){switch(arguments.length){case 1:return k.call(this,m);case 2:return f.call(this,m,t)}throw Error("Invalid arity: "+arguments.length);};l.h=k;l.g=f;return l}()};
$CLJS.aY=function(a){return $CLJS.yd(a)&&1<$CLJS.E(a)&&$CLJS.B(a)instanceof $CLJS.K&&$CLJS.wd($CLJS.hd(a))&&$CLJS.Gd($CLJS.hd(a),$CLJS.nG)};$CLJS.bY=function(a){return $CLJS.aY(a)?$CLJS.sC(a,new $CLJS.Q(null,2,5,$CLJS.R,[1,$CLJS.PG],null)):null};$CLJS.cY=function(a,b){return $CLJS.xN($CLJS.aY(a)?a:new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.yj,new $CLJS.h(null,2,[$CLJS.nG,$CLJS.p.h($CLJS.yG()),$CLJS.zi,$CLJS.CG(a)],null),a],null),new $CLJS.Q(null,2,5,$CLJS.R,[1,$CLJS.PG],null),b)};
dY=function(a,b,c){var d=$CLJS.Gd(a,b)?$CLJS.Kk.j(a,b,function(e){return $CLJS.yd(e)&&$CLJS.F.g($CLJS.B(e),$CLJS.rt)?$CLJS.uf($CLJS.Te(1,e)):new $CLJS.Q(null,1,5,$CLJS.R,[e],null)}):a;return $CLJS.Gd(a,b)?$CLJS.cX(d,$CLJS.Ce([b,c])):d};Qia=function(a){var b=$CLJS.Wl(a,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.IQ,$CLJS.lS],null));b=eY.h?eY.h(b):eY.call(null,b);return $CLJS.TX($CLJS.S.l(dY($CLJS.zk.l(a,$CLJS.IQ,$CLJS.G([$CLJS.lS])),$CLJS.tT,$CLJS.XM),$CLJS.ov,$CLJS.PM,$CLJS.G([$CLJS.UM,b])))};
Ria=function(a){return $CLJS.Jk.g(Qia,a)};Tia=function(a){return $CLJS.n(a)?$CLJS.S.j($CLJS.Kk.j($CLJS.Dl(a)?new $CLJS.h(null,1,[$CLJS.fY,a],null):a,$CLJS.fY,function(b){return $CLJS.Jk.g(function(c){return $CLJS.S.j($CLJS.tC(c,$CLJS.UC),$CLJS.ov,$CLJS.tM)},b)}),$CLJS.ov,Sia):null};
$CLJS.gY=function(a){if($CLJS.F.g($CLJS.ov.h(a),$CLJS.KX))return a;var b=$CLJS.hj.h(a);b=b instanceof $CLJS.K?b.T:null;switch(b){case "native":return $CLJS.Zk.l($CLJS.G([new $CLJS.h(null,2,[$CLJS.ov,$CLJS.KX,$CLJS.UM,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.Zk.l($CLJS.G([new $CLJS.h(null,1,[$CLJS.ov,$CLJS.EX],null),$CLJS.cX($CLJS.MR.h(a),new $CLJS.h(null,1,[$CLJS.kO,$CLJS.MR],null))]))],null)],null),$CLJS.zk.l(a,$CLJS.hj,$CLJS.G([$CLJS.MR]))]));case "query":return $CLJS.Zk.l($CLJS.G([new $CLJS.h(null,
2,[$CLJS.ov,$CLJS.KX,$CLJS.UM,eY($CLJS.kO.h(a))],null),$CLJS.zk.l(a,$CLJS.hj,$CLJS.G([$CLJS.kO]))]));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}};$CLJS.hY=function(a,b){a=$CLJS.O(a);a=$CLJS.I.g(a,$CLJS.UM);var c=0>b?$CLJS.E(a)+b:b;if(c>=$CLJS.E(a)||0>c)throw $CLJS.ai($CLJS.YF("Stage {0} does not exist",$CLJS.G([b])),new $CLJS.h(null,1,[Uia,$CLJS.E(a)],null));return c};
$CLJS.iY=function(a,b){a=$CLJS.gY(a);a=$CLJS.O(a);var c=$CLJS.I.g(a,$CLJS.UM);return $CLJS.I.g($CLJS.uf(c),$CLJS.hY(a,b))};$CLJS.jY=function(a){return"string"===typeof a&&(a=$CLJS.gh(/^card__(\d+)$/,a),$CLJS.n(a))?($CLJS.H(a,0,null),a=$CLJS.H(a,1,null),$CLJS.XX(a)):null};$CLJS.lY=function(){return $CLJS.Hk.g(kY,Pia($CLJS.G([$CLJS.gX,$CLJS.QC,$CLJS.eX,function(a,b){return kY.h([$CLJS.p.h(a),"_",$CLJS.p.h(b)].join(""))}])))};$X=null;
$CLJS.mY=new $CLJS.K("metabase.lib.util","cannot-remove-final-join-condition","metabase.lib.util/cannot-remove-final-join-condition",-470911224);$CLJS.nY=new $CLJS.K(null,"join","join",-758861890);$CLJS.oY=new $CLJS.K(null,"stage-number","stage-number",-1752729638);$CLJS.pY=new $CLJS.K(null,"stage","stage",1843544772);Uia=new $CLJS.K(null,"num-stages","num-stages",1426797535);$CLJS.fY=new $CLJS.K(null,"columns","columns",1998437288);$CLJS.qY=new $CLJS.K(null,"table","table",-564943036);
Sia=new $CLJS.K("metadata","results","metadata/results",330329298);var eY,kY;
eY=function eY(a){a=$CLJS.O(a);var c=$CLJS.I.g(a,$CLJS.lS),d=$CLJS.I.g(a,$CLJS.uQ);c=$CLJS.n(c)?eY.h?eY.h(c):eY.call(null,c):$CLJS.tf;d=Tia(d);var e=$CLJS.E(c)-1;c=$CLJS.n($CLJS.n(d)?!(0>e):d)?$CLJS.xN(c,new $CLJS.Q(null,2,5,$CLJS.R,[e,$CLJS.QW],null),d):c;d=$CLJS.n($CLJS.MR.h(a))?$CLJS.EX:$CLJS.xX;a=$CLJS.Zk.l($CLJS.G([new $CLJS.h(null,1,[$CLJS.ov,d],null),$CLJS.zk.l(a,$CLJS.lS,$CLJS.G([$CLJS.uQ]))]));a=$CLJS.z($CLJS.gP.h(a))?$CLJS.Kk.j(a,$CLJS.gP,Ria):a;a=dY(a,$CLJS.BH,$CLJS.MX);return $CLJS.$d.g(c,a)};
$CLJS.rY=function(){function a(c,d,e,f){var k=null;if(3<arguments.length){k=0;for(var l=Array(arguments.length-3);k<l.length;)l[k]=arguments[k+3],++k;k=new $CLJS.y(l,0,null)}return b.call(this,c,d,e,k)}function b(c,d,e,f){c=$CLJS.gY(c);c=$CLJS.O(c);var k=$CLJS.I.g(c,$CLJS.UM);d=$CLJS.hY(c,d);e=$CLJS.P.N($CLJS.Kk,$CLJS.uf(k),d,e,f);return $CLJS.S.j(c,$CLJS.UM,e)}a.A=3;a.B=function(c){var d=$CLJS.B(c);c=$CLJS.C(c);var e=$CLJS.B(c);c=$CLJS.C(c);var f=$CLJS.B(c);c=$CLJS.Kc(c);return b(d,e,f,c)};a.l=b;
return a}();
kY=function(){function a(d,e){if((new TextEncoder).encode(d).length<=e)return d;var f;a:for(f=($CLJS.RX.str(d,0)>>>0).toString(16);;)if(8>$CLJS.E(f))f=["0",$CLJS.p.h(f)].join("");else break a;e=new Uint8Array(e-9);e=(new TextEncoder).encodeInto(d,e);return[d.substring(0,e.read),"_",$CLJS.p.h(f)].join("")}function b(d){return c.g?c.g(d,60):c.call(null,d,60)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);
};c.h=b;c.g=a;return c}();$CLJS.sY=RegExp(" id$","i");