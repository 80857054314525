var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.schema.common.js");require("./metabase.mbql.predicates.js");require("./metabase.mbql.schema.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var aX;$CLJS.WW=function(a){return a instanceof Error?a.message:null};
$CLJS.XW=function(a,b,c){if($CLJS.Yd(c)){var d=$CLJS.P.g($CLJS.W,$CLJS.Ze.g(a,c));return b.h?b.h(d):b.call(null,d)}return $CLJS.qf(c)?(d=new $CLJS.Pf(function(){var e=$CLJS.yb(c);return a.h?a.h(e):a.call(null,e)}(),function(){var e=$CLJS.zb(c);return a.h?a.h(e):a.call(null,e)}()),b.h?b.h(d):b.call(null,d)):$CLJS.Cd(c)?(d=$CLJS.eh($CLJS.Ze.g(a,c)),b.h?b.h(d):b.call(null,d)):$CLJS.xd(c)?(d=$CLJS.ab(function(e,f){return $CLJS.$d.g(e,a.h?a.h(f):a.call(null,f))},c,c),b.h?b.h(d):b.call(null,d)):$CLJS.td(c)?
(d=$CLJS.Wf.g($CLJS.jd(c),$CLJS.Ze.g(a,c)),b.h?b.h(d):b.call(null,d)):b.h?b.h(c):b.call(null,c)};$CLJS.ZW=function(a){function b(c){var d=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);return"string"===typeof d?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.rh.h(d),c],null):new $CLJS.Q(null,2,5,$CLJS.R,[d,c],null)}return $CLJS.YW(function(c){return $CLJS.wd(c)?$CLJS.Wf.g($CLJS.N,$CLJS.Ze.g(b,c)):c},a)};
$CLJS.$W=function(a){var b=new $CLJS.Ea;for(a=$CLJS.z(a);;)if(null!=a)b=b.append($CLJS.p.h($CLJS.B(a))),a=$CLJS.C(a);else return b.toString()};$CLJS.bX=function(a,b,c){a=$CLJS.vC(a,/''/,"'");var d=$CLJS.dv(a,aX);d=$CLJS.F.g($CLJS.E(d),1)?new $CLJS.Q(null,2,5,$CLJS.R,[a,""],null):d;a=$CLJS.gh(/.*\{0\}.*/,a);return $CLJS.Bu.ngettext($CLJS.Bu.msgid($CLJS.bl(d),$CLJS.n(a)?c:""),$CLJS.vC($CLJS.vC(b,/''/,"'"),aX,$CLJS.p.h(c)),c)};
$CLJS.cX=function(a,b){return $CLJS.ab(function(c,d){var e=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);return $CLJS.Gd(a,e)?$CLJS.S.j(c,d,$CLJS.I.g(a,e)):c},$CLJS.P.j($CLJS.zk,a,$CLJS.Pg(b)),b)};$CLJS.dX=function(a,b,c){return $CLJS.wd(c)?$CLJS.$e(function(d){var e=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);e=$CLJS.$d.g(b,e);return a.g?a.g(e,d):a.call(null,e,d)},$CLJS.G([c])):$CLJS.vd(c)?$CLJS.$e($CLJS.Me(a,$CLJS.B(c)instanceof $CLJS.K?$CLJS.$d.g(b,$CLJS.B(c)):b),$CLJS.G([c])):null};
$CLJS.YW=function YW(a,b){return $CLJS.XW($CLJS.Me(YW,a),a,b)};aX=/\{0\}/;$CLJS.eX=new $CLJS.K(null,"unique-alias-fn","unique-alias-fn",32555420);$CLJS.fX=new $CLJS.K("clojure.core.match","not-found","clojure.core.match/not-found",1553053780);$CLJS.gX=new $CLJS.K(null,"name-key-fn","name-key-fn",-1634839199);var via=$CLJS.Ag([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.YK,null],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.BK,null],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.OK,$CLJS.HK],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.OK,null],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.VK,null],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.NK,null],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.RK,null],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uK,null],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.SK,null],null),new $CLJS.Q(null,2,5,
$CLJS.R,[$CLJS.OK,$CLJS.yK],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.OK,$CLJS.MK],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.AK,null],null)],[$CLJS.ui,$CLJS.PI,$CLJS.ZK,$CLJS.EK,$CLJS.pi,$CLJS.Aj,$CLJS.dj,$CLJS.fk,$CLJS.Ai,$CLJS.EK,$CLJS.$K,$CLJS.MI]),iX;$CLJS.Yg($CLJS.Ze.g($CLJS.B,$CLJS.Pg(via)));var hX,wia=$CLJS.Oe($CLJS.N),xia=$CLJS.Oe($CLJS.N),yia=$CLJS.Oe($CLJS.N),zia=$CLJS.Oe($CLJS.N),Aia=$CLJS.I.j($CLJS.N,$CLJS.cj,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));
hX=new $CLJS.Xh($CLJS.xh.g("metabase.mbql.util","negate*"),$CLJS.B,Aia,wia,xia,yia,zia);hX.m(null,$CLJS.nt,function(a){$CLJS.H(a,0,null);return $CLJS.H(a,1,null)});hX.m(null,$CLJS.rt,function(a){a=$CLJS.z(a);$CLJS.B(a);a=$CLJS.C(a);return $CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.lt],null),$CLJS.Ze.g(hX,a))});hX.m(null,$CLJS.lt,function(a){a=$CLJS.z(a);$CLJS.B(a);a=$CLJS.C(a);return $CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.rt],null),$CLJS.Ze.g(hX,a))});
hX.m(null,$CLJS.Ls,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qI,b,a],null)});hX.m(null,$CLJS.qI,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ls,b,a],null)});hX.m(null,$CLJS.Es,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ks,b,a],null)});
hX.m(null,$CLJS.Is,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Gs,b,a],null)});hX.m(null,$CLJS.Gs,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Is,b,a],null)});hX.m(null,$CLJS.Ks,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Es,b,a],null)});
hX.m(null,$CLJS.zH,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Is,b,c],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Es,b,a],null)],null)});hX.m(null,$CLJS.XH,function(a){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.nt,a],null)});hX.m(null,$CLJS.PH,function(a){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.nt,a],null)});
hX.m(null,$CLJS.iI,function(a){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.nt,a],null)});
iX=function iX(a){return $CLJS.wd(a)?$CLJS.De($CLJS.Wf.g($CLJS.N,function(){return function e(d){return new $CLJS.le(null,function(){for(var f=d;;)if(f=$CLJS.z(f)){if($CLJS.zd(f)){var k=$CLJS.kc(f),l=$CLJS.E(k),m=$CLJS.oe(l);a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.H(u,0,null);u=$CLJS.H(u,1,null);u=iX.h?iX.h(u):iX.call(null,u);null!=u&&m.add(new $CLJS.Q(null,2,5,$CLJS.R,[v,u],null));t+=1}else{k=!0;break a}return k?$CLJS.re($CLJS.te(m),e($CLJS.lc(f))):$CLJS.re($CLJS.te(m),null)}k=$CLJS.B(f);
m=$CLJS.H(k,0,null);k=$CLJS.H(k,1,null);k=iX.h?iX.h(k):iX.call(null,k);if(null!=k)return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[m,k],null),e($CLJS.Kc(f)));f=$CLJS.Kc(f)}else return null},null,null)}(a)}())):$CLJS.vd(a)?$CLJS.De($CLJS.Wf.g($CLJS.jd(a),$CLJS.bf($CLJS.Ta,$CLJS.Ze.g(iX,a)))):a};
$CLJS.jX=function(){function a(c,d,e){var f=null;if(2<arguments.length){f=0;for(var k=Array(arguments.length-2);f<k.length;)k[f]=arguments[f+2],++f;f=new $CLJS.y(k,0,null)}return b.call(this,c,d,f)}function b(c,d,e){var f=$CLJS.H(c,0,null),k=$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);d=$CLJS.De(iX($CLJS.P.j(d,c,e)));return $CLJS.n(d)?new $CLJS.Q(null,3,5,$CLJS.R,[f,k,d],null):$CLJS.F.g(f,$CLJS.YH)?new $CLJS.Q(null,3,5,$CLJS.R,[f,k,null],null):new $CLJS.Q(null,2,5,$CLJS.R,[f,k],null)}a.A=2;a.B=function(c){var d=
$CLJS.B(c);c=$CLJS.C(c);var e=$CLJS.B(c);c=$CLJS.Kc(c);return b(d,e,c)};a.l=b;return a}();