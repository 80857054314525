var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.breakout.js");require("./metabase.lib.expression.js");require("./metabase.lib.field.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var xqa,yqa,N8,zqa,Aqa,Bqa,Cqa,Dqa,Eqa,Fqa,O8,P8,Q8,Gqa,R8;
xqa=function(a){return function(b){var c=$CLJS.Re($CLJS.Ug);return function(){function d(l,m){var t=a.h?a.h(m):a.call(null,m);if($CLJS.Gd($CLJS.q(c),t))return l;c.Cd(null,$CLJS.$d.g(c.Qb(null),t));return b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);
};k.o=f;k.h=e;k.g=d;return k}()}};yqa=function(a,b,c){return $CLJS.Wf.j($CLJS.tf,$CLJS.XF(function(d){return $CLJS.c2.v(a,b,d,new $CLJS.h(null,2,[$CLJS.M1,c,$CLJS.H1,!1],null))}),$CLJS.gP.h($CLJS.iY(a,b)))};N8=function(a,b,c){return $CLJS.Wf.j($CLJS.tf,$CLJS.XF(function(d){return $CLJS.a2.v(a,b,d,c)}),$CLJS.gP.h($CLJS.iY(a,b)))};$CLJS.z4=function(a,b){return $CLJS.ab(function(c,d){return $CLJS.rY.l(c,d,$CLJS.S,$CLJS.G([$CLJS.W7,$CLJS.a2.j(c,d,$CLJS.iY(c,d))]))},a,$CLJS.Nv(0,$CLJS.hY(a,b)))};
zqa=function(a,b){a=$CLJS.iY(a,b);a=$CLJS.O(a);var c=$CLJS.I.g(a,$CLJS.ov),d=$CLJS.I.g(a,$CLJS.qX);b=$CLJS.W7.h(a);if($CLJS.n(b))return b;var e=$CLJS.QW.h(a);if($CLJS.n(e)&&$CLJS.n(function(){var k=$CLJS.F.g(c,$CLJS.EX);return k?k:d}())){var f=function(){var k=c instanceof $CLJS.K?c.T:null;switch(k){case "mbql.stage/native":return $CLJS.u0;case "mbql.stage/mbql":return $CLJS.o0;default:throw Error(["No matching clause: ",$CLJS.p.h(k)].join(""));}}();return $CLJS.De(function(){return function m(l){return new $CLJS.le(null,
function(){for(;;){var t=$CLJS.z(l);if(t){if($CLJS.zd(t)){var u=$CLJS.kc(t),v=$CLJS.E(u),x=$CLJS.oe(v);a:for(var A=0;;)if(A<v){var D=$CLJS.kd(u,A);D=$CLJS.Zk.l($CLJS.G([new $CLJS.h(null,2,[$CLJS.Y_,$CLJS.T.h(D),$CLJS.k0,$CLJS.T.h(D)],null),D,new $CLJS.h(null,1,[$CLJS.vM,f],null)]));x.add(D);A+=1}else{u=!0;break a}return u?$CLJS.re($CLJS.te(x),m($CLJS.lc(t))):$CLJS.re($CLJS.te(x),null)}x=$CLJS.B(t);return $CLJS.ce($CLJS.Zk.l($CLJS.G([new $CLJS.h(null,2,[$CLJS.Y_,$CLJS.T.h(x),$CLJS.k0,$CLJS.T.h(x)],
null),x,new $CLJS.h(null,1,[$CLJS.vM,f],null)])),m($CLJS.Kc(t)))}return null}},null,null)}($CLJS.fY.h(e))}())}return null};
Aqa=function(a,b,c){return $CLJS.De(function(){return function f(e){return new $CLJS.le(null,function(){for(;;){var k=$CLJS.z(e);if(k){if($CLJS.zd(k)){var l=$CLJS.kc(k),m=$CLJS.E(l),t=$CLJS.oe(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.kd(l,v),A=t,D=$CLJS.S,J=D.l,M=x,V=$CLJS.rC($CLJS.Y_,$CLJS.T)(x);x=$CLJS.u2(a,x);x=c.h?c.h(x):c.call(null,x);D=J.call(D,M,$CLJS.vM,$CLJS.IM,$CLJS.G([$CLJS.Y_,V,$CLJS.k0,x]));A.add(D);v+=1}else return!0}()?$CLJS.re($CLJS.te(t),f($CLJS.lc(k))):$CLJS.re($CLJS.te(t),
null)}var u=$CLJS.B(k);return $CLJS.ce($CLJS.S.l(u,$CLJS.vM,$CLJS.IM,$CLJS.G([$CLJS.Y_,$CLJS.rC($CLJS.Y_,$CLJS.T)(u),$CLJS.k0,function(){var v=$CLJS.u2(a,u);return c.h?c.h(v):c.call(null,v)}()])),f($CLJS.Kc(k)))}return null}},null,null)}($CLJS.V5(a,b))}())};
Bqa=function(a,b,c){return $CLJS.De(function(){return function f(e){return new $CLJS.le(null,function(){for(;;){var k=$CLJS.z(e);if(k){if($CLJS.zd(k)){var l=$CLJS.kc(k),m=$CLJS.E(l),t=$CLJS.oe(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.kd(l,v),A=t,D=$CLJS.S,J=D.l,M=x,V=$CLJS.T.h(x);x=$CLJS.T.h(x);x=c.h?c.h(x):c.call(null,x);D=J.call(D,M,$CLJS.vM,$CLJS.NM,$CLJS.G([$CLJS.Y_,V,$CLJS.k0,x]));A.add(D);v+=1}else return!0}()?$CLJS.re($CLJS.te(t),f($CLJS.lc(k))):$CLJS.re($CLJS.te(t),null)}var u=
$CLJS.B(k);return $CLJS.ce($CLJS.S.l(u,$CLJS.vM,$CLJS.NM,$CLJS.G([$CLJS.Y_,$CLJS.T.h(u),$CLJS.k0,function(){var v=$CLJS.T.h(u);return c.h?c.h(v):c.call(null,v)}()])),f($CLJS.Kc(k)))}return null}},null,null)}($CLJS.h5.g(a,b))}())};
Cqa=function(a,b,c){var d=$CLJS.iY(a,b);if($CLJS.n(d)){d=$CLJS.O(d);var e=$CLJS.I.g(d,$CLJS.HG);return $CLJS.De(function(){return function l(k){return new $CLJS.le(null,function(){for(;;){var m=$CLJS.z(k);if(m){if($CLJS.zd(m)){var t=$CLJS.kc(m),u=$CLJS.E(t),v=$CLJS.oe(u);return function(){for(var M=0;;)if(M<u){var V=$CLJS.kd(t,M),Z=$CLJS.H(V,0,null),fa=function(){var Ha=Z;Ha=Ha instanceof $CLJS.K?Ha.T:null;switch(Ha){case "field":return $CLJS.t0;case "expression":return $CLJS.e0;default:throw Error(["No matching clause: ",
$CLJS.p.h(Ha)].join(""));}}(),Da=$CLJS.t1.j(a,b,V);$CLJS.se(v,$CLJS.S.l(Da,$CLJS.vM,fa,$CLJS.G([$CLJS.Y_,$CLJS.U1.j(a,b,Da),$CLJS.k0,function(){var Ha=$CLJS.u2(a,Da);return c.h?c.h(Ha):c.call(null,Ha)}()])));M+=1}else return!0}()?$CLJS.re($CLJS.te(v),l($CLJS.lc(m))):$CLJS.re($CLJS.te(v),null)}var x=$CLJS.B(m),A=$CLJS.H(x,0,null),D=function(){var M=A;M=M instanceof $CLJS.K?M.T:null;switch(M){case "field":return $CLJS.t0;case "expression":return $CLJS.e0;default:throw Error(["No matching clause: ",
$CLJS.p.h(M)].join(""));}}(),J=$CLJS.t1.j(a,b,x);return $CLJS.ce($CLJS.S.l(J,$CLJS.vM,D,$CLJS.G([$CLJS.Y_,$CLJS.U1.j(a,b,J),$CLJS.k0,function(){var M=$CLJS.u2(a,J);return c.h?c.h(M):c.call(null,M)}()])),l($CLJS.Kc(m)))}return null}},null,null)}(e)}())}return null};Dqa=function(a,b,c){return $CLJS.De($CLJS.Wf.j($CLJS.tf,$CLJS.XF(function(d){return d.j?d.j(a,b,c):d.call(null,a,b,c)}),new $CLJS.Q(null,2,5,$CLJS.R,[Aqa,Bqa],null)))};
Eqa=function(a,b,c){var d=$CLJS.O2(a,b);return $CLJS.n(d)?$CLJS.De(function(){return function k(f){return new $CLJS.le(null,function(){for(;;){var l=$CLJS.z(f);if(l){if($CLJS.zd(l)){var m=$CLJS.kc(l),t=$CLJS.E(m),u=$CLJS.oe(t);return function(){for(var A=0;;)if(A<t){var D=$CLJS.kd(m,A);var J=$CLJS.rC($CLJS.k0,$CLJS.Y_)(D);J=$CLJS.n(J)?J:$CLJS.U1.j(a,b,D);D=$CLJS.zk.g($CLJS.Zk.l($CLJS.G([D,new $CLJS.h(null,3,[$CLJS.vM,$CLJS.Z_,$CLJS.Y_,J,$CLJS.k0,c.h?c.h(J):c.call(null,J)],null),$CLJS.n($CLJS.z2.h(D))?
$CLJS.Wl(D,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.z2],null)):null])),$CLJS.Y7);u.add(D);A+=1}else return!0}()?$CLJS.re($CLJS.te(u),k($CLJS.lc(l))):$CLJS.re($CLJS.te(u),null)}var v=$CLJS.B(l),x=function(){var A=$CLJS.rC($CLJS.k0,$CLJS.Y_)(v);return $CLJS.n(A)?A:$CLJS.U1.j(a,b,v)}();return $CLJS.ce($CLJS.zk.g($CLJS.Zk.l($CLJS.G([v,new $CLJS.h(null,3,[$CLJS.vM,$CLJS.Z_,$CLJS.Y_,x,$CLJS.k0,c.h?c.h(x):c.call(null,x)],null),$CLJS.n($CLJS.z2.h(v))?$CLJS.Wl(v,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.z2],null)):null])),
$CLJS.Y7),k($CLJS.Kc(l)))}return null}},null,null)}($CLJS.a2.j(a,d,$CLJS.iY(a,d)))}()):null};Fqa=function(a,b,c,d){return $CLJS.n(c)?(c=$CLJS.s1(a,c),$CLJS.n(c)?$CLJS.De($CLJS.c2.v(a,b,c,d)):null):null};
O8=function(a,b,c){return $CLJS.De(function(){return function f(e){return new $CLJS.le(null,function(){for(;;){var k=$CLJS.z(e);if(k){if($CLJS.zd(k)){var l=$CLJS.kc(k),m=$CLJS.E(l),t=$CLJS.oe(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.kd(l,v),A=t;var D=$CLJS.iD.h(x);var J=$CLJS.S.l,M=x,V=$CLJS.T.h(x);x=$CLJS.T.h(x);x=c.h?c.h(x):c.call(null,x);D=$CLJS.d3(J.call($CLJS.S,M,$CLJS.vM,$CLJS.e0,$CLJS.G([$CLJS.Y_,V,$CLJS.k0,x])),$CLJS.zi,$CLJS.n(D)?D:$CLJS.vj);A.add(D);v+=1}else return!0}()?$CLJS.re($CLJS.te(t),
f($CLJS.lc(k))):$CLJS.re($CLJS.te(t),null)}var u=$CLJS.B(k);return $CLJS.ce(function(){var v=$CLJS.iD.h(u),x=$CLJS.S.l,A=u,D=$CLJS.T.h(u);var J=$CLJS.T.h(u);J=c.h?c.h(J):c.call(null,J);return $CLJS.d3(x.call($CLJS.S,A,$CLJS.vM,$CLJS.e0,$CLJS.G([$CLJS.Y_,D,$CLJS.k0,J])),$CLJS.zi,$CLJS.n(v)?v:$CLJS.vj)}(),f($CLJS.Kc(k)))}return null}},null,null)}($CLJS.Koa.g(a,b))}())};
P8=function(a,b,c){var d=$CLJS.O(c),e=$CLJS.I.g(d,$CLJS.M1);return $CLJS.Jk.g(function(f){return $CLJS.zk.l(f,$CLJS.z0,$CLJS.G([$CLJS.Y7,$CLJS.X7,$CLJS.FM]))},function(){var f=Eqa(a,b,e);if($CLJS.n(f))return f;f=$CLJS.iY(a,b);f=$CLJS.O(f);var k=$CLJS.I.g(f,$CLJS.IQ),l=$CLJS.I.g(f,$CLJS.qX),m=$CLJS.n(k)?function(){var t=$CLJS.q1(a,k);return $CLJS.c2.v(a,b,t,d)}():null;if($CLJS.n(m))return m;l=$CLJS.n(l)?Fqa(a,b,l,$CLJS.S.j(d,$CLJS.H1,!1)):null;return $CLJS.n(l)?l:function v(u){return new $CLJS.le(null,
function(){for(;;){var x=$CLJS.z(u);if(x){if($CLJS.zd(x)){var A=$CLJS.kc(x),D=$CLJS.E(A),J=$CLJS.oe(D);return function(){for(var V=0;;)if(V<D){var Z=$CLJS.kd(A,V),fa=J,Da=$CLJS.S,Ha=Da.l,mb=Z,Qb=$CLJS.T.h(Z);Z=$CLJS.T.h(Z);Z=e.h?e.h(Z):e.call(null,Z);Da=Ha.call(Da,mb,$CLJS.vM,$CLJS.u0,$CLJS.G([$CLJS.Y_,Qb,$CLJS.k0,Z]));fa.add(Da);V+=1}else return!0}()?$CLJS.re($CLJS.te(J),v($CLJS.lc(x))):$CLJS.re($CLJS.te(J),null)}var M=$CLJS.B(x);return $CLJS.ce($CLJS.S.l(M,$CLJS.vM,$CLJS.u0,$CLJS.G([$CLJS.Y_,$CLJS.T.h(M),
$CLJS.k0,function(){var V=$CLJS.T.h(M);return e.h?e.h(V):e.call(null,V)}()])),v($CLJS.Kc(x)))}return null}},null,null)}($CLJS.fY.h($CLJS.QW.h(f)))}())};
Q8=function(a,b){var c=$CLJS.H(a,0,null);$CLJS.H(a,1,null);var d=$CLJS.H(a,2,null);switch(c instanceof $CLJS.K?c.T:null){case "field":if("number"===typeof d||"string"===typeof d)return $CLJS.F.g(d,$CLJS.Ti.h(b));throw $CLJS.ai("unknown type of :field ref in lib.stage/ref-to?",new $CLJS.h(null,2,[$CLJS.CT,a,$CLJS.Mi,b],null));case "expression":return $CLJS.F.g(d,$CLJS.T.h(b));default:throw $CLJS.ai("unknown clause in lib.stage/ref-to?",new $CLJS.h(null,2,[$CLJS.CT,a,$CLJS.Mi,b],null));}};
Gqa=function(a,b,c){var d=$CLJS.MH.h($CLJS.iY(a,b));return $CLJS.n(d)?function k(f){return new $CLJS.le(null,function(){for(;;){var l=$CLJS.z(f);if(l){var m=l;if($CLJS.zd(m)){var t=$CLJS.kc(m),u=$CLJS.E(t),v=$CLJS.oe(u);return function(){for(var A=0;;)if(A<u){var D=$CLJS.kd(t,A);$CLJS.se(v,function(){var J=$CLJS.jJ(function(Z,fa){return function(Da){return Q8(Da,fa)}}(A,D,t,u,v,m,l,d,d),d);if($CLJS.n(J)){var M=$CLJS.Q7(J);J=$CLJS.f4(J);J=$CLJS.O(J);J=$CLJS.I.g(J,$CLJS.li);var V=D;M=$CLJS.n(M)?$CLJS.N5(V,
M):V;return $CLJS.n(J)?$CLJS.h3(M,J):M}return D}());A+=1}else return!0}()?$CLJS.re($CLJS.te(v),k($CLJS.lc(m))):$CLJS.re($CLJS.te(v),null)}var x=$CLJS.B(m);return $CLJS.ce(function(){var A=$CLJS.jJ(function(M){return function(V){return Q8(V,M)}}(x,m,l,d,d),d);if($CLJS.n(A)){var D=$CLJS.Q7(A);A=$CLJS.f4(A);A=$CLJS.O(A);A=$CLJS.I.g(A,$CLJS.li);var J=x;D=$CLJS.n(D)?$CLJS.N5(J,D):J;return $CLJS.n(A)?$CLJS.h3(D,A):D}return x}(),k($CLJS.Kc(m)))}return null}},null,null)}(c):c};
R8=new $CLJS.K("metabase.lib.stage","stage","metabase.lib.stage/stage",1448689281);$CLJS.Gma={};$CLJS.gH($CLJS.xX,R8);$CLJS.gH($CLJS.EX,R8);$CLJS.g2.m(null,$CLJS.xX,function(a){return $CLJS.f2(a,new $CLJS.h(null,2,[$CLJS.NH,$CLJS.Me($CLJS.Jk,$CLJS.g2),$CLJS.MX,$CLJS.Me($CLJS.Jk,$CLJS.g2)],null))});$CLJS.Y1.m(null,R8,function(){throw $CLJS.ai("You can't calculate a metadata map for a stage! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});
$CLJS.b2.m(null,R8,function(a,b,c,d){d=$CLJS.O(d);c=$CLJS.I.g(d,$CLJS.M1);var e=$CLJS.I.g(d,$CLJS.H1);a=$CLJS.z4(a,b);var f=$CLJS.O(d);var k=$CLJS.I.g(f,$CLJS.M1),l=$CLJS.I.g(f,$CLJS.K1),m=$CLJS.I.g(f,$CLJS.R1);f=$CLJS.Ye.l(P8(a,b,f),$CLJS.n(m)?O8(a,b,k):null,$CLJS.G([$CLJS.n(l)?yqa(a,b,k):null]));k=$CLJS.Ye.g;d=$CLJS.n(e)?(e=$CLJS.Sa($CLJS.qX.h($CLJS.iY(a,b))))?e:$CLJS.O1.h(d):e;return Gqa(a,b,k.call($CLJS.Ye,f,$CLJS.n(d)?$CLJS.v2(a,b,f,c):null))});
$CLJS.$1.m(null,R8,function(a,b,c,d){c=$CLJS.O(d);d=$CLJS.I.g(c,$CLJS.M1);var e=zqa(a,b);if($CLJS.n(e))return e;a=$CLJS.z4(a,b);e=Dqa(a,b,d);var f=Cqa(a,b,d);return $CLJS.n(e)?$CLJS.Wf.g(e,f):$CLJS.n(f)?($CLJS.eh(f),$CLJS.Wf.j($CLJS.tf,xqa(function(k){return $CLJS.zk.l(k,$CLJS.x0,$CLJS.G([$CLJS.vM,$CLJS.Q1,$CLJS.k0]))}),$CLJS.Ye.g(f,N8(a,b,c)))):$CLJS.Ye.l(P8(a,b,new $CLJS.h(null,2,[$CLJS.H1,!1,$CLJS.M1,d],null)),O8(a,b,d),$CLJS.G([N8(a,b,c)]))});$CLJS.S1.m(null,$CLJS.EX,function(){return $CLJS.zG("Native query")});
var S8=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.IQ,$CLJS.qX,$CLJS.gP],null),T8=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.NH,$CLJS.MH,$CLJS.MX,$CLJS.rT,$CLJS.OT],null);
$CLJS.S1.m(null,$CLJS.xX,function(a,b,c,d){var e=$CLJS.z4(a,b);a=$CLJS.De(function(){var f=$CLJS.Wf.j($CLJS.N,$CLJS.Hk.g($CLJS.hE,$CLJS.Ze.h(function(l){return new $CLJS.Q(null,2,5,$CLJS.R,[l,$CLJS.$ka.j(e,b,l)],null)})),new $CLJS.Q(null,2,5,$CLJS.R,[S8,T8],null)),k=$CLJS.Hu(" + ",$CLJS.Ik.g($CLJS.wC,$CLJS.Ze.g(f,S8)));f=$CLJS.Ze.g(f,T8);return $CLJS.Hu(", ",$CLJS.Ik.g($CLJS.wC,$CLJS.ce(k,f)))}());if($CLJS.n(a))return a;a=$CLJS.O2(e,b);return $CLJS.n(a)?$CLJS.u1.v(e,a,$CLJS.iY(e,a),d):null});