var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.schema.js");require("./metabase.util.malli.registry.js");
'use strict';var FW,GW,HW,JW,KW,LW,MW,NW,OW,PW;FW=new $CLJS.K("metabase.lib.schema.template-tag","widget-type","metabase.lib.schema.template-tag/widget-type",2021141560);GW=new $CLJS.K("metabase.lib.schema.template-tag","raw-value","metabase.lib.schema.template-tag/raw-value",-530604923);HW=new $CLJS.K("metabase.lib.schema.template-tag","template-tag","metabase.lib.schema.template-tag/template-tag",-1178540449);
$CLJS.IW=new $CLJS.K("metabase.lib.schema.template-tag","template-tag-map","metabase.lib.schema.template-tag/template-tag-map",1471181299);JW=new $CLJS.K("metabase.lib.schema.template-tag","type","metabase.lib.schema.template-tag/type",1926679295);KW=new $CLJS.K("metabase.lib.schema.template-tag","field-filter","metabase.lib.schema.template-tag/field-filter",1376344463);LW=new $CLJS.K("metabase.lib.schema.template-tag","snippet","metabase.lib.schema.template-tag/snippet",190689821);
MW=new $CLJS.K("metabase.lib.schema.template-tag","common","metabase.lib.schema.template-tag/common",1713198790);NW=new $CLJS.K("metabase.lib.schema.template-tag","value.common","metabase.lib.schema.template-tag/value.common",-974864959);OW=new $CLJS.K("metabase.lib.schema.template-tag","raw-value.type","metabase.lib.schema.template-tag/raw-value.type",-805651412);PW=new $CLJS.K("metabase.lib.schema.template-tag","source-query","metabase.lib.schema.template-tag/source-query",479361081);$CLJS.Y(FW,$CLJS.Wf.g(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"Valid template tag :widget-type"],null),$CLJS.YB],null),$CLJS.Pg($CLJS.AW)));$CLJS.Y(JW,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.ht,$CLJS.iO,$CLJS.ZO,$CLJS.MN,$CLJS.fv,$CLJS.mD,$CLJS.eR],null));
$CLJS.Y(MW,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.hG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cG,$CLJS.hG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ti,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,$CLJS.hG,$CLJS.Xj],null)],null)],null));
$CLJS.Y(NW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,MW],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wh,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.ul],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.AP,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.zt],null)],null)],null));
$CLJS.Y(KW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,NW],null),new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,$CLJS.MN],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.MN,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.QG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.tP,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,FW],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aj,new $CLJS.h(null,1,[$CLJS.Ft,!0],
null),$CLJS.ck],null)],null)],null));
$CLJS.Y(LW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,MW],null),new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,$CLJS.iO],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.VN,$CLJS.hG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.wR,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.LM],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.xR,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.EM],null)],null)],null));
$CLJS.Y(PW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,MW],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,$CLJS.ZO],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.GS,$CLJS.DM],null)],null)],null));$CLJS.Y(OW,$CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.ht],null),$CLJS.oW));
$CLJS.Y(GW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,NW],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,OW],null)],null)],null)],null));
$CLJS.Y(HW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,JW],null)],null)],null),new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.qt,new $CLJS.h(null,1,[$CLJS.Ii,$CLJS.hj],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.MN,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,KW],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.iO,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,LW],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ZO,
new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,PW],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ro,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,GW],null)],null)],null)],null));
$CLJS.Y($CLJS.IW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Pi,$CLJS.hG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,HW],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,1,[$CLJS.wu,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Ie(function(b){var c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);return $CLJS.F.g(c,$CLJS.T.h(b))},a)}],null)],null));