var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.expression.arithmetic.js");require("./metabase.lib.schema.expression.conditional.js");require("./metabase.lib.schema.expression.string.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.schema.template_tag.js");require("./metabase.lib.schema.util.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.util.malli.registry.js");
'use strict';var kX,mX,nX,Bia,rX,sX,tX,Dia,uX,vX,wX,yX,zX,AX,BX,CX,DX,FX,GX,HX,IX,JX,NX,OX,Cia;kX=function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.Ik.h(function(b){var c=$CLJS.H(b,0,null);$CLJS.H(b,1,null);return $CLJS.ke(c)}),a)};
$CLJS.lX=function(a){var b=2>$CLJS.E(a);return b?b:$CLJS.P.g($CLJS.oE,function(){return function e(d){return new $CLJS.le(null,function(){for(;;){var f=$CLJS.z(d);if(f){var k=f;if($CLJS.zd(k)){var l=$CLJS.kc(k),m=$CLJS.E(l),t=$CLJS.oe(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.kd(l,v);$CLJS.se(t,$CLJS.xG(x,function(){return function(A){return $CLJS.zk.l(kX(A),$CLJS.iD,$CLJS.G([$CLJS.zi]))}}(v,x,l,m,t,k,f,b)));v+=1}else return!0}()?$CLJS.re($CLJS.te(t),e($CLJS.lc(k))):$CLJS.re($CLJS.te(t),
null)}var u=$CLJS.B(k);return $CLJS.ce($CLJS.xG(u,function(){return function(v){return $CLJS.zk.l(kX(v),$CLJS.iD,$CLJS.G([$CLJS.zi]))}}(u,k,f,b)),e($CLJS.Kc(k)))}return null}},null,null)}(a)}())};
mX=function(a,b){a=new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.tf,a],null)],null);for(var c=$CLJS.tf;;){var d=$CLJS.qd(a);if($CLJS.n(d)){var e=d,f=$CLJS.H(e,0,null),k=$CLJS.H(e,1,null),l=e,m=$CLJS.rd(a);a=function(t,u,v,x,A,D,J,M){return function(V){return $CLJS.Wf.j(v,$CLJS.Ze.h(function(Z,fa,Da,Ha,mb){return function(Qb){var Ab=$CLJS.H(Qb,0,null);Qb=$CLJS.H(Qb,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$d.g(mb,Ab),Qb],null)}}(t,u,v,x,A,D,J,M)),V)}}(a,c,m,e,f,k,l,d);
$CLJS.n(b.h?b.h(k):b.call(null,k))?(a=m,c=$CLJS.$d.g(c,l)):a=$CLJS.wd(k)?a(k):$CLJS.vd(k)?a($CLJS.Vl($CLJS.Ct,k)):m}else return c}};nX=function(a,b,c){return $CLJS.yd(c)&&$CLJS.F.g(a,$CLJS.B(c))&&!$CLJS.Gd(b,$CLJS.I.g(c,2))};$CLJS.oX=function(a){var b=$CLJS.Wf.j($CLJS.Ug,$CLJS.Ze.h($CLJS.Hk.g($CLJS.PG,$CLJS.hd)),$CLJS.CR.h(a));return mX($CLJS.zk.l(a,$CLJS.gP,$CLJS.G([$CLJS.QW])),function(c){return nX($CLJS.fD,b,c)})};
$CLJS.pX=function(a){var b=$CLJS.Wf.j($CLJS.Ug,$CLJS.Ze.h($CLJS.Hk.g($CLJS.nG,$CLJS.hd)),$CLJS.NH.h(a));return mX($CLJS.zk.l(a,$CLJS.gP,$CLJS.G([$CLJS.QW])),function(c){return nX($CLJS.NH,b,c)})};Bia=function(a){function b(d){return $CLJS.$e(c,$CLJS.G([$CLJS.gP.h(d)]))}function c(d){return $CLJS.ce($CLJS.LG.h(d),$CLJS.$e(b,$CLJS.G([$CLJS.UM.h(d)])))}return $CLJS.n($CLJS.qX.h(a))?$CLJS.Le(!0):$CLJS.Yg(b(a))};
rX=function(a){if($CLJS.vd(a))for(var b=$CLJS.Le(!1),c=$CLJS.z(a),d=$CLJS.B(c),e=$CLJS.C(c),f=b,k=0,l=a;;){var m=f,t=k,u=l,v=$CLJS.z(u),x=$CLJS.B(v),A=$CLJS.C(v),D=x,J=A,M=$CLJS.rC(m,Bia(D));f=$CLJS.B($CLJS.z($CLJS.bf($CLJS.Ta,function(V,Z,fa,Da,Ha,mb){return function Tb(Ab,jc){try{if($CLJS.yd(jc)&&3===$CLJS.E(jc))try{var rc=$CLJS.bd(jc,0);if($CLJS.fe(rc,$CLJS.YH))try{var Vb=$CLJS.bd(jc,1);if($CLJS.n($CLJS.XQ.h(Vb)))try{var Sb=$CLJS.bd(jc,1);if(null!=Sb?Sb.C&256||$CLJS.r===Sb.kf||(Sb.C?0:$CLJS.Wa($CLJS.rb,
Sb)):$CLJS.Wa($CLJS.rb,Sb))try{var Vd=$CLJS.I.j(Sb,$CLJS.XQ,$CLJS.fX);if($CLJS.n($CLJS.Ke(Da)(Vd))){var Iw=$CLJS.I.g(Sb,$CLJS.XQ);$CLJS.bd(jc,2);return new $CLJS.Q(null,1,5,$CLJS.R,[["Invalid :field reference in stage ",$CLJS.p.h(mb),": no join named ",$CLJS.Eh.l($CLJS.G([Iw]))].join("")],null)}throw $CLJS.VW;}catch(og){if(og instanceof Error){var Vi=og;if(Vi===$CLJS.VW)throw $CLJS.VW;throw Vi;}throw og;}else throw $CLJS.VW;}catch(og){if(og instanceof Error){Vi=og;if(Vi===$CLJS.VW)throw $CLJS.VW;
throw Vi;}throw og;}else throw $CLJS.VW;}catch(og){if(og instanceof Error){Vi=og;if(Vi===$CLJS.VW)throw $CLJS.VW;throw Vi;}throw og;}else throw $CLJS.VW;}catch(og){if(og instanceof Error){Vi=og;if(Vi===$CLJS.VW)throw $CLJS.VW;throw Vi;}throw og;}else throw $CLJS.VW;}catch(og){if(og instanceof Error){Vi=og;if(Vi===$CLJS.VW)return $CLJS.dX(Tb,Ab,jc);throw Vi;}throw og;}}}(f,k,l,M,m,t,u,v,x,A,D,J,b,0,a,a,c,d,e,d,e)($CLJS.tf,$CLJS.zk.l(D,$CLJS.gP,$CLJS.G([Cia]))))));if($CLJS.n(f))return f;if($CLJS.z(J))k=
t+1,f=M,l=J;else return null}else return null};sX=new $CLJS.K("metabase.lib.schema","breakouts","metabase.lib.schema/breakouts",1354104361);tX=new $CLJS.w("metabase.lib.schema.util","distinct-refs?","metabase.lib.schema.util/distinct-refs?",-262561159,null);Dia=new $CLJS.K("metabase.lib.schema","stage","metabase.lib.schema/stage",1626908550);uX=new $CLJS.w(null,"distinct-refs?","distinct-refs?",-2065255505,null);
vX=new $CLJS.K("metabase.lib.schema","stage.mbql.with-source-table","metabase.lib.schema/stage.mbql.with-source-table",-2119049176);wX=new $CLJS.K("metabase.lib.schema","stage.mbql.with-source-card","metabase.lib.schema/stage.mbql.with-source-card",-1292215799);$CLJS.xX=new $CLJS.K("mbql.stage","mbql","mbql.stage/mbql",1578747798);yX=new $CLJS.K("metabase.lib.schema","stage.additional","metabase.lib.schema/stage.additional",1347605157);
zX=new $CLJS.K("metabase.lib.schema","stage.mbql.with-source","metabase.lib.schema/stage.mbql.with-source",1947556064);AX=new $CLJS.w(null,"refs","refs",80480079,null);BX=new $CLJS.K("metabase.lib.schema","stage.native","metabase.lib.schema/stage.native",-1206212320);CX=new $CLJS.K("metabase.lib.schema","stage.mbql.without-source","metabase.lib.schema/stage.mbql.without-source",1507072886);DX=new $CLJS.K("metabase.lib.schema","stage.type","metabase.lib.schema/stage.type",1362996639);
$CLJS.EX=new $CLJS.K("mbql.stage","native","mbql.stage/native",359422194);FX=new $CLJS.K("metabase.lib.schema","filters","metabase.lib.schema/filters",889389881);GX=new $CLJS.K("metabase.lib.schema","breakout","metabase.lib.schema/breakout",-921298600);HX=new $CLJS.K("metabase.lib.schema","fields","metabase.lib.schema/fields",-2010307316);IX=new $CLJS.K("metabase.lib.schema","filterable","metabase.lib.schema/filterable",-1640076631);
JX=new $CLJS.K("metabase.lib.schema","stage.valid-refs","metabase.lib.schema/stage.valid-refs",-383625241);$CLJS.KX=new $CLJS.K("mbql","query","mbql/query",-1285688662);$CLJS.qX=new $CLJS.K(null,"source-card","source-card",-1580820390);$CLJS.LX=new $CLJS.K("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);$CLJS.MX=new $CLJS.K(null,"filters","filters",974726919);NX=new $CLJS.K("metabase.lib.schema","stages.valid-refs","metabase.lib.schema/stages.valid-refs",-193458245);
OX=new $CLJS.K("metabase.lib.schema","stage.initial","metabase.lib.schema/stage.initial",-1483255395);$CLJS.PX=new $CLJS.K("metabase.lib.schema","stage.mbql","metabase.lib.schema/stage.mbql",1051351388);Cia=new $CLJS.K("stage","metadata","stage/metadata",1707239131);$CLJS.Y(BX,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ov,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,$CLJS.EX],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.MR,$CLJS.Ta],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Tt,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uj,$CLJS.ul],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.PP,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.hG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.jS,new $CLJS.h(null,1,[$CLJS.Ft,
!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.IW],null)],null)],null));$CLJS.Y(GX,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.bI],null));
$CLJS.Y(sX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uj,new $CLJS.h(null,1,[$CLJS.Qn,1],null),GX],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,1,[$CLJS.wu,"Breakouts must be distinct"],null),new $CLJS.Fc(function(){return $CLJS.lX},tX,$CLJS.Ag([$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,$CLJS.Mi,$CLJS.ij,$CLJS.qk,$CLJS.ni,$CLJS.U,$CLJS.hk],[$CLJS.UW,uX,"metabase/lib/schema/util.cljc",21,1,64,64,$CLJS.W(new $CLJS.Q(null,1,5,$CLJS.R,[AX],null)),"Is a sequence of `refs` distinct for the purposes of appearing in `:fields` or `:breakouts` (ignoring keys that\n  aren't important such as namespaced keys and type info)?",
$CLJS.n($CLJS.lX)?$CLJS.lX.H:null]))],null)],null));
$CLJS.Y(HX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uj,new $CLJS.h(null,1,[$CLJS.Qn,1],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.bI],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,1,[$CLJS.wu,":fields must be distinct"],null),new $CLJS.Fc(function(){return $CLJS.lX},tX,$CLJS.Ag([$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,$CLJS.Mi,$CLJS.ij,$CLJS.qk,$CLJS.ni,$CLJS.U,$CLJS.hk],[$CLJS.UW,uX,"metabase/lib/schema/util.cljc",21,1,64,64,$CLJS.W(new $CLJS.Q(null,
1,5,$CLJS.R,[AX],null)),"Is a sequence of `refs` distinct for the purposes of appearing in `:fields` or `:breakouts` (ignoring keys that\n  aren't important such as namespaced keys and type info)?",$CLJS.n($CLJS.lX)?$CLJS.lX.H:null]))],null)],null));$CLJS.Y(IX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.MG],null),new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ot,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,$CLJS.vH],null),$CLJS.ck,$CLJS.Tj],null)],null));
$CLJS.Y(FX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uj,new $CLJS.h(null,1,[$CLJS.Qn,1],null),IX],null));
var QX=$CLJS.rC(function(a){a=$CLJS.B($CLJS.oX(a));if($CLJS.n(a)){var b=$CLJS.sC(a,new $CLJS.Q(null,2,5,$CLJS.R,[1,2],null));return $CLJS.n(b)?["Invalid :expression reference: no expression named ",$CLJS.Eh.l($CLJS.G([b]))].join(""):["Invalid :expression reference: ",$CLJS.p.h($CLJS.I.g(a,1))].join("")}return null},function(a){a=$CLJS.B($CLJS.pX(a));if($CLJS.n(a)){var b=$CLJS.sC(a,new $CLJS.Q(null,2,5,$CLJS.R,[1,2],null));return $CLJS.n(b)?["Invalid :aggregation reference: no aggregation with uuid ",
$CLJS.p.h(b)].join(""):["Invalid :aggregation reference: ",$CLJS.p.h($CLJS.I.g(a,1))].join("")}return null});$CLJS.Y(JX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,2,[$CLJS.wu,"Valid references for a single query stage",$CLJS.yu,function(a){a=$CLJS.O(a);a=$CLJS.I.g(a,$CLJS.yj);return QX(a)}],null),$CLJS.Ke(QX)],null));
$CLJS.Y($CLJS.PX,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.rt,new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ov,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,$CLJS.xX],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gP,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.SM],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.CR,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.JG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,
[$CLJS.MH,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),sX],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.NH,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.BI],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.HG,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),HX],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.MX,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),FX],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rT,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.fN],null)],
null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.IQ,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.yM],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qX,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.DM],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,1,[$CLJS.wu,":source-query is not allowed in pMBQL queries."],null),function(a){return!$CLJS.Gd(a,$CLJS.lS)}],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,
new $CLJS.h(null,1,[$CLJS.wu,"A query cannot have both a :source-table and a :source-card."],null),$CLJS.Ke($CLJS.qN($CLJS.IQ,$CLJS.qX))],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,JX],null)],null));$CLJS.Y(vX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.PX],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.IQ,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.yM],null)],null)],null)],null));
$CLJS.Y(wX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.PX],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qX,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.DM],null)],null)],null)],null));$CLJS.Y(zX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,vX],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,wX],null)],null));
$CLJS.Y(CX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.PX],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,1,[$CLJS.wu,"Only the initial stage of a query can have a :source-table or :source-card."],null),$CLJS.Ke($CLJS.rC($CLJS.IQ,$CLJS.qX))],null)],null));$CLJS.Y(DX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,$CLJS.EX,$CLJS.xX],null));
$CLJS.Y(Dia,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ov,DX],null)],null),new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.qt,new $CLJS.h(null,1,[$CLJS.Ii,$CLJS.ov],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.EX,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,BX],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xX,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.PX],null)],null)],null)],null));
$CLJS.Y(OX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ov,DX],null)],null),new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.qt,new $CLJS.h(null,1,[$CLJS.Ii,$CLJS.ov],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.EX,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,BX],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xX,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,zX],null)],null)],null)],null));$CLJS.Y(yX,CX);
$CLJS.Y(NX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,2,[$CLJS.wu,"Valid references for all query stages",$CLJS.yu,function(a){a=$CLJS.O(a);a=$CLJS.I.g(a,$CLJS.yj);return rX.h?rX.h(a):rX.call(null,a)}],null),$CLJS.Ke(rX)],null));
$CLJS.Y($CLJS.QM,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ys,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,OX],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ps,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,yX],null)],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,NX],null)],null));
$CLJS.Y($CLJS.LX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rt,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ov,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,$CLJS.KX],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xR,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,$CLJS.EM,$CLJS.JM],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.UM,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.QM],null)],null)],null),$CLJS.uia],null));