var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./lambdaisland.glogi.js");require("./lambdaisland.glogi.print.js");
'use strict';var wB=function(){},xB=function(a){return $CLJS.I.g($CLJS.lA,a).value},Cba=function(a){var b=xB(a);return $CLJS.n(function(){var c=xB($CLJS.CA);return $CLJS.Ck.g?$CLJS.Ck.g(c,b):$CLJS.Ck.call(null,c,b)}())?$CLJS.hB:$CLJS.n(function(){var c=xB($CLJS.BA);return $CLJS.Ck.g?$CLJS.Ck.g(c,b):$CLJS.Ck.call(null,c,b)}())?$CLJS.rB:$CLJS.n(function(){var c=xB($CLJS.zA);return $CLJS.Ck.g?$CLJS.Ck.g(c,b):$CLJS.Ck.call(null,c,b)}())?$CLJS.kB:$CLJS.n(function(){var c=xB($CLJS.tA);return $CLJS.Ck.g?
$CLJS.Ck.g(c,b):$CLJS.Ck.call(null,c,b)}())?$CLJS.qB:$CLJS.n(function(){var c=xB($CLJS.AA);return $CLJS.Ck.g?$CLJS.Ck.g(c,b):$CLJS.Ck.call(null,c,b)}())?$CLJS.sB:$CLJS.n(function(){var c=xB($CLJS.wA);return $CLJS.Ck.g?$CLJS.Ck.g(c,b):$CLJS.Ck.call(null,c,b)}())?$CLJS.nB:$CLJS.n(function(){var c=xB($CLJS.qA);return $CLJS.Ck.g?$CLJS.Ck.g(c,b):$CLJS.Ck.call(null,c,b)}())?$CLJS.oB:$CLJS.pB},yB=function(a){var b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[[$CLJS.p.h(b),$CLJS.p.h(" ")].join(""),
a],null)},zB=function(a,b,c){var d=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[[$CLJS.p.h(d),"%c",$CLJS.p.h(b),"%c"].join(""),$CLJS.$d.l(a,["color:",$CLJS.p.h($CLJS.I.g($CLJS.vB,c))].join(""),$CLJS.G(["color:black"]))],null)},AB=function(a,b,c){var d=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[[$CLJS.p.h(d),"%c",$CLJS.p.h(b),"%c"].join(""),$CLJS.$d.l(a,["color:",$CLJS.p.h($CLJS.I.g($CLJS.vB,$CLJS.tB)),";background-color:",$CLJS.p.h($CLJS.I.g($CLJS.vB,
c))].join(""),$CLJS.G(["color:black;background-color:inherit"]))],null)},Dba=function(a){function b(c,d){return d>=c}a=xB(a);if(b(xB($CLJS.CA),a))return"error";if(b(xB($CLJS.BA),a))return"warn";if(b(xB($CLJS.zA),a))return"info";b(xB($CLJS.tA),a);return"log"},BB=function(a){return function(b){var c=$CLJS.O(b),d=$CLJS.I.g(c,$CLJS.rA),e=$CLJS.I.g(c,$CLJS.Ru);b=$CLJS.I.g(c,$CLJS.DA);e=Dba(e);e=$CLJS.Ba(console,e);e=$CLJS.n(e)?e:console.log;$CLJS.P.g(e,a.h?a.h(c):a.call(null,c));return $CLJS.n(b)?(c=["[",
$CLJS.p.h(d),"]"].join(""),d=$CLJS.p.h(b),b=b.stack,e.v?e.v(c,d,"\n",b):e.call(null,c,d,"\n",b)):null}},CB=function CB(a,b){for(;;){if($CLJS.F.g($CLJS.gB,b))return zB(a,", ",$CLJS.pB);if($CLJS.F.g($CLJS.iB,b))return yB(a);if(b instanceof $CLJS.K)return zB(a,b,$CLJS.kB);if(b instanceof $CLJS.w)return zB(a,b,$CLJS.qB);if("string"===typeof b)return zB(a,$CLJS.Eh.l($CLJS.G([b])),$CLJS.jB);if($CLJS.qf(b)){var d=yB(function(){var f=a,k=$CLJS.yb(b);return CB.g?CB.g(f,k):CB.call(null,f,k)}()),e=$CLJS.zb(b);
return CB.g?CB.g(d,e):CB.call(null,d,e)}if(b instanceof $CLJS.h||b instanceof $CLJS.zg)return d=a,d=zB(d,"{",$CLJS.uB),d=$CLJS.ab(CB,d,$CLJS.Xe($CLJS.gB,b)),zB(d,"}",$CLJS.uB);if($CLJS.wd(b))return d=a,d=zB(d,["#",$CLJS.p.h(function(){var f=$CLJS.Xa(b),k=f.name;return $CLJS.sd(k)?$CLJS.Eh.l($CLJS.G([f])):k}())," "].join(""),$CLJS.lB),d=zB(d,"{",$CLJS.uB),d=$CLJS.ab(CB,d,$CLJS.Xe($CLJS.gB,b)),zB(d,"}",$CLJS.uB);if($CLJS.ud(b))return d=a,d=zB(d,"#{",$CLJS.uB),d=$CLJS.ab(CB,d,$CLJS.Xe($CLJS.iB,b)),zB(d,
"}",$CLJS.uB);if($CLJS.yd(b))return d=a,d=zB(d,"[",$CLJS.uB),d=$CLJS.ab(CB,d,$CLJS.Xe($CLJS.iB,b)),zB(d,"]",$CLJS.uB);if(b instanceof $CLJS.If)d=zB(a,"#queue ",$CLJS.lB),e=$CLJS.Wf.g($CLJS.tf,b),a=d,b=e;else{if($CLJS.Cd(b))return d=a,d=zB(d,"(",$CLJS.lB),d=$CLJS.ab(CB,d,$CLJS.Xe($CLJS.iB,b)),zB(d,")",$CLJS.lB);if(null!=b?b.I&16384||$CLJS.r===b.Ui||(b.I?0:$CLJS.Wa(wB,b)):$CLJS.Wa(wB,b))d=zB(a,"#atom ",$CLJS.lB),e=$CLJS.q(b),a=d,b=e;else if($CLJS.Yh(b))d=zB(a,"#uuid ",$CLJS.lB),e=$CLJS.p.h(b),a=d,b=
e;else if($CLJS.Ua(b))d=zB(a,"#js ",$CLJS.lB),e=$CLJS.ab(function(f,k){return function(l,m){return $CLJS.S.j(l,$CLJS.rh.h(m),$CLJS.Ba(k,m))}}(a,b),$CLJS.N,Object.keys(b)),a=d,b=e;else if($CLJS.Ra(b))d=zB(a,"#js ",$CLJS.lB),e=$CLJS.Wf.g($CLJS.tf,b),a=d,b=e;else return zB(a,$CLJS.Eh.l($CLJS.G([b])),$CLJS.mB)}}};$CLJS.Eba=BB(function(a){a=$CLJS.O(a);$CLJS.I.g(a,$CLJS.Ru);var b=$CLJS.I.g(a,$CLJS.rA),c=$CLJS.I.g(a,$CLJS.Ki);$CLJS.I.g(a,$CLJS.DA);return new $CLJS.Q(null,2,5,$CLJS.R,[["[",$CLJS.p.h(b),"]"].join(""),c],null)});
$CLJS.Fba=BB(function(a){var b=$CLJS.O(a),c=$CLJS.I.g(b,$CLJS.Ru);a=$CLJS.I.g(b,$CLJS.rA);var d=$CLJS.I.g(b,$CLJS.Ki);$CLJS.I.g(b,$CLJS.DA);b=Cba(c);d=CB(yB(AB(AB(AB(new $CLJS.Q(null,2,5,$CLJS.R,["",$CLJS.tf],null),"[",b),a,b),"]",b)),d);a=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);return $CLJS.ce(a,d)});
$CLJS.Gba=BB(function(a){a=$CLJS.O(a);$CLJS.I.g(a,$CLJS.Ru);var b=$CLJS.I.g(a,$CLJS.rA),c=$CLJS.I.g(a,$CLJS.Ki);$CLJS.I.g(a,$CLJS.DA);return new $CLJS.Q(null,2,5,$CLJS.R,[["[",$CLJS.p.h(b),"]"].join(""),$CLJS.Eh.l($CLJS.G([c]))],null)});