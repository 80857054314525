var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.query.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.template_tag.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.humanization.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var mqa,nqa,pqa,qqa,rqa,sqa;mqa=function(a,b){return $CLJS.xC(function(c){return function(d,e,f){return $CLJS.n(a.h?a.h(e):a.call(null,e))?c.j?c.j(d,e,f):c.call(null,d,e,f):d}},b)};nqa=function(a,b){return $CLJS.ec($CLJS.ab(function(c,d){return $CLJS.Zf.j(c,a.h?a.h(d):a.call(null,d),d)},$CLJS.cc($CLJS.N),b))};pqa=function(a){return $CLJS.Wf.j($CLJS.Ug,$CLJS.Hk.g($CLJS.XF(function(b){return $CLJS.uY(b,a)}),$CLJS.Ze.h($CLJS.hd)),oqa)};
qqa=function(a){return new $CLJS.h(null,3,[$CLJS.hj,$CLJS.mD,$CLJS.T,a,$CLJS.Ti,$CLJS.p.h($CLJS.yG())],null)};
rqa=function(a){a=$CLJS.O(a);var b=$CLJS.I.g(a,$CLJS.T);return $CLJS.Zk.l($CLJS.G([a,function(){var c=$CLJS.fh(/^#(\d+)(-[a-z0-9-]*)?$/,b);$CLJS.n(c)?($CLJS.H(c,0,null),c=$CLJS.H(c,1,null),c=$CLJS.XX(c)):c=null;return $CLJS.n(c)?new $CLJS.h(null,2,[$CLJS.hj,$CLJS.ZO,$CLJS.GS,c],null):null}(),function(){var c=0==b.lastIndexOf("snippet:",0)?(0,$CLJS.ma)(b.substring(8)):null;return $CLJS.n(c)?new $CLJS.h(null,2,[$CLJS.hj,$CLJS.iO,$CLJS.VN,c],null):null}(),$CLJS.n($CLJS.cG.h(a))?null:new $CLJS.h(null,
1,[$CLJS.cG,$CLJS.q2.g($CLJS.nD,b)],null)]))};$CLJS.E8=function(a){a=$CLJS.j2($CLJS.p1(a));return $CLJS.n($CLJS.sC(a,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ZB,sqa],null)))?$CLJS.$d.g($CLJS.Ug,$CLJS.PP):$CLJS.Ug};$CLJS.F8=function(a,b){var c=$CLJS.E8(a);return(0,$CLJS.rY)(a,0,function(d){var e=$CLJS.yv.g(tqa,c);d=$CLJS.P.j($CLJS.zk,d,e);d=$CLJS.Zk.l($CLJS.G([d,$CLJS.Wl(b,c)]));$CLJS.yv.g(c,$CLJS.Yg($CLJS.Pg(b)));$CLJS.iY(a,0);return d})};$CLJS.G8=function(a){return $CLJS.De($CLJS.Wl($CLJS.iY(a,0),$CLJS.E8(a)))};
$CLJS.H8=function(a){return $CLJS.MR.h($CLJS.iY(a,0))};sqa=new $CLJS.K(null,"native-requires-specified-collection","native-requires-specified-collection",1239149925);$CLJS.uqa=new $CLJS.K(null,"write","write",-1857649168);$CLJS.vqa=new $CLJS.K(null,"native-permissions","native-permissions",1277672164);var oqa,tqa;oqa=new $CLJS.Q(null,3,5,$CLJS.R,[/\{\{\s*([A-Za-z0-9_\.]+)\s*\}\}/,/\{\{\s*(snippet:\s*[^}]+)\s*\}\}/,/\{\{\s*(#([0-9]*)(-[a-z0-9-]*)?)\s*\}\}/],null);
$CLJS.I8=function(){function a(d,e){d=$CLJS.De(pqa(d));var f=$CLJS.De($CLJS.Yg($CLJS.Pg(e)));if($CLJS.n($CLJS.n(d)?d:f)){var k=$CLJS.yv.g(d,f);d=$CLJS.yv.g(f,d);if($CLJS.F.l(1,$CLJS.E(k),$CLJS.G([$CLJS.E(d)]))){d=$CLJS.B(d);k=$CLJS.B(k);f=$CLJS.I.g(e,d);var l=$CLJS.F.g($CLJS.cG.h(f),$CLJS.q2.g($CLJS.nD,d))?$CLJS.q2.g($CLJS.nD,k):$CLJS.cG.h(f);f=$CLJS.S.l($CLJS.zk.l(f,$CLJS.VN,$CLJS.G([$CLJS.GS,$CLJS.wR])),$CLJS.cG,l,$CLJS.G([$CLJS.T,k]));e=$CLJS.S.j($CLJS.zk.g(e,d),k,f)}else e=$CLJS.Zk.l($CLJS.G([mqa($CLJS.Ke(d),
e),nqa($CLJS.T,$CLJS.Ze.g(qqa,k))]));e=$CLJS.Gu(e,rqa)}else e=$CLJS.N;return e}function b(d){return $CLJS.I8.g?$CLJS.I8.g(d,null):$CLJS.I8.call(null,d,null)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();tqa=new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.PP,null],null),null);
$CLJS.Y(new $CLJS.K("metabase.lib.native","native-extras","metabase.lib.native/native-extras",1125545077),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.PP,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.hG],null)],null));
$CLJS.J8=function(){function a(d,e,f,k){var l=$CLJS.I8.h(e);return $CLJS.F8($CLJS.n2.g(d,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.TX(new $CLJS.h(null,4,[$CLJS.ov,$CLJS.EX,$CLJS.QW,f,$CLJS.jS,l,$CLJS.MR,e],null))],null)),k)}function b(d,e){return $CLJS.J8.v?$CLJS.J8.v(d,e,null,null):$CLJS.J8.call(null,d,e,null,null)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 2:return b.call(this,d,e);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.v=a;return c}();
$CLJS.K8=function(){function a(d,e,f){$CLJS.iY(d,0);return $CLJS.F8($CLJS.n2.g(e,$CLJS.UM.h(d)),f)}function b(d,e){return $CLJS.K8.j?$CLJS.K8.j(d,e,null):$CLJS.K8.call(null,d,e,null)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.m2.m(null,$CLJS.EX,function(a){return $CLJS.h2($CLJS.E8(a),$CLJS.Yg($CLJS.Pg($CLJS.G8(a))))&&!$CLJS.wC($CLJS.H8(a))});