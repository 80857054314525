var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.util.malli.registry.js");
'use strict';var W_,zka,Aka,Bka,Cka,Dka,b0,Eka,Fka,Gka,d0,Hka,Ika,Jka,i0,j0,Kka,Lka,Mka,s0,Nka,Oka,Pka,v0,Qka,Rka,Ska,Tka;W_=function(){};$CLJS.X_=function(a){return null!=a?$CLJS.r===a.fi?!0:a.Vc?!1:$CLJS.Wa(W_,a):$CLJS.Wa(W_,a)};zka=new $CLJS.w(null,"metabase.lib.metadata.protocols","metabase.lib.metadata.protocols",128660233,null);$CLJS.Y_=new $CLJS.K("lib","source-column-alias","lib/source-column-alias",1494821386);
Aka=new $CLJS.K("metabase.lib.schema.metadata","database","metabase.lib.schema.metadata/database",270611124);Bka=new $CLJS.K("lib","persisted-info","lib/persisted-info",-118569625);$CLJS.Z_=new $CLJS.K("source","previous-stage","source/previous-stage",1962610878);$CLJS.$_=new $CLJS.K(null,"database-id","database-id",1883826326);Cka=new $CLJS.w("metabase.lib.metadata.protocols","metadata-provider?","metabase.lib.metadata.protocols/metadata-provider?",-472923865,null);
$CLJS.a0=new $CLJS.K("source","table-defaults","source/table-defaults",909975194);Dka=new $CLJS.K(null,"human-readable-values","human-readable-values",-624842907);b0=new $CLJS.K("metabase.lib.schema.metadata","column.remapping.internal","metabase.lib.schema.metadata/column.remapping.internal",-285151559);Eka=new $CLJS.K("metabase.lib.schema.metadata","metadata-providerable","metabase.lib.schema.metadata/metadata-providerable",1520216538);Fka=new $CLJS.K(null,"dbms-version","dbms-version",-69238036);
Gka=new $CLJS.K(null,"field-name","field-name",1300687948);$CLJS.c0=new $CLJS.K(null,"dataset","dataset",1159262238);d0=new $CLJS.K("metabase.lib.schema.metadata","persisted-info","metabase.lib.schema.metadata/persisted-info",1457716669);Hka=new $CLJS.K(null,"field-definitions","field-definitions",1650155116);$CLJS.e0=new $CLJS.K("source","expressions","source/expressions",-458367840);$CLJS.f0=new $CLJS.K(null,"definition","definition",-1198729982);Ika=new $CLJS.K(null,"state","state",-1988618099);
$CLJS.g0=new $CLJS.K("source","implicitly-joinable","source/implicitly-joinable",-729101197);Jka=new $CLJS.K(null,"is-audit","is-audit",327058420);$CLJS.h0=new $CLJS.K("source","joins","source/joins",1225821486);i0=new $CLJS.K("metabase.lib.schema.metadata","column-source","metabase.lib.schema.metadata/column-source",1071627828);j0=new $CLJS.K("metabase.lib.schema.metadata","metadata-provider","metabase.lib.schema.metadata/metadata-provider",-1514728827);
$CLJS.k0=new $CLJS.K("lib","desired-column-alias","lib/desired-column-alias",-1998967402);$CLJS.l0=new $CLJS.K(null,"fk-target-field-id","fk-target-field-id",2019750287);Kka=new $CLJS.K("lib","external-remap","lib/external-remap",543358316);$CLJS.m0=new $CLJS.K(null,"selected?","selected?",-742502788);Lka=new $CLJS.K("metabase.lib.schema.metadata","segment","metabase.lib.schema.metadata/segment",-393440267);
Mka=new $CLJS.K("metabase.lib.schema.metadata","metric","metabase.lib.schema.metadata/metric",1984974682);$CLJS.n0=new $CLJS.K(null,"table-name","table-name",-2117866341);$CLJS.o0=new $CLJS.K("source","card","source/card",-139977973);$CLJS.p0=new $CLJS.K(null,"active","active",1895962068);$CLJS.q0=new $CLJS.K(null,"dataset-query","dataset-query",1851066427);$CLJS.r0=new $CLJS.K(null,"result-metadata","result-metadata",2017934672);
s0=new $CLJS.K("metabase.lib.schema.metadata","column.remapping.external","metabase.lib.schema.metadata/column.remapping.external",304054297);Nka=new $CLJS.K(null,"details","details",1956795411);$CLJS.t0=new $CLJS.K("source","fields","source/fields",-649667981);Oka=new $CLJS.K("metabase.lib.schema.metadata","card","metabase.lib.schema.metadata/card",147726027);$CLJS.u0=new $CLJS.K("source","native","source/native",-1444604147);
Pka=new $CLJS.K("metabase.lib.schema.metadata","table","metabase.lib.schema.metadata/table",-2128230385);v0=new $CLJS.K("metabase.lib.schema.metadata","persisted-info.definition","metabase.lib.schema.metadata/persisted-info.definition",496848961);$CLJS.w0=new $CLJS.K("lib","card-id","lib/card-id",-1770167062);Qka=new $CLJS.K("metadata.column.remapping","internal","metadata.column.remapping/internal",-1077920983);Rka=new $CLJS.w(null,"metadata-provider?","metadata-provider?",1669871524,null);
Ska=new $CLJS.K("lib","internal-remap","lib/internal-remap",-220033801);Tka=new $CLJS.K("metadata.column.remapping","external","metadata.column.remapping/external",-1813287755);$CLJS.x0=new $CLJS.K(null,"source-alias","source-alias",1652088724);$CLJS.y0=new $CLJS.K(null,"engine","engine",1459054265);$CLJS.Y(i0,new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.ht,$CLJS.o0,$CLJS.u0,$CLJS.Z_,$CLJS.a0,$CLJS.t0,$CLJS.NM,$CLJS.IM,$CLJS.h0,$CLJS.e0,$CLJS.g0],null));$CLJS.Y(s0,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ov,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,Tka],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ti,$CLJS.BM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.hG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.CM,$CLJS.pG],null)],null));
$CLJS.Y(b0,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ov,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,Qka],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ti,$CLJS.BM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.hG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.au,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uj,$CLJS.ei],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[Dka,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uj,$CLJS.ei],null)],null)],null));
$CLJS.Y($CLJS.dG,new $CLJS.Q(null,18,5,$CLJS.R,[$CLJS.ck,new $CLJS.h(null,1,[$CLJS.wu,"Valid column metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ov,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,$CLJS.tM],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.Tj],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.iD,$CLJS.qG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ti,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.pG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.cG,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.kt,$CLJS.Tj],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,$CLJS.qG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.FM,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,$CLJS.pG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.l0,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,$CLJS.pG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.x0,
new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,$CLJS.hG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vM,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,i0],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.w0,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,$CLJS.DM],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Y_,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,$CLJS.hG],
null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.k0,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Tj,new $CLJS.h(null,2,[$CLJS.Qn,1,$CLJS.dk,60],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.m0,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.zt],null),new $CLJS.Q(null,3,5,$CLJS.R,[Kka,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,s0],null)],null)],
null),new $CLJS.Q(null,3,5,$CLJS.R,[Ska,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,b0],null)],null)],null)],null));
$CLJS.Y(v0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.n0,$CLJS.hG],null),new $CLJS.Q(null,2,5,$CLJS.R,[Hka,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[Gka,$CLJS.hG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.iD,$CLJS.qG],null)],null)],null)],null)],null)],null));
$CLJS.Y(d0,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.p0,$CLJS.zt],null),new $CLJS.Q(null,2,5,$CLJS.R,[Ika,$CLJS.hG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.n0,$CLJS.hG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.f0,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,v0],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.sM,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,
$CLJS.hG],null)],null)],null));
$CLJS.Y(Oka,new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.ck,new $CLJS.h(null,1,[$CLJS.wu,"Valid Card metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ov,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,$CLJS.MM],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ti,$CLJS.DM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.hG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$_,$CLJS.EM],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.q0,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.ck],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.r0,
new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uj,$CLJS.ck],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.c0,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.zt],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.GM,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,$CLJS.yM],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Bka,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.X,d0],null)],null)],null)],null));
$CLJS.Y(Lka,new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.ck,new $CLJS.h(null,1,[$CLJS.wu,"Valid Segment metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ov,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,$CLJS.xM],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ti,$CLJS.wM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.hG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.GM,$CLJS.yM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.f0,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,$CLJS.ck],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,
[$CLJS.Tu,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,$CLJS.hG],null)],null)],null));
$CLJS.Y(Mka,new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.ck,new $CLJS.h(null,1,[$CLJS.wu,"Valid Metric metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ov,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,$CLJS.zM],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ti,$CLJS.uM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.hG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.GM,$CLJS.yM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.f0,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,$CLJS.ck],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,
[$CLJS.Tu,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,$CLJS.hG],null)],null)],null));
$CLJS.Y(Pka,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.ck,new $CLJS.h(null,1,[$CLJS.wu,"Valid Table metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ov,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,$CLJS.AM],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ti,$CLJS.yM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.hG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.cG,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,$CLJS.hG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.hl,new $CLJS.h(null,
1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,$CLJS.hG],null)],null)],null));
$CLJS.Y(Aka,new $CLJS.Q(null,10,5,$CLJS.R,[$CLJS.ck,new $CLJS.h(null,1,[$CLJS.wu,"Valid Database metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ov,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,$CLJS.KM],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ti,$CLJS.EM],null),new $CLJS.Q(null,3,5,$CLJS.R,[Fka,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,$CLJS.ck],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Nka,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.ck],null),new $CLJS.Q(null,
3,5,$CLJS.R,[$CLJS.y0,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.Ri],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ZB,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ui,$CLJS.Ri],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Jka,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.zt],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.HM,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,$CLJS.ck],null)],null)],null));
$CLJS.Y(j0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,1,[$CLJS.wu,"Valid MetadataProvider"],null),new $CLJS.Fc(function(){return $CLJS.X_},Cka,$CLJS.Ag([$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,$CLJS.Mi,$CLJS.ij,$CLJS.qk,$CLJS.ni,$CLJS.U,$CLJS.hk],[zka,Rka,"metabase/lib/metadata/protocols.cljc",25,1,78,78,$CLJS.W(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.Rq],null)),"Whether `x` is a valid [[MetadataProvider]].",$CLJS.n($CLJS.X_)?$CLJS.X_.H:null]))],null));
$CLJS.Y(Eka,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,j0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ck,new $CLJS.h(null,1,[$CLJS.wu,"map with a MetadataProvider in the key :lib/metadata (i.e. a query)"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ZF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,j0],null)],null)],null)],null));