var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./inflections.core.js");require("./medley.core.js");require("./metabase.lib.card.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.filter.js");require("./metabase.lib.filter.operator.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.query.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var qma,rma,sma,d4,tma,i4,j4,k4,l4,uma,vma,wma,xma,yma,zma,Ama,Bma,Cma,v4,w4,x4,y4,Dma,Ema,u4,t4,Fma;$CLJS.b4=function(a,b){$CLJS.ab(function(c,d){return a.h?a.h(d):a.call(null,d)},null,b)};
qma=function(a){return function(b){var c=$CLJS.Re(!0);return function(){function d(l,m){var t=$CLJS.q(c);if($CLJS.n($CLJS.n(t)?a.h?a.h(m):a.call(null,m):t))return l;$CLJS.xl(c,null);return b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=
f;k.h=e;k.g=d;return k}()}};rma=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;c=arguments[0];d=arguments[1];var e=arguments[2];b=3<b.length?new $CLJS.y(b.slice(3),0,null):null;return $CLJS.Sa($CLJS.z($CLJS.sC(c,d)))?c:$CLJS.P.N($CLJS.YY,c,d,e,b)};$CLJS.c4=function(a,b){return $CLJS.aY(a)&&$CLJS.F.g($CLJS.B(a),b)};
sma=function(a,b){return $CLJS.B($CLJS.Ul(function(c){if($CLJS.n($CLJS.n(c)?b:c)){var d=$CLJS.cC.h(c);c=$CLJS.dC.h(c);d=$CLJS.n($CLJS.gh(d,b))?$CLJS.vC(b,d,c):null}else d=null;return d},a))};d4=function(a){a=$CLJS.D2(a);var b=$CLJS.wC(a);b||($CLJS.n(a)?(b=$CLJS.n(a)?!$CLJS.Gd($CLJS.q($CLJS.J2),$CLJS.D2(a).toLowerCase()):null,b=$CLJS.Sa(b)):b=null);return $CLJS.n(b)?a:sma($CLJS.Zb($CLJS.q($CLJS.F2)),a)};
$CLJS.e4=function(a,b,c,d){if($CLJS.z(c)){d=$CLJS.Jk.g($CLJS.UX,d);var e=$CLJS.Wf.j($CLJS.Ug,$CLJS.Ze.h(function(f){return $CLJS.X2.v(a,b,f,c)}),d);return $CLJS.Jk.g(function(f){return $CLJS.S.j(f,$CLJS.m0,$CLJS.Gd(e,f))},c)}return null};$CLJS.f4=function(a){a=$CLJS.j3.h(a);return $CLJS.n(a)?new $CLJS.h(null,2,[$CLJS.ov,$CLJS.UI,$CLJS.li,a],null):null};$CLJS.g4=function(a){return $CLJS.F.g($CLJS.VD(a),$CLJS.PM)};
tma=function(a,b){return $CLJS.n($CLJS.HG.h(a))?$CLJS.Kk.j(a,$CLJS.HG,function(c){return $CLJS.vd(c)?$CLJS.Jk.g(function(d){return $CLJS.h4.g?$CLJS.h4.g(d,b):$CLJS.h4.call(null,d,b)},c):c}):a};
i4=function(a){if($CLJS.n(a)){var b=$CLJS.bf;a:try{if($CLJS.yd(a)&&4===$CLJS.E(a))try{if($CLJS.bd(a,0)instanceof $CLJS.K)try{var c=$CLJS.bd(a,2);if($CLJS.yd(c)&&3===$CLJS.E(c))try{var d=$CLJS.bd(c,0);if($CLJS.fe(d,$CLJS.YH))try{var e=$CLJS.bd(a,3);if($CLJS.yd(e)&&3===$CLJS.E(e))try{var f=$CLJS.bd(e,0);if($CLJS.fe(f,$CLJS.YH)){$CLJS.bd(e,1);$CLJS.bd(e,2);$CLJS.bd(c,1);$CLJS.bd(c,2);$CLJS.bd(a,0);$CLJS.bd(a,1);var k=new $CLJS.Q(null,1,5,$CLJS.R,[!0],null)}else throw $CLJS.VW;}catch(m){if(m instanceof
Error){var l=m;if(l===$CLJS.VW)throw $CLJS.VW;throw l;}throw m;}else throw $CLJS.VW;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.VW)throw $CLJS.VW;throw l;}throw m;}else throw $CLJS.VW;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.VW)throw $CLJS.VW;throw l;}throw m;}else throw $CLJS.VW;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.VW)throw $CLJS.VW;throw l;}throw m;}else throw $CLJS.VW;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.VW)throw $CLJS.VW;throw l;}throw m;}else throw $CLJS.VW;
}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.VW){k=new $CLJS.Q(null,1,5,$CLJS.R,[!1],null);break a}throw l;}throw m;}a=$CLJS.B($CLJS.z(b($CLJS.Ta,k)))}else a=null;return a};j4=function(a){if($CLJS.n(i4(a))){$CLJS.H(a,0,null);$CLJS.H(a,1,null);var b=$CLJS.H(a,2,null);$CLJS.H(a,3,null);return b}return null};k4=function(a){return $CLJS.n(i4(a))?($CLJS.H(a,0,null),$CLJS.H(a,1,null),$CLJS.H(a,2,null),$CLJS.H(a,3,null)):null};
l4=function(a,b){var c=$CLJS.h4;if($CLJS.Sa(i4(a)))return a;var d=$CLJS.H(a,0,null),e=$CLJS.H(a,1,null),f=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return new $CLJS.Q(null,4,5,$CLJS.R,[d,e,f,$CLJS.P.j(c,a,b)],null)};
uma=function(a,b,c){return $CLJS.sd($CLJS.XM.h(a))?a:$CLJS.n(b)?rma(a,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.XM],null),function(d){return function l(f,k){try{if($CLJS.yd(k)&&3===$CLJS.E(k))try{var m=$CLJS.bd(k,0);if($CLJS.fe(m,$CLJS.YH))try{var t=$CLJS.bd(k,1);if(null!=t?t.C&256||$CLJS.r===t.kf||(t.C?0:$CLJS.Wa($CLJS.rb,t)):$CLJS.Wa($CLJS.rb,t))try{var u=$CLJS.I.j(t,$CLJS.XQ,$CLJS.fX);if($CLJS.F.g(u,b))return $CLJS.bd(k,2),$CLJS.h4.g?$CLJS.h4.g(k,c):$CLJS.h4.call(null,k,c);throw $CLJS.VW;}catch(x){if(x instanceof
Error){var v=x;if(v===$CLJS.VW)throw $CLJS.VW;throw v;}throw x;}else throw $CLJS.VW;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.VW)throw $CLJS.VW;throw v;}throw x;}else throw $CLJS.VW;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.VW)throw $CLJS.VW;throw v;}throw x;}else throw $CLJS.VW;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.VW)return $CLJS.AY(l,f,k);throw v;}throw x;}}($CLJS.tf,d)}):$CLJS.Kk.j(a,$CLJS.XM,function(d){return $CLJS.Jk.g(function(e){return l4(e,$CLJS.G([c]))},d)})};
$CLJS.h4=function(a,b){var c=$CLJS.VD(a);switch(c instanceof $CLJS.K?c.T:null){case "field":return $CLJS.wG(a,$CLJS.uG,$CLJS.G([$CLJS.XQ,b]));case "metadata/column":return $CLJS.uG(a,$CLJS.z0,b);case "mbql/join":return c=$CLJS.s2(a),uma(tma($CLJS.uG(a,$CLJS.LG,b),b),c,b);default:return b=$CLJS.BC($CLJS.vA),$CLJS.n($CLJS.AC("metabase.lib.join",b))&&$CLJS.zC("metabase.lib.join",b,$CLJS.fy.l($CLJS.G(["with-join-value should not be called with",$CLJS.Eh.l($CLJS.G([a]))])),null),a}};
$CLJS.m4=function(a,b,c){var d=$CLJS.S.l,e=$CLJS.rC($CLJS.Y_,$CLJS.T)(c);a=$CLJS.t2($CLJS.LG.h(a),$CLJS.rC($CLJS.Y_,$CLJS.T)(c));b=b.h?b.h(a):b.call(null,a);return d.call($CLJS.S,c,$CLJS.Y_,e,$CLJS.G([$CLJS.k0,b]))};vma=function(a,b){return $CLJS.Sa(b)?a:$CLJS.Jk.g(function(c){var d=k4(c);d=$CLJS.n(d)?$CLJS.n($CLJS.s2(d))?null:l4(c,$CLJS.G([b])):null;return $CLJS.n(d)?d:c},a)};$CLJS.n4=function(a,b){b=vma($CLJS.Jk.g($CLJS.VX,b),$CLJS.s2(a));return $CLJS.uG(a,$CLJS.XM,$CLJS.De(b))};
$CLJS.o4=function(a,b){b=b instanceof $CLJS.K?b:$CLJS.F.g(b,$CLJS.tf)?$CLJS.YB:$CLJS.De($CLJS.Wf.j($CLJS.tf,$CLJS.Hk.g($CLJS.Ze.h($CLJS.UX),function(){var c=$CLJS.s2(a);return $CLJS.n(c)?$CLJS.Ze.h(function(d){return $CLJS.h4(d,c)}):$CLJS.Rd}()),b));return $CLJS.uG(a,$CLJS.HG,b)};
wma=function(a,b){b=$CLJS.Ul(function(d){return $CLJS.X2.g(d,a)},b);var c=$CLJS.jJ($CLJS.rC($CLJS.c1,$CLJS.d1),b);if($CLJS.n(c))return c;b=$CLJS.B(b);if($CLJS.n(b))return b;b=$CLJS.jJ($CLJS.c1,a);if($CLJS.n(b))return b;b=$CLJS.jJ($CLJS.d1,a);return $CLJS.n(b)?b:$CLJS.B(a)};xma=function(a){return"string"===typeof a?(0,$CLJS.ma)($CLJS.vC(a,/ id$/i,"")):null};
yma=function(a,b){var c="string"===typeof a;if(c){if(c="string"===typeof b){c=new $CLJS.Bh(function(){return d4(b)});var d=new $CLJS.Bh(function(){return d4(a)});return $CLJS.F.g(a,b)||$CLJS.F.g(a,$CLJS.q(c))||$CLJS.F.g($CLJS.q(d),b)||$CLJS.F.g($CLJS.q(d),$CLJS.q(c))}return c}return c};
zma=function(a,b,c){var d=$CLJS.u1.g($CLJS.F.g($CLJS.ov.h(b),$CLJS.KX)?b:a,b),e=$CLJS.n(c)?xma($CLJS.u1.g(a,c)):null,f=yma(d,e);return function(){var k=function(){if($CLJS.n(d)){if($CLJS.n(e)){var l=$CLJS.Sa($CLJS.fh(/id/i,e));return l?(l=!f)?[$CLJS.p.h(d)," - ",e].join(""):l:l}return e}return d}();return $CLJS.n(k)?k:$CLJS.n(d)?d:$CLJS.n(e)?e:"source"}()};
Ama=function(a,b,c,d,e){return function m(k,l){try{if($CLJS.c4(l,$CLJS.YH)&&$CLJS.Dd($CLJS.X2.v(a,b,l,e)))return $CLJS.h4(l,d);throw $CLJS.VW;}catch(u){if(u instanceof Error){var t=u;if(t===$CLJS.VW)return $CLJS.AY(m,k,l);throw t;}throw u;}}($CLJS.tf,c)};
Bma=function(a,b,c,d,e,f){c=Ama(a,b,c,d,f);return function t(l,m){try{if($CLJS.yd(m)&&4===$CLJS.E(m))try{var u=$CLJS.bd(m,2);if($CLJS.c4(u,$CLJS.YH))try{var v=$CLJS.bd(m,3);if($CLJS.c4(v,$CLJS.YH)){var x=$CLJS.bd(m,3),A=$CLJS.bd(m,2),D=$CLJS.bd(m,0),J=$CLJS.bd(m,1),M=$CLJS.s2(A),V=$CLJS.s2(x);if($CLJS.Sa($CLJS.n(M)?M:V))return $CLJS.n($CLJS.X2.v(a,b,x,e))?new $CLJS.Q(null,4,5,$CLJS.R,[D,J,$CLJS.h4(A,d),x],null):new $CLJS.Q(null,4,5,$CLJS.R,[D,J,A,$CLJS.h4(x,d)],null);if($CLJS.F.g(M,d)&&$CLJS.F.g(V,
d)){var Z=$CLJS.wG(A,$CLJS.zk,$CLJS.G([$CLJS.XQ])),fa=$CLJS.wG(x,$CLJS.zk,$CLJS.G([$CLJS.XQ])),Da=null==$CLJS.X2.v(a,b,Z,e);var Ha=Da?$CLJS.X2.v(a,b,fa,e):Da;return $CLJS.n(Ha)?new $CLJS.Q(null,4,5,$CLJS.R,[D,J,A,fa],null):new $CLJS.Q(null,4,5,$CLJS.R,[D,J,Z,x],null)}return m}throw $CLJS.VW;}catch(Qb){if(Qb instanceof Error){var mb=Qb;if(mb===$CLJS.VW)throw $CLJS.VW;throw mb;}throw Qb;}else throw $CLJS.VW;}catch(Qb){if(Qb instanceof Error){mb=Qb;if(mb===$CLJS.VW)throw $CLJS.VW;throw mb;}throw Qb;
}else throw $CLJS.VW;}catch(Qb){if(Qb instanceof Error){mb=Qb;if(mb===$CLJS.VW)return $CLJS.AY(t,l,m);throw mb;}throw Qb;}}($CLJS.tf,c)};Cma=function(a,b){var c=$CLJS.lY();$CLJS.b4(c,b);return c(a)};
$CLJS.p4=function(a,b,c){if($CLJS.Gd(c,$CLJS.LG))return c;var d=$CLJS.iY(a,b),e=$CLJS.c2.j(a,b,d),f=$CLJS.z($CLJS.bf($CLJS.Ta,function v(t,u){try{if($CLJS.yd(u)&&1<=$CLJS.E(u))try{var x=$CLJS.Ok.j(u,0,1);if($CLJS.yd(x)&&1===$CLJS.E(x))try{var A=$CLJS.bd(x,0);if($CLJS.fe(A,$CLJS.YH))return new $CLJS.Q(null,1,5,$CLJS.R,[u],null);throw $CLJS.VW;}catch(J){if(J instanceof Error){var D=J;if(D===$CLJS.VW)throw $CLJS.VW;throw D;}throw J;}else throw $CLJS.VW;}catch(J){if(J instanceof Error){D=J;if(D===$CLJS.VW)throw $CLJS.VW;
throw D;}throw J;}else throw $CLJS.VW;}catch(J){if(J instanceof Error){D=J;if(D===$CLJS.VW)return $CLJS.dX(v,t,u);throw D;}throw J;}}($CLJS.tf,$CLJS.XM.h(c))));f=wma(e,f);var k=Cma(zma(a,c,f),$CLJS.Ul($CLJS.LG,$CLJS.gP.h(d))),l=$CLJS.a2.h($CLJS.n2.g(a,$CLJS.UM.h(c)));return $CLJS.h4($CLJS.Kk.j(c,$CLJS.XM,function(t){return $CLJS.Jk.g(function(u){return Bma(a,b,u,k,e,l)},t)}),k)};$CLJS.q4=function(a){return $CLJS.XM.h(a)};
$CLJS.r4=function(a){return $CLJS.Zk.l($CLJS.G([new $CLJS.h(null,2,[$CLJS.ov,$CLJS.YM,$CLJS.sG,a],null),$CLJS.F.g(a,$CLJS.RG)?new $CLJS.h(null,1,[$CLJS.Wh,!0],null):null]))};$CLJS.s4=function(a,b){b=$CLJS.B($CLJS.UM.h(b));return $CLJS.n($CLJS.qX.h(b))?$CLJS.s1(a,$CLJS.qX.h(b)):$CLJS.n($CLJS.IQ.h(b))?$CLJS.q1(a,$CLJS.IQ.h(b)):null};
v4=function(a){a=$CLJS.iJ(function(d){return $CLJS.d1(d)?t4:$CLJS.c1(d)?u4:$CLJS.hv},a);var b=$CLJS.O(a);a=$CLJS.I.g(b,t4);var c=$CLJS.I.g(b,u4);b=$CLJS.I.g(b,$CLJS.hv);return $CLJS.Ye.l(a,c,$CLJS.G([b]))};w4=function(a,b,c,d){return $CLJS.Sa(c)?d:$CLJS.Jk.g(function(e){return $CLJS.n($CLJS.m0.h(e))?$CLJS.h3(e,$CLJS.f4(c)):e},$CLJS.e4(a,b,d,new $CLJS.Q(null,1,5,$CLJS.R,[c],null)))};x4=function(a,b,c){return $CLJS.jJ($CLJS.d1,$CLJS.c2.j(a,b,c))};
y4=function(a,b){var c=$CLJS.Ti.h(a);return $CLJS.n(c)?$CLJS.jJ(function(d){d=$CLJS.O(d);var e=$CLJS.I.g(d,$CLJS.l0);return $CLJS.c1(d)&&$CLJS.F.g(e,c)},b):null};Dma={};Ema=new $CLJS.K("metabase.lib.join","join-fields","metabase.lib.join/join-fields",-2085751377);u4=new $CLJS.K(null,"fk","fk",398442651);t4=new $CLJS.K(null,"pk","pk",-771936732);Fma=new $CLJS.w("metabase.lib.stage","ensure-previous-stages-have-metadata","metabase.lib.stage/ensure-previous-stages-have-metadata",2048342183,null);$CLJS.S1.m(null,$CLJS.PM,function(a,b,c){b=$CLJS.O(c);b=$CLJS.I.g(b,$CLJS.UM);b=$CLJS.H(b,0,null);c=$CLJS.O(b);b=$CLJS.I.g(c,$CLJS.IQ);c=$CLJS.I.g(c,$CLJS.qX);a=$CLJS.n(b)?$CLJS.cG.h($CLJS.q1(a,b)):null;if($CLJS.n(a))return a;a=$CLJS.n(c)?$CLJS.x2(c):null;return $CLJS.n(a)?a:$CLJS.zG("Native Query")});$CLJS.Z1.m(null,$CLJS.PM,function(a,b,c){a=$CLJS.u1.j(a,b,c);c=$CLJS.LG.h(c);return new $CLJS.h(null,2,[$CLJS.T,$CLJS.n(c)?c:a,$CLJS.cG,a],null)});
$CLJS.Y1.m(null,$CLJS.PM,function(){throw $CLJS.ai("You can't calculate a metadata map for a join! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});
$CLJS.S1.m(null,$CLJS.YM,function(a,b,c){a=$CLJS.O(c);a=$CLJS.I.g(a,$CLJS.sG);a=a instanceof $CLJS.K?a.T:null;switch(a){case "left-join":return $CLJS.zG("Left outer join");case "right-join":return $CLJS.zG("Right outer join");case "inner-join":return $CLJS.zG("Inner join");case "full-join":return $CLJS.zG("Full outer join");default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});
$CLJS.Z1.m(null,$CLJS.YM,function(a,b,c){var d=$CLJS.O(c),e=$CLJS.I.g(d,$CLJS.sG);c=$CLJS.I.g(d,$CLJS.Wh);a=new $CLJS.h(null,2,[$CLJS.P1,$CLJS.PC(e),$CLJS.cG,$CLJS.u1.j(a,b,d)],null);return $CLJS.n(c)?$CLJS.S.j(a,$CLJS.Wh,!0):a});
$CLJS.$1.m(null,$CLJS.PM,function(a,b,c,d){var e=$CLJS.O(c),f=$CLJS.I.g(e,$CLJS.LG),k=$CLJS.I.j(e,$CLJS.HG,$CLJS.YB),l=$CLJS.I.g(e,$CLJS.UM);c=$CLJS.O(d);var m=$CLJS.I.g(c,$CLJS.M1);if($CLJS.F.g(k,$CLJS.YB))return null;var t="undefined"!==typeof $CLJS.HN&&"undefined"!==typeof Dma&&"undefined"!==typeof $CLJS.Gma&&"undefined"!==typeof $CLJS.z4?new $CLJS.Fc(function(){return $CLJS.z4},$CLJS.od(Fma,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),null):null,u=function(){var v=$CLJS.S.j(a,$CLJS.UM,l);return $CLJS.n(t)?
t.g?t.g(v,-1):t.call(null,v,-1):v}();c=$CLJS.F.g(k,$CLJS.pA)?$CLJS.a2.v(u,-1,$CLJS.qd(l),c):function(){return function A(x){return new $CLJS.le(null,function(){for(;;){var D=$CLJS.z(x);if(D){if($CLJS.zd(D)){var J=$CLJS.kc(D),M=$CLJS.E(J),V=$CLJS.oe(M);a:for(var Z=0;;)if(Z<M){var fa=$CLJS.kd(J,Z);fa=$CLJS.wG(fa,$CLJS.zk,$CLJS.G([$CLJS.XQ]));fa=$CLJS.t1.j(u,-1,fa);V.add(fa);Z+=1}else{J=!0;break a}return J?$CLJS.re($CLJS.te(V),A($CLJS.lc(D))):$CLJS.re($CLJS.te(V),null)}V=$CLJS.B(D);V=$CLJS.wG(V,$CLJS.zk,
$CLJS.G([$CLJS.XQ]));return $CLJS.ce($CLJS.t1.j(u,-1,V),A($CLJS.Kc(D)))}return null}},null,null)}(k)}();return $CLJS.Jk.g(function(v){v=$CLJS.S.j(v,$CLJS.x0,f);v=$CLJS.h4($CLJS.S.l(v,$CLJS.cG,$CLJS.u1.j(a,b,v),$CLJS.G([$CLJS.vM,$CLJS.h0])),f);return $CLJS.m4(e,m,v)},c)});$CLJS.b2.m(null,$CLJS.PM,function(a,b,c,d){return $CLJS.a2.v(a,b,$CLJS.S.j(c,$CLJS.HG,$CLJS.pA),d)});
var A4,Hma=$CLJS.Oe($CLJS.N),Ima=$CLJS.Oe($CLJS.N),Jma=$CLJS.Oe($CLJS.N),Kma=$CLJS.Oe($CLJS.N),Lma=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.cj,$CLJS.XD],null),$CLJS.cj,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));A4=new $CLJS.Xh($CLJS.xh.g("metabase.lib.join","join-clause-method"),$CLJS.VD,Lma,Hma,Ima,Jma,Kma);A4.m(null,$CLJS.PM,function(a){return a});A4.m(null,$CLJS.KX,function(a){return $CLJS.TX(new $CLJS.h(null,2,[$CLJS.ov,$CLJS.PM,$CLJS.UM,$CLJS.UM.h($CLJS.gY(a))],null))});
A4.m(null,$CLJS.xX,function(a){return $CLJS.TX(new $CLJS.h(null,2,[$CLJS.ov,$CLJS.PM,$CLJS.UM,new $CLJS.Q(null,1,5,$CLJS.R,[a],null)],null))});A4.m(null,$CLJS.MM,function(a){return $CLJS.TX(new $CLJS.h(null,2,[$CLJS.ov,$CLJS.PM,$CLJS.UM,new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.h(null,2,[$CLJS.qX,$CLJS.Ti.h(a),$CLJS.ov,$CLJS.xX],null)],null)],null))});
A4.m(null,$CLJS.AM,function(a){var b=$CLJS.O(a),c=$CLJS.I.g(b,$CLJS.z0);a=$CLJS.I.g(b,Ema);b=A4.h(new $CLJS.h(null,3,[$CLJS.ov,$CLJS.xX,$CLJS.jD,new $CLJS.h(null,1,[$CLJS.nG,$CLJS.p.h($CLJS.yG())],null),$CLJS.IQ,$CLJS.Ti.h(b)],null));c=$CLJS.n(c)?$CLJS.h4(b,c):b;return $CLJS.n(a)?$CLJS.o4.g?$CLJS.o4.g(c,a):$CLJS.o4.call(null,c,a):c});
$CLJS.B4=function(){function a(d,e){return $CLJS.n4(c.h?c.h(d):c.call(null,d),e)}function b(d){return $CLJS.d3(A4.h(d),$CLJS.HG,$CLJS.pA)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Mma=function(){function a(d,e,f){var k=$CLJS.B4.h(f);f=$CLJS.sd($CLJS.q4.h?$CLJS.q4.h(k):$CLJS.q4.call(null,k))?function(){var m=$CLJS.s4.g?$CLJS.s4.g(d,k):$CLJS.s4.call(null,d,k);return $CLJS.C4.j?$CLJS.C4.j(d,e,m):$CLJS.C4.call(null,d,e,m)}():null;f=$CLJS.n(f)?$CLJS.n4(k,new $CLJS.Q(null,1,5,$CLJS.R,[f],null)):k;var l=$CLJS.p4(d,e,f);return $CLJS.rY.l(d,e,$CLJS.Kk,$CLJS.G([$CLJS.gP,function(m){return $CLJS.$d.g($CLJS.uf(m),l)}]))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}
var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.D4=function(){function a(d,e){return $CLJS.De($CLJS.I.g($CLJS.iY(d,e),$CLJS.gP))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Nma=function(){function a(d){d=$CLJS.j2($CLJS.p1(d));d=$CLJS.ZB.h(d);return $CLJS.Wf.j($CLJS.tf,$CLJS.Hk.g($CLJS.af($CLJS.Me($CLJS.Gd,d)),$CLJS.Ze.h($CLJS.r4)),new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.RG,$CLJS.GG,$CLJS.VG,$CLJS.YG],null))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Oma=function(){function a(d,e,f,k){var l=$CLJS.g4(f)?$CLJS.s2(f):null,m=$CLJS.Wf.j($CLJS.Ug,$CLJS.Hk.g($CLJS.Ze.h($CLJS.s2),qma(function(t){return $CLJS.Fk.g(t,l)})),$CLJS.D4.g(d,e));f=$CLJS.n(k)?k:$CLJS.g4(f)?j4($CLJS.B($CLJS.q4(f))):null;return v4(w4(d,e,f,$CLJS.Ik.g(function(t){t=$CLJS.s2(t);return $CLJS.n(t)?$CLJS.Gd(m,t):null},$CLJS.c2.v(d,e,$CLJS.iY(d,e),new $CLJS.h(null,1,[$CLJS.H1,!1],null)))))}function b(d,e,f,k){return c.N?c.N(d,-1,e,f,k):c.call(null,d,-1,e,f,k)}var c=null;c=function(d,
e,f,k,l){switch(arguments.length){case 4:return b.call(this,d,e,f,k);case 5:return a.call(this,d,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.N=a;return c}();
$CLJS.Pma=function(){function a(d,e,f,k,l){k=$CLJS.g4(f)?$CLJS.s4(d,f):f;var m=$CLJS.g4(f)?$CLJS.s2(f):null;f=$CLJS.n(l)?l:$CLJS.g4(f)?k4($CLJS.B($CLJS.q4(f))):null;f=$CLJS.n(f)?$CLJS.Sa(m)?$CLJS.wG(f,$CLJS.zk,$CLJS.G([$CLJS.XQ])):f:null;return v4(w4(d,e,f,$CLJS.Ze.g(function(t){t=$CLJS.S.j(t,$CLJS.vM,$CLJS.h0);return $CLJS.n(m)?$CLJS.h4(t,m):t},$CLJS.c2.v(d,e,k,new $CLJS.h(null,1,[$CLJS.H1,!1],null)))))}function b(d,e,f,k){return c.N?c.N(d,-1,e,f,k):c.call(null,d,-1,e,f,k)}var c=null;c=function(d,
e,f,k,l){switch(arguments.length){case 4:return b.call(this,d,e,f,k);case 5:return a.call(this,d,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.N=a;return c}();$CLJS.Qma=function(){function a(c,d,e){return b.v?b.v(c,-1,d,e):b.call(null,c,-1,d,e)}var b=null;b=function(c,d,e,f){switch(arguments.length){case 3:return a.call(this,c,d,e);case 4:return $CLJS.b3}throw Error("Invalid arity: "+arguments.length);};b.j=a;b.v=function(){return $CLJS.b3};return b}();
$CLJS.C4=function(){function a(d,e,f){function k(t,u){return $CLJS.a4.l($CLJS.$2.h($CLJS.Ls),t,$CLJS.G([u]))}var l=function(){var t=x4(d,e,f);if($CLJS.n(t)){if($CLJS.n(t)){var u=$CLJS.c2.j(d,e,$CLJS.iY(d,e));u=y4(t,u)}else u=null;return $CLJS.n(u)?k(u,t):null}return null}();if($CLJS.n(l))return l;l=x4(d,e,$CLJS.iY(d,e));if($CLJS.n(l)){var m=y4(l,$CLJS.c2.j(d,e,f));return $CLJS.n(m)?k(l,m):null}return null}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.Rma=function(){function a(e,f,k,l){l=$CLJS.n(l)?l:$CLJS.g4(k)?j4($CLJS.B($CLJS.q4(k))):null;$CLJS.n(l)?(l=$CLJS.x1.j(e,f,l),l=$CLJS.sC(l,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qY,$CLJS.cG],null))):l=null;if($CLJS.n(l))return l;if(l=0===$CLJS.hY(e,f)){l=$CLJS.D4.g(e,f);var m=$CLJS.sd(l);k=m?m:$CLJS.g4(k)?$CLJS.F.g($CLJS.LG.h(k),$CLJS.LG.h($CLJS.B(l))):null;k=$CLJS.n(k)?$CLJS.l1(e):k}else k=l;$CLJS.n(k)?(k=$CLJS.l1(e),k=$CLJS.q1(e,k),e=$CLJS.u1.j(e,f,k)):e=null;return $CLJS.n(e)?e:$CLJS.zG("Previous results")}
function b(e,f,k){return d.v?d.v(e,-1,f,k):d.call(null,e,-1,f,k)}function c(e,f){return d.j?d.j(e,f,null):d.call(null,e,f,null)}var d=null;d=function(e,f,k,l){switch(arguments.length){case 2:return c.call(this,e,f);case 3:return b.call(this,e,f,k);case 4:return a.call(this,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};d.g=c;d.j=b;d.v=a;return d}();
$CLJS.Sma=function(){function a(d,e,f,k){f=$CLJS.VX.h(f);$CLJS.H(f,0,null);$CLJS.H(f,1,null);var l=$CLJS.H(f,2,null),m=$CLJS.H(f,3,null);k=k instanceof $CLJS.K?k:$CLJS.li.h(k);e=$CLJS.hY(d,e);l=$CLJS.m3.j(d,e,l);d=$CLJS.m3.j(d,e,m);m=null==k||$CLJS.Gd($CLJS.Yg($CLJS.Ze.g($CLJS.li,l)),k);d=null==k||$CLJS.Gd($CLJS.Yg($CLJS.Ze.g($CLJS.li,d)),k);f=m?$CLJS.Kk.v(f,2,$CLJS.h3,k):f;return d?$CLJS.Kk.v(f,3,$CLJS.h3,k):f}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,
e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();$CLJS.V1.m(null,$CLJS.gP,function(a,b){var c=$CLJS.De($CLJS.D4.g(a,b));c=null==c?null:$CLJS.Ze.g(function(d){return $CLJS.u1.j(a,b,d)},c);return null==c?null:$CLJS.Hu(" + ",c)});