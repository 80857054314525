var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.match.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var c7,koa,loa,moa,noa,e7,f7,g7,h7,ooa,poa,k7,i7,j7,l7,m7,qoa,roa,soa,n7,toa,uoa,o7,voa,woa;c7=function(a,b){if(b=$CLJS.z(b)){var c=$CLJS.z(b);b=$CLJS.B(c);c=$CLJS.C(c);return $CLJS.z(c)?(c=c7($CLJS.I.g(a,b),c),$CLJS.sd(c)?$CLJS.zk.g(a,b):$CLJS.S.j(a,b,c)):$CLJS.zk.g(a,b)}return a};
koa=function(a,b,c,d){return function t(f,k,l,m){var u=$CLJS.z(k);k=$CLJS.B(u);u=$CLJS.C(u);var v=$CLJS.Xl(f,k);return $CLJS.n(v)?u?$CLJS.S.j(f,k,t($CLJS.zb(v),u,l,m)):$CLJS.S.j(f,k,$CLJS.P.j(l,$CLJS.zb(v),m)):f}(a,b,c,d)};loa=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return koa(arguments[0],arguments[1],arguments[2],3<b.length?new $CLJS.y(b.slice(3),0,null):null)};
moa=function(a,b){return $CLJS.B($CLJS.N2(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?c:null},b))};$CLJS.d7=function(a){return $CLJS.nG.h($CLJS.vG(a))};
noa=function(a,b,c,d){var e=$CLJS.F.g($CLJS.CR,$CLJS.B(b))?$CLJS.cY(d,$CLJS.bY(c)):d;return loa(a,b,function(f){return $CLJS.uf(function(){return function m(l){return new $CLJS.le(null,function(){for(;;){var t=$CLJS.z(l);if(t){if($CLJS.zd(t)){var u=$CLJS.kc(t),v=$CLJS.E(u),x=$CLJS.oe(v);a:for(var A=0;;)if(A<v){var D=$CLJS.kd(u,A);D=$CLJS.F.g($CLJS.d7(D),$CLJS.d7(c))?e:D;x.add(D);A+=1}else{u=!0;break a}return u?$CLJS.re($CLJS.te(x),m($CLJS.lc(t))):$CLJS.re($CLJS.te(x),null)}x=$CLJS.B(t);return $CLJS.ce($CLJS.F.g($CLJS.d7(x),
$CLJS.d7(c))?e:x,m($CLJS.Kc(t)))}return null}},null,null)}(f)}())})};
e7=function(a,b,c){var d=$CLJS.sC(a,b);if($CLJS.n(d)){var e=$CLJS.d7(c),f=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.B(b),$CLJS.id(b)],null);c=$CLJS.H(f,0,null);f=$CLJS.H(f,1,null);d=$CLJS.Wf.j($CLJS.tf,$CLJS.Ik.h($CLJS.Hk.g($CLJS.Vg([e]),$CLJS.d7)),d);d=$CLJS.F.g(b,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.HG],null))&&$CLJS.Ie(function(k){return $CLJS.c4(k,$CLJS.fD)},d)?null:d;if($CLJS.z(d))return $CLJS.xN(a,b,d);if($CLJS.F.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gP,$CLJS.XM],null),new $CLJS.Q(null,2,5,$CLJS.R,
[c,f],null)))throw $CLJS.ai($CLJS.zG("Cannot remove the final join condition"),new $CLJS.h(null,4,[$CLJS.vA,$CLJS.mY,$CLJS.XM,$CLJS.sC(a,b),$CLJS.nY,$CLJS.sC(a,$CLJS.rd(b)),$CLJS.pY,a],null));return $CLJS.F.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gP,$CLJS.HG],null),new $CLJS.Q(null,2,5,$CLJS.R,[c,f],null))?$CLJS.YY.v(a,$CLJS.rd(b),$CLJS.zk,f):c7(a,b)}return a};f7=function(a,b){a=$CLJS.O(a);a=$CLJS.I.g(a,$CLJS.UM);b=0>b?$CLJS.E(a)+b:b;return b+1<$CLJS.E(a)?b+1:null};
g7=function(a,b){var c=$CLJS.D4.g(a,b),d=$CLJS.dh(0,$CLJS.E(c));return $CLJS.Ye.l(new $CLJS.Q(null,6,5,$CLJS.R,[new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.rT],null),new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.MH],null),new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.MX],null),new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.HG],null),new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.NH],null),new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.CR],null)],null),function(){return function k(f){return new $CLJS.le(null,function(){for(var l=f;;)if(l=$CLJS.z(l)){if($CLJS.zd(l)){var m=
$CLJS.kc(l),t=$CLJS.E(m),u=$CLJS.oe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v),A=$CLJS.bd(c,x);$CLJS.HG.h(A)instanceof $CLJS.K||u.add(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gP,x,$CLJS.HG],null));v+=1}else{m=!0;break a}return m?$CLJS.re($CLJS.te(u),k($CLJS.lc(l))):$CLJS.re($CLJS.te(u),null)}u=$CLJS.B(l);m=$CLJS.bd(c,u);if($CLJS.HG.h(m)instanceof $CLJS.K)l=$CLJS.Kc(l);else return $CLJS.ce(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gP,u,$CLJS.HG],null),k($CLJS.Kc(l)))}else return null},null,null)}(d)}(),$CLJS.G([function(){return function k(f){return new $CLJS.le(null,
function(){for(;;){var l=$CLJS.z(f);if(l){if($CLJS.zd(l)){var m=$CLJS.kc(l),t=$CLJS.E(m),u=$CLJS.oe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v);u.add(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gP,x,$CLJS.XM],null));v+=1}else{m=!0;break a}return m?$CLJS.re($CLJS.te(u),k($CLJS.lc(l))):$CLJS.re($CLJS.te(u),null)}u=$CLJS.B(l);return $CLJS.ce(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gP,u,$CLJS.XM],null),k($CLJS.Kc(l)))}return null}},null,null)}(d)}()]))};
h7=function(a,b,c){var d=$CLJS.H(c,0,null),e=$CLJS.H(c,1,null);e=$CLJS.O(e);var f=$CLJS.I.g(e,$CLJS.dI),k=$CLJS.I.g(e,$CLJS.YS),l=$CLJS.H(c,2,null);return $CLJS.B($CLJS.jJ(function(m){$CLJS.H(m,0,null);m=$CLJS.H(m,1,null);$CLJS.H(m,0,null);$CLJS.H(m,1,null);m=$CLJS.H(m,2,null);return $CLJS.F.g($CLJS.B(m),d)&&$CLJS.F.g($CLJS.dI.h($CLJS.hd(m)),f)&&$CLJS.F.g($CLJS.YS.h($CLJS.hd(m)),k)&&$CLJS.F.g($CLJS.id(m),l)},$CLJS.Vl($CLJS.Ct,$CLJS.rT.h($CLJS.iY(a,b)))))};
ooa=function(a,b,c,d){c=h7(a,b,c);return $CLJS.n(c)?$CLJS.rY.l(a,b,$CLJS.YY,$CLJS.G([new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.rT,c,2,1],null),$CLJS.Hk.g(function(e){return $CLJS.yC($CLJS.Ke($CLJS.rl),e)},$CLJS.Zk),d])):a};poa=function(a,b,c){c=h7(a,b,c);return $CLJS.n(c)?$CLJS.rY.l(a,b,e7,$CLJS.G([new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.rT],null),$CLJS.sC($CLJS.iY(a,b),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.rT,c],null))])):a};
k7=function(a,b,c,d,e,f){var k=$CLJS.rY.l(a,b,f,$CLJS.G([d,e])),l=$CLJS.d7(e);return $CLJS.Fk.g(a,k)?$CLJS.B($CLJS.z($CLJS.bf($CLJS.Ta,function(m,t){try{if($CLJS.yd(t)&&1===$CLJS.E(t))try{var u=$CLJS.bd(t,0);if($CLJS.fe(u,$CLJS.CR))return new $CLJS.Q(null,1,5,$CLJS.R,[function(){var M=$CLJS.bY(e);M=i7.W?i7.W(k,b,c,$CLJS.fD,$CLJS.N,M):i7.call(null,k,b,c,$CLJS.fD,$CLJS.N,M);return j7.v?j7.v(M,b,c,l):j7.call(null,M,b,c,l)}()],null);throw $CLJS.VW;}catch(M){if(M instanceof Error)if(m=M,m===$CLJS.VW)try{u=
$CLJS.bd(t,0);if($CLJS.fe(u,$CLJS.NH))return new $CLJS.Q(null,1,5,$CLJS.R,[function(){var V=i7.W?i7.W(k,b,c,$CLJS.NH,$CLJS.N,l):i7.call(null,k,b,c,$CLJS.NH,$CLJS.N,l);return j7.v?j7.v(V,b,c,l):j7.call(null,V,b,c,l)}()],null);throw $CLJS.VW;}catch(V){if(V instanceof Error){var v=V;if(v===$CLJS.VW)throw $CLJS.VW;throw v;}throw V;}else throw m;else throw M;}else throw $CLJS.VW;}catch(M){if(M instanceof Error)if(m=M,m===$CLJS.VW)try{if($CLJS.yd(t)&&1===$CLJS.E(t))try{var x=$CLJS.bd(t,0);if($CLJS.fe(x,
$CLJS.MH))return new $CLJS.Q(null,1,5,$CLJS.R,[j7.v?j7.v(k,b,c,l):j7.call(null,k,b,c,l)],null);throw $CLJS.VW;}catch(V){if(V instanceof Error)if(v=V,v===$CLJS.VW)try{x=$CLJS.bd(t,0);if($CLJS.fe(x,$CLJS.HG))return new $CLJS.Q(null,1,5,$CLJS.R,[j7.v?j7.v(k,b,c,l):j7.call(null,k,b,c,l)],null);throw $CLJS.VW;}catch(Z){if(Z instanceof Error){var A=Z;if(A===$CLJS.VW)throw $CLJS.VW;throw A;}throw Z;}else throw v;else throw V;}else throw $CLJS.VW;}catch(V){if(V instanceof Error)if(v=V,v===$CLJS.VW)try{if($CLJS.yd(t)&&
3===$CLJS.E(t))try{var D=$CLJS.bd(t,0);if($CLJS.fe(D,$CLJS.gP))try{var J=$CLJS.bd(t,2);if($CLJS.fe(J,$CLJS.HG))return new $CLJS.Q(null,1,5,$CLJS.R,[j7.v?j7.v(k,b,c,l):j7.call(null,k,b,c,l)],null);throw $CLJS.VW;}catch(Z){if(Z instanceof Error){A=Z;if(A===$CLJS.VW)throw $CLJS.VW;throw A;}throw Z;}else throw $CLJS.VW;}catch(Z){if(Z instanceof Error){A=Z;if(A===$CLJS.VW)throw $CLJS.VW;throw A;}throw Z;}else throw $CLJS.VW;}catch(Z){if(Z instanceof Error){A=Z;if(A===$CLJS.VW)return new $CLJS.Q(null,1,
5,$CLJS.R,[k],null);throw A;}throw Z;}else throw v;else throw V;}else throw m;else throw M;}}($CLJS.tf,d)))):k};
i7=function(a,b,c,d,e,f){var k=$CLJS.iY(a,b),l=$CLJS.$e(function(m){var t=$CLJS.sC(k,m);return $CLJS.n(t)?$CLJS.Ul(function(u){return $CLJS.B($CLJS.z($CLJS.bf($CLJS.Ta,function D(x,A){try{if($CLJS.yd(A)&&3===$CLJS.E(A))try{var J=$CLJS.bd(A,0);if($CLJS.F.g(J,d))try{var M=$CLJS.bd(A,1);if($CLJS.sd(e)||$CLJS.h2($CLJS.Yg(e),$CLJS.Yg(M)))try{var V=$CLJS.bd(A,2);if($CLJS.F.g(V,f))return new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[m,u],null)],null);throw $CLJS.VW;}catch(fa){if(fa instanceof
Error){var Z=fa;if(Z===$CLJS.VW)throw $CLJS.VW;throw Z;}throw fa;}else throw $CLJS.VW;}catch(fa){if(fa instanceof Error){Z=fa;if(Z===$CLJS.VW)throw $CLJS.VW;throw Z;}throw fa;}else throw $CLJS.VW;}catch(fa){if(fa instanceof Error){Z=fa;if(Z===$CLJS.VW)throw $CLJS.VW;throw Z;}throw fa;}else throw $CLJS.VW;}catch(fa){if(fa instanceof Error){Z=fa;if(Z===$CLJS.VW)return $CLJS.dX(D,x,A);throw Z;}throw fa;}}($CLJS.tf,u))))},t):null},$CLJS.G([g7(a,b)]));return $CLJS.ab(function(m,t){var u=$CLJS.H(t,0,null);
t=$CLJS.H(t,1,null);return k7(m,b,c,u,t,e7)},a,l)};j7=function(a,b,c,d){b=f7(c,b);if($CLJS.n(b)){var e=$CLJS.iY(c,b);c=$CLJS.Je(function(f){f=$CLJS.O(f);var k=$CLJS.I.g(f,$CLJS.vM),l=$CLJS.I.g(f,$CLJS.Q1);return $CLJS.F.g($CLJS.Z_,k)&&$CLJS.F.g(d,l)?$CLJS.k0.h(f):null},$CLJS.c2.j(c,b,e));return $CLJS.n(c)?i7(a,b,a,$CLJS.YH,$CLJS.N,c):a}return a};
l7=function(a,b,c,d,e){var f=$CLJS.VX.h(c),k=$CLJS.iY(a,b);c=$CLJS.jJ(function(t){var u=$CLJS.sC(k,t);if($CLJS.n(u)){var v=$CLJS.d7(f);return $CLJS.n($CLJS.Je($CLJS.Hk.j($CLJS.Vg([v]),$CLJS.nG,$CLJS.hd),u))?t:null}return null},g7(a,b));var l=(d=$CLJS.F.g($CLJS.xI,d))?$CLJS.VX.h(e):null;e=d?function(t,u,v){return noa(t,u,v,l)}:e7;var m=$CLJS.F.g(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.MH],null),c);a=d&&m&&$CLJS.F.g($CLJS.B(f),$CLJS.B(l))&&$CLJS.F.g($CLJS.id(f),$CLJS.id(l))?ooa(a,b,f,$CLJS.Wl($CLJS.hd(l),
new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.YS,$CLJS.dI],null))):m?poa(a,b,f):a;return $CLJS.n(c)?k7(a,b,a,c,f,e):a};m7=function(a,b){return $CLJS.c4(a,$CLJS.YH)&&$CLJS.F.g($CLJS.s2(a),b)};qoa=function(a,b,c){return function k(e,f){try{if(m7(f,b))return $CLJS.h4(f,c);throw $CLJS.VW;}catch(m){if(m instanceof Error){var l=m;if(l===$CLJS.VW)return $CLJS.AY(k,e,f);throw l;}throw m;}}($CLJS.tf,a)};
roa=function(a,b,c){var d=$CLJS.gP.h(a),e=-1<b&&b<$CLJS.E(d)?new $CLJS.Q(null,2,5,$CLJS.R,[b,$CLJS.sC(d,new $CLJS.Q(null,2,5,$CLJS.R,[b,$CLJS.LG],null))],null):null;b=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);if($CLJS.n($CLJS.n(b)?$CLJS.Fk.g(e,c):b)){var f=$CLJS.lY();$CLJS.b4(f,$CLJS.Ze.g($CLJS.LG,d));c=f(c);return qoa($CLJS.xN(a,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gP,b,$CLJS.LG],null),c),e,c)}return a};
soa=function(a,b,c){if($CLJS.Ed(c))return c;var d=function(){var e=$CLJS.Vg([c]);return"string"===typeof c?$CLJS.Hk.g(e,$CLJS.LG):e}();return $CLJS.Je(function(e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);return $CLJS.n(d.h?d.h(e):d.call(null,e))?f:null},$CLJS.Vl($CLJS.Ct,$CLJS.gP.h($CLJS.iY(a,b))))};
n7=function(a,b,c,d){b=$CLJS.yv.g($CLJS.Yg($CLJS.c2.j(b,c,$CLJS.iY(b,c))),$CLJS.Yg($CLJS.c2.j(a,c,$CLJS.iY(a,c))));return $CLJS.ab(function(e,f){return $CLJS.P.N(i7,e,c,a,d.h?d.h(f):d.call(null,f))},a,b)};toa=function(a,b,c){a=n7(a,b,c,function(d){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.YH,new $CLJS.h(null,1,[$CLJS.XQ,$CLJS.z0.h(d)],null),$CLJS.Ti.h(d)],null)});c=f7(a,c);return $CLJS.n(c)?n7(a,b,c,function(d){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.YH,$CLJS.N,$CLJS.k0.h(d)],null)}):a};
uoa=function(a,b,c){return $CLJS.Ed(c)?$CLJS.sC($CLJS.iY(a,b),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gP,c,$CLJS.LG],null)):$CLJS.wd(c)?$CLJS.LG.h(c):c};o7=function(a,b,c,d){var e=uoa(a,b,c);return $CLJS.n(e)?(c=function(){var f=(0,$CLJS.rY)(a,b,function(k){var l=$CLJS.gP.h(k);l=d.g?d.g(l,e):d.call(null,l,e);return $CLJS.uG(k,$CLJS.gP,l)});return(0,$CLJS.rY)(f,b,function(k){return $CLJS.vY(k,$CLJS.gP,function(l){return $CLJS.Jk.g(function(m){return $CLJS.p4(f,b,m)},l)})})}(),toa(c,a,b)):a};
voa=function(a,b){return null!=$CLJS.B($CLJS.z($CLJS.bf($CLJS.Ta,function f(d,e){try{if(m7(e,b))return new $CLJS.Q(null,1,5,$CLJS.R,[e],null);throw $CLJS.VW;}catch(l){if(l instanceof Error){var k=l;if(k===$CLJS.VW)return $CLJS.dX(f,d,e);throw k;}throw l;}}($CLJS.tf,a))))};woa=new $CLJS.K(null,"remove","remove",-131428414);$CLJS.xoa=function(){function a(d,e,f){return $CLJS.wd(f)&&$CLJS.F.g($CLJS.ov.h(f),$CLJS.PM)?$CLJS.p7.j?$CLJS.p7.j(d,e,f):$CLJS.p7.call(null,d,e,f):l7(d,e,f,woa,null)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.r7=function(){function a(d,e,f,k){return $CLJS.wd(f)&&$CLJS.F.g($CLJS.ov.h(f),$CLJS.PM)?$CLJS.q7.v?$CLJS.q7.v(d,e,f,k):$CLJS.q7.call(null,d,e,f,k):l7(d,e,f,$CLJS.xI,k)}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.yoa=function(){function a(d,e,f,k){f=soa(d,e,f);return $CLJS.n(f)?$CLJS.rY.l(d,e,roa,$CLJS.G([f,k])):d}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.p7=function(){function a(d,e,f){try{return o7(d,e,f,function(v,x){return $CLJS.De($CLJS.c3(function(A){return!($CLJS.F.g($CLJS.LG.h(A),x)||voa(A,x))},v))})}catch(v){var k=v,l=$CLJS.O($CLJS.Zl(k)),m=$CLJS.I.g(l,$CLJS.vA),t=$CLJS.I.g(l,$CLJS.pY),u=$CLJS.I.g(l,$CLJS.nY);if($CLJS.F.g(m,$CLJS.mY))return k=function(){var x=moa($CLJS.Vg([t]),$CLJS.UM.h(d));return c.j?c.j(d,x,u):c.call(null,d,x,u)}(),c.j?c.j(k,e,f):c.call(null,k,e,f);throw k;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,
e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.q7=function(){function a(d,e,f,k){return null==k?$CLJS.p7.j(d,e,f):o7(d,e,f,function(l,m){return $CLJS.Jk.g(function(t){return $CLJS.F.g($CLJS.LG.h(t),m)?k:t},l)})}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();