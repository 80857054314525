var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var ida,jda,kda,lda,mda,nda,oda,EI,pda,qda,rda,sda,tda,uda,vda,wda,GI,xda;$CLJS.AI=new $CLJS.K(null,"supported-field","supported-field",-2061545519);ida=new $CLJS.K("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.BI=new $CLJS.K("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);jda=new $CLJS.K("mbql.clause","count-where","mbql.clause/count-where",2010666417);
kda=new $CLJS.K("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.CI=new $CLJS.K(null,"requires-column?","requires-column?",1623131448);lda=new $CLJS.K("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.DI=new $CLJS.K(null,"display-info","display-info",-816930907);mda=new $CLJS.K("mbql.clause","max","mbql.clause/max",1615385533);nda=new $CLJS.K("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);
oda=new $CLJS.K("mbql.clause","median","mbql.clause/median",-1026386338);EI=new $CLJS.K("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);pda=new $CLJS.K("mbql.clause","min","mbql.clause/min",2074448531);qda=new $CLJS.K("mbql.clause","percentile","mbql.clause/percentile",2064253651);rda=new $CLJS.K("mbql.clause","stddev","mbql.clause/stddev",659381076);sda=new $CLJS.K("mbql.clause","var","mbql.clause/var",809434920);
tda=new $CLJS.K("mbql.clause","share","mbql.clause/share",-37921592);uda=new $CLJS.K("mbql.clause","count","mbql.clause/count",-454225910);vda=new $CLJS.K("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.FI=new $CLJS.K(null,"driver-feature","driver-feature",1905324248);wda=new $CLJS.K("mbql.clause","avg","mbql.clause/avg",1826863173);GI=new $CLJS.K("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);
$CLJS.HI=new $CLJS.K("operator","aggregation","operator/aggregation",-1961510924);xda=new $CLJS.K("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.pH($CLJS.Kz,$CLJS.G([$CLJS.mu,$CLJS.rj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fD,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qs,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.NG],null)],null)],null)],null)]));$CLJS.pH($CLJS.ZH,$CLJS.G([$CLJS.mu,$CLJS.rj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fD,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qs,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.NG],null)],null)],null)],null)]));
$CLJS.nH($CLJS.SH,$CLJS.G([$CLJS.mu,$CLJS.EF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.ZG],null)],null)]));$CLJS.nH($CLJS.CH,$CLJS.G([$CLJS.mu,$CLJS.rj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.NG],null)],null)]));$CLJS.nH($CLJS.gI,$CLJS.G([$CLJS.mu,$CLJS.rj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.MG],null)],null)]));
$CLJS.nH($CLJS.dk,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.UG],null)],null)]));$CLJS.gH($CLJS.dk,$CLJS.TG);$CLJS.nH($CLJS.lI,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.ZG],null)],null)]));$CLJS.gH($CLJS.lI,$CLJS.TG);$CLJS.nH($CLJS.Qn,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.UG],null)],null)]));$CLJS.gH($CLJS.Qn,$CLJS.TG);
$CLJS.Y(EI,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.rt,new $CLJS.h(null,1,[$CLJS.wu,"valid percentile"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.ZG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,1,[$CLJS.wu,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.nH($CLJS.JH,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.ZG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,EI],null)]));$CLJS.gH($CLJS.JH,$CLJS.TG);
$CLJS.nH($CLJS.eI,$CLJS.G([$CLJS.mu,$CLJS.EF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.MG],null)],null)]));$CLJS.nH($CLJS.oI,$CLJS.G([$CLJS.mu,$CLJS.EF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.ZG],null)],null)]));$CLJS.nH($CLJS.kI,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.ZG],null)],null)]));
$CLJS.nH($CLJS.vI,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.ZG],null)],null)]));$CLJS.gH($CLJS.kI,$CLJS.TG);$CLJS.gH($CLJS.vI,$CLJS.TG);$CLJS.nH($CLJS.tI,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.ZG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.MG],null)],null)]));$CLJS.gH($CLJS.tI,$CLJS.TG);
$CLJS.nH($CLJS.DH,$CLJS.G([$CLJS.mu,$CLJS.EF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.ZG],null)],null)]));$CLJS.Y(GI,new $CLJS.Q(null,17,5,$CLJS.R,[$CLJS.lt,wda,uda,nda,jda,ida,mda,oda,pda,qda,tda,rda,xda,vda,kda,sda,$CLJS.ul],null));$CLJS.Y($CLJS.BI,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uj,new $CLJS.h(null,1,[$CLJS.Qn,1],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,GI],null)],null));
$CLJS.II=new $CLJS.Q(null,10,5,$CLJS.R,[new $CLJS.h(null,4,[$CLJS.RH,$CLJS.Kz,$CLJS.CI,!1,$CLJS.FI,$CLJS.sH,$CLJS.DI,function(){return new $CLJS.h(null,3,[$CLJS.cG,$CLJS.zG("Count of rows"),$CLJS.IH,$CLJS.zG("Count"),$CLJS.Tu,$CLJS.zG("Total number of rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.RH,$CLJS.kI,$CLJS.AI,$CLJS.ak,$CLJS.CI,!0,$CLJS.FI,$CLJS.sH,$CLJS.DI,function(){return new $CLJS.h(null,3,[$CLJS.cG,$CLJS.zG("Sum of ..."),$CLJS.IH,$CLJS.zG("Sum"),$CLJS.Tu,$CLJS.zG("Sum of all the values of a column.")],
null)}],null),new $CLJS.h(null,5,[$CLJS.RH,$CLJS.SH,$CLJS.AI,$CLJS.ak,$CLJS.CI,!0,$CLJS.FI,$CLJS.sH,$CLJS.DI,function(){return new $CLJS.h(null,3,[$CLJS.cG,$CLJS.zG("Average of ..."),$CLJS.IH,$CLJS.zG("Average"),$CLJS.Tu,$CLJS.zG("Average of all the values of a column")],null)}],null),new $CLJS.h(null,5,[$CLJS.RH,$CLJS.lI,$CLJS.AI,$CLJS.ak,$CLJS.CI,!0,$CLJS.FI,$CLJS.UH,$CLJS.DI,function(){return new $CLJS.h(null,3,[$CLJS.cG,$CLJS.zG("Median of ..."),$CLJS.IH,$CLJS.zG("Median"),$CLJS.Tu,$CLJS.zG("Median of all the values of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.RH,$CLJS.CH,$CLJS.AI,$CLJS.ei,$CLJS.CI,!0,$CLJS.FI,$CLJS.sH,$CLJS.DI,function(){return new $CLJS.h(null,3,[$CLJS.cG,$CLJS.zG("Number of distinct values of ..."),$CLJS.IH,$CLJS.zG("Distinct values"),$CLJS.Tu,$CLJS.zG("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.RH,$CLJS.vI,$CLJS.AI,$CLJS.ak,$CLJS.CI,!0,$CLJS.FI,$CLJS.sH,$CLJS.DI,function(){return new $CLJS.h(null,3,[$CLJS.cG,$CLJS.zG("Cumulative sum of ..."),
$CLJS.IH,$CLJS.zG("Sum"),$CLJS.Tu,$CLJS.zG("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.h(null,4,[$CLJS.RH,$CLJS.ZH,$CLJS.CI,!1,$CLJS.FI,$CLJS.sH,$CLJS.DI,function(){return new $CLJS.h(null,3,[$CLJS.cG,$CLJS.zG("Cumulative count of rows"),$CLJS.IH,$CLJS.zG("Count"),$CLJS.Tu,$CLJS.zG("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.h(null,5,[$CLJS.RH,$CLJS.oI,$CLJS.AI,$CLJS.ak,$CLJS.CI,
!0,$CLJS.FI,$CLJS.hI,$CLJS.DI,function(){return new $CLJS.h(null,3,[$CLJS.cG,$CLJS.zG("Standard deviation of ..."),$CLJS.IH,$CLJS.zG("SD"),$CLJS.Tu,$CLJS.zG("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.RH,$CLJS.Qn,$CLJS.AI,$CLJS.Si,$CLJS.CI,!0,$CLJS.FI,$CLJS.sH,$CLJS.DI,function(){return new $CLJS.h(null,3,[$CLJS.cG,$CLJS.zG("Minimum of ..."),$CLJS.IH,$CLJS.zG("Min"),$CLJS.Tu,$CLJS.zG("Minimum value of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.RH,$CLJS.dk,$CLJS.AI,$CLJS.Si,$CLJS.CI,!0,$CLJS.FI,$CLJS.sH,$CLJS.DI,function(){return new $CLJS.h(null,3,[$CLJS.cG,$CLJS.zG("Maximum of ..."),$CLJS.IH,$CLJS.zG("Max"),$CLJS.Tu,$CLJS.zG("Maximum value of a column")],null)}],null)],null);
$CLJS.Y(lda,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ov,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,$CLJS.HI],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.RH,$CLJS.Wf.j(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.ht],null),$CLJS.Ze.h($CLJS.RH),$CLJS.II)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.AI,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,$CLJS.Ri],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.CI,$CLJS.zt],null),new $CLJS.Q(null,2,5,
$CLJS.R,[$CLJS.FI,$CLJS.Ri],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.DI,$CLJS.md],null)],null));