var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.literal.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.schema.macros.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var sN,tN,uN,ufa,vfa,yN,wfa,BN,CN,DN,EN,xfa,FN,GN,IN,JN,vN,KN,yfa,LN,zfa,NN,ON,PN,Afa,QN,RN,Bfa,SN,Cfa,TN,UN,Dfa,WN,Efa,Ffa,Gfa,YN,ZN,$N,aO,bO,cO,dO,eO,Hfa,fO,gO,hO,jO,lO,Ifa,mO,nO,oO,pO,Jfa,qO,rO,sO,tO,Kfa,uO,vO,wO,xO,Lfa,yO,Mfa,zO,AO,BO,Nfa,CO,DO,EO,FO,HO,IO,Ofa,JO,KO,Pfa,LO,MO,NO,OO,PO,Qfa,QO,Rfa,RO,SO,UO,Sfa,VO,WO,XO,Tfa,$O,aP,Ufa,Vfa,bP,dP,eP,fP,Wfa,iP,jP,Xfa,Yfa,Zfa,kP,lP,mP,nP,oP,pP,$fa,qP,aga,rP,sP,uP,wP,xP,yP,zP,bga,BP,cga,dga,CP,DP,EP,FP,GP,ega,HP,fga,gga,IP,JP,KP,LP,MP,hga,
iga,jga,NP,kga,OP,lga,QP,RP,mga,TP,UP,VP,WP,nga,ZP,oga,pga,$P,aQ,bQ,qga,cQ,dQ,eQ,fQ,rga,hQ,iQ,jQ,kQ,lQ,sga,mQ,nQ,tga,oQ,pQ,qQ,rQ,sQ,tQ,vQ,uga,wQ,vga,xQ,yQ,zQ,wga,xga,yga,BQ,CQ,DQ,zga,EQ,FQ,GQ,HQ,Aga,Bga,JQ,Cga,Dga,KQ,LQ,MQ,NQ,OQ,Ega,PQ,QQ,RQ,SQ,Fga,TQ,UQ,VQ,WQ,YQ,Gga,ZQ,$Q,Hga,aR,bR,cR,Iga,dR,Jga,fR,Kga,gR,hR,iR,jR,kR,lR,mR,Lga,Mga,Nga,nR,Oga,oR,pR,Pga,Qga,qR,rR,sR,Rga,tR,Sga,uR,Tga,Uga,vR,Vga,Wga,Xga,yR,zR,Yga,AR,BR,DR,Zga,ER,$ga,FR,GR,HR,aha,bha,IR,JR,KR,LR,cha,NR,OR,dha,PR,QR,RR,SR,TR,UR,VR,WR,
eha,YR,ZR,fha,$R,gha,aS,bS,cS,hha,dS,eS,iha,jha,fS,gS,hS,kha,lha,iS,mha,nha,oha,kS,pha,mS,qha,rha,nS,oS,sha,tha,uha,pS,qS,rS,sS,tS,uS,vS,vha,wS,xS,wha,yS,xha,yha,zha,zS,AS,BS,Aha,CS,DS,Bha,ES,Cha,FS,Dha,Eha,HS,IS,JS,KS,LS,Fha,MS,NS,Gha,OS,PS,QS,RS,SS,TS,US,VS,Hha,WS,XS,ZS,$S,aT,bT,cT,dT,eT,fT,gT,hT,Iha,iT,jT,kT,lT,mT,Jha,nT,oT,pT,qT,Kha,sT,Lha,Mha,uT,Nha,vT,Oha,wT,Pha,Qha,xT,Rha,yT,AT,BT,Sha,FT,GT,Tha,Uha,HT,IT,JT,KT,LT,MT,NT,PT,QT,Vha;
$CLJS.qN=function(a,b){return function(){function c(l,m,t){return $CLJS.Dd(function(){var u=a.h?a.h(l):a.call(null,l);return $CLJS.n(u)?(u=a.h?a.h(m):a.call(null,m),$CLJS.n(u)?(u=a.h?a.h(t):a.call(null,t),$CLJS.n(u)?(u=b.h?b.h(l):b.call(null,l),$CLJS.n(u)?(u=b.h?b.h(m):b.call(null,m),$CLJS.n(u)?b.h?b.h(t):b.call(null,t):u):u):u):u):u}())}function d(l,m){return $CLJS.Dd(function(){var t=a.h?a.h(l):a.call(null,l);return $CLJS.n(t)?(t=a.h?a.h(m):a.call(null,m),$CLJS.n(t)?(t=b.h?b.h(l):b.call(null,l),
$CLJS.n(t)?b.h?b.h(m):b.call(null,m):t):t):t}())}function e(l){var m=a.h?a.h(l):a.call(null,l);l=$CLJS.n(m)?b.h?b.h(l):b.call(null,l):m;return $CLJS.Dd(l)}var f=null,k=function(){function l(t,u,v,x){var A=null;if(3<arguments.length){A=0;for(var D=Array(arguments.length-3);A<D.length;)D[A]=arguments[A+3],++A;A=new $CLJS.y(D,0,null)}return m.call(this,t,u,v,A)}function m(t,u,v,x){return $CLJS.Dd(function(){var A=f.j(t,u,v);return $CLJS.n(A)?$CLJS.Ie(function(D){var J=a.h?a.h(D):a.call(null,D);return $CLJS.n(J)?
b.h?b.h(D):b.call(null,D):J},x):A}())}l.A=3;l.B=function(t){var u=$CLJS.B(t);t=$CLJS.C(t);var v=$CLJS.B(t);t=$CLJS.C(t);var x=$CLJS.B(t);t=$CLJS.Kc(t);return m(u,v,x,t)};l.l=m;return l}();f=function(l,m,t,u){switch(arguments.length){case 0:return!0;case 1:return e.call(this,l);case 2:return d.call(this,l,m);case 3:return c.call(this,l,m,t);default:var v=null;if(3<arguments.length){v=0;for(var x=Array(arguments.length-3);v<x.length;)x[v]=arguments[v+3],++v;v=new $CLJS.y(x,0,null)}return k.l(l,m,t,
v)}throw Error("Invalid arity: "+arguments.length);};f.A=3;f.B=k.B;f.o=function(){return!0};f.h=e;f.g=d;f.j=c;f.l=k.l;return f}()};
$CLJS.rN=function(a){var b=$CLJS.po.g(a,null),c=$CLJS.cH(b,$CLJS.Ht,function(d){return $CLJS.on(d,$CLJS.tf)});return function(){function d(k,l,m){return(l=$CLJS.z(c.j?c.j(k,l,m):c.call(null,k,l,m)))?new $CLJS.h(null,3,[$CLJS.hl,b,$CLJS.yj,k,$CLJS.Ot,l],null):null}function e(k){return f.j(k,$CLJS.tf,$CLJS.tf)}var f=null;f=function(k,l,m){switch(arguments.length){case 1:return e.call(this,k);case 3:return d.call(this,k,l,m)}throw Error("Invalid arity: "+arguments.length);};f.h=e;f.j=d;return f}()};
sN=function(a,b){return $CLJS.wd(a)?$CLJS.I.g(a,b):a};tN=function(a,b,c,d){d=$CLJS.n(d)?d:$CLJS.rn($CLJS.po.g($CLJS.hl.h(a),null));if($CLJS.n(b)){var e=sN($CLJS.yu.h(b),c);$CLJS.n(e)?(e=$CLJS.yq(e,d),a=e.g?e.g(a,d):e.call(null,a,d)):a=null;return $CLJS.n(a)?a:sN($CLJS.wu.h(b),c)}return null};uN=function(a,b){return $CLJS.ud(a)||$CLJS.Al(a)?$CLJS.I.g(a,b):$CLJS.vd(a)?$CLJS.I.g($CLJS.uf(a),b):null};
ufa=function(a,b){a=$CLJS.O(a);var c=$CLJS.I.g(a,$CLJS.hl),d=$CLJS.I.g(a,$CLJS.hj);b=$CLJS.O(b);var e=$CLJS.I.j(b,$CLJS.Ot,$CLJS.qca),f=$CLJS.I.j(b,$CLJS.lca,!0),k=$CLJS.I.g(b,$CLJS.UD),l=$CLJS.I.j(b,$CLJS.SD,$CLJS.QD),m=tN(a,$CLJS.Kq.h(c),k,b);if($CLJS.n(m))return m;m=tN(a,$CLJS.cu.h(c),k,b);if($CLJS.n(m))return m;m=tN(a,e.h?e.h(d):e.call(null,d),k,b);if($CLJS.n(m))return m;m=tN(a,function(){var t=$CLJS.Wn.h(c);return e.h?e.h(t):e.call(null,t)}(),k,b);if($CLJS.n(m))return m;m=tN(a,$CLJS.Kq.h(c),
l,b);if($CLJS.n(m))return m;m=tN(a,$CLJS.cu.h(c),l,b);if($CLJS.n(m))return m;d=tN(a,e.h?e.h(d):e.call(null,d),l,b);if($CLJS.n(d))return d;d=tN(a,function(){var t=$CLJS.Wn.h(c);return e.h?e.h(t):e.call(null,t)}(),l,b);if($CLJS.n(d))return d;k=$CLJS.n(f)?tN(a,e.h?e.h($CLJS.OD):e.call(null,$CLJS.OD),k,b):f;return $CLJS.n(k)?k:$CLJS.n(f)?tN(a,e.h?e.h($CLJS.OD):e.call(null,$CLJS.OD),l,b):f};
vfa=function(a,b,c){a=$CLJS.R;var d=$CLJS.Wf.g;var e=$CLJS.el.h(b);var f=$CLJS.O(b);var k=$CLJS.I.g(f,$CLJS.hl),l=$CLJS.O(c);f=$CLJS.I.g(l,$CLJS.UD);l=$CLJS.I.j(l,$CLJS.SD,$CLJS.QD);k=$CLJS.Kq.h(k);f=sN($CLJS.RD.h(k),f);f=$CLJS.n(f)?f:sN($CLJS.RD.h(k),l);d=d.call($CLJS.Wf,e,f);return new $CLJS.Q(null,2,5,a,[d,ufa(b,c)],null)};
$CLJS.wN=function(a){var b=$CLJS.O(a),c=$CLJS.I.g(b,$CLJS.yj);a=$CLJS.I.g(b,$CLJS.Ot);var d=$CLJS.O(null),e=$CLJS.I.j(d,$CLJS.mca,$CLJS.Ki),f=$CLJS.I.j(d,$CLJS.oca,vfa);return $CLJS.n(a)?$CLJS.ab(function(k,l){var m=f.j?f.j(b,l,d):f.call(null,b,l,d),t=$CLJS.H(m,0,null);m=$CLJS.H(m,1,null);l=$CLJS.S.j(l,$CLJS.Ki,m);l=e.h?e.h(l):e.call(null,l);return vN(k,c,t,l)},null,a):null};
yN=function(a,b,c){var d=$CLJS.sC($CLJS.q($CLJS.SF),new $CLJS.Q(null,2,5,$CLJS.R,[a,b],null));if($CLJS.n(d))return d;c=c.o?c.o():c.call(null);$CLJS.Gh.v($CLJS.SF,$CLJS.xN,new $CLJS.Q(null,2,5,$CLJS.R,[a,b],null),c);return c};$CLJS.zN=function(a){return yN($CLJS.pk,a,function(){return $CLJS.dH.h(a)})};wfa=function(){var a=AN;return yN($CLJS.Ht,a,function(){var b=$CLJS.dH.h(a),c=$CLJS.rN(a);return function(d){return $CLJS.n(b.h?b.h(d):b.call(null,d))?null:c.h?c.h(d):c.call(null,d)}})};
BN=function(a){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,$CLJS.ke(a)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,a],null):a],null)};CN=function(a){if($CLJS.yd(a)){var b=$CLJS.H(a,0,null),c=$CLJS.H(a,1,null);switch(b instanceof $CLJS.K?b.T:null){case "optional":return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qs,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,BN(c)],null)],null);case "rest":return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ps,BN(c)],null);default:return BN(a)}}else return BN(a)};
DN=function(a,b){var c=$CLJS.yd(b);return c?(c=$CLJS.B(b)instanceof $CLJS.K)?$CLJS.td(a)?(b=$CLJS.B(b),a=$CLJS.Yg(a),a.h?a.h(b):a.call(null,b)):$CLJS.F.g(a,$CLJS.B(b)):c:c};
EN=function(a,b){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,1,[$CLJS.wu,["not a ",$CLJS.p.h(a)," clause"].join("")],null),$CLJS.Me(DN,a)],null),$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.at,new $CLJS.Q(null,2,5,$CLJS.R,["tag",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,a],null)],null)],null),function(){return function e(d){return new $CLJS.le(null,function(){for(;;){var f=$CLJS.z(d);if(f){if($CLJS.zd(f)){var k=$CLJS.kc(f),l=$CLJS.E(k),m=$CLJS.oe(l);
a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.H(u,0,null);u=$CLJS.H(u,1,null);v=new $CLJS.Q(null,2,5,$CLJS.R,[v,CN(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.re($CLJS.te(m),e($CLJS.lc(f))):$CLJS.re($CLJS.te(m),null)}k=$CLJS.B(f);m=$CLJS.H(k,0,null);k=$CLJS.H(k,1,null);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[m,CN(k)],null),e($CLJS.Kc(f)))}return null}},null,null)}($CLJS.fu(2,2,b))}())],null)};xfa=function(a){return $CLJS.yd(a)&&$CLJS.B(a)instanceof $CLJS.K?$CLJS.B(a):null};
FN=function(a){return $CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qt,new $CLJS.h(null,2,[$CLJS.Ii,xfa,$CLJS.wu,["valid instance of one of these MBQL clauses: ",$CLJS.Hu(", ",$CLJS.Ze.g($CLJS.B,a))].join("")],null)],null),function(){return function d(c){return new $CLJS.le(null,function(){for(;;){var e=$CLJS.z(c);if(e){if($CLJS.zd(e)){var f=$CLJS.kc(e),k=$CLJS.E(f),l=$CLJS.oe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.H(t,0,null);t=$CLJS.H(t,1,null);u=new $CLJS.Q(null,2,5,$CLJS.R,[u,
$CLJS.ke(t)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,t],null):t],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.re($CLJS.te(l),d($CLJS.lc(e))):$CLJS.re($CLJS.te(l),null)}f=$CLJS.B(e);l=$CLJS.H(f,0,null);f=$CLJS.H(f,1,null);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[l,$CLJS.ke(f)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,f],null):f],null),d($CLJS.Kc(e)))}return null}},null,null)}(a)}())};
GN=function(a){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rt,a,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.pN],null)],null)};$CLJS.HN={};IN={};JN={};$CLJS.xN=function xN(a,b,c){var e=$CLJS.z(b);b=$CLJS.B(e);var f=$CLJS.C(e);if(f){e=$CLJS.S.j;var k=$CLJS.I.g(a,b);c=xN.j?xN.j(k,f,c):xN.call(null,k,f,c);a=e.call($CLJS.S,a,b,c)}else a=$CLJS.S.j(a,b,c);return a};
vN=function vN(a,b,c,d){var f=$CLJS.z(c);c=$CLJS.B(f);var k=$CLJS.C(f),l=uN(b,c);f=$CLJS.n(a)?a:$CLJS.vd(b)?$CLJS.tf:$CLJS.xd(b)?$CLJS.N:$CLJS.jd(b);return $CLJS.n($CLJS.n(c)?$CLJS.PD.h($CLJS.pd(f)):c)?a:$CLJS.n(c)?(b=uN(f,c),d=vN.v?vN.v(b,l,k,d):vN.call(null,b,l,k,d),$CLJS.Fl(c)&&$CLJS.vd(f)&&c>$CLJS.E(f)&&(b=$CLJS.Ve(c-$CLJS.E(f),null),b=$CLJS.Ye.g(f,b),f=null==f||$CLJS.Cd(f)?b:$CLJS.Wf.g($CLJS.jd(f),b)),null==f||$CLJS.Al(f)?$CLJS.S.j(f,c,d):$CLJS.ud(f)?$CLJS.$d.g(f,d):$CLJS.P.g($CLJS.W,$CLJS.S.j($CLJS.uf(f),
c,d))):$CLJS.wd(a)?(c=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.nca],null),vN.v?vN.v(f,b,c,d):vN.call(null,f,b,c,d)):$CLJS.n($CLJS.PD.h($CLJS.pd(f)))?$CLJS.$d.g(f,d):$CLJS.yd($CLJS.De(f))?f:$CLJS.od(new $CLJS.Q(null,1,5,$CLJS.R,[d],null),new $CLJS.h(null,1,[$CLJS.PD,!0],null))};KN=new $CLJS.K("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",-662824303);yfa=new $CLJS.w("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);
LN=new $CLJS.K("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",-345484364);$CLJS.MN=new $CLJS.K(null,"dimension","dimension",543254198);zfa=new $CLJS.w("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);NN=new $CLJS.K("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",1412149596);ON=new $CLJS.K("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",-985876836);
PN=new $CLJS.K("metabase.mbql.schema","substring","metabase.mbql.schema/substring",188871083);Afa=new $CLJS.w("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);QN=new $CLJS.K("metabase.mbql.schema","TimeLiteral","metabase.mbql.schema/TimeLiteral",-822346498);RN=new $CLJS.K("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",549586740);Bfa=new $CLJS.K(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);
SN=new $CLJS.K("metabase.mbql.schema","StringExpressionArg","metabase.mbql.schema/StringExpressionArg",596241960);Cfa=new $CLJS.K(null,"from","from",1815293044);TN=new $CLJS.K("metabase.mbql.schema","and","metabase.mbql.schema/and",-86675456);UN=new $CLJS.w(null,"does-not-contain","does-not-contain",1365584674,null);Dfa=new $CLJS.K(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);$CLJS.VN=new $CLJS.K(null,"snippet-name","snippet-name",819240328);
WN=new $CLJS.K("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",560735368);Efa=new $CLJS.w("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);Ffa=new $CLJS.w("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);$CLJS.XN=new $CLJS.K(null,"aggregation-options","aggregation-options",-1904917550);Gfa=new $CLJS.w("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);
YN=new $CLJS.K("location","zip_code","location/zip_code",1641155222);ZN=new $CLJS.w(null,"ExpressionArg","ExpressionArg",1060743736,null);$N=new $CLJS.w(null,"get-hour","get-hour",-2031721710,null);aO=new $CLJS.K("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",-140332301);bO=new $CLJS.w(null,"ag:var","ag:var",-1804440286,null);cO=new $CLJS.K("metabase.mbql.schema","IntGreaterThanZeroOrNumericExpression","metabase.mbql.schema/IntGreaterThanZeroOrNumericExpression",1485789132);
dO=new $CLJS.K("metabase.mbql.schema","exp","metabase.mbql.schema/exp",-1188751039);eO=new $CLJS.K("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",-364748152);Hfa=new $CLJS.K(null,"lon-max","lon-max",1590224717);fO=new $CLJS.K("metabase.mbql.schema","asc","metabase.mbql.schema/asc",-302489464);gO=new $CLJS.w(null,"stddev","stddev",775056588,null);hO=new $CLJS.K("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-1954451840);
$CLJS.iO=new $CLJS.K(null,"snippet","snippet",953581994);jO=new $CLJS.w("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);$CLJS.kO=new $CLJS.K(null,"query","query",-1288509510);lO=new $CLJS.K(null,"string-expression","string-expression",-1395337766);Ifa=new $CLJS.K(null,"lat-field","lat-field",-830652957);mO=new $CLJS.K("metabase.mbql.schema","now","metabase.mbql.schema/now",-975392912);nO=new $CLJS.K("metabase.mbql.schema","avg","metabase.mbql.schema/avg",1123586863);
oO=new $CLJS.K("metabase.mbql.schema","not","metabase.mbql.schema/not",-1753911131);pO=new $CLJS.K("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",-670335423);Jfa=new $CLJS.w("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);qO=new $CLJS.K("location","country","location/country",1666636202);rO=new $CLJS.K("metabase.mbql.schema","trim","metabase.mbql.schema/trim",350521664);
sO=new $CLJS.K("metabase.mbql.schema","Reference","metabase.mbql.schema/Reference",-770092616);tO=new $CLJS.K("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",-1080110117);Kfa=new $CLJS.w("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);uO=new $CLJS.K(null,"unary","unary",-989314568);vO=new $CLJS.K("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",297284410);
wO=new $CLJS.K("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",324961083);xO=new $CLJS.w(null,"ceil","ceil",-184398425,null);Lfa=new $CLJS.K(null,"lon-min","lon-min",-787291357);yO=new $CLJS.w(null,"NonBlankString","NonBlankString",-719244809,null);Mfa=new $CLJS.K(null,"match","match",1220059550);zO=new $CLJS.w(null,"count-where","count-where",2025939247,null);AO=new $CLJS.K("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",1844658706);
BO=new $CLJS.K(null,"max-results-bare-rows","max-results-bare-rows",2096475889);Nfa=new $CLJS.K(null,"embedded-question","embedded-question",-2146473954);CO=new $CLJS.K("metabase.mbql.schema","WidgetType","metabase.mbql.schema/WidgetType",1831406784);DO=new $CLJS.w(null,"sum","sum",1777518341,null);EO=new $CLJS.K("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",197727284);FO=new $CLJS.w("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);
$CLJS.GO=new $CLJS.K("date","range","date/range",1647265776);HO=new $CLJS.w(null,"between","between",-1523336493,null);IO=new $CLJS.K(null,"clause-form","clause-form",1820463737);Ofa=new $CLJS.w("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);JO=new $CLJS.K("metabase.mbql.schema","Query","metabase.mbql.schema/Query",1321874752);KO=new $CLJS.w(null,"field","field",338095027,null);Pfa=new $CLJS.K(null,"segment-id","segment-id",1810133590);
LO=new $CLJS.K("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",94927598);MO=new $CLJS.w(null,"not-null","not-null",313812992,null);NO=new $CLJS.K("metabase.mbql.schema","Joins","metabase.mbql.schema/Joins",1376278278);OO=new $CLJS.K("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",-1587636578);PO=new $CLJS.K("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",556298105);Qfa=new $CLJS.K(null,"template-tag","template-tag",310841038);
QO=new $CLJS.K(null,"invalid","invalid",412869516);Rfa=new $CLJS.w("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);RO=new $CLJS.K("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",1473793750);SO=new $CLJS.w(null,"regex-match-first","regex-match-first",983231809,null);$CLJS.TO=new $CLJS.K(null,"context","context",-830191113);UO=new $CLJS.w(null,"get-year","get-year",704520253,null);
Sfa=new $CLJS.K(null,"format-rows?","format-rows?",992129486);VO=new $CLJS.K("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",336705322);WO=new $CLJS.K("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",829238608);XO=new $CLJS.K("metabase.mbql.schema","variable","metabase.mbql.schema/variable",672451083);$CLJS.YO=new $CLJS.K(null,"parameters","parameters",-1229919748);Tfa=new $CLJS.K(null,"json-download","json-download",-971130133);
$CLJS.ZO=new $CLJS.K(null,"card","card",-1430355152);$O=new $CLJS.K(null,"string-or-field","string-or-field",-1970678542);aP=new $CLJS.w(null,"!\x3d","!\x3d",-201205829,null);Ufa=new $CLJS.w("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);Vfa=new $CLJS.w("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);bP=new $CLJS.w(null,"expression","expression",1842843403,null);$CLJS.cP=new $CLJS.K("date","month-year","date/month-year",1948031290);
dP=new $CLJS.K("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-2109835363);eP=new $CLJS.w(null,"ends-with","ends-with",1183740516,null);fP=new $CLJS.K("metabase.mbql.schema","time","metabase.mbql.schema/time",727162193);$CLJS.gP=new $CLJS.K(null,"joins","joins",1033962699);Wfa=new $CLJS.K(null,"slug","slug",2029314850);$CLJS.hP=new $CLJS.K(null,"source-field","source-field",933829534);iP=new $CLJS.w(null,"Field","Field",430385967,null);
jP=new $CLJS.w(null,"convert-timezone","convert-timezone",1515728526,null);Xfa=new $CLJS.w("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);Yfa=new $CLJS.K(null,"disable-max-results?","disable-max-results?",857693204);Zfa=new $CLJS.K(null,"items","items",1031954938);kP=new $CLJS.K(null,"datetime-expression","datetime-expression",391782195);lP=new $CLJS.K("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",1605865100);
mP=new $CLJS.K(null,"more","more",-2058821800);nP=new $CLJS.K(null,"first-clause","first-clause",-20953491);oP=new $CLJS.K("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",-671453147);pP=new $CLJS.w(null,"OrderComparable","OrderComparable",772072595,null);$fa=new $CLJS.w("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);qP=new $CLJS.w(null,"contains","contains",-1977535957,null);
aga=new $CLJS.w("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);rP=new $CLJS.w("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);sP=new $CLJS.K("number","\x3d","number/\x3d",-2094581309);$CLJS.tP=new $CLJS.K(null,"widget-type","widget-type",1836256899);uP=new $CLJS.K("metabase.mbql.schema","max","metabase.mbql.schema/max",1256568155);$CLJS.vP=new $CLJS.K(null,"fingerprint","fingerprint",598613022);
wP=new $CLJS.w(null,"is-null","is-null",-356519403,null);xP=new $CLJS.K(null,"other-clauses","other-clauses",1570511021);yP=new $CLJS.K("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",-1003477115);zP=new $CLJS.K("metabase.mbql.schema","upper","metabase.mbql.schema/upper",-646839893);bga=new $CLJS.K(null,"map-tiles","map-tiles",1961865797);$CLJS.AP=new $CLJS.K(null,"required","required",1807647006);BP=new $CLJS.K(null,"unnamed-aggregation","unnamed-aggregation",-93854280);
cga=new $CLJS.K(null,"datetime-x","datetime-x",1519265947);dga=new $CLJS.K(null,"datetime-y","datetime-y",-1666955771);CP=new $CLJS.w(null,"CaseClauses","CaseClauses",-1749071354,null);DP=new $CLJS.K(null,"date-arithmetics","date-arithmetics",-1832808309);EP=new $CLJS.K("string","contains","string/contains",1602423827);FP=new $CLJS.K("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",1430203983);GP=new $CLJS.K("metabase.mbql.schema","or","metabase.mbql.schema/or",1154080514);
ega=new $CLJS.w("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);HP=new $CLJS.K("string","!\x3d","string/!\x3d",-1083772573);fga=new $CLJS.w(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);gga=new $CLJS.w("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);IP=new $CLJS.K("metabase.mbql.schema","min","metabase.mbql.schema/min",1119925081);
JP=new $CLJS.w("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);KP=new $CLJS.K("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",-11600894);LP=new $CLJS.K("metabase.mbql.schema","Join","metabase.mbql.schema/Join",-2100258965);MP=new $CLJS.w(null,"share","share",1051097594,null);hga=new $CLJS.w("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);
iga=new $CLJS.w("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);jga=new $CLJS.w("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);NP=new $CLJS.K("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",1830595254);kga=new $CLJS.K(null,"xlsx-download","xlsx-download",-1622892009);OP=new $CLJS.K("metabase.mbql.schema","contains","metabase.mbql.schema/contains",-241280695);
lga=new $CLJS.w("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);$CLJS.PP=new $CLJS.K(null,"collection","collection",-683361892);QP=new $CLJS.K("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",45949435);RP=new $CLJS.K("metabase.mbql.schema","median","metabase.mbql.schema/median",414562044);$CLJS.SP=new $CLJS.K("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);
mga=new $CLJS.w("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);TP=new $CLJS.K("number","\x3c\x3d","number/\x3c\x3d",-1499316353);UP=new $CLJS.w(null,"metric","metric",2049329604,null);VP=new $CLJS.w(null,"concat","concat",-467652465,null);WP=new $CLJS.K(null,"variable","variable",-281346492);$CLJS.XP=new $CLJS.K("date","quarter-year","date/quarter-year",-1453950150);nga=new $CLJS.w(null,"TimeUnit","TimeUnit",1200517789,null);
$CLJS.YP=new $CLJS.K("date","relative","date/relative",25987732);ZP=new $CLJS.K("location","city","location/city",-1746973325);oga=new $CLJS.K(null,"public-dashboard","public-dashboard",645968405);pga=new $CLJS.w("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);$P=new $CLJS.K("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",1054422818);aQ=new $CLJS.K("number","\x3e\x3d","number/\x3e\x3d",-1670691032);bQ=new $CLJS.K("number","between","number/between",97700581);
qga=new $CLJS.w("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);cQ=new $CLJS.K("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",-576867936);dQ=new $CLJS.w(null,"sqrt","sqrt",370479598,null);eQ=new $CLJS.K("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",515738880);fQ=new $CLJS.K("metabase.mbql.schema","expression","metabase.mbql.schema/expression",-1270197207);$CLJS.gQ=new $CLJS.K(null,"semantic_type","semantic_type",272485089);
rga=new $CLJS.K(null,"metric-id","metric-id",-686486942);hQ=new $CLJS.w(null,"*","*",345799209,null);iQ=new $CLJS.w(null,"+","+",-740910886,null);jQ=new $CLJS.w(null,"-","-",-471816912,null);kQ=new $CLJS.w(null,"template-tag","template-tag",1951372565,null);lQ=new $CLJS.K(null,"allowed-for","allowed-for",122724334);sga=new $CLJS.K(null,"question","question",-1411720117);mQ=new $CLJS.w(null,"asc","asc",1997386096,null);
nQ=new $CLJS.K("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",-391950078);tga=new $CLJS.K(null,"pulse-id","pulse-id",1331432237);oQ=new $CLJS.K("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",277590849);pQ=new $CLJS.w(null,"\x3c\x3d","\x3c\x3d",1244895369,null);qQ=new $CLJS.w(null,"\x3c","\x3c",993667236,null);rQ=new $CLJS.w(null,"\x3e","\x3e",1085014381,null);sQ=new $CLJS.w(null,"\x3d","\x3d",-1501502141,null);
tQ=new $CLJS.K("metabase.mbql.schema","OrderComparable","metabase.mbql.schema/OrderComparable",1970325905);$CLJS.uQ=new $CLJS.K(null,"source-metadata","source-metadata",-477816085);vQ=new $CLJS.K("string","ends-with","string/ends-with",302681156);uga=new $CLJS.w("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);wQ=new $CLJS.w(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);vga=new $CLJS.w(null,"MetricID","MetricID",-2128635641,null);
xQ=new $CLJS.w(null,"and","and",668631710,null);yQ=new $CLJS.w(null,"\x3e\x3d","\x3e\x3d",1016916022,null);zQ=new $CLJS.w(null,"round","round",-645002441,null);wga=new $CLJS.K(null,"to","to",192099007);$CLJS.AQ=new $CLJS.K("date","single","date/single",1554682003);xga=new $CLJS.K(null,"action-id","action-id",-1727958578);yga=new $CLJS.w("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);BQ=new $CLJS.w(null,"exp","exp",1378825265,null);
CQ=new $CLJS.w(null,"Filter","Filter",-424893332,null);DQ=new $CLJS.w(null,"cum-count","cum-count",-323900016,null);zga=new $CLJS.K(null,"dashboard-id","dashboard-id",1965414288);EQ=new $CLJS.K("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-1731861963);FQ=new $CLJS.K("metabase.mbql.schema","abs","metabase.mbql.schema/abs",152348622);GQ=new $CLJS.K("metabase.mbql.schema","desc","metabase.mbql.schema/desc",604322345);
HQ=new $CLJS.K(null,"numeric-expression","numeric-expression",-1661337235);$CLJS.IQ=new $CLJS.K(null,"source-table","source-table",-225307692);Aga=new $CLJS.K(null,"embedded-dashboard","embedded-dashboard",-485078014);Bga=new $CLJS.w("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);JQ=new $CLJS.w(null,"floor","floor",-772394748,null);Cga=new $CLJS.K(null,"middleware","middleware",1462115504);
Dga=new $CLJS.w("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);KQ=new $CLJS.K(null,"requires-features","requires-features",-101116256);LQ=new $CLJS.K("metabase.mbql.schema","power","metabase.mbql.schema/power",2136530448);MQ=new $CLJS.K(null,"clause-name","clause-name",-996419059);NQ=new $CLJS.K("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-1905561955);OQ=new $CLJS.w(null,"now","now",-9994004,null);
Ega=new $CLJS.w("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);PQ=new $CLJS.w(null,"not","not",1044554643,null);QQ=new $CLJS.w(null,"avg","avg",1837937727,null);RQ=new $CLJS.w(null,"sum-where","sum-where",-519087341,null);SQ=new $CLJS.K(null,"max-results","max-results",-32858165);Fga=new $CLJS.w("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);TQ=new $CLJS.w(null,"case","case",-1510733573,null);
UQ=new $CLJS.w(null,"distinct","distinct",-148347594,null);VQ=new $CLJS.w(null,"get-second","get-second",-425414791,null);WQ=new $CLJS.K("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",537143190);$CLJS.XQ=new $CLJS.K(null,"join-alias","join-alias",1454206794);YQ=new $CLJS.w(null,"is-empty","is-empty",600228619,null);Gga=new $CLJS.w("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);
ZQ=new $CLJS.w(null,"relative-datetime","relative-datetime",-1369266491,null);$Q=new $CLJS.K("number","!\x3d","number/!\x3d",-673025509);Hga=new $CLJS.K(null,"original","original",-445386197);aR=new $CLJS.K("metabase.mbql.schema","interval","metabase.mbql.schema/interval",-1491935720);bR=new $CLJS.w(null,"abs","abs",1394505050,null);cR=new $CLJS.w(null,"datetime-diff","datetime-diff",-1521323614,null);Iga=new $CLJS.w("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);
dR=new $CLJS.K("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",-1044004696);Jga=new $CLJS.w(null,"DatetimeDiffUnit","DatetimeDiffUnit",-1310225671,null);$CLJS.eR=new $CLJS.K(null,"date","date",-1463434462);fR=new $CLJS.K(null,"second-clause","second-clause",-461435645);Kga=new $CLJS.w("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);gR=new $CLJS.w(null,"rtrim","rtrim",979195078,null);
hR=new $CLJS.K("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",1829772896);iR=new $CLJS.K("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",-1089141753);jR=new $CLJS.w("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);kR=new $CLJS.K("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",1210108656);lR=new $CLJS.w(null,"CaseOptions","CaseOptions",1989286806,null);mR=new $CLJS.w(null,"or","or",1876275696,null);
Lga=new $CLJS.w("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);Mga=new $CLJS.K(null,"constraints","constraints",422775616);Nga=new $CLJS.w("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);nR=new $CLJS.K("metabase.mbql.schema","Field","metabase.mbql.schema/Field",-551724627);Oga=new $CLJS.K(null,"csv-download","csv-download",2141432084);oR=new $CLJS.K("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",367107562);
pR=new $CLJS.w(null,"datetime-add","datetime-add",1850134938,null);Pga=new $CLJS.w("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);Qga=new $CLJS.w("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);qR=new $CLJS.w("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);rR=new $CLJS.w("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);
sR=new $CLJS.w("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);Rga=new $CLJS.w("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);tR=new $CLJS.w(null,"get-quarter","get-quarter",-1326657176,null);Sga=new $CLJS.w("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);uR=new $CLJS.w("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);Tga=new $CLJS.w("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);
Uga=new $CLJS.w("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);vR=new $CLJS.w(null,"temporal-extract","temporal-extract",-872749364,null);Vga=new $CLJS.w("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);Wga=new $CLJS.w("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);Xga=new $CLJS.K(null,"display_name","display_name",-1494335013);$CLJS.wR=new $CLJS.K(null,"snippet-id","snippet-id",1987785841);
$CLJS.xR=new $CLJS.K(null,"database","database",1849087575);yR=new $CLJS.w(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);zR=new $CLJS.w(null,"get-day-of-week","get-day-of-week",-644295017,null);Yga=new $CLJS.w("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);AR=new $CLJS.K("metabase.mbql.schema","floor","metabase.mbql.schema/floor",-1217604600);
BR=new $CLJS.K("metabase.mbql.schema","NumericExpressionArgOrInterval","metabase.mbql.schema/NumericExpressionArgOrInterval",-596692841);$CLJS.CR=new $CLJS.K(null,"expressions","expressions",255689909);DR=new $CLJS.K("metabase.mbql.schema","ExpressionArg","metabase.mbql.schema/ExpressionArg",372701340);Zga=new $CLJS.K(null,"ad-hoc","ad-hoc",-2033634036);ER=new $CLJS.K("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",912159158);
$ga=new $CLJS.w("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);FR=new $CLJS.K("metabase.mbql.schema","aggregation-options","metabase.mbql.schema/aggregation-options",-936374135);GR=new $CLJS.K("metabase.mbql.schema","log","metabase.mbql.schema/log",1754975861);HR=new $CLJS.w(null,"absolute-datetime","absolute-datetime",1080191062,null);aha=new $CLJS.w(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);bha=new $CLJS.K(null,"action","action",-811238024);
IR=new $CLJS.w(null,"get-day","get-day",1768100384,null);JR=new $CLJS.K("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",323823775);KR=new $CLJS.K("metabase.mbql.schema","between","metabase.mbql.schema/between",-1943504157);LR=new $CLJS.w(null,"not-empty","not-empty",2029453590,null);$CLJS.MR=new $CLJS.K(null,"native","native",-613060878);cha=new $CLJS.w("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);NR=new $CLJS.K(null,"page","page",849072397);
OR=new $CLJS.w(null,"length","length",-2065447907,null);dha=new $CLJS.K(null,"dashboard","dashboard",-631747508);PR=new $CLJS.w(null,"get-week","get-week",752472178,null);QR=new $CLJS.w(null,"get-month","get-month",1271156796,null);RR=new $CLJS.w(null,"dimension","dimension",-2111181571,null);SR=new $CLJS.K(null,"boolean-expression","boolean-expression",-394924008);TR=new $CLJS.K("metabase.mbql.schema","length","metabase.mbql.schema/length",1810214269);
UR=new $CLJS.w(null,"StringFilterOptions","StringFilterOptions",-847398229,null);VR=new $CLJS.K("metabase.mbql.schema","segment","metabase.mbql.schema/segment",2142314297);WR=new $CLJS.w("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);eha=new $CLJS.w("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);YR=new $CLJS.K("metabase.mbql.schema","case","metabase.mbql.schema/case",459951449);ZR=new $CLJS.w(null,"substring","substring",-1513569493,null);
fha=new $CLJS.w("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);$R=new $CLJS.K(null,"internal","internal",-854870097);gha=new $CLJS.w("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);aS=new $CLJS.K("metabase.mbql.schema","DateTimeExpressionArg","metabase.mbql.schema/DateTimeExpressionArg",1033762257);bS=new $CLJS.K("metabase.mbql.schema","TemplateTag","metabase.mbql.schema/TemplateTag",-1837740311);
cS=new $CLJS.K("metabase.mbql.schema","EqualityComparable","metabase.mbql.schema/EqualityComparable",-72675242);hha=new $CLJS.w("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);dS=new $CLJS.K(null,"more-values-or-fields","more-values-or-fields",-886177554);eS=new $CLJS.w(null,"ltrim","ltrim",-1000166486,null);iha=new $CLJS.w("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);
jha=new $CLJS.w("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);fS=new $CLJS.K("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",1214423543);gS=new $CLJS.K("metabase.mbql.schema","template-tag","metabase.mbql.schema/template-tag",1296341515);hS=new $CLJS.w("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);
kha=new $CLJS.w("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);lha=new $CLJS.w("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);iS=new $CLJS.w(null,"desc","desc",-560950005,null);$CLJS.jS=new $CLJS.K(null,"template-tags","template-tags",1853115685);mha=new $CLJS.K(null,"public-question","public-question",629369976);nha=new $CLJS.w("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);
oha=new $CLJS.K(null,"binary","binary",-1802232288);kS=new $CLJS.w(null,"time-interval","time-interval",-1949813754,null);pha=new $CLJS.K(null,"userland-query?","userland-query?",-123699383);$CLJS.lS=new $CLJS.K(null,"source-query","source-query",198004422);mS=new $CLJS.K("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-1932527842);qha=new $CLJS.w(null,"SegmentID","SegmentID",-1773652704,null);
rha=new $CLJS.w("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);nS=new $CLJS.K("metabase.mbql.schema","UnnamedAggregation","metabase.mbql.schema/UnnamedAggregation",-196705529);oS=new $CLJS.K("metabase.mbql.schema","share","metabase.mbql.schema/share",337132634);sha=new $CLJS.K(null,"executed-by","executed-by",-739811161);tha=new $CLJS.w("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",977707224,null);
uha=new $CLJS.w("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);pS=new $CLJS.K(null,"amount","amount",364489504);qS=new $CLJS.w(null,"percentile","percentile",1039342775,null);rS=new $CLJS.K("metabase.mbql.schema","metric","metabase.mbql.schema/metric",-610527242);sS=new $CLJS.w(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);tS=new $CLJS.K("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",1249807143);
uS=new $CLJS.w(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);vS=new $CLJS.w(null,"trim","trim",-1880116002,null);vha=new $CLJS.w(null,"RawTimeLiteral","RawTimeLiteral",-2137224789,null);wS=new $CLJS.w(null,"coalesce","coalesce",-1999813740,null);xS=new $CLJS.K("metabase.mbql.schema","sum","metabase.mbql.schema/sum",1064545669);wha=new $CLJS.K("metabase.mbql.schema","DateTimeValue","metabase.mbql.schema/DateTimeValue",1983295553);
yS=new $CLJS.K("string","\x3d","string/\x3d",983744235);xha=new $CLJS.K(null,"skip-results-metadata?","skip-results-metadata?",251010463);yha=new $CLJS.w("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);zha=new $CLJS.w("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);zS=new $CLJS.w(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);AS=new $CLJS.K(null,"value-or-field","value-or-field",-1387286309);
BS=new $CLJS.w(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);Aha=new $CLJS.K(null,"lat-min","lat-min",1630784161);CS=new $CLJS.w(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);DS=new $CLJS.w(null,"inside","inside",-681932758,null);Bha=new $CLJS.w("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);ES=new $CLJS.K("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",1794540739);
Cha=new $CLJS.w("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);FS=new $CLJS.w(null,"starts-with","starts-with",2007034536,null);Dha=new $CLJS.K(null,"process-viz-settings?","process-viz-settings?",-173592315);Eha=new $CLJS.w("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);$CLJS.GS=new $CLJS.K(null,"card-id","card-id",-1770060179);HS=new $CLJS.K(null,"variadic","variadic",882626057);
IS=new $CLJS.w(null,"upper","upper",1886775433,null);JS=new $CLJS.K(null,"field-or-expression","field-or-expression",-1409494368);KS=new $CLJS.K("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",746170880);LS=new $CLJS.w(null,"optional","optional",-600484260,null);Fha=new $CLJS.w("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);MS=new $CLJS.K("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-2115604229);
NS=new $CLJS.K(null,"sugar","sugar",-73788488);Gha=new $CLJS.K(null,"lat-max","lat-max",841568226);OS=new $CLJS.w(null,"power","power",702679448,null);PS=new $CLJS.w("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);QS=new $CLJS.K("metabase.mbql.schema","replace","metabase.mbql.schema/replace",-371168701);RS=new $CLJS.K("metabase.mbql.schema","NumericExpressionArg","metabase.mbql.schema/NumericExpressionArg",-1965874261);
SS=new $CLJS.K("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",1872461942);TS=new $CLJS.w(null,"median","median",-2084869638,null);US=new $CLJS.K("metabase.mbql.schema","ParameterType","metabase.mbql.schema/ParameterType",1268586893);VS=new $CLJS.w(null,"cum-sum","cum-sum",-589533420,null);Hha=new $CLJS.w("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);WS=new $CLJS.K(null,"y","y",-1757859776);XS=new $CLJS.w(null,"aggregation","aggregation",-1056959073,null);
$CLJS.YS=new $CLJS.K(null,"binning","binning",1709835866);ZS=new $CLJS.w(null,"TimezoneId","TimezoneId",-1406242397,null);$S=new $CLJS.K("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",1034573295);aT=new $CLJS.K("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",1597044182);bT=new $CLJS.K("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",209553857);cT=new $CLJS.w(null,"Reference","Reference",2024574086,null);dT=new $CLJS.K(null,"b","b",1482224470);
eT=new $CLJS.K(null,"a","a",-2123407586);fT=new $CLJS.K("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",1846805017);gT=new $CLJS.w(null,"replace","replace",853943757,null);hT=new $CLJS.K("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",576093708);Iha=new $CLJS.w("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);iT=new $CLJS.K("metabase.mbql.schema","/","metabase.mbql.schema//",363603269);
jT=new $CLJS.K("metabase.mbql.schema","-","metabase.mbql.schema/-",1213831346);kT=new $CLJS.K("metabase.mbql.schema","*","metabase.mbql.schema/*",202015001);lT=new $CLJS.K("metabase.mbql.schema","+","metabase.mbql.schema/+",852320058);mT=new $CLJS.w(null,"segment","segment",675610331,null);Jha=new $CLJS.w("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);nT=new $CLJS.K("metabase.mbql.schema","field","metabase.mbql.schema/field",-73678671);
oT=new $CLJS.w("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);pT=new $CLJS.K("metabase.mbql.schema","count","metabase.mbql.schema/count",-962464844);qT=new $CLJS.K("string","starts-with","string/starts-with",1266861170);Kha=new $CLJS.w(null,"TemporalExtractUnit","TemporalExtractUnit",1691102108,null);$CLJS.rT=new $CLJS.K(null,"order-by","order-by",1527318070);sT=new $CLJS.K(null,"advanced-math-expressions","advanced-math-expressions",1076581757);
Lha=new $CLJS.w("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);Mha=new $CLJS.K("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);$CLJS.tT=new $CLJS.K(null,"condition","condition",1668437652);uT=new $CLJS.K("string","does-not-contain","string/does-not-contain",-1536178964);Nha=new $CLJS.K(null,"report-timezone","report-timezone",-2053796389);vT=new $CLJS.w("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);
Oha=new $CLJS.K(null,"card-name","card-name",-2035606807);wT=new $CLJS.K("metabase.mbql.schema","value","metabase.mbql.schema/value",772933314);Pha=new $CLJS.w("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);Qha=new $CLJS.w("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);xT=new $CLJS.w(null,"log","log",45015523,null);Rha=new $CLJS.w("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);
yT=new $CLJS.w(null,"time","time",-1268547887,null);$CLJS.zT=new $CLJS.K(null,"database_type","database_type",-54700895);AT=new $CLJS.w(null,"EqualityComparable","EqualityComparable",-2076258858,null);BT=new $CLJS.w(null,"get-minute","get-minute",-1186349426,null);$CLJS.CT=new $CLJS.K(null,"clause","clause",1479668060);$CLJS.DT=new $CLJS.K(null,"expression-name","expression-name",-1798450709);$CLJS.ET=new $CLJS.K("date","all-options","date/all-options",549325958);
Sha=new $CLJS.w("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);FT=new $CLJS.K("location","state","location/state",-114378652);GT=new $CLJS.w(null,"datetime-subtract","datetime-subtract",1915839224,null);Tha=new $CLJS.K(null,"aggregation-clause-index","aggregation-clause-index",-1510292261);Uha=new $CLJS.K(null,"lon-field","lon-field",517872067);HT=new $CLJS.K("metabase.mbql.schema","TemporalLiteral","metabase.mbql.schema/TemporalLiteral",-904081026);
IT=new $CLJS.w(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);JT=new $CLJS.K(null,"numeric","numeric",-1495594714);KT=new $CLJS.w(null,"variable","variable",1359185035,null);LT=new $CLJS.w(null,"lower","lower",-1534114948,null);MT=new $CLJS.K("metabase.mbql.schema","concat","metabase.mbql.schema/concat",2073716991);NT=new $CLJS.K("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",-204212346);$CLJS.OT=new $CLJS.K(null,"limit","limit",-1355822363);
PT=new $CLJS.K("metabase.mbql.schema","round","metabase.mbql.schema/round",-1325713739);QT=new $CLJS.w(null,"ExtractWeekMode","ExtractWeekMode",397218079,null);Vha=new $CLJS.K(null,"pulse","pulse",-244494476);var RT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.hG],null),ST=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.qG],null),TT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.eG],null),UT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.$F],null),VT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.lG],null),WT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.pG],null),Wha=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.DM],null),Xha=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.yM],null),XT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,
$CLJS.jK],null),YT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.nK],null),ZT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.eK],null),$T=new $CLJS.Tg(null,new $CLJS.h(null,12,[$CLJS.ii,null,$CLJS.mk,null,$CLJS.jj,null,$CLJS.Wh,null,$CLJS.Ai,null,$CLJS.sj,null,$CLJS.fk,null,$CLJS.Aj,null,$CLJS.ci,null,$CLJS.zj,null,$CLJS.ji,null,$CLJS.ui,null],null),null),aU=new $CLJS.Tg(null,new $CLJS.h(null,7,[$CLJS.pv,null,$CLJS.LI,null,$CLJS.Wh,null,$CLJS.pi,null,$CLJS.OI,null,$CLJS.dj,null,$CLJS.Ni,null],null),null),
bU=$CLJS.qv.g($T,aU),Yha=$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"date bucketing unit"],null)],null),$T),Zha=$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"time bucketing unit"],null)],null),aU),cU=$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"datetime bucketing unit"],null)],null),bU),dU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.TK],null),$ha=new $CLJS.Q(null,13,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,
1,[$CLJS.wu,"temporal extract unit"],null),$CLJS.PI,$CLJS.ui,$CLJS.fk,$CLJS.EK,$CLJS.ZK,$CLJS.$K,$CLJS.Aj,$CLJS.Ai,$CLJS.pi,$CLJS.dj,$CLJS.MI],null),aia=new $CLJS.Q(null,10,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"datetime-diff unit"],null),$CLJS.LI,$CLJS.Ni,$CLJS.pv,$CLJS.mk,$CLJS.jj,$CLJS.sj,$CLJS.ii,$CLJS.ci],null),eU=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"temporal-extract week extraction mode"],null),$CLJS.yK,$CLJS.HK,$CLJS.MK],null),fU=new $CLJS.Q(null,
10,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"relative-datetime unit"],null),$CLJS.Wh,$CLJS.Ni,$CLJS.pv,$CLJS.mk,$CLJS.jj,$CLJS.sj,$CLJS.ii,$CLJS.ci],null),mU,cia,uU,vU,wU,xU,yU,zU,AU,lW,eia,mW,fia,gia,nW,hia,iia,jia;$CLJS.Y(wO,EN($CLJS.IK,$CLJS.G(["n",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,$CLJS.vx],null),$CLJS.ri],null),"unit",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ft,fU],null)])));var gU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,wO],null);
$CLJS.Y(aR,EN($CLJS.GK,$CLJS.G(["n",$CLJS.ri,"unit",fU])));var bia=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,aR],null);
$CLJS.Y(RO,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.qt,new $CLJS.h(null,2,[$CLJS.wu,"valid :absolute-datetime clause",$CLJS.Ii,function(a){if($CLJS.Sa(DN($CLJS.CK,a)))a=QO;else{a=$CLJS.hd(a);var b=$CLJS.zN(XT);a=b.h?b.h(a):b.call(null,a);a=$CLJS.n(a)?$CLJS.eR:$CLJS.JK}return a}],null),new $CLJS.Q(null,2,5,$CLJS.R,[QO,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,1,[$CLJS.wu,"not an :absolute-datetime clause"],null),$CLJS.Le(!1)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.eR,EN($CLJS.CK,
$CLJS.G(["date",XT,"unit",Yha]))],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.JK,EN($CLJS.CK,$CLJS.G(["datetime",YT,"unit",cU]))],null)],null));var hU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,RO],null);$CLJS.Y(fP,EN($CLJS.yA,$CLJS.G(["time",ZT,"unit",Zha])));var iU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,fP],null),jU=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.wu,"date or datetime literal"],null),hU,YT,XT],null);
$CLJS.Y(QN,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.wu,"time literal"],null),iU,ZT],null));$CLJS.Y(HT,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.wu,"temporal literal"],null),jU,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,QN],null)],null));var kU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,HT],null);
$CLJS.Y(wha,FN($CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof hU?new $CLJS.Fc(function(){return hU},$CLJS.od(Xfa,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$R,$CLJS.ij,$CLJS.qk,$CLJS.ni,$CLJS.U,$CLJS.hk],[$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),HR,"metabase/mbql/schema.cljc",69,$CLJS.CK,1,!0,168,168,$CLJS.Jc,"Schema for an `:absolute-datetime` clause.",$CLJS.n(hU)?hU.H:null])):null));return $CLJS.n(a)?a:HR}(),hU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof gU?new $CLJS.Fc(function(){return gU},$CLJS.od(Sha,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,
$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZQ,"metabase/mbql/schema.cljc",29,$CLJS.IK,1,131,131,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.IK,$CLJS.My,$CLJS.W($CLJS.Kj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,$CLJS.vx],null),$CLJS.ri],null)),$CLJS.li,$CLJS.W($CLJS.Kj,$CLJS.W(LS,wQ))],null),$CLJS.Jc,"Schema for a valid relative-datetime clause.",
$CLJS.n(gU)?gU.H:null])):null));return $CLJS.n(a)?a:ZQ}(),gU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof iU?new $CLJS.Fc(function(){return iU},$CLJS.od(Uga,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$R,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),yT,"metabase/mbql/schema.cljc",27,$CLJS.yA,1,!0,175,175,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.yA,$CLJS.yA,$CLJS.W($CLJS.Kj,vha),$CLJS.li,$CLJS.W($CLJS.Kj,nga)],null),$CLJS.Jc,"Schema for a valid time clause.",$CLJS.n(iU)?iU.H:null])):null));return $CLJS.n(a)?a:yT}(),iU],null)])));
$CLJS.Y(wT,EN($CLJS.yj,$CLJS.G(["value",$CLJS.ei,"type-info",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zT,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,RT],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.WE,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,ST],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gQ,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.kt,TT],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.li,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,cU],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,RT],null)],null)],null)],null)])));var lU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,wT],null);$CLJS.Y(fQ,EN($CLJS.fD,$CLJS.G(["expression-name",RT,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ft,$CLJS.ck],null)])));
mU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,fQ],null);
cia=function(a){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rt,a,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,1,[$CLJS.wu,"You must specify :num-bins when using the :num-bins strategy."],null),function(b){var c=$CLJS.O(b);b=$CLJS.I.g(c,$CLJS.sG);c=$CLJS.I.g(c,$CLJS.aG);return $CLJS.F.g(b,$CLJS.aG)?c:!0}],null)],null)}(function(a){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rt,a,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,1,[$CLJS.wu,"You must specify :bin-width when using the :bin-width strategy."],
null),function(b){var c=$CLJS.O(b);b=$CLJS.I.g(c,$CLJS.sG);c=$CLJS.I.g(c,$CLJS.rG);return $CLJS.F.g(b,$CLJS.rG)?c:!0}],null)],null)}(new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.ck,new $CLJS.h(null,1,[$CLJS.wu,"binning options"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.sG,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"binning strategy"],null),$CLJS.aG,$CLJS.rG,$CLJS.Wh],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aG,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),UT],null),new $CLJS.Q(null,
3,5,$CLJS.R,[$CLJS.rG,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rt,$CLJS.sl,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,1,[$CLJS.wu,"bin width must be \x3e\x3d 0."],null),$CLJS.Ke($CLJS.Nl)],null)],null)],null)],null)));$CLJS.nU=function nU(a){switch(arguments.length){case 1:return nU.h(arguments[0]);case 2:return nU.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.nU.h=function(a){var b=$CLJS.O(a);a=$CLJS.I.g(b,$CLJS.iD);b=$CLJS.I.g(b,$CLJS.dI);return $CLJS.nU.g(a,b)};$CLJS.nU.g=function(a,b){a=$CLJS.n($CLJS.n(b)?a:b)?$CLJS.iE(a,$CLJS.JE)?$T:$CLJS.iE(a,$CLJS.HF)?aU:$CLJS.iE(a,$CLJS.hF)?bU:null:null;return $CLJS.n(a)?$CLJS.Gd(a,b):!0};$CLJS.nU.A=2;
$CLJS.Y(MS,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rt,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.ck,new $CLJS.h(null,1,[$CLJS.wu,"field options"],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.iD,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,ST],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.hP,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,WT],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dI,new $CLJS.h(null,
1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,cU],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.XQ,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,RT],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.YS,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,cia],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,1,[$CLJS.wu,"Invalid :temporal-unit for the specified :base-type."],null),$CLJS.nU],null)],
null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,1,[$CLJS.wu,"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."],null),$CLJS.Ke($CLJS.sG)],null)],null));
$CLJS.Y(nT,function(a){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rt,a,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,1,[$CLJS.wu,":field clauses using a string field name must specify :base-type."],null),function(b){$CLJS.H(b,0,null);var c=$CLJS.H(b,1,null);b=$CLJS.H(b,2,null);b=$CLJS.O(b);b=$CLJS.I.g(b,$CLJS.iD);return"string"===typeof c?b:!0}],null)],null)}(EN($CLJS.YH,$CLJS.G(["id-or-name",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,WT,RT],null),"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,
new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,MS],null)],null)]))));var oU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,nT],null);
$CLJS.Y(nR,FN($CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof mU?new $CLJS.Fc(function(){return mU},$CLJS.od(rP,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.CR,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),bP,"metabase/mbql/schema.cljc",60,$CLJS.fD,1,249,249,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.fD,$CLJS.DT,$CLJS.W($CLJS.Kj,yO),$CLJS.aj,$CLJS.W($CLJS.Kj,$CLJS.W(LS,$CLJS.ck))],null),$CLJS.Jc,"Schema for a valid expression clause.",$CLJS.n(mU)?mU.H:null])):null));return $CLJS.n(a)?a:bP}(),mU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof oU?new $CLJS.Fc(function(){return oU},
$CLJS.od(JP,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([$CLJS.Nr,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,$CLJS.ni,$CLJS.U,$CLJS.hk],["0.39.0",$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KO,"metabase/mbql/schema.cljc",51,$CLJS.YH,1,382,382,$CLJS.Jc,"Schema for a `:field` clause.",$CLJS.n(oU)?oU.H:null])):null));return $CLJS.n(a)?a:KO}(),oU],null)])));
$CLJS.pU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,nR],null);$CLJS.Y(KN,EN($CLJS.NH,$CLJS.G(["aggregation-clause-index",$CLJS.ri,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ft,$CLJS.ck],null)])));var qU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,KN],null);
$CLJS.Y(sO,FN($CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof qU?new $CLJS.Fc(function(){return qU},$CLJS.od(tha,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),XS,"metabase/mbql/schema.cljc",23,$CLJS.NH,1,418,418,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.NH,Tha,$CLJS.W($CLJS.Kj,$CLJS.ri),$CLJS.aj,$CLJS.W($CLJS.Kj,$CLJS.W(LS,$CLJS.ck))],null),$CLJS.Jc,"Schema for a valid aggregation clause.",$CLJS.n(qU)?qU.H:null])):null));return $CLJS.n(a)?a:XS}(),qU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof mU?new $CLJS.Fc(function(){return mU},
$CLJS.od(rP,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.CR,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),bP,"metabase/mbql/schema.cljc",60,$CLJS.fD,1,249,249,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.fD,$CLJS.DT,$CLJS.W($CLJS.Kj,yO),$CLJS.aj,
$CLJS.W($CLJS.Kj,$CLJS.W(LS,$CLJS.ck))],null),$CLJS.Jc,"Schema for a valid expression clause.",$CLJS.n(mU)?mU.H:null])):null));return $CLJS.n(a)?a:bP}(),mU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof oU?new $CLJS.Fc(function(){return oU},$CLJS.od(JP,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([$CLJS.Nr,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,$CLJS.ni,
$CLJS.U,$CLJS.hk],["0.39.0",$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KO,"metabase/mbql/schema.cljc",51,$CLJS.YH,1,382,382,$CLJS.Jc,"Schema for a `:field` clause.",$CLJS.n(oU)?oU.H:null])):null));return $CLJS.n(a)?a:KO}(),oU],null)])));
var rU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,sO],null),sU=new $CLJS.Tg(null,new $CLJS.h(null,11,[$CLJS.Ku,null,$CLJS.rI,null,$CLJS.rH,null,$CLJS.xI,null,$CLJS.wH,null,$CLJS.aI,null,$CLJS.GH,null,$CLJS.Su,null,$CLJS.pI,null,$CLJS.$H,null,$CLJS.yI,null],null),null),tU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,pO],null);
$CLJS.Y(SN,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.qt,new $CLJS.h(null,1,[$CLJS.Ii,function(a){return"string"===typeof a?$CLJS.Tj:$CLJS.n(DN(sU,a))?lO:$CLJS.n(DN($CLJS.yj,a))?$CLJS.yj:$CLJS.Mu}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Tj,$CLJS.Tj],null),new $CLJS.Q(null,2,5,$CLJS.R,[lO,tU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.yj,lU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Mu,$CLJS.pU],null)],null));uU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,SN],null);
vU=new $CLJS.Tg(null,new $CLJS.h(null,26,[$CLJS.tH,null,$CLJS.YK,null,$CLJS.Ps,null,$CLJS.UK,null,$CLJS.RK,null,$CLJS.LH,null,$CLJS.mu,null,$CLJS.qH,null,$CLJS.VK,null,$CLJS.OK,null,$CLJS.aI,null,$CLJS.uI,null,$CLJS.SK,null,$CLJS.cI,null,$CLJS.uH,null,$CLJS.AK,null,$CLJS.Os,null,$CLJS.xH,null,$CLJS.GH,null,$CLJS.uK,null,$CLJS.Iz,null,$CLJS.BK,null,$CLJS.NK,null,$CLJS.FK,null,$CLJS.wI,null,$CLJS.WH,null],null),null);
wU=new $CLJS.Tg(null,new $CLJS.h(null,9,[$CLJS.Ks,null,$CLJS.Es,null,$CLJS.lt,null,$CLJS.nt,null,$CLJS.Gs,null,$CLJS.qI,null,$CLJS.rt,null,$CLJS.Ls,null,$CLJS.Is,null],null),null);xU=new $CLJS.Tg(null,new $CLJS.h(null,17,[$CLJS.Qn,null,$CLJS.oI,null,$CLJS.gI,null,$CLJS.ZH,null,$CLJS.tI,null,$CLJS.vI,null,$CLJS.CH,null,$CLJS.JH,null,$CLJS.XN,null,$CLJS.DH,null,$CLJS.lI,null,$CLJS.eI,null,$CLJS.dk,null,$CLJS.Kz,null,$CLJS.SH,null,$CLJS.TH,null,$CLJS.kI,null],null),null);
yU=new $CLJS.Tg(null,new $CLJS.h(null,5,[$CLJS.KK,null,$CLJS.wK,null,$CLJS.WK,null,$CLJS.Os,null,$CLJS.QK,null],null),null);zU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,ES],null);AU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,dP],null);$CLJS.BU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,fS],null);$CLJS.CU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,KS],null);
$CLJS.Y(RS,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.qt,new $CLJS.h(null,2,[$CLJS.wu,"numeric expression argument",$CLJS.Ii,function(a){return"number"===typeof a?$CLJS.fv:$CLJS.n(DN(vU,a))?HQ:$CLJS.n(DN(xU,a))?$CLJS.NH:$CLJS.n(DN($CLJS.yj,a))?$CLJS.yj:$CLJS.YH}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fv,$CLJS.sl],null),new $CLJS.Q(null,2,5,$CLJS.R,[HQ,zU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.NH,$CLJS.CU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.yj,lU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.YH,
$CLJS.pU],null)],null));var DU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,RS],null);
$CLJS.Y(aS,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.qt,new $CLJS.h(null,2,[$CLJS.wu,"datetime expression argument",$CLJS.Ii,function(a){return $CLJS.n(DN(xU,a))?$CLJS.NH:$CLJS.n(DN($CLJS.yj,a))?$CLJS.yj:$CLJS.n(DN(yU,a))?kP:$CLJS.Mu}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.NH,$CLJS.CU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.yj,lU],null),new $CLJS.Q(null,2,5,$CLJS.R,[kP,$CLJS.BU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Mu,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,jU,$CLJS.pU],null)],null)],null));
var EU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,aS],null);
$CLJS.Y(DR,new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.qt,new $CLJS.h(null,2,[$CLJS.wu,"expression argument",$CLJS.Ii,function(a){return"number"===typeof a?$CLJS.fv:$CLJS.Bd(a)?$CLJS.zt:$CLJS.n(DN(wU,a))?SR:$CLJS.n(DN(vU,a))?HQ:$CLJS.n(DN(yU,a))?kP:"string"===typeof a?$CLJS.Tj:$CLJS.n(DN(sU,a))?lO:$CLJS.n(DN($CLJS.yj,a))?$CLJS.yj:$CLJS.Mu}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fv,$CLJS.sl],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.zt,$CLJS.zt],null),new $CLJS.Q(null,2,5,$CLJS.R,[SR,AU],null),new $CLJS.Q(null,
2,5,$CLJS.R,[HQ,zU],null),new $CLJS.Q(null,2,5,$CLJS.R,[kP,$CLJS.BU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Tj,$CLJS.Tj],null),new $CLJS.Q(null,2,5,$CLJS.R,[lO,tU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.yj,lU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Mu,$CLJS.pU],null)],null));var FU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,DR],null);$CLJS.Y(BR,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.wu,"numeric expression arg or interval"],null),bia,DU],null));
var GU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,BR],null);$CLJS.Y(cO,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.qt,new $CLJS.h(null,2,[$CLJS.wu,"int greater than zero or numeric expression",$CLJS.Ii,function(a){return"number"===typeof a?$CLJS.fv:$CLJS.Mu}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fv,UT],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Mu,zU],null)],null));var dia=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,cO],null);
$CLJS.Y(lP,EN($CLJS.aI,$CLJS.G(["a",FU,"b",FU,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ji,FU],null)])));var HU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,lP],null);$CLJS.Y(PN,EN($CLJS.rH,$CLJS.G(["s",uU,"start",dia,"length",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ft,DU],null)])));var IU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,PN],null);$CLJS.Y(TR,EN($CLJS.Iz,$CLJS.G(["s",uU])));var JU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,TR],null);$CLJS.Y(rO,EN($CLJS.pI,$CLJS.G(["s",uU])));
var KU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,rO],null);$CLJS.Y(vO,EN($CLJS.yI,$CLJS.G(["s",uU])));var LU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,vO],null);$CLJS.Y(OO,EN($CLJS.rI,$CLJS.G(["s",uU])));var MU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,OO],null);$CLJS.Y(zP,EN($CLJS.Ku,$CLJS.G(["s",uU])));var NU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,zP],null);$CLJS.Y(hO,EN($CLJS.Su,$CLJS.G(["s",uU])));var OU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,hO],null);
$CLJS.Y(QS,EN($CLJS.xI,$CLJS.G(["s",uU,"match",$CLJS.Tj,"replacement",$CLJS.Tj])));var PU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,QS],null);$CLJS.Y(MT,EN($CLJS.wH,$CLJS.G(["a",uU,"b",uU,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ji,uU],null)])));var QU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,MT],null);$CLJS.Y(oQ,EN($CLJS.$H,$CLJS.G(["s",uU,"pattern",$CLJS.Tj])));var RU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,oQ],null);
$CLJS.Y(lT,EN($CLJS.Os,$CLJS.G(["x",GU,"y",GU,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ji,GU],null)])));var SU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,lT],null);$CLJS.Y(jT,EN($CLJS.mu,$CLJS.G(["x",DU,"y",GU,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ji,GU],null)])));var TU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,jT],null);$CLJS.Y(iT,EN($CLJS.uI,$CLJS.G(["x",DU,"y",DU,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ji,DU],null)])));var UU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,iT],null);
$CLJS.Y(kT,EN($CLJS.Ps,$CLJS.G(["x",DU,"y",DU,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ji,DU],null)])));var VU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,kT],null);$CLJS.Y(AR,EN($CLJS.WH,$CLJS.G(["x",DU])));var WU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,AR],null);$CLJS.Y(tS,EN($CLJS.tH,$CLJS.G(["x",DU])));var XU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,tS],null);$CLJS.Y(PT,EN($CLJS.cI,$CLJS.G(["x",DU])));var YU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,PT],null);$CLJS.Y(FQ,EN($CLJS.xH,$CLJS.G(["x",DU])));
var ZU=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,FQ],null);$CLJS.Y(LQ,EN($CLJS.uH,$CLJS.G(["x",DU,"y",DU])));var $U=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,LQ],null);$CLJS.Y(hR,EN($CLJS.LH,$CLJS.G(["x",DU])));var aV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,hR],null);$CLJS.Y(dO,EN($CLJS.qH,$CLJS.G(["x",DU])));var bV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,dO],null);$CLJS.Y(GR,EN($CLJS.wI,$CLJS.G(["x",DU])));var cV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,GR],null);
$CLJS.Y(EO,EN($CLJS.FK,$CLJS.G(["datetime-x",EU,"datetime-y",EU,"unit",aia])));var dV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,EO],null);$CLJS.Y(WO,EN($CLJS.UK,$CLJS.G(["datetime",EU,"unit",$ha,"mode",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ft,eU],null)])));var eV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,WO],null);$CLJS.Y(NQ,EN($CLJS.BK,$CLJS.G(["date",EU])));var fV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,NQ],null);$CLJS.Y(oR,EN($CLJS.YK,$CLJS.G(["date",EU])));
var gV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,oR],null);$CLJS.Y(RN,EN($CLJS.uK,$CLJS.G(["date",EU])));var hV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,RN],null);$CLJS.Y(NT,EN($CLJS.OK,$CLJS.G(["date",EU,"mode",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ft,eU],null)])));var iV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,NT],null);$CLJS.Y($P,EN($CLJS.NK,$CLJS.G(["date",EU])));var jV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$P],null);$CLJS.Y(iR,EN($CLJS.SK,$CLJS.G(["date",EU])));
var kV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,iR],null);$CLJS.Y(AO,EN($CLJS.VK,$CLJS.G(["datetime",EU])));var lV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,AO],null);$CLJS.Y(eQ,EN($CLJS.RK,$CLJS.G(["datetime",EU])));var mV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,eQ],null);$CLJS.Y(tO,EN($CLJS.AK,$CLJS.G(["datetime",EU])));var nV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,tO],null);$CLJS.Y(KP,EN($CLJS.wK,$CLJS.G(["datetime",EU,"to",dU,"from",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ft,dU],null)])));
var oV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,KP],null),pV=new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"datetime arithmetic unit"],null),$CLJS.OI,$CLJS.LI,$CLJS.Ni,$CLJS.pv,$CLJS.mk,$CLJS.jj,$CLJS.sj,$CLJS.ii,$CLJS.ci],null);$CLJS.Y(ON,EN($CLJS.QK,$CLJS.G(["datetime",EU,"amount",DU,"unit",pV])));var qV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,ON],null);
$CLJS.Y(mO,function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return EN(arguments[0],1<b.length?new $CLJS.y(b.slice(1),0,null):null)}($CLJS.KK));var rV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,mO],null);$CLJS.Y(NN,EN($CLJS.WK,$CLJS.G(["datetime",EU,"amount",DU,"unit",pV])));var sV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,NN],null);
$CLJS.Y(fS,FN($CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof SU?new $CLJS.Fc(function(){return SU},$CLJS.od(uR,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.CR,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),iQ,"metabase/mbql/schema.cljc",51,$CLJS.Os,1,612,612,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Os,$CLJS.oG,$CLJS.W($CLJS.Kj,yR),WS,$CLJS.W($CLJS.Kj,yR),mP,$CLJS.W($CLJS.Kj,$CLJS.W($CLJS.Gi,yR))],null),$CLJS.Jc,"Schema for a valid + clause.",$CLJS.n(SU)?SU.H:null])):null));return $CLJS.n(a)?a:iQ}(),SU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof qV?new $CLJS.Fc(function(){return qV},
$CLJS.od(Ega,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[DP,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pR,"metabase/mbql/schema.cljc",67,$CLJS.QK,1,703,703,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.QK,$CLJS.JK,$CLJS.W($CLJS.Kj,IT),pS,$CLJS.W($CLJS.Kj,
uS),$CLJS.li,$CLJS.W($CLJS.Kj,CS)],null),$CLJS.Jc,"Schema for a valid datetime-add clause.",$CLJS.n(qV)?qV.H:null])):null));return $CLJS.n(a)?a:pR}(),qV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof sV?new $CLJS.Fc(function(){return sV},$CLJS.od(yfa,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,
$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[DP,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GT,"metabase/mbql/schema.cljc",72,$CLJS.WK,1,710,710,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.WK,$CLJS.JK,$CLJS.W($CLJS.Kj,IT),pS,$CLJS.W($CLJS.Kj,uS),$CLJS.li,$CLJS.W($CLJS.Kj,CS)],null),$CLJS.Jc,"Schema for a valid datetime-subtract clause.",$CLJS.n(sV)?sV.H:null])):null));
return $CLJS.n(a)?a:GT}(),sV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof oV?new $CLJS.Fc(function(){return oV},$CLJS.od(Cha,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.wK,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jP,"metabase/mbql/schema.cljc",71,$CLJS.wK,1,695,695,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.wK,$CLJS.JK,$CLJS.W($CLJS.Kj,IT),wga,$CLJS.W($CLJS.Kj,ZS),Cfa,$CLJS.W($CLJS.Kj,$CLJS.W(LS,ZS))],null),$CLJS.Jc,"Schema for a valid convert-timezone clause.",$CLJS.n(oV)?oV.H:null])):null));return $CLJS.n(a)?a:jP}(),oV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&
"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof rV?new $CLJS.Fc(function(){return rV},$CLJS.od(eha,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.KK,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OQ,"metabase/mbql/schema.cljc",
45,$CLJS.KK,1,708,708,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.KK],null),$CLJS.Jc,"Schema for a valid now clause.",$CLJS.n(rV)?rV.H:null])):null));return $CLJS.n(a)?a:OQ}(),rV],null)])));$CLJS.tV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,kR],null);$CLJS.Y(TN,EN($CLJS.rt,$CLJS.G(["first-clause",$CLJS.tV,"second-clause",$CLJS.tV,"other-clauses",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ji,$CLJS.tV],null)])));var uV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,TN],null);
$CLJS.Y(GP,EN($CLJS.lt,$CLJS.G(["first-clause",$CLJS.tV,"second-clause",$CLJS.tV,"other-clauses",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ji,$CLJS.tV],null)])));var vV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,GP],null);$CLJS.Y(oO,EN($CLJS.nt,$CLJS.G(["clause",$CLJS.tV])));
var wV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,oO],null),xV=new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.qt,new $CLJS.h(null,3,[$CLJS.wu,":field or :expression reference or :relative-datetime",$CLJS.yu,$CLJS.Le(":field or :expression reference or :relative-datetime"),$CLJS.Ii,function(a){return $CLJS.n(DN($CLJS.IK,a))?$CLJS.IK:$CLJS.Mu}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.IK,gU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Mu,$CLJS.pU],null)],null);
$CLJS.Y(cS,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kt,new $CLJS.h(null,1,[$CLJS.wu,"equality comparable"],null),new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.lt,$CLJS.zt,$CLJS.sl,$CLJS.Tj,kU,xV,FU,lU],null)],null));var yV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,cS],null);
$CLJS.Y(tQ,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.qt,new $CLJS.h(null,2,[$CLJS.wu,"order comparable",$CLJS.Ii,function(a){return $CLJS.n(DN($CLJS.yj,a))?$CLJS.yj:$CLJS.Mu}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.yj,lU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Mu,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.lt,$CLJS.sl,$CLJS.Tj,kU,FU,xV],null)],null)],null));var zV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,tQ],null);
$CLJS.Y(bT,EN($CLJS.Ls,$CLJS.G(["field",yV,"value-or-field",yV,"more-values-or-fields",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ji,yV],null)])));var AV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,bT],null);$CLJS.Y(fT,EN($CLJS.qI,$CLJS.G(["field",yV,"value-or-field",yV,"more-values-or-fields",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ji,yV],null)])));var BV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,fT],null);$CLJS.Y(aT,EN($CLJS.Is,$CLJS.G(["field",zV,"value-or-field",zV])));
var CV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,aT],null);$CLJS.Y($S,EN($CLJS.Es,$CLJS.G(["field",zV,"value-or-field",zV])));var DV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$S],null);$CLJS.Y(PO,EN($CLJS.Ks,$CLJS.G(["field",zV,"value-or-field",zV])));var EV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,PO],null);$CLJS.Y(VO,EN($CLJS.Gs,$CLJS.G(["field",zV,"value-or-field",zV])));var FV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,VO],null);$CLJS.Y(KR,EN($CLJS.zH,$CLJS.G(["field",zV,"min",zV,"max",zV])));
var GV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,KR],null);$CLJS.Y(mS,EN($CLJS.FH,$CLJS.G(["lat-field",zV,"lon-field",zV,"lat-max",zV,"lon-min",zV,"lat-min",zV,"lon-max",zV])));var HV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,mS],null);$CLJS.Y(yP,EN($CLJS.yH,$CLJS.G(["field",$CLJS.pU])));var IV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,yP],null);$CLJS.Y(nQ,EN($CLJS.KH,$CLJS.G(["field",$CLJS.pU])));var JV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,nQ],null);$CLJS.Y(QP,EN($CLJS.AH,$CLJS.G(["field",$CLJS.pU])));
var KV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,QP],null);$CLJS.Y(WN,EN($CLJS.HH,$CLJS.G(["field",$CLJS.pU])));var LV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,WN],null),MV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.fI,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.zt],null)],null);$CLJS.Y(cQ,EN($CLJS.PH,$CLJS.G(["field",uU,"string-or-field",uU,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ft,MV],null)])));var NV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,cQ],null);
$CLJS.Y(WQ,EN($CLJS.iI,$CLJS.G(["field",uU,"string-or-field",uU,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ft,MV],null)])));var OV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,WQ],null);$CLJS.Y(OP,EN($CLJS.XH,$CLJS.G(["field",uU,"string-or-field",uU,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ft,MV],null)])));var PV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,OP],null);$CLJS.Y(ER,EN($CLJS.QH,$CLJS.G(["field",uU,"string-or-field",uU,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ft,MV],null)])));
var QV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,ER],null);$CLJS.Y(eO,EN($CLJS.jI,$CLJS.G(["field",$CLJS.pU,"n",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,$CLJS.ri,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ht,$CLJS.vx,$CLJS.Oz,$CLJS.EH],null)],null),"unit",fU,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ft,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.nI,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.zt],null)],null)],null)])));var RV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,eO],null);
$CLJS.Y(VR,EN($CLJS.vH,$CLJS.G(["segment-id",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.wM],null),RT],null)])));var SV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,VR],null);
$CLJS.Y(dP,FN($CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof uV?new $CLJS.Fc(function(){return uV},$CLJS.od(PS,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),xQ,"metabase/mbql/schema.cljc",15,$CLJS.rt,1,724,724,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.rt,nP,$CLJS.W($CLJS.Kj,CQ),fR,$CLJS.W($CLJS.Kj,CQ),xP,$CLJS.W($CLJS.Kj,$CLJS.W($CLJS.Gi,CQ))],null),$CLJS.Jc,"Schema for a valid and clause.",$CLJS.n(uV)?uV.H:null])):null));return $CLJS.n(a)?a:xQ}(),uV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof vV?new $CLJS.Fc(function(){return vV},
$CLJS.od(jR,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mR,"metabase/mbql/schema.cljc",14,$CLJS.lt,1,729,729,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.lt,nP,$CLJS.W($CLJS.Kj,CQ),fR,$CLJS.W($CLJS.Kj,CQ),xP,$CLJS.W($CLJS.Kj,$CLJS.W($CLJS.Gi,CQ))],null),$CLJS.Jc,
"Schema for a valid or clause.",$CLJS.n(vV)?vV.H:null])):null));return $CLJS.n(a)?a:mR}(),vV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof wV?new $CLJS.Fc(function(){return wV},$CLJS.od(WR,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),PQ,"metabase/mbql/schema.cljc",15,$CLJS.nt,1,734,734,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.nt,$CLJS.CT,$CLJS.W($CLJS.Kj,CQ)],null),$CLJS.Jc,"Schema for a valid not clause.",$CLJS.n(wV)?wV.H:null])):null));return $CLJS.n(a)?a:PQ}(),wV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof CV?new $CLJS.Fc(function(){return CV},$CLJS.od(sR,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([$CLJS.Qi,
$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qQ,"metabase/mbql/schema.cljc",13,$CLJS.Is,1,798,798,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Is,$CLJS.YH,$CLJS.W($CLJS.Kj,pP),AS,$CLJS.W($CLJS.Kj,pP)],null),$CLJS.Jc,"Schema for a valid \x3c clause.",$CLJS.n(CV)?CV.H:null])):null));return $CLJS.n(a)?a:qQ}(),CV],null),
new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof EV?new $CLJS.Fc(function(){return EV},$CLJS.od(vT,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),pQ,"metabase/mbql/schema.cljc",14,$CLJS.Ks,1,800,800,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Ks,$CLJS.YH,$CLJS.W($CLJS.Kj,pP),AS,$CLJS.W($CLJS.Kj,pP)],null),$CLJS.Jc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(EV)?EV.H:null])):null));return $CLJS.n(a)?a:pQ}(),EV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof DV?new $CLJS.Fc(function(){return DV},$CLJS.od(qR,new $CLJS.h(null,
1,[$CLJS.Vj,!0],null)),$CLJS.Ag([$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rQ,"metabase/mbql/schema.cljc",13,$CLJS.Es,1,799,799,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Es,$CLJS.YH,$CLJS.W($CLJS.Kj,pP),AS,$CLJS.W($CLJS.Kj,pP)],null),$CLJS.Jc,"Schema for a valid \x3e clause.",$CLJS.n(DV)?DV.H:null])):null));
return $CLJS.n(a)?a:rQ}(),DV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof FV?new $CLJS.Fc(function(){return FV},$CLJS.od(oT,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),yQ,"metabase/mbql/schema.cljc",14,$CLJS.Gs,1,801,801,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Gs,$CLJS.YH,$CLJS.W($CLJS.Kj,pP),AS,$CLJS.W($CLJS.Kj,pP)],null),$CLJS.Jc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(FV)?FV.H:null])):null));return $CLJS.n(a)?a:yQ}(),FV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof AV?new $CLJS.Fc(function(){return AV},$CLJS.od(rR,new $CLJS.h(null,
1,[$CLJS.Vj,!0],null)),$CLJS.Ag([$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sQ,"metabase/mbql/schema.cljc",13,$CLJS.Ls,1,795,795,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Ls,$CLJS.YH,$CLJS.W($CLJS.Kj,AT),AS,$CLJS.W($CLJS.Kj,AT),dS,$CLJS.W($CLJS.Kj,$CLJS.W($CLJS.Gi,AT))],null),$CLJS.Jc,"Schema for a valid \x3d clause.",
$CLJS.n(AV)?AV.H:null])):null));return $CLJS.n(a)?a:sQ}(),AV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof BV?new $CLJS.Fc(function(){return BV},$CLJS.od(FO,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),aP,"metabase/mbql/schema.cljc",14,$CLJS.qI,1,796,796,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.qI,$CLJS.YH,$CLJS.W($CLJS.Kj,AT),AS,$CLJS.W($CLJS.Kj,AT),dS,$CLJS.W($CLJS.Kj,$CLJS.W($CLJS.Gi,AT))],null),$CLJS.Jc,"Schema for a valid !\x3d clause.",$CLJS.n(BV)?BV.H:null])):null));return $CLJS.n(a)?a:aP}(),BV],null)])));
$CLJS.Y(kR,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.qt,new $CLJS.h(null,2,[$CLJS.wu,"valid filter expression",$CLJS.Ii,function(a){return $CLJS.n(DN(yU,a))?$CLJS.JK:$CLJS.n(DN(vU,a))?JT:$CLJS.n(DN(sU,a))?$CLJS.Tj:$CLJS.n(DN(wU,a))?$CLJS.zt:$CLJS.Mu}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.JK,$CLJS.BU],null),new $CLJS.Q(null,2,5,$CLJS.R,[JT,zU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Tj,tU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.zt,AU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Mu,FN($CLJS.G([new $CLJS.Q(null,
2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof uV?new $CLJS.Fc(function(){return uV},$CLJS.od(PS,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xQ,"metabase/mbql/schema.cljc",
15,$CLJS.rt,1,724,724,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.rt,nP,$CLJS.W($CLJS.Kj,CQ),fR,$CLJS.W($CLJS.Kj,CQ),xP,$CLJS.W($CLJS.Kj,$CLJS.W($CLJS.Gi,CQ))],null),$CLJS.Jc,"Schema for a valid and clause.",$CLJS.n(uV)?uV.H:null])):null));return $CLJS.n(a)?a:xQ}(),uV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof vV?new $CLJS.Fc(function(){return vV},$CLJS.od(jR,new $CLJS.h(null,1,[$CLJS.Vj,
!0],null)),$CLJS.Ag([$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mR,"metabase/mbql/schema.cljc",14,$CLJS.lt,1,729,729,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.lt,nP,$CLJS.W($CLJS.Kj,CQ),fR,$CLJS.W($CLJS.Kj,CQ),xP,$CLJS.W($CLJS.Kj,$CLJS.W($CLJS.Gi,CQ))],null),$CLJS.Jc,"Schema for a valid or clause.",$CLJS.n(vV)?
vV.H:null])):null));return $CLJS.n(a)?a:mR}(),vV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof wV?new $CLJS.Fc(function(){return wV},$CLJS.od(WR,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),PQ,"metabase/mbql/schema.cljc",15,$CLJS.nt,1,734,734,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.nt,$CLJS.CT,$CLJS.W($CLJS.Kj,CQ)],null),$CLJS.Jc,"Schema for a valid not clause.",$CLJS.n(wV)?wV.H:null])):null));return $CLJS.n(a)?a:PQ}(),wV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof AV?new $CLJS.Fc(function(){return AV},$CLJS.od(rR,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([$CLJS.Qi,
$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sQ,"metabase/mbql/schema.cljc",13,$CLJS.Ls,1,795,795,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Ls,$CLJS.YH,$CLJS.W($CLJS.Kj,AT),AS,$CLJS.W($CLJS.Kj,AT),dS,$CLJS.W($CLJS.Kj,$CLJS.W($CLJS.Gi,AT))],null),$CLJS.Jc,"Schema for a valid \x3d clause.",$CLJS.n(AV)?AV.H:null])):null));
return $CLJS.n(a)?a:sQ}(),AV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof BV?new $CLJS.Fc(function(){return BV},$CLJS.od(FO,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),aP,"metabase/mbql/schema.cljc",14,$CLJS.qI,1,796,796,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.qI,$CLJS.YH,$CLJS.W($CLJS.Kj,AT),AS,$CLJS.W($CLJS.Kj,AT),dS,$CLJS.W($CLJS.Kj,$CLJS.W($CLJS.Gi,AT))],null),$CLJS.Jc,"Schema for a valid !\x3d clause.",$CLJS.n(BV)?BV.H:null])):null));return $CLJS.n(a)?a:aP}(),BV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof CV?new $CLJS.Fc(function(){return CV},
$CLJS.od(sR,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qQ,"metabase/mbql/schema.cljc",13,$CLJS.Is,1,798,798,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Is,$CLJS.YH,$CLJS.W($CLJS.Kj,pP),AS,$CLJS.W($CLJS.Kj,pP)],null),$CLJS.Jc,"Schema for a valid \x3c clause.",
$CLJS.n(CV)?CV.H:null])):null));return $CLJS.n(a)?a:qQ}(),CV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof DV?new $CLJS.Fc(function(){return DV},$CLJS.od(qR,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),rQ,"metabase/mbql/schema.cljc",13,$CLJS.Es,1,799,799,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Es,$CLJS.YH,$CLJS.W($CLJS.Kj,pP),AS,$CLJS.W($CLJS.Kj,pP)],null),$CLJS.Jc,"Schema for a valid \x3e clause.",$CLJS.n(DV)?DV.H:null])):null));return $CLJS.n(a)?a:rQ}(),DV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof EV?new $CLJS.Fc(function(){return EV},$CLJS.od(vT,new $CLJS.h(null,
1,[$CLJS.Vj,!0],null)),$CLJS.Ag([$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pQ,"metabase/mbql/schema.cljc",14,$CLJS.Ks,1,800,800,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Ks,$CLJS.YH,$CLJS.W($CLJS.Kj,pP),AS,$CLJS.W($CLJS.Kj,pP)],null),$CLJS.Jc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(EV)?EV.H:null])):
null));return $CLJS.n(a)?a:pQ}(),EV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof FV?new $CLJS.Fc(function(){return FV},$CLJS.od(oT,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),yQ,"metabase/mbql/schema.cljc",14,$CLJS.Gs,1,801,801,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Gs,$CLJS.YH,$CLJS.W($CLJS.Kj,pP),AS,$CLJS.W($CLJS.Kj,pP)],null),$CLJS.Jc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(FV)?FV.H:null])):null));return $CLJS.n(a)?a:yQ}(),FV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof GV?new $CLJS.Fc(function(){return GV},$CLJS.od(Wga,new $CLJS.h(null,
1,[$CLJS.Vj,!0],null)),$CLJS.Ag([$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HO,"metabase/mbql/schema.cljc",19,$CLJS.zH,1,804,804,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.zH,$CLJS.YH,$CLJS.W($CLJS.Kj,pP),$CLJS.Qn,$CLJS.W($CLJS.Kj,pP),$CLJS.dk,$CLJS.W($CLJS.Kj,pP)],null),$CLJS.Jc,"Schema for a valid between clause.",
$CLJS.n(GV)?GV.H:null])):null));return $CLJS.n(a)?a:HO}(),GV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof NV?new $CLJS.Fc(function(){return NV},$CLJS.od(Yga,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),FS,"metabase/mbql/schema.cljc",23,$CLJS.PH,1,829,829,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.PH,$CLJS.YH,$CLJS.W($CLJS.Kj,zS),$O,$CLJS.W($CLJS.Kj,zS),$CLJS.aj,$CLJS.W($CLJS.Kj,$CLJS.W(LS,UR))],null),$CLJS.Jc,"Schema for a valid starts-with clause.",$CLJS.n(NV)?NV.H:null])):null));return $CLJS.n(a)?a:FS}(),NV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof OV?new $CLJS.Fc(function(){return OV},
$CLJS.od($fa,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eP,"metabase/mbql/schema.cljc",21,$CLJS.iI,1,830,830,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.iI,$CLJS.YH,$CLJS.W($CLJS.Kj,zS),$O,$CLJS.W($CLJS.Kj,zS),$CLJS.aj,$CLJS.W($CLJS.Kj,$CLJS.W(LS,UR))],null),
$CLJS.Jc,"Schema for a valid ends-with clause.",$CLJS.n(OV)?OV.H:null])):null));return $CLJS.n(a)?a:eP}(),OV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof PV?new $CLJS.Fc(function(){return PV},$CLJS.od(lga,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[$CLJS.od(sS,new $CLJS.h(null,
1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qP,"metabase/mbql/schema.cljc",20,$CLJS.XH,1,831,831,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.XH,$CLJS.YH,$CLJS.W($CLJS.Kj,zS),$O,$CLJS.W($CLJS.Kj,zS),$CLJS.aj,$CLJS.W($CLJS.Kj,$CLJS.W(LS,UR))],null),$CLJS.Jc,"Schema for a valid contains clause.",$CLJS.n(PV)?PV.H:null])):null));return $CLJS.n(a)?a:qP}(),PV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==
typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof QV?new $CLJS.Fc(function(){return QV},$CLJS.od(Qha,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,NS,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UN,"metabase/mbql/schema.cljc",36,$CLJS.QH,1,834,834,!0,new $CLJS.Q(null,
7,5,$CLJS.R,[$CLJS.QH,$CLJS.YH,$CLJS.W($CLJS.Kj,zS),$O,$CLJS.W($CLJS.Kj,zS),$CLJS.aj,$CLJS.W($CLJS.Kj,$CLJS.W(LS,UR))],null),$CLJS.Jc,"Schema for a valid does-not-contain clause.",$CLJS.n(QV)?QV.H:null])):null));return $CLJS.n(a)?a:UN}(),QV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof HV?new $CLJS.Fc(function(){return HV},$CLJS.od(zha,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),
$CLJS.Ag([$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,NS,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DS,"metabase/mbql/schema.cljc",26,$CLJS.FH,1,807,807,!0,new $CLJS.Q(null,13,5,$CLJS.R,[$CLJS.FH,Ifa,$CLJS.W($CLJS.Kj,pP),Uha,$CLJS.W($CLJS.Kj,pP),Gha,$CLJS.W($CLJS.Kj,pP),Lfa,$CLJS.W($CLJS.Kj,pP),Aha,$CLJS.W($CLJS.Kj,pP),Hfa,$CLJS.W($CLJS.Kj,
pP)],null),$CLJS.Jc,"Schema for a valid inside clause.",$CLJS.n(HV)?HV.H:null])):null));return $CLJS.n(a)?a:DS}(),HV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof KV?new $CLJS.Fc(function(){return KV},$CLJS.od(mga,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,NS,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[$CLJS.od(sS,new $CLJS.h(null,
1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YQ,"metabase/mbql/schema.cljc",28,$CLJS.AH,1,821,821,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.AH,$CLJS.YH,$CLJS.W($CLJS.Kj,iP)],null),$CLJS.Jc,"Schema for a valid is-empty clause.",$CLJS.n(KV)?KV.H:null])):null));return $CLJS.n(a)?a:YQ}(),KV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&
"undefined"!==typeof LV?new $CLJS.Fc(function(){return LV},$CLJS.od(kha,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,NS,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LR,"metabase/mbql/schema.cljc",29,$CLJS.HH,1,822,822,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.HH,$CLJS.YH,$CLJS.W($CLJS.Kj,iP)],null),
$CLJS.Jc,"Schema for a valid not-empty clause.",$CLJS.n(LV)?LV.H:null])):null));return $CLJS.n(a)?a:LR}(),LV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof IV?new $CLJS.Fc(function(){return IV},$CLJS.od(Lha,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,NS,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[$CLJS.od(sS,new $CLJS.h(null,
1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wP,"metabase/mbql/schema.cljc",27,$CLJS.yH,1,816,816,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.yH,$CLJS.YH,$CLJS.W($CLJS.Kj,iP)],null),$CLJS.Jc,"Schema for a valid is-null clause.",$CLJS.n(IV)?IV.H:null])):null));return $CLJS.n(a)?a:wP}(),IV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&
"undefined"!==typeof JV?new $CLJS.Fc(function(){return JV},$CLJS.od(yga,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,NS,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MO,"metabase/mbql/schema.cljc",28,$CLJS.KH,1,817,817,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.KH,$CLJS.YH,$CLJS.W($CLJS.Kj,iP)],null),
$CLJS.Jc,"Schema for a valid not-null clause.",$CLJS.n(JV)?JV.H:null])):null));return $CLJS.n(a)?a:MO}(),JV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof RV?new $CLJS.Fc(function(){return RV},$CLJS.od(zfa,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,NS,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[$CLJS.od(sS,new $CLJS.h(null,
1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kS,"metabase/mbql/schema.cljc",33,$CLJS.jI,1,856,856,!0,new $CLJS.Q(null,9,5,$CLJS.R,[$CLJS.jI,$CLJS.YH,$CLJS.W($CLJS.Kj,iP),$CLJS.My,$CLJS.W($CLJS.Kj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,$CLJS.ri,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ht,$CLJS.vx,$CLJS.Oz,$CLJS.EH],null)],null)),$CLJS.li,$CLJS.W($CLJS.Kj,wQ),$CLJS.aj,$CLJS.W($CLJS.Kj,$CLJS.W(LS,aha))],null),$CLJS.Jc,"Schema for a valid time-interval clause.",
$CLJS.n(RV)?RV.H:null])):null));return $CLJS.n(a)?a:kS}(),RV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof SV?new $CLJS.Fc(function(){return SV},$CLJS.od(Gga,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,NS,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),mT,"metabase/mbql/schema.cljc",27,$CLJS.vH,1,872,872,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vH,Pfa,$CLJS.W($CLJS.Kj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,qha,yO],null))],null),$CLJS.Jc,"Schema for a valid segment clause.",$CLJS.n(SV)?SV.H:null])):null));return $CLJS.n(a)?a:mT}(),SV],null)]))],null)],null));
$CLJS.Y(YR,EN($CLJS.GH,$CLJS.G(["clauses",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ot,new $CLJS.h(null,1,[$CLJS.wu,":case subclause"],null),$CLJS.tV,FU],null)],null)],null),"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ft,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ck,new $CLJS.h(null,1,[$CLJS.wu,":case options"],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wh,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),FU],null)],null)],null)])));
var TV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,YR],null);
$CLJS.Y(ES,FN($CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof SU?new $CLJS.Fc(function(){return SU},$CLJS.od(uR,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.CR,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),iQ,"metabase/mbql/schema.cljc",51,$CLJS.Os,1,612,612,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Os,$CLJS.oG,$CLJS.W($CLJS.Kj,yR),WS,$CLJS.W($CLJS.Kj,yR),mP,$CLJS.W($CLJS.Kj,$CLJS.W($CLJS.Gi,yR))],null),$CLJS.Jc,"Schema for a valid + clause.",$CLJS.n(SU)?SU.H:null])):null));return $CLJS.n(a)?a:iQ}(),SU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof TU?new $CLJS.Fc(function(){return TU},
$CLJS.od(Sga,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.CR,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jQ,"metabase/mbql/schema.cljc",51,$CLJS.mu,1,615,615,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.mu,$CLJS.oG,$CLJS.W($CLJS.Kj,uS),
WS,$CLJS.W($CLJS.Kj,yR),mP,$CLJS.W($CLJS.Kj,$CLJS.W($CLJS.Gi,yR))],null),$CLJS.Jc,"Schema for a valid - clause.",$CLJS.n(TU)?TU.H:null])):null));return $CLJS.n(a)?a:jQ}(),TU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN?new $CLJS.Fc(function(){return UU},$CLJS.od(Rga,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,MQ,$CLJS.Mi,$CLJS.ij,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,
new $CLJS.h(null,1,[$CLJS.CR,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.XB,"metabase/mbql/schema.cljc",$CLJS.uI,1,618,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.uI,$CLJS.oG,$CLJS.W($CLJS.Kj,uS),WS,$CLJS.W($CLJS.Kj,uS),mP,$CLJS.W($CLJS.Kj,$CLJS.W($CLJS.Gi,uS))],null),$CLJS.Jc,"Schema for a valid / clause.",$CLJS.n(UU)?UU.H:null])):null));return $CLJS.n(a)?a:$CLJS.XB}(),UU],
null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof VU?new $CLJS.Fc(function(){return VU},$CLJS.od(Tga,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.CR,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),hQ,"metabase/mbql/schema.cljc",51,$CLJS.Ps,1,620,620,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Ps,$CLJS.oG,$CLJS.W($CLJS.Kj,uS),WS,$CLJS.W($CLJS.Kj,uS),mP,$CLJS.W($CLJS.Kj,$CLJS.W($CLJS.Gi,uS))],null),$CLJS.Jc,"Schema for a valid * clause.",$CLJS.n(VU)?VU.H:null])):null));return $CLJS.n(a)?a:hQ}(),VU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof HU?new $CLJS.Fc(function(){return HU},
$CLJS.od(hS,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.CR,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wS,"metabase/mbql/schema.cljc",58,$CLJS.aI,1,579,579,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.aI,eT,$CLJS.W($CLJS.Kj,ZN),dT,$CLJS.W($CLJS.Kj,
ZN),mP,$CLJS.W($CLJS.Kj,$CLJS.W($CLJS.Gi,ZN))],null),$CLJS.Jc,"Schema for a valid coalesce clause.",$CLJS.n(HU)?HU.H:null])):null));return $CLJS.n(a)?a:wS}(),HU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof JU?new $CLJS.Fc(function(){return JU},$CLJS.od(jha,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,
$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.CR,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OR,"metabase/mbql/schema.cljc",56,$CLJS.Iz,1,585,585,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Iz,$CLJS.eA,$CLJS.W($CLJS.Kj,zS)],null),$CLJS.Jc,"Schema for a valid length clause.",$CLJS.n(JU)?JU.H:null])):null));return $CLJS.n(a)?a:OR}(),JU],null),new $CLJS.Q(null,
2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof WU?new $CLJS.Fc(function(){return WU},$CLJS.od(aga,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.CR,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),JQ,"metabase/mbql/schema.cljc",55,$CLJS.WH,1,622,622,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.WH,$CLJS.oG,$CLJS.W($CLJS.Kj,uS)],null),$CLJS.Jc,"Schema for a valid floor clause.",$CLJS.n(WU)?WU.H:null])):null));return $CLJS.n(a)?a:JQ}(),WU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof XU?new $CLJS.Fc(function(){return XU},$CLJS.od(Vfa,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),
$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.CR,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xO,"metabase/mbql/schema.cljc",54,$CLJS.tH,1,625,625,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.tH,$CLJS.oG,$CLJS.W($CLJS.Kj,uS)],null),$CLJS.Jc,"Schema for a valid ceil clause.",$CLJS.n(XU)?
XU.H:null])):null));return $CLJS.n(a)?a:xO}(),XU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof YU?new $CLJS.Fc(function(){return YU},$CLJS.od(iga,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.CR,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,
1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zQ,"metabase/mbql/schema.cljc",55,$CLJS.cI,1,628,628,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.cI,$CLJS.oG,$CLJS.W($CLJS.Kj,uS)],null),$CLJS.Jc,"Schema for a valid round clause.",$CLJS.n(YU)?YU.H:null])):null));return $CLJS.n(a)?a:zQ}(),YU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&
"undefined"!==typeof ZU?new $CLJS.Fc(function(){return ZU},$CLJS.od(Rha,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.CR,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),bR,"metabase/mbql/schema.cljc",53,$CLJS.xH,1,631,631,new $CLJS.Q(null,
3,5,$CLJS.R,[$CLJS.xH,$CLJS.oG,$CLJS.W($CLJS.Kj,uS)],null),$CLJS.Jc,"Schema for a valid abs clause.",$CLJS.n(ZU)?ZU.H:null])):null));return $CLJS.n(a)?a:bR}(),ZU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof $U?new $CLJS.Fc(function(){return $U},$CLJS.od(Eha,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,
IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[sT,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OS,"metabase/mbql/schema.cljc",69,$CLJS.uH,1,634,634,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.uH,$CLJS.oG,$CLJS.W($CLJS.Kj,uS),WS,$CLJS.W($CLJS.Kj,uS)],null),$CLJS.Jc,"Schema for a valid power clause.",$CLJS.n($U)?$U.H:null])):null));return $CLJS.n(a)?a:OS}(),$U],
null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof aV?new $CLJS.Fc(function(){return aV},$CLJS.od(Bga,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[sT,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),dQ,"metabase/mbql/schema.cljc",68,$CLJS.LH,1,637,637,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.LH,$CLJS.oG,$CLJS.W($CLJS.Kj,uS)],null),$CLJS.Jc,"Schema for a valid sqrt clause.",$CLJS.n(aV)?aV.H:null])):null));return $CLJS.n(a)?a:dQ}(),aV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof bV?new $CLJS.Fc(function(){return bV},$CLJS.od(Fha,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),
$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[sT,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BQ,"metabase/mbql/schema.cljc",67,$CLJS.qH,1,640,640,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qH,$CLJS.oG,$CLJS.W($CLJS.Kj,uS)],null),$CLJS.Jc,"Schema for a valid exp clause.",$CLJS.n(bV)?
bV.H:null])):null));return $CLJS.n(a)?a:BQ}(),bV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof cV?new $CLJS.Fc(function(){return cV},$CLJS.od(Ofa,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[sT,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,
1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xT,"metabase/mbql/schema.cljc",67,$CLJS.wI,1,643,643,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.wI,$CLJS.oG,$CLJS.W($CLJS.Kj,uS)],null),$CLJS.Jc,"Schema for a valid log clause.",$CLJS.n(cV)?cV.H:null])):null));return $CLJS.n(a)?a:xT}(),cV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&
"undefined"!==typeof TV?new $CLJS.Fc(function(){return TV},$CLJS.od(jO,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.sH,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TQ,"metabase/mbql/schema.cljc",61,$CLJS.GH,1,909,909,new $CLJS.Q(null,
5,5,$CLJS.R,[$CLJS.GH,$CLJS.Py,$CLJS.W($CLJS.Kj,CP),$CLJS.aj,$CLJS.W($CLJS.Kj,$CLJS.W(LS,lR))],null),$CLJS.Jc,"Schema for a valid case clause.",$CLJS.n(TV)?TV.H:null])):null));return $CLJS.n(a)?a:TQ}(),TV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof dV?new $CLJS.Fc(function(){return dV},$CLJS.od(Rfa,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,
$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.FK,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cR,"metabase/mbql/schema.cljc",65,$CLJS.FK,1,656,656,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.FK,cga,$CLJS.W($CLJS.Kj,IT),dga,$CLJS.W($CLJS.Kj,IT),$CLJS.li,$CLJS.W($CLJS.Kj,Jga)],null),$CLJS.Jc,"Schema for a valid datetime-diff clause.",
$CLJS.n(dV)?dV.H:null])):null));return $CLJS.n(a)?a:cR}(),dV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof eV?new $CLJS.Fc(function(){return eV},$CLJS.od(nha,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.UK,"null"],null),null),$CLJS.od(sS,
new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vR,"metabase/mbql/schema.cljc",71,$CLJS.UK,1,661,661,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.UK,$CLJS.JK,$CLJS.W($CLJS.Kj,IT),$CLJS.li,$CLJS.W($CLJS.Kj,Kha),$CLJS.si,$CLJS.W($CLJS.Kj,$CLJS.W(LS,QT))],null),$CLJS.Jc,"Schema for a valid temporal-extract clause.",$CLJS.n(eV)?eV.H:null])):null));return $CLJS.n(a)?a:vR}(),eV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=
MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof fV?new $CLJS.Fc(function(){return fV},$CLJS.od(uga,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,NS,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.UK,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),UO,"metabase/mbql/schema.cljc",71,$CLJS.BK,1,667,667,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.BK,$CLJS.eR,$CLJS.W($CLJS.Kj,IT)],null),$CLJS.Jc,"Schema for a valid get-year clause.",$CLJS.n(fV)?fV.H:null])):null));return $CLJS.n(a)?a:UO}(),fV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof gV?new $CLJS.Fc(function(){return gV},$CLJS.od(jga,new $CLJS.h(null,1,[$CLJS.Vj,!0],
null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,NS,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.UK,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tR,"metabase/mbql/schema.cljc",74,$CLJS.YK,1,670,670,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.YK,$CLJS.eR,$CLJS.W($CLJS.Kj,IT)],null),$CLJS.Jc,"Schema for a valid get-quarter clause.",
$CLJS.n(gV)?gV.H:null])):null));return $CLJS.n(a)?a:tR}(),gV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof hV?new $CLJS.Fc(function(){return hV},$CLJS.od(Qga,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,NS,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.UK,"null"],null),null),
$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QR,"metabase/mbql/schema.cljc",72,$CLJS.uK,1,673,673,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uK,$CLJS.eR,$CLJS.W($CLJS.Kj,IT)],null),$CLJS.Jc,"Schema for a valid get-month clause.",$CLJS.n(hV)?hV.H:null])):null));return $CLJS.n(a)?a:QR}(),hV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==
typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof iV?new $CLJS.Fc(function(){return iV},$CLJS.od(Jha,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,NS,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.UK,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PR,"metabase/mbql/schema.cljc",
71,$CLJS.OK,1,676,676,!0,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.OK,$CLJS.eR,$CLJS.W($CLJS.Kj,IT),$CLJS.si,$CLJS.W($CLJS.Kj,$CLJS.W(LS,QT))],null),$CLJS.Jc,"Schema for a valid get-week clause.",$CLJS.n(iV)?iV.H:null])):null));return $CLJS.n(a)?a:PR}(),iV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof jV?new $CLJS.Fc(function(){return jV},$CLJS.od(Ffa,new $CLJS.h(null,1,[$CLJS.Vj,
!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,NS,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.UK,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IR,"metabase/mbql/schema.cljc",70,$CLJS.NK,1,680,680,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.NK,$CLJS.eR,$CLJS.W($CLJS.Kj,IT)],null),$CLJS.Jc,"Schema for a valid get-day clause.",
$CLJS.n(jV)?jV.H:null])):null));return $CLJS.n(a)?a:IR}(),jV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof kV?new $CLJS.Fc(function(){return kV},$CLJS.od(Kga,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,NS,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.UK,"null"],null),null),
$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zR,"metabase/mbql/schema.cljc",78,$CLJS.SK,1,683,683,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.SK,$CLJS.eR,$CLJS.W($CLJS.Kj,IT)],null),$CLJS.Jc,"Schema for a valid get-day-of-week clause.",$CLJS.n(kV)?kV.H:null])):null));return $CLJS.n(a)?a:zR}(),kV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==
typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof lV?new $CLJS.Fc(function(){return lV},$CLJS.od(Iga,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,NS,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.UK,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$N,"metabase/mbql/schema.cljc",
71,$CLJS.VK,1,686,686,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.VK,$CLJS.JK,$CLJS.W($CLJS.Kj,IT)],null),$CLJS.Jc,"Schema for a valid get-hour clause.",$CLJS.n(lV)?lV.H:null])):null));return $CLJS.n(a)?a:$N}(),lV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof mV?new $CLJS.Fc(function(){return mV},$CLJS.od(rha,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,
$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,NS,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.UK,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BT,"metabase/mbql/schema.cljc",73,$CLJS.RK,1,689,689,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.RK,$CLJS.JK,$CLJS.W($CLJS.Kj,IT)],null),$CLJS.Jc,"Schema for a valid get-minute clause.",$CLJS.n(mV)?mV.H:null])):null));
return $CLJS.n(a)?a:BT}(),mV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof nV?new $CLJS.Fc(function(){return nV},$CLJS.od(Fga,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,NS,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.UK,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VQ,"metabase/mbql/schema.cljc",73,$CLJS.AK,1,692,692,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.AK,$CLJS.JK,$CLJS.W($CLJS.Kj,IT)],null),$CLJS.Jc,"Schema for a valid get-second clause.",$CLJS.n(nV)?nV.H:null])):null));return $CLJS.n(a)?a:VQ}(),nV],null)])));
$CLJS.Y(pO,FN($CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof IU?new $CLJS.Fc(function(){return IU},$CLJS.od(hha,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.CR,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),ZR,"metabase/mbql/schema.cljc",59,$CLJS.rH,1,582,582,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.rH,$CLJS.eA,$CLJS.W($CLJS.Kj,zS),$CLJS.ax,$CLJS.W($CLJS.Kj,fga),$CLJS.Iz,$CLJS.W($CLJS.Kj,$CLJS.W(LS,uS))],null),$CLJS.Jc,"Schema for a valid substring clause.",$CLJS.n(IU)?IU.H:null])):null));return $CLJS.n(a)?a:ZR}(),IU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof KU?new $CLJS.Fc(function(){return KU},
$CLJS.od(Hha,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.CR,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vS,"metabase/mbql/schema.cljc",54,$CLJS.pI,1,588,588,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.pI,$CLJS.eA,$CLJS.W($CLJS.Kj,zS)],
null),$CLJS.Jc,"Schema for a valid trim clause.",$CLJS.n(KU)?KU.H:null])):null));return $CLJS.n(a)?a:vS}(),KU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof MU?new $CLJS.Fc(function(){return MU},$CLJS.od(Lga,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,
1,[$CLJS.CR,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eS,"metabase/mbql/schema.cljc",55,$CLJS.rI,1,594,594,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rI,$CLJS.eA,$CLJS.W($CLJS.Kj,zS)],null),$CLJS.Jc,"Schema for a valid ltrim clause.",$CLJS.n(MU)?MU.H:null])):null));return $CLJS.n(a)?a:eS}(),MU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&
"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof LU?new $CLJS.Fc(function(){return LU},$CLJS.od(iha,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.CR,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gR,"metabase/mbql/schema.cljc",
55,$CLJS.yI,1,591,591,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.yI,$CLJS.eA,$CLJS.W($CLJS.Kj,zS)],null),$CLJS.Jc,"Schema for a valid rtrim clause.",$CLJS.n(LU)?LU.H:null])):null));return $CLJS.n(a)?a:gR}(),LU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof PU?new $CLJS.Fc(function(){return PU},$CLJS.od(Afa,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,
$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.CR,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gT,"metabase/mbql/schema.cljc",57,$CLJS.xI,1,603,603,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.xI,$CLJS.eA,$CLJS.W($CLJS.Kj,zS),Mfa,$CLJS.W($CLJS.Kj,$CLJS.Tj),$CLJS.dC,$CLJS.W($CLJS.Kj,$CLJS.Tj)],null),$CLJS.Jc,"Schema for a valid replace clause.",
$CLJS.n(PU)?PU.H:null])):null));return $CLJS.n(a)?a:gT}(),PU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof OU?new $CLJS.Fc(function(){return OU},$CLJS.od(lha,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.CR,"null"],null),null),$CLJS.od(sS,
new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LT,"metabase/mbql/schema.cljc",55,$CLJS.Su,1,600,600,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Su,$CLJS.eA,$CLJS.W($CLJS.Kj,zS)],null),$CLJS.Jc,"Schema for a valid lower clause.",$CLJS.n(OU)?OU.H:null])):null));return $CLJS.n(a)?a:LT}(),OU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==
typeof JN&&"undefined"!==typeof NU?new $CLJS.Fc(function(){return NU},$CLJS.od(Iha,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.CR,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IS,"metabase/mbql/schema.cljc",55,$CLJS.Ku,1,597,597,
new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ku,$CLJS.eA,$CLJS.W($CLJS.Kj,zS)],null),$CLJS.Jc,"Schema for a valid upper clause.",$CLJS.n(NU)?NU.H:null])):null));return $CLJS.n(a)?a:IS}(),NU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof QU?new $CLJS.Fc(function(){return QU},$CLJS.od($ga,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,
$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.CR,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VP,"metabase/mbql/schema.cljc",56,$CLJS.wH,1,606,606,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.wH,eT,$CLJS.W($CLJS.Kj,zS),dT,$CLJS.W($CLJS.Kj,zS),mP,$CLJS.W($CLJS.Kj,$CLJS.W($CLJS.Gi,zS))],null),$CLJS.Jc,"Schema for a valid concat clause.",$CLJS.n(QU)?
QU.H:null])):null));return $CLJS.n(a)?a:VP}(),QU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof RU?new $CLJS.Fc(function(){return RU},$CLJS.od(gha,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.CR,"null",$CLJS.Rba,"null"],null),null),
$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SO,"metabase/mbql/schema.cljc",74,$CLJS.$H,1,609,609,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.$H,$CLJS.eA,$CLJS.W($CLJS.Kj,zS),$CLJS.cC,$CLJS.W($CLJS.Kj,$CLJS.Tj)],null),$CLJS.Jc,"Schema for a valid regex-match-first clause.",$CLJS.n(RU)?RU.H:null])):null));return $CLJS.n(a)?a:SO}(),RU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==
typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof HU?new $CLJS.Fc(function(){return HU},$CLJS.od(hS,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.CR,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
wS,"metabase/mbql/schema.cljc",58,$CLJS.aI,1,579,579,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.aI,eT,$CLJS.W($CLJS.Kj,ZN),dT,$CLJS.W($CLJS.Kj,ZN),mP,$CLJS.W($CLJS.Kj,$CLJS.W($CLJS.Gi,ZN))],null),$CLJS.Jc,"Schema for a valid coalesce clause.",$CLJS.n(HU)?HU.H:null])):null));return $CLJS.n(a)?a:wS}(),HU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof TV?new $CLJS.Fc(function(){return TV},
$CLJS.od(jO,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.sH,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TQ,"metabase/mbql/schema.cljc",61,$CLJS.GH,1,909,909,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.GH,$CLJS.Py,$CLJS.W($CLJS.Kj,CP),$CLJS.aj,
$CLJS.W($CLJS.Kj,$CLJS.W(LS,lR))],null),$CLJS.Jc,"Schema for a valid case clause.",$CLJS.n(TV)?TV.H:null])):null));return $CLJS.n(a)?a:TQ}(),TV],null)])));
$CLJS.UV=new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.qt,new $CLJS.h(null,2,[$CLJS.wu,":field or :expression reference or expression",$CLJS.Ii,function(a){return $CLJS.n(DN(vU,a))?JT:$CLJS.n(DN(sU,a))?$CLJS.Tj:$CLJS.n(DN(wU,a))?$CLJS.zt:$CLJS.n(DN(yU,a))?$CLJS.JK:$CLJS.n(DN($CLJS.GH,a))?$CLJS.GH:$CLJS.Mu}],null),new $CLJS.Q(null,2,5,$CLJS.R,[JT,zU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Tj,tU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.zt,AU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.JK,$CLJS.BU],null),
new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.GH,TV],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Mu,$CLJS.pU],null)],null);$CLJS.Y(pT,EN($CLJS.Kz,$CLJS.G(["field",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ft,$CLJS.pU],null)])));var VV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,pT],null);$CLJS.Y(dR,EN($CLJS.ZH,$CLJS.G(["field",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ft,$CLJS.pU],null)])));var WV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,dR],null);$CLJS.Y(nO,EN($CLJS.SH,$CLJS.G(["field-or-expression",$CLJS.UV])));
var XV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,nO],null);$CLJS.Y(hT,EN($CLJS.vI,$CLJS.G(["field-or-expression",$CLJS.UV])));var YV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,hT],null);$CLJS.Y(NP,EN($CLJS.CH,$CLJS.G(["field-or-expression",$CLJS.UV])));var ZV=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,NP],null);$CLJS.Y(xS,EN($CLJS.kI,$CLJS.G(["field-or-expression",$CLJS.UV])));var $V=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,xS],null);$CLJS.Y(IP,EN($CLJS.Qn,$CLJS.G(["field-or-expression",$CLJS.UV])));
var aW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,IP],null);$CLJS.Y(uP,EN($CLJS.dk,$CLJS.G(["field-or-expression",$CLJS.UV])));var bW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,uP],null);$CLJS.Y(oP,EN($CLJS.tI,$CLJS.G(["field-or-expression",$CLJS.UV,"pred",$CLJS.tV])));var cW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,oP],null);$CLJS.Y(FP,EN($CLJS.gI,$CLJS.G(["pred",$CLJS.tV])));var dW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,FP],null);$CLJS.Y(oS,EN($CLJS.eI,$CLJS.G(["pred",$CLJS.tV])));
var eW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,oS],null);$CLJS.Y(LO,EN($CLJS.oI,$CLJS.G(["field-or-expression",$CLJS.UV])));var fW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,LO],null);$CLJS.Y(LN,EN($CLJS.DH,$CLJS.G(["field-or-expression",$CLJS.UV])));var gW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,LN],null);$CLJS.Y(RP,EN($CLJS.lI,$CLJS.G(["field-or-expression",$CLJS.UV])));var hW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,RP],null);$CLJS.Y(EQ,EN($CLJS.JH,$CLJS.G(["field-or-expression",$CLJS.UV,"percentile",DU])));
var iW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,EQ],null);$CLJS.Y(rS,EN($CLJS.TH,$CLJS.G(["metric-id",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.uM],null),RT],null)])));var jW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,rS],null);
$CLJS.Y(nS,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.qt,new $CLJS.h(null,2,[$CLJS.wu,"unnamed aggregation clause or numeric expression",$CLJS.Ii,function(a){return $CLJS.n(DN(vU,a))?HQ:$CLJS.Mu}],null),new $CLJS.Q(null,2,5,$CLJS.R,[HQ,zU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Mu,FN($CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof XV?new $CLJS.Fc(function(){return XV},$CLJS.od(fha,
new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.sH,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QQ,"metabase/mbql/schema.cljc",60,$CLJS.SH,1,957,957,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.SH,JS,$CLJS.W($CLJS.Kj,BS)],null),$CLJS.Jc,"Schema for a valid avg clause.",
$CLJS.n(XV)?XV.H:null])):null));return $CLJS.n(a)?a:QQ}(),XV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof YV?new $CLJS.Fc(function(){return YV},$CLJS.od(qga,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.sH,"null"],null),null),$CLJS.od(sS,
new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VS,"metabase/mbql/schema.cljc",64,$CLJS.vI,1,958,958,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vI,JS,$CLJS.W($CLJS.Kj,BS)],null),$CLJS.Jc,"Schema for a valid cum-sum clause.",$CLJS.n(YV)?YV.H:null])):null));return $CLJS.n(a)?a:VS}(),YV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==
typeof JN&&"undefined"!==typeof ZV?new $CLJS.Fc(function(){return ZV},$CLJS.od(hga,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.sH,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UQ,"metabase/mbql/schema.cljc",65,$CLJS.CH,1,959,959,
new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.CH,JS,$CLJS.W($CLJS.Kj,BS)],null),$CLJS.Jc,"Schema for a valid distinct clause.",$CLJS.n(ZV)?ZV.H:null])):null));return $CLJS.n(a)?a:UQ}(),ZV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof fW?new $CLJS.Fc(function(){return fW},$CLJS.od(Bha,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,
$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.hI,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gO,"metabase/mbql/schema.cljc",76,$CLJS.oI,1,973,973,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.oI,JS,$CLJS.W($CLJS.Kj,BS)],null),$CLJS.Jc,"Schema for a valid stddev clause.",$CLJS.n(fW)?fW.H:null])):null));return $CLJS.n(a)?a:gO}(),fW],null),new $CLJS.Q(null,
2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof $V?new $CLJS.Fc(function(){return $V},$CLJS.od(Vga,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.sH,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),DO,"metabase/mbql/schema.cljc",60,$CLJS.kI,1,960,960,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kI,JS,$CLJS.W($CLJS.Kj,BS)],null),$CLJS.Jc,"Schema for a valid sum clause.",$CLJS.n($V)?$V.H:null])):null));return $CLJS.n(a)?a:DO}(),$V],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof aW?new $CLJS.Fc(function(){return aW},$CLJS.od(Jfa,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,
$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.sH,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Ut,"metabase/mbql/schema.cljc",60,$CLJS.Qn,1,961,961,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Qn,JS,$CLJS.W($CLJS.Kj,BS)],null),$CLJS.Jc,"Schema for a valid min clause.",$CLJS.n(aW)?aW.H:
null])):null));return $CLJS.n(a)?a:$CLJS.Ut}(),aW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof bW?new $CLJS.Fc(function(){return bW},$CLJS.od(Efa,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.sH,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,
1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Zt,"metabase/mbql/schema.cljc",60,$CLJS.dk,1,962,962,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dk,JS,$CLJS.W($CLJS.Kj,BS)],null),$CLJS.Jc,"Schema for a valid max clause.",$CLJS.n(bW)?bW.H:null])):null));return $CLJS.n(a)?a:$CLJS.Zt}(),bW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&
"undefined"!==typeof jW?new $CLJS.Fc(function(){return jW},$CLJS.od(ega,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UP,"metabase/mbql/schema.cljc",18,$CLJS.TH,1,994,994,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.TH,rga,$CLJS.W($CLJS.Kj,new $CLJS.Q(null,3,5,
$CLJS.R,[$CLJS.lt,vga,yO],null))],null),$CLJS.Jc,"Schema for a valid metric clause.",$CLJS.n(jW)?jW.H:null])):null));return $CLJS.n(a)?a:UP}(),jW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof eW?new $CLJS.Fc(function(){return eW},$CLJS.od(Dga,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,
$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.sH,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MP,"metabase/mbql/schema.cljc",62,$CLJS.eI,1,970,970,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.eI,$CLJS.io,$CLJS.W($CLJS.Kj,CQ)],null),$CLJS.Jc,"Schema for a valid share clause.",$CLJS.n(eW)?eW.H:null])):null));return $CLJS.n(a)?a:MP}(),eW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=
MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof dW?new $CLJS.Fc(function(){return dW},$CLJS.od(Kfa,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.sH,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),zO,"metabase/mbql/schema.cljc",68,$CLJS.gI,1,967,967,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gI,$CLJS.io,$CLJS.W($CLJS.Kj,CQ)],null),$CLJS.Jc,"Schema for a valid count-where clause.",$CLJS.n(dW)?dW.H:null])):null));return $CLJS.n(a)?a:zO}(),dW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof cW?new $CLJS.Fc(function(){return cW},$CLJS.od(Pha,new $CLJS.h(null,1,[$CLJS.Vj,!0],
null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.sH,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RQ,"metabase/mbql/schema.cljc",66,$CLJS.tI,1,964,964,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.tI,JS,$CLJS.W($CLJS.Kj,BS),$CLJS.io,$CLJS.W($CLJS.Kj,CQ)],null),$CLJS.Jc,"Schema for a valid sum-where clause.",
$CLJS.n(cW)?cW.H:null])):null));return $CLJS.n(a)?a:RQ}(),cW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof TV?new $CLJS.Fc(function(){return TV},$CLJS.od(jO,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.sH,"null"],null),null),$CLJS.od(sS,
new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TQ,"metabase/mbql/schema.cljc",61,$CLJS.GH,1,909,909,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.GH,$CLJS.Py,$CLJS.W($CLJS.Kj,CP),$CLJS.aj,$CLJS.W($CLJS.Kj,$CLJS.W(LS,lR))],null),$CLJS.Jc,"Schema for a valid case clause.",$CLJS.n(TV)?TV.H:null])):null));return $CLJS.n(a)?a:TQ}(),TV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&
"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof hW?new $CLJS.Fc(function(){return hW},$CLJS.od(uha,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.UH,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TS,"metabase/mbql/schema.cljc",
68,$CLJS.lI,1,979,979,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lI,JS,$CLJS.W($CLJS.Kj,BS)],null),$CLJS.Jc,"Schema for a valid median clause.",$CLJS.n(hW)?hW.H:null])):null));return $CLJS.n(a)?a:TS}(),hW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof iW?new $CLJS.Fc(function(){return iW},$CLJS.od(Pga,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,
MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.UH,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qS,"metabase/mbql/schema.cljc",72,$CLJS.JH,1,982,982,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.JH,JS,$CLJS.W($CLJS.Kj,BS),$CLJS.JH,$CLJS.W($CLJS.Kj,uS)],null),$CLJS.Jc,"Schema for a valid percentile clause.",$CLJS.n(iW)?iW.H:null])):
null));return $CLJS.n(a)?a:qS}(),iW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof gW?new $CLJS.Fc(function(){return gW},$CLJS.od(Ufa,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),bO,"metabase/mbql/schema.cljc",77,$CLJS.DH,1,976,976,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.DH,JS,$CLJS.W($CLJS.Kj,BS)],null),$CLJS.Jc,"Schema for a valid var clause.",$CLJS.n(gW)?gW.H:null])):null));return $CLJS.n(a)?a:bO}(),gW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof WV?new $CLJS.Fc(function(){return WV},$CLJS.od(Gfa,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,
$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,NS,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.sH,"null"],null),null),$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DQ,"metabase/mbql/schema.cljc",74,$CLJS.ZH,1,947,947,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ZH,$CLJS.YH,$CLJS.W($CLJS.Kj,$CLJS.W(LS,iP))],null),$CLJS.Jc,"Schema for a valid cum-count clause.",
$CLJS.n(WV)?WV.H:null])):null));return $CLJS.n(a)?a:DQ}(),WV],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof VV?new $CLJS.Fc(function(){return VV},$CLJS.od(gga,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([KQ,$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,NS,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.sH,"null"],null),null),
$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.qi,"metabase/mbql/schema.cljc",70,$CLJS.Kz,1,946,946,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Kz,$CLJS.YH,$CLJS.W($CLJS.Kj,$CLJS.W(LS,iP))],null),$CLJS.Jc,"Schema for a valid count clause.",$CLJS.n(VV)?VV.H:null])):null));return $CLJS.n(a)?a:$CLJS.qi}(),VV],null)]))],null)],null));
$CLJS.Y(FR,EN($CLJS.XN,$CLJS.G(["aggregation",nS,"options",new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ck,new $CLJS.h(null,1,[$CLJS.wu,":aggregation-options options"],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),RT],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.cG,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),RT],null)],null)])));
$CLJS.Y(KS,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.qt,new $CLJS.h(null,2,[$CLJS.wu,"aggregation clause or numeric expression",$CLJS.Ii,function(a){return $CLJS.n(DN($CLJS.XN,a))?$CLJS.XN:BP}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.XN,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,FR],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[BP,nS],null)],null));$CLJS.Y(fO,EN($CLJS.VH,$CLJS.G(["field",rU])));var kW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,fO],null);$CLJS.Y(GQ,EN($CLJS.OH,$CLJS.G(["field",rU])));
lW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,GQ],null);
eia=FN($CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof kW?new $CLJS.Fc(function(){return kW},$CLJS.od(yha,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
mQ,"metabase/mbql/schema.cljc",15,$CLJS.VH,1,1049,1049,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.VH,$CLJS.YH,$CLJS.W($CLJS.Kj,cT)],null),$CLJS.Jc,"Schema for a valid asc clause.",$CLJS.n(kW)?kW.H:null])):null));return $CLJS.n(a)?a:mQ}(),kW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof lW?new $CLJS.Fc(function(){return lW},$CLJS.od(pga,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([$CLJS.Qi,
$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iS,"metabase/mbql/schema.cljc",16,$CLJS.OH,1,1050,1050,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.OH,$CLJS.YH,$CLJS.W($CLJS.Kj,cT)],null),$CLJS.Jc,"Schema for a valid desc clause.",$CLJS.n(lW)?lW.H:null])):null));return $CLJS.n(a)?a:iS}(),lW],null)]));
mW=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hj,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.ht,$CLJS.iO,$CLJS.ZO,$CLJS.MN,$CLJS.fv,$CLJS.mD,$CLJS.eR],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,RT],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cG,RT],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ti,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),RT],null)],null);
fia=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,mW,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,$CLJS.iO],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.VN,RT],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.wR,UT],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.xR,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),UT],null)],null)],null);
gia=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,mW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,$CLJS.ZO],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.GS,UT],null)],null)],null);
nW=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,mW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wh,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.ei],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.AP,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.zt],null)],null)],null);hia=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,US],null);
iia=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,nW,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,$CLJS.MN],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.MN,oU],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.tP,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,CO],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aj,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Pi,$CLJS.Ri,$CLJS.ei],null)],null)],null)],null);
$CLJS.oW=new $CLJS.Tg(null,new $CLJS.h(null,4,[$CLJS.eR,null,$CLJS.fv,null,$CLJS.zt,null,$CLJS.mD,null],null),null);jia=$CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.ht],null),$CLJS.oW);
$CLJS.Y(bS,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.qt,new $CLJS.h(null,1,[$CLJS.Ii,$CLJS.hj],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.MN,iia],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.iO,fia],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ZO,gia],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ro,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,nW,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hj,jia],null)],null)],null)],null)],null));
var pW=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.jS,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Pi,RT,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,bS],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,1,[$CLJS.wu,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Ie(function(b){var c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);return $CLJS.F.g(c,
$CLJS.T.h(b))},a)}],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.PP,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,RT],null)],null)],null),kia=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,pW,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kO,$CLJS.ei],null)],null)],null),qW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,JR],null),rW=new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.qt,new $CLJS.h(null,1,[$CLJS.Ii,function(a){return $CLJS.n($CLJS.qN($CLJS.wd,
$CLJS.MR)(a))?$CLJS.MR:$CLJS.bG}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.MR,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,pW,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.MR,$CLJS.ei],null)],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bG,qW],null)],null),sW=new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,RT],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.WE,ST],null),new $CLJS.Q(null,2,5,$CLJS.R,[Xga,RT],null),new $CLJS.Q(null,3,5,$CLJS.R,
[$CLJS.gQ,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,TT],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vP,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,$CLJS.ck],null)],null)],null),tW=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,Xha,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,new $CLJS.h(null,1,[$CLJS.wu,"'card__\x3cid\x3e' string Table ID"],null),/^card__[1-9]\d*$/],null)],null),lia=$CLJS.Wf.g(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.ht],null),
new $CLJS.Tg(null,new $CLJS.h(null,4,[$CLJS.YG,null,$CLJS.GG,null,$CLJS.RG,null,$CLJS.VG,null],null),null)),uW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,SS],null);
$CLJS.Y(LP,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rt,new $CLJS.Q(null,9,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.IQ,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),tW],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lS,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),rW],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.tT,$CLJS.tV],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.sG,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),lia],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.HG,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,
4,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.wu,"Valid join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,$CLJS.pA,$CLJS.YB],null),uW],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.LG,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),RT],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.FM,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,WT],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uQ,new $CLJS.h(null,
1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uj,sW],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,1,[$CLJS.wu,"Joins must have either a `source-table` or `source-query`, but not both."],null),$CLJS.qN($CLJS.rC($CLJS.IQ,$CLJS.lS),$CLJS.Ke($CLJS.qN($CLJS.IQ,$CLJS.lS)))],null)],null));var mia=$CLJS.R,vW;var wW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,LP],null)],null);
if($CLJS.vd(wW)&&$CLJS.F.g($CLJS.B(wW),$CLJS.uj)){var xW=$CLJS.z(wW);$CLJS.B(xW);var yW=$CLJS.C(xW),nia=$CLJS.wd($CLJS.B(yW))?yW:$CLJS.ce(null,yW),zW=$CLJS.z(nia),oia=$CLJS.B(zW),pia=$CLJS.C(zW);vW=$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uj,$CLJS.S.j(oia,$CLJS.Qn,1)],null),pia)}else vW=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rt,wW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,1,[$CLJS.wu,"non-empty"],null),$CLJS.z],null)],null);
$CLJS.Y(NO,new $CLJS.Q(null,3,5,mia,[$CLJS.rt,vW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,1,[$CLJS.wu,"All join aliases must be unique."],null),function(a){return $CLJS.oN($CLJS.bf($CLJS.Ta,$CLJS.Ze.g($CLJS.LG,a)))}],null)],null));var qia=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,NO],null);
$CLJS.Y(SS,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.hl,new $CLJS.h(null,1,[$CLJS.wu,"Distinct, non-empty sequence of Field clauses"],null),GN(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uj,new $CLJS.h(null,1,[$CLJS.Qn,1],null),$CLJS.pU],null))],null));
$CLJS.Y(JR,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.rt,new $CLJS.Q(null,13,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lS,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),rW],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.IQ,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),tW],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.NH,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uj,new $CLJS.h(null,1,[$CLJS.Qn,1],null),$CLJS.CU],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.MH,new $CLJS.h(null,1,
[$CLJS.Ft,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uj,new $CLJS.h(null,1,[$CLJS.Qn,1],null),$CLJS.pU],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.CR,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Pi,RT,$CLJS.UV],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.HG,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),uW],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.BH,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.tV],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.OT,new $CLJS.h(null,
1,[$CLJS.Ft,!0],null),VT],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rT,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),GN(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uj,new $CLJS.h(null,1,[$CLJS.Qn,1],null),eia],null))],null),new $CLJS.Q(null,3,5,$CLJS.R,[NR,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[NR,UT],null),new $CLJS.Q(null,2,5,$CLJS.R,[Zfa,UT],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gP,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),qia],
null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uQ,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uj,sW],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,1,[$CLJS.wu,"Query must specify either `:source-table` or `:source-query`, but not both."],null),function(a){return $CLJS.F.g(1,$CLJS.E($CLJS.Wl(a,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.lS,$CLJS.IQ],null))))}],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,
new $CLJS.h(null,1,[$CLJS.wu,"Fields specified in `:breakout` should not be specified in `:fields`; this is implied."],null),function(a){var b=$CLJS.O(a);a=$CLJS.I.g(b,$CLJS.MH);b=$CLJS.I.g(b,$CLJS.HG);return $CLJS.sd($CLJS.zv.g($CLJS.Yg(a),$CLJS.Yg(b)))}],null)],null));
$CLJS.AW=$CLJS.Ag([$CLJS.sI,$CLJS.eR,HP,sP,vQ,FT,bQ,$CLJS.ET,$CLJS.fv,aQ,qO,yS,uT,$CLJS.GO,qT,EP,$CLJS.AQ,ZP,$CLJS.Ti,$CLJS.YP,YN,$CLJS.cP,$CLJS.XP,$Q,$CLJS.zt,$CLJS.mD,TP],[new $CLJS.h(null,1,[lQ,new $CLJS.Tg(null,new $CLJS.h(null,5,[$CLJS.sI,null,$CLJS.eR,null,$CLJS.fv,null,$CLJS.zt,null,$CLJS.mD,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.hj,$CLJS.eR,lQ,new $CLJS.Tg(null,new $CLJS.h(null,5,[$CLJS.sI,null,$CLJS.eR,null,$CLJS.ET,null,$CLJS.AQ,null,$CLJS.Ti,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.hj,$CLJS.Tj,$CLJS.kG,HS,lQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[HP,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.hj,JT,$CLJS.kG,HS,lQ,new $CLJS.Tg(null,new $CLJS.h(null,5,[$CLJS.sI,null,sP,null,$CLJS.fv,null,$CLJS.Ti,null,YN,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.hj,$CLJS.Tj,$CLJS.kG,uO,lQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[vQ,null],null),null)],null),new $CLJS.h(null,1,[lQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[FT,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.hj,
JT,$CLJS.kG,oha,lQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[bQ,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.hj,$CLJS.eR,lQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.ET,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.hj,JT,lQ,new $CLJS.Tg(null,new $CLJS.h(null,5,[$CLJS.sI,null,sP,null,$CLJS.fv,null,$CLJS.Ti,null,YN,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.hj,JT,$CLJS.kG,uO,lQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[aQ,null],null),null)],null),new $CLJS.h(null,1,[lQ,new $CLJS.Tg(null,
new $CLJS.h(null,1,[qO,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.hj,$CLJS.Tj,$CLJS.kG,HS,lQ,new $CLJS.Tg(null,new $CLJS.h(null,8,[$CLJS.sI,null,FT,null,qO,null,yS,null,ZP,null,$CLJS.Ti,null,YN,null,$CLJS.mD,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.hj,$CLJS.Tj,$CLJS.kG,uO,lQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[uT,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.hj,$CLJS.eR,lQ,new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.ET,null,$CLJS.GO,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.hj,$CLJS.Tj,$CLJS.kG,uO,lQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[qT,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.hj,$CLJS.Tj,$CLJS.kG,uO,lQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[EP,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.hj,$CLJS.eR,lQ,new $CLJS.Tg(null,new $CLJS.h(null,5,[$CLJS.sI,null,$CLJS.eR,null,$CLJS.ET,null,$CLJS.AQ,null,$CLJS.Ti,null],null),null)],null),new $CLJS.h(null,1,[lQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[ZP,null],null),null)],null),new $CLJS.h(null,1,[lQ,
new $CLJS.Tg(null,new $CLJS.h(null,1,[$CLJS.Ti,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.hj,$CLJS.eR,lQ,new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.ET,null,$CLJS.YP,null],null),null)],null),new $CLJS.h(null,1,[lQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[YN,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.hj,$CLJS.eR,lQ,new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.ET,null,$CLJS.cP,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.hj,$CLJS.eR,lQ,new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.ET,
null,$CLJS.XP,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.hj,JT,$CLJS.kG,HS,lQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[$Q,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.hj,$CLJS.zt,lQ,new $CLJS.Tg(null,new $CLJS.h(null,3,[$CLJS.sI,null,$CLJS.Ti,null,$CLJS.zt,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.hj,$CLJS.Tj,lQ,new $CLJS.Tg(null,new $CLJS.h(null,8,[$CLJS.sI,null,FT,null,qO,null,yS,null,ZP,null,$CLJS.Ti,null,YN,null,$CLJS.mD,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.hj,
JT,$CLJS.kG,uO,lQ,new $CLJS.Tg(null,new $CLJS.h(null,1,[TP,null],null),null)],null)]);$CLJS.Y(US,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"valid parameter type"],null)],null),$CLJS.Pg($CLJS.AW)));$CLJS.Y(CO,$CLJS.Wf.g(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,1,[$CLJS.wu,"valid template tag widget type"],null),$CLJS.YB],null),$CLJS.Pg($CLJS.AW)));
$CLJS.Y(gS,EN(Qfa,$CLJS.G(["tag-name",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,RT,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ti,RT],null)],null)],null)])));var BW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,gS],null);$CLJS.Y(aO,EN($CLJS.MN,$CLJS.G(["target",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,$CLJS.pU,BW],null)])));var CW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,aO],null);$CLJS.Y(XO,EN(WP,$CLJS.G(["target",BW])));
var DW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,XO],null),ria=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,$CLJS.pU,FN($CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof CW?new $CLJS.Fc(function(){return CW},$CLJS.od(Nga,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[$CLJS.od(sS,new $CLJS.h(null,
1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RR,"metabase/mbql/schema.cljc",21,$CLJS.MN,1,1612,1612,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.MN,$CLJS.aL,$CLJS.W($CLJS.Kj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,iP,kQ],null))],null),$CLJS.Jc,"Schema for a valid dimension clause.",$CLJS.n(CW)?CW.H:null])):null));return $CLJS.n(a)?a:RR}(),CW],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=MQ.h($CLJS.pd("undefined"!==typeof $CLJS.HN&&
"undefined"!==typeof IN&&"undefined"!==typeof JN&&"undefined"!==typeof DW?new $CLJS.Fc(function(){return DW},$CLJS.od(cha,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),$CLJS.Ag([$CLJS.Qi,$CLJS.T,$CLJS.ik,$CLJS.oi,MQ,$CLJS.Mi,$CLJS.ij,$CLJS.qk,IO,$CLJS.ni,$CLJS.U,$CLJS.hk],[$CLJS.od(sS,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KT,"metabase/mbql/schema.cljc",20,WP,1,1615,1615,new $CLJS.Q(null,3,5,$CLJS.R,[WP,$CLJS.aL,
$CLJS.W($CLJS.Kj,kQ)],null),$CLJS.Jc,"Schema for a valid variable clause.",$CLJS.n(DW)?DW.H:null])):null));return $CLJS.n(a)?a:KT}(),DW],null)]))],null),AN=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,JO],null);
$CLJS.Y(JO,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rt,function(a){return new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.rt,a,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,1,[$CLJS.wu,"Query must specify either `:native` or `:query`, but not both."],null),$CLJS.qN($CLJS.rC($CLJS.MR,$CLJS.kO),$CLJS.Ke($CLJS.qN($CLJS.MR,$CLJS.kO)))],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,1,[$CLJS.wu,"Native queries must specify `:native`; MBQL queries must specify `:query`."],null),function(b){var c=
$CLJS.O(b);b=$CLJS.I.g(c,$CLJS.MR);var d=$CLJS.I.g(c,$CLJS.kO);c=$CLJS.I.g(c,$CLJS.hj);c=c instanceof $CLJS.K?c.T:null;switch(c){case "native":return b;case "query":return d;default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}}],null)],null)}(new $CLJS.Q(null,10,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xR,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.wu,"valid Database ID"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.JM],null),new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.X,$CLJS.EM],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,$CLJS.kO,$CLJS.MR],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.MR,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),kia],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kO,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),qW],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.YO,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,
8,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hj,hia],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ti,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),RT],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aL,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),ria],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.yj,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.ei],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),RT],null),new $CLJS.Q(null,3,5,$CLJS.R,[Wfa,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),
RT],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wh,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.ei],null)],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.HM,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,3,5,$CLJS.R,[Nha,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),dU],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Mga,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,
new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,3,5,$CLJS.R,[SQ,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),VT],null),new $CLJS.Q(null,3,5,$CLJS.R,[BO,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),VT],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,1,[$CLJS.wu,"max-results-bare-rows must be less or equal to than max-results"],null),function(a){var b=$CLJS.O(a);a=$CLJS.I.g(b,SQ);b=$CLJS.I.g(b,BO);return $CLJS.Sa($CLJS.n(a)?b:a)?!0:a>=b}],null)],
null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Cga,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,3,5,$CLJS.R,[xha,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.zt],null),new $CLJS.Q(null,3,5,$CLJS.R,[Sfa,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.zt],null),new $CLJS.Q(null,3,5,$CLJS.R,[Bfa,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.zt],null),new $CLJS.Q(null,3,5,$CLJS.R,[Yfa,new $CLJS.h(null,1,[$CLJS.Ft,
!0],null),$CLJS.zt],null),new $CLJS.Q(null,3,5,$CLJS.R,[pha,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,$CLJS.zt],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Dfa,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,$CLJS.zt],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Dha,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,$CLJS.zt],null)],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zA,new $CLJS.h(null,
1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.TO,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,15,5,$CLJS.R,[$CLJS.ht,bha,Zga,$CLJS.PP,Oga,dha,Aga,Nfa,Tfa,bga,oga,mha,Vha,sga,kga],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[sha,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,UT],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[xga,
new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,UT],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.GS,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,Wha],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Oha,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,RT],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[zga,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,UT],null)],null),new $CLJS.Q(null,
3,5,$CLJS.R,[Mha,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Pi,$CLJS.ei,$CLJS.ei],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[tga,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,UT],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.SP,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Pi,$CLJS.ei,
$CLJS.ei],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.sM,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,$CLJS.ei],null)],null)],null)],null)],null)],null)),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,1,[$CLJS.wu,"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)"],null),$CLJS.Ke($CLJS.uQ)],null)],null));var EW=$CLJS.zN(AN);
(function(){var a=wfa();return function(b){if($CLJS.n(EW.h?EW.h(b):EW.call(null,b)))return b;b=a.h?a.h(b):a.call(null,b);var c=$CLJS.wN(b);throw $CLJS.ai($CLJS.YF("Invalid query: {0}",$CLJS.G([$CLJS.Eh.l($CLJS.G([c]))])),new $CLJS.h(null,2,[$CLJS.vA,c,Hga,b],null));}})();