var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var AG,FG,ada,bda,cda,dda,eda,fda;
$CLJS.yG=function(){function a(){return Math.floor(16*Math.random()).toString(16)}var b=(8|3&Math.floor(16*Math.random())).toString(16);return new $CLJS.$B([$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-4",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(b),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),
$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a())].join("").toLowerCase())};$CLJS.zG=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return $CLJS.YF(arguments[0],1<b.length?new $CLJS.y(b.slice(1),0,null):null)};AG=function(a){return $CLJS.yd(a)&&$CLJS.B(a)instanceof $CLJS.K};
$CLJS.CG=function(a){var b=function(){var c=AG(a);return c?(c=$CLJS.wd($CLJS.hd(a)))?(c=$CLJS.zi.h($CLJS.hd(a)),$CLJS.n(c)?c:$CLJS.iD.h($CLJS.hd(a))):c:c}();return $CLJS.n(b)?b:$CLJS.BG.h(a)};
FG=function(a,b){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,2,[$CLJS.wu,"valid MBQL clause",$CLJS.yu,function(c){c=$CLJS.O(c);c=$CLJS.I.g(c,$CLJS.yj);return["invalid MBQL clause: ",$CLJS.Eh.l($CLJS.G([c]))].join("")}],null),$CLJS.Ke(AG)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.DG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,1,[$CLJS.wu,b],null),function(c){c=$CLJS.CG(c);
return EG(c,a)}],null)],null)};$CLJS.GG=new $CLJS.K(null,"right-join","right-join",-56349359);ada=new $CLJS.K("metabase.lib.schema.expression","datetime","metabase.lib.schema.expression/datetime",-51960022);bda=new $CLJS.K("metabase.lib.schema.expression","base-type","metabase.lib.schema.expression/base-type",578343967);cda=new $CLJS.K("metabase.lib.schema.expression","non-integer-real","metabase.lib.schema.expression/non-integer-real",-1855491016);$CLJS.HG=new $CLJS.K(null,"fields","fields",-1932066230);
$CLJS.IG=new $CLJS.K(null,"expr","expr",745722291);$CLJS.JG=new $CLJS.K("metabase.lib.schema.expression","expressions","metabase.lib.schema.expression/expressions",790922225);$CLJS.KG=new $CLJS.K("metabase.lib.schema.expression","temporal","metabase.lib.schema.expression/temporal",320116754);$CLJS.LG=new $CLJS.K(null,"alias","alias",-2039751630);$CLJS.MG=new $CLJS.K("metabase.lib.schema.expression","boolean","metabase.lib.schema.expression/boolean",-1396506592);
$CLJS.NG=new $CLJS.K("metabase.lib.schema.expression","expression","metabase.lib.schema.expression/expression",-1389098704);$CLJS.OG=new $CLJS.K("metabase.lib.schema.expression","type.unknown","metabase.lib.schema.expression/type.unknown",-98159107);$CLJS.PG=new $CLJS.K("lib","expression-name","lib/expression-name",-1799326590);$CLJS.QG=new $CLJS.K("mbql.clause","field","mbql.clause/field",1497292735);dda=new $CLJS.K("metabase.lib.schema.expression","date","metabase.lib.schema.expression/date",1943847782);
$CLJS.RG=new $CLJS.K(null,"left-join","left-join",-672831855);$CLJS.SG=new $CLJS.K("metabase.lib.schema.expression","equality-comparable","metabase.lib.schema.expression/equality-comparable",-658449046);$CLJS.TG=new $CLJS.K("lib.type-of","type-is-type-of-first-arg","lib.type-of/type-is-type-of-first-arg",-317600808);$CLJS.UG=new $CLJS.K("metabase.lib.schema.expression","orderable","metabase.lib.schema.expression/orderable",-1555566130);
eda=new $CLJS.w("metabase.lib.schema.expression","type-of","metabase.lib.schema.expression/type-of",-1625245814,null);fda=new $CLJS.K("metabase.lib.schema.expression","time","metabase.lib.schema.expression/time",865466446);$CLJS.VG=new $CLJS.K(null,"inner-join","inner-join",659431740);$CLJS.WG=new $CLJS.K("metabase.lib.schema.expression","emptyable","metabase.lib.schema.expression/emptyable",941775581);
$CLJS.DG=new $CLJS.K("metabase.lib.schema.mbql-clause","clause","metabase.lib.schema.mbql-clause/clause",955279388);$CLJS.XG=new $CLJS.K("metabase.lib.schema.expression","string","metabase.lib.schema.expression/string",1750008170);$CLJS.YG=new $CLJS.K(null,"full-join","full-join",1305476385);$CLJS.ZG=new $CLJS.K("metabase.lib.schema.expression","number","metabase.lib.schema.expression/number",779948930);
$CLJS.$G=new $CLJS.K("metabase.lib.schema.expression","integer","metabase.lib.schema.expression/integer",2060430870);$CLJS.BG=function(){var a=$CLJS.Oe($CLJS.N),b=$CLJS.Oe($CLJS.N),c=$CLJS.Oe($CLJS.N),d=$CLJS.Oe($CLJS.N),e=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.cj,$CLJS.XD],null),$CLJS.cj,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));return new $CLJS.Xh($CLJS.xh.g("metabase.lib.schema.expression","type-of-method"),function(f){var k=$CLJS.VD(f);return $CLJS.F.g(k,$CLJS.hD)?$CLJS.Xa(f):k},e,a,b,c,d)}();
$CLJS.Y(bda,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,$CLJS.OG],null),$CLJS.qG],null));$CLJS.BG.m(null,$CLJS.Wh,function(a){throw $CLJS.ai($CLJS.YF("{0}: Don''t know how to determine the type of {1}",$CLJS.G([eda,$CLJS.Eh.l($CLJS.G([a]))])),new $CLJS.h(null,1,[$CLJS.IG,a],null));});$CLJS.BG.m(null,$CLJS.TG,function(a){$CLJS.H(a,0,null);$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.CG(a)});
var EG=function EG(a,b){return $CLJS.ud(a)?$CLJS.Je(function(d){return EG.g?EG.g(d,b):EG.call(null,d,b)},a):$CLJS.ud(b)?$CLJS.Je(function(d){return EG.g?EG.g(a,d):EG.call(null,a,d)},b):$CLJS.F.g(a,$CLJS.OG)?!0:$CLJS.iE(a,b)};$CLJS.Y($CLJS.MG,FG($CLJS.Fj,"expression returning a boolean"));$CLJS.Y($CLJS.XG,FG($CLJS.Yj,"expression returning a string"));$CLJS.Y($CLJS.$G,FG($CLJS.rj,"expression returning an integer"));$CLJS.Y(cda,FG($CLJS.EF,"expression returning a non-integer real number"));
$CLJS.Y($CLJS.ZG,FG($CLJS.Jj,"expression returning a number"));$CLJS.Y(dda,FG($CLJS.JE,"expression returning a date"));$CLJS.Y(fda,FG($CLJS.HF,"expression returning a time"));$CLJS.Y(ada,FG($CLJS.hF,"expression returning a date time"));$CLJS.Y($CLJS.KG,FG($CLJS.Qj,"expression returning a date, time, or date time"));$CLJS.aH=new $CLJS.Tg(null,new $CLJS.h(null,3,[$CLJS.Jj,null,$CLJS.Yj,null,$CLJS.Qj,null],null),null);$CLJS.Y($CLJS.UG,FG($CLJS.aH,"an expression that can be compared with :\x3e or :\x3c"));
var gda=new $CLJS.Tg(null,new $CLJS.h(null,7,[$CLJS.sE,null,$CLJS.Jj,null,$CLJS.Yj,null,$CLJS.ZE,null,$CLJS.Qj,null,$CLJS.Fj,null,$CLJS.CF,null],null),null);$CLJS.Y($CLJS.WG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.XG],null),FG($CLJS.ZE,"expression returning a BSONID")],null));$CLJS.Y($CLJS.SG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,FG(gda,"an expression that can appear in :\x3d or :!\x3d")],null));
$CLJS.Y($CLJS.NG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,FG($CLJS.vj,"any type of expression")],null));
$CLJS.Y($CLJS.JG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uj,new $CLJS.h(null,1,[$CLJS.Qn,1],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.NG],null),new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Ys,$CLJS.ei,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.PG,$CLJS.Tj],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ps,$CLJS.ei],null)],null)],null)],null));