var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var vK,xK,zK,LK,PK,XK;$CLJS.uK=new $CLJS.K(null,"get-month","get-month",-369374731);vK=new $CLJS.K("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);$CLJS.wK=new $CLJS.K(null,"convert-timezone","convert-timezone",-124803001);xK=new $CLJS.K("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
$CLJS.yK=new $CLJS.K(null,"iso","iso",-1366207543);zK=new $CLJS.K("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.AK=new $CLJS.K(null,"get-second","get-second",-2065946318);$CLJS.BK=new $CLJS.K(null,"get-year","get-year",-936011274);$CLJS.CK=new $CLJS.K(null,"absolute-datetime","absolute-datetime",-560340465);
$CLJS.DK=new $CLJS.K("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);$CLJS.EK=new $CLJS.K(null,"week-of-year-iso","week-of-year-iso",-1827744293);$CLJS.FK=new $CLJS.K(null,"datetime-diff","datetime-diff",1133112155);$CLJS.GK=new $CLJS.K(null,"interval","interval",1708495417);$CLJS.HK=new $CLJS.K(null,"us","us",746429226);$CLJS.IK=new $CLJS.K(null,"relative-datetime","relative-datetime",1285169278);
$CLJS.JK=new $CLJS.K(null,"datetime","datetime",494675702);$CLJS.KK=new $CLJS.K(null,"now","now",-1650525531);LK=new $CLJS.K("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);$CLJS.MK=new $CLJS.K(null,"instance","instance",-2121349050);$CLJS.NK=new $CLJS.K(null,"get-day","get-day",127568857);$CLJS.OK=new $CLJS.K(null,"get-week","get-week",-888059349);
PK=new $CLJS.K("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);$CLJS.QK=new $CLJS.K(null,"datetime-add","datetime-add",209603411);$CLJS.RK=new $CLJS.K(null,"get-minute","get-minute",1468086343);$CLJS.SK=new $CLJS.K(null,"get-day-of-week","get-day-of-week",2010140752);$CLJS.TK=new $CLJS.K("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);
$CLJS.UK=new $CLJS.K(null,"temporal-extract","temporal-extract",1781686405);$CLJS.VK=new $CLJS.K(null,"get-hour","get-hour",622714059);$CLJS.WK=new $CLJS.K(null,"datetime-subtract","datetime-subtract",275307697);XK=new $CLJS.K("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.YK=new $CLJS.K(null,"get-quarter","get-quarter",1327778593);$CLJS.ZK=new $CLJS.K(null,"week-of-year-us","week-of-year-us",849423511);
$CLJS.$K=new $CLJS.K(null,"week-of-year-instance","week-of-year-instance",2047974624);$CLJS.aL=new $CLJS.K(null,"target","target",253001721);$CLJS.nH($CLJS.GK,$CLJS.G([$CLJS.mu,$CLJS.UE,$CLJS.ri,$CLJS.TI]));$CLJS.BG.m(null,$CLJS.DK,function(a){$CLJS.H(a,0,null);$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);a=$CLJS.CG(a);return $CLJS.ud(a)?(a=$CLJS.zv.g(a,new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.JE,null,$CLJS.hF,null],null),null)),$CLJS.F.g($CLJS.E(a),1)?$CLJS.B(a):a):a});$CLJS.gH($CLJS.DK,$CLJS.TG);
for(var bL=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.QK,$CLJS.WK],null)),cL=null,dL=0,eL=0;;)if(eL<dL){var fL=cL.X(null,eL);$CLJS.nH(fL,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.KG],null),$CLJS.ri,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.TI],null)]));$CLJS.gH(fL,$CLJS.DK);eL+=1}else{var gL=$CLJS.z(bL);if(gL){var hL=gL;if($CLJS.zd(hL)){var iL=$CLJS.kc(hL),Eea=$CLJS.lc(hL),Fea=iL,Gea=$CLJS.E(iL);bL=Eea;cL=Fea;dL=Gea}else{var jL=$CLJS.B(hL);$CLJS.nH(jL,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,
[$CLJS.X,$CLJS.KG],null),$CLJS.ri,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.TI],null)]));$CLJS.gH(jL,$CLJS.DK);bL=$CLJS.C(hL);cL=null;dL=0}eL=0}else break}
for(var kL=$CLJS.z(new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.BK,$CLJS.uK,$CLJS.NK,$CLJS.VK,$CLJS.RK,$CLJS.AK,$CLJS.YK],null)),lL=null,mL=0,nL=0;;)if(nL<mL){var Hea=lL.X(null,nL);$CLJS.nH(Hea,$CLJS.G([$CLJS.mu,$CLJS.rj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.KG],null)],null)]));nL+=1}else{var oL=$CLJS.z(kL);if(oL){var pL=oL;if($CLJS.zd(pL)){var qL=$CLJS.kc(pL),Iea=$CLJS.lc(pL),Jea=qL,Kea=$CLJS.E(qL);kL=Iea;lL=Jea;mL=Kea}else{var Lea=$CLJS.B(pL);$CLJS.nH(Lea,$CLJS.G([$CLJS.mu,
$CLJS.rj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.KG],null)],null)]));kL=$CLJS.C(pL);lL=null;mL=0}nL=0}else break}$CLJS.nH($CLJS.FK,$CLJS.G([$CLJS.mu,$CLJS.rj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.KG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.KG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.QI],null)]));
for(var rL=$CLJS.z(new $CLJS.Tg(null,new $CLJS.h(null,8,[$CLJS.YK,null,$CLJS.RK,null,$CLJS.VK,null,$CLJS.SK,null,$CLJS.AK,null,$CLJS.uK,null,$CLJS.BK,null,$CLJS.NK,null],null),null)),sL=null,tL=0,uL=0;;)if(uL<tL){var Mea=sL.X(null,uL);$CLJS.nH(Mea,$CLJS.G([$CLJS.mu,$CLJS.rj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.KG],null)],null)]));uL+=1}else{var vL=$CLJS.z(rL);if(vL){var wL=vL;if($CLJS.zd(wL)){var xL=$CLJS.kc(wL),Nea=$CLJS.lc(wL),Oea=xL,Pea=$CLJS.E(xL);
rL=Nea;sL=Oea;tL=Pea}else{var Qea=$CLJS.B(wL);$CLJS.nH(Qea,$CLJS.G([$CLJS.mu,$CLJS.rj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.KG],null)],null)]));rL=$CLJS.C(wL);sL=null;tL=0}uL=0}else break}$CLJS.Y(XK,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ht,$CLJS.yK,$CLJS.HK,$CLJS.MK],null));
$CLJS.pH($CLJS.OK,$CLJS.G([$CLJS.mu,$CLJS.rj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.JK,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.KG],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.si,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qs,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,XK],null)],null)],null)],null)]));
$CLJS.Y($CLJS.TK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rt,$CLJS.hG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ht,new $CLJS.h(null,2,[$CLJS.wu,"valid timezone ID",$CLJS.yu,function(a){a=$CLJS.O(a);a=$CLJS.I.g(a,$CLJS.yj);return["invalid timezone ID: ",$CLJS.Eh.l($CLJS.G([a]))].join("")}],null)],null),$CLJS.KI($CLJS.Hd,$CLJS.tK.tz.names())),$CLJS.fK],null)],null));
$CLJS.pH($CLJS.wK,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.JK,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.KG],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.aL,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.TK],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.TB,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qs,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.TK],null)],null)],null)],
null)]));$CLJS.gH($CLJS.wK,$CLJS.DK);$CLJS.nH($CLJS.KK,$CLJS.G([$CLJS.mu,$CLJS.ME]));$CLJS.Y(zK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.qG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,1,[$CLJS.wu,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Je(function(b){return $CLJS.iE(a,b)},new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.JE,$CLJS.hF],null))}],null)],null));
$CLJS.Y(vK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vu,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.mG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.iD,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,zK],null)],null)],null)],null));
$CLJS.zI.g($CLJS.CK,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Ys,new $CLJS.h(null,1,[$CLJS.wu,"valid :absolute-datetime clause"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,$CLJS.CK],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,vK],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.$s,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Ys,new $CLJS.h(null,1,[$CLJS.wu,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,
4,5,$CLJS.R,[$CLJS.lt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.jK],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.lK],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.dK],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,$CLJS.Wh],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.SI],null)],null)],null)],null),new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Ys,new $CLJS.h(null,1,[$CLJS.wu,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,$CLJS.vx],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.nK],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,$CLJS.Wh],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.NI],null)],null)],null)],null)],null)],null));
$CLJS.BG.m(null,$CLJS.CK,function(a){$CLJS.H(a,0,null);$CLJS.H(a,1,null);var b=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);a=$CLJS.F.g(b,$CLJS.vx)?$CLJS.F.g(a,$CLJS.Wh)?$CLJS.hF:$CLJS.n($CLJS.fH($CLJS.SI,a))?$CLJS.JE:$CLJS.hF:null;if($CLJS.n(a))return a;a="string"===typeof b?$CLJS.n($CLJS.fh($CLJS.rK,b))?$CLJS.JE:$CLJS.n($CLJS.fh($CLJS.sK,b))?$CLJS.JE:null:null;if($CLJS.n(a))return a;b=$CLJS.CG(b);b=$CLJS.ud(b)?$CLJS.Wf.j($CLJS.Ug,$CLJS.af(function(c){return $CLJS.iE(c,$CLJS.Qj)}),b):b;return $CLJS.ud(b)&&
$CLJS.F.g($CLJS.E(b),1)?$CLJS.B(b):b});$CLJS.Y(PK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,$CLJS.vx],null),$CLJS.ri],null));
$CLJS.pH($CLJS.IK,$CLJS.G([$CLJS.mu,$CLJS.hF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.My,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,PK],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.li,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qs,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.TI],null)],null)],null)],null)]));
$CLJS.nH($CLJS.yA,$CLJS.G([$CLJS.mu,$CLJS.HF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.XG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.RI],null)]));$CLJS.Y(xK,new $CLJS.Q(null,12,5,$CLJS.R,[$CLJS.ht,$CLJS.PI,$CLJS.ui,$CLJS.fk,$CLJS.EK,$CLJS.ZK,$CLJS.$K,$CLJS.Aj,$CLJS.Ai,$CLJS.pi,$CLJS.dj,$CLJS.MI],null));$CLJS.Y(LK,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ht,$CLJS.yK,$CLJS.HK,$CLJS.MK],null));
$CLJS.pH($CLJS.UK,$CLJS.G([$CLJS.mu,$CLJS.rj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.JK,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.KG],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.li,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,xK],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.si,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qs,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,LK],null)],null)],
null)],null)]));