var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var $ca,fG,iG,tG;$CLJS.XF=function(a){return $CLJS.Hk.g($CLJS.Ze.h(a),$CLJS.hE)};$CLJS.YF=function(a,b){a=$CLJS.dv($CLJS.vC(a,/''/,"'"),/\{\d+\}/);return $CLJS.P.j($CLJS.Bu.t,$CLJS.bl(a),$CLJS.bl(b))};$CLJS.ZF=new $CLJS.K("lib","metadata","lib/metadata",1798917220);$CLJS.$F=new $CLJS.K("metabase.lib.schema.common","positive-int","metabase.lib.schema.common/positive-int",-733946956);$CLJS.aG=new $CLJS.K(null,"num-bins","num-bins",847672055);$CLJS.bG=new $CLJS.K(null,"mbql","mbql",69346710);
$CLJS.cG=new $CLJS.K(null,"display-name","display-name",694513143);$ca=new $CLJS.K("metabase.lib.schema.common","external-op","metabase.lib.schema.common/external-op",1918684841);$CLJS.dG=new $CLJS.K("metabase.lib.schema.metadata","column","metabase.lib.schema.metadata/column",-570344650);$CLJS.eG=new $CLJS.K("metabase.lib.schema.common","semantic-or-relation-type","metabase.lib.schema.common/semantic-or-relation-type",-158604052);
fG=new $CLJS.K("metabase.lib.schema.common","relation-type","metabase.lib.schema.common/relation-type",1412287664);$CLJS.gG=new $CLJS.K("lib","external-op","lib/external-op",-1470923877);$CLJS.hG=new $CLJS.K("metabase.lib.schema.common","non-blank-string","metabase.lib.schema.common/non-blank-string",-2032366619);iG=new $CLJS.K("metabase.lib.schema.common","semantic-type","metabase.lib.schema.common/semantic-type",-1623124128);$CLJS.jG=new $CLJS.K(null,"database-type","database-type",-426840562);
$CLJS.kG=new $CLJS.K(null,"operator","operator",-1860875338);$CLJS.lG=new $CLJS.K("metabase.lib.schema.common","int-greater-than-or-equal-to-zero","metabase.lib.schema.common/int-greater-than-or-equal-to-zero",-1646435681);$CLJS.mG=new $CLJS.K("metabase.lib.schema.common","options","metabase.lib.schema.common/options",-1078359294);$CLJS.nG=new $CLJS.K("lib","uuid","lib/uuid",-2145250720);$CLJS.oG=new $CLJS.K(null,"x","x",2099068185);
$CLJS.pG=new $CLJS.K("metabase.lib.schema.id","field","metabase.lib.schema.id/field",-985236431);$CLJS.qG=new $CLJS.K("metabase.lib.schema.common","base-type","metabase.lib.schema.common/base-type",999524206);$CLJS.rG=new $CLJS.K(null,"bin-width","bin-width",1377922579);$CLJS.sG=new $CLJS.K(null,"strategy","strategy",-1471631918);tG=new $CLJS.K("metabase.lib.schema.common","uuid","metabase.lib.schema.common/uuid",1172938098);$CLJS.Y($CLJS.hG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Tj,new $CLJS.h(null,1,[$CLJS.Qn,1],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,1,[$CLJS.wu,"non-blank string"],null),$CLJS.Ke($CLJS.wC)],null)],null));$CLJS.Y($CLJS.lG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ri,new $CLJS.h(null,1,[$CLJS.Qn,0],null)],null));$CLJS.Y($CLJS.$F,$CLJS.Gl);$CLJS.Y(tG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Tj,new $CLJS.h(null,2,[$CLJS.Qn,36,$CLJS.dk,36],null)],null));
$CLJS.Y(iG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,2,[$CLJS.wu,"valid semantic type",$CLJS.yu,function(a){a=$CLJS.O(a);a=$CLJS.I.g(a,$CLJS.yj);return["Not a valid semantic type: ",$CLJS.Eh.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.iE(a,$CLJS.nj)}],null));
$CLJS.Y(fG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,2,[$CLJS.wu,"valid relation type",$CLJS.yu,function(a){a=$CLJS.O(a);a=$CLJS.I.g(a,$CLJS.yj);return["Not a valid relation type: ",$CLJS.Eh.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.iE(a,$CLJS.gi)}],null));$CLJS.Y($CLJS.eG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,iG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,fG],null)],null));
$CLJS.Y($CLJS.qG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,2,[$CLJS.wu,"valid base type",$CLJS.yu,function(a){a=$CLJS.O(a);a=$CLJS.I.g(a,$CLJS.yj);return["Not a valid base type: ",$CLJS.Eh.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.iE(a,$CLJS.vj)}],null));
$CLJS.Y($CLJS.mG,new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.nG,tG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.iD,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,$CLJS.qG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,$CLJS.qG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Pj,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,
$CLJS.eG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.jG,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,$CLJS.hG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,$CLJS.hG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.cG,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kt,$CLJS.hG],null)],null)],null));
$CLJS.Y($ca,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.ck,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ov,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ls,$CLJS.gG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lt,$CLJS.Tj,$CLJS.Ri],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Tt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uj,$CLJS.ei],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aj,new $CLJS.h(null,1,[$CLJS.Ft,!0],null),$CLJS.mG],null)],null));